import { Card } from "@mui/material";
import TitleWithDate from "../partial/TitleWithDate";
import AvatarProgressItem from "../partial/AvatarProgressItem";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { dateFormatter } from "../../../utils/formatter";
import { getApi } from "../../../store/apis";

const PsychologicalUpgrade = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const [psychological, setPsychological] = useState({});

  useEffect(() => {
    getPsychological();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPsychological = (date = dateFormatter(new Date())) => {
    let config = {
      path: `admin/board-result/psychological?date=${date}`,
    };
    dispatch(getApi(config))
      .then((res) => {
        (res === null || res === undefined) && (res = {});
        setPsychological(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card className="pa-3 rounded-lg">
      <TitleWithDate title="Psychological Upgrade" subTitle="Jumlah pengguna Psychological Upgrade" onDone={(value) => getPsychological(dateFormatter(value.value))} />
      <div className="mt-4" style={{ minHeight: "10rem" }}>
        {psychological?.collection?.map((item, index) => {
          return <AvatarProgressItem key={index} title={item.judul} border={0} colorBorder={theme.act_pink} colorBar={theme.act_lbl_pink} value={item.jumlah} total={psychological.total} />;
        })}
      </div>
    </Card>
  );
};

export default PsychologicalUpgrade;

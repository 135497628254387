import React, { useState } from "react";
import { Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import day from "dayjs";
import { DialogDelete } from "../../../components/base/dialogDelete";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { postApi } from "../../../store/apis";

const CompanyList = ({ theme, dataSet, ava, refetch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [dialog, setDialog] = useState(false);
  const [pocket, setPocket] = useState(null);
  const [dataSetList, setDataList] = useState(dataSet);

  const onChangeStatus = (account) => {
    let { id, status } = account;
    status = status === 1 ? 0 : 1;
    const data = {
      path: "admin/company/switch",
      body: {
        id: id,
        status,
      },
    };

    dispatch(postApi(data))
      .then((data) => {
        const variant = "success";
        let listData = [...dataSetList];
        const index = listData.findIndex((el) => el.id === id);
        listData[index] = { ...listData[index], status };
        setDataList(listData);
        enqueueSnackbar(data.message ?? "Status berhasil diubah", { variant });
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
      });
  };

  const deleteData = (item) => {
    setPocket({
      path: "admin/company/delete",
      body: {
        id: item.id,
      },
    });
    setDialog(true);
  };

  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell
                style={{
                  minWidth: "50px",
                  background: theme.tertiary,
                  borderRadius: "10px 0 0 10px",
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  ID
                </p>
              </TableCell>

              <TableCell style={{ minWidth: "200px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Company/Institution
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "130px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tgl. Registrasi
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Email
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Nomor Hp
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Jenis
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Status
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "100px",
                  background: theme.tertiary,
                  borderRadius: "0 10px 10px 0",
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tindakan
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSetList.map((item, i) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.id}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.nama}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{day(item.created_at).format("DD/MM/YY | HH:mm")}</p>
                </TableCell>

                <TableCell className="py-2">
                  <p className="px11_txt">{item.email}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.no_kontak}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.jenis}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <div onClick={() => onChangeStatus(item)} className="d-flex align-center justify-center">
                    <Icon path={item.status === 1 ? mdiToggleSwitch : mdiToggleSwitchOffOutline} size={1.8} color={theme.primary} className="cursor_pointer" />
                  </div>
                </TableCell>
                <TableCell className="py-2">
                  <div className="d-flex">
                    <Button
                      variant="contained"
                      className="rounded-xl capitalize mr-3"
                      onClick={() => navigate(`/company/detail/${item.id}`)}
                      disableElevation
                      size="small"
                      style={{ background: theme.act_blue }}>
                      <p className="font-weight-bold px11_txt" style={{ color: theme.act_lbl_blue }}>
                        Detail
                      </p>
                    </Button>
                    <Button variant="contained" className="rounded-xl capitalize" onClick={() => deleteData(item)} disableElevation size="small" style={{ background: theme.act_red }}>
                      <p className="font-weight-bold px11_txt" style={{ color: theme.act_lbl_red }}>
                        Delete
                      </p>
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pa-2">
        <DialogDelete d_del={dialog} close={() => setDialog(false)} pocket={pocket} refetch={refetch} />
      </div>
    </div>
  );
};

export default CompanyList;

/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, Button, Avatar } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SkeletonTable from "../../../components/base/SkeletonTable";
import { getApi } from "../../../store/apis";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { AddCircle, ArrowDropDown, GetApp, IosShare } from "@mui/icons-material";
import SearchDataTable from "../../../components/base/searchDataTable";
import EmptyData from "../../../components/base/EmptyData";
import GiveUserVcr from "./giveUserVcr";
import day from "dayjs";
import { DialogDelete } from "../../../components/base/dialogDelete";
import GiveManyUserVcr from "./giveManyUserVcr";
const VcrListUser = ({ id }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [dataSet, setData] = useState([]);
  const [find, setFind] = useState("");
  const [state, setState] = useState({
    page: 1,
    limit: 10,
    last_page: null,
    total: null,
  });
  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `admin/voucher/pelanggan?id=${id}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const env = process.env.REACT_APP_ENV;
  const exportData = () => {
    window.location = `${env}/admin/voucher/pelanggan/export?id=${id}`;
  };

  const [dialog, setDialog] = useState({
    manual: false,
    import: false,
    delete: false,
  });

  const listLimit = [10, 50, 100];
  const handlePage = (event, value) => {
    setState({ ...state, page: value });
  };
  const dummyAva = require(`../../../assets/img/ava/ava1.png`);
  const [pocket, setPocket] = useState(null);
  const deleteData = (item) => {
    setPocket({
      path: "admin/voucher/pelanggan/delete",
      body: {
        id: item.id,
      },
    });
    setDialog({ ...dialog, delete: true });
  };

  useEffect(() => {
    fetchData();
  }, [find]);

  return (
    <Paper className="pa-5 rounded-lg">
      <Grid container spacing={2} className="mb-5">
        <Grid item xs={3}>
          <h3>List User</h3>
        </Grid>
        <Grid item xs={9}>
          <div className="d-flex align-center justify-end">
            <Button className="capitalize rounded-lg mr-3" variant="contained" onClick={() => setDialog({ ...dialog, manual: true })}>
              <AddCircle className="mr-1" />
              <p className="font-weight-bold">Tambah User</p>
            </Button>
            <Button className="capitalize rounded-lg mr-3" variant="contained" onClick={() => setDialog({ ...dialog, import: true })}>
              <IosShare className="mr-1" />
              <p className="font-weight-bold">Import User</p>
            </Button>
            <Button className="capitalize rounded-lg" variant="contained" onClick={exportData}>
              <GetApp className="mr-1" />
              <p className="font-weight-bold">Download</p>
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="d-flex align-center">
            <p className="small_txt font-weight-bold mr-2">Tampilkan</p>
            <Menu
              menuClassName="my_menu"
              menuButton={
                <Paper className="px-3 py-2 rounded-lg d-flex align-center mr-5 cursor_pointer pb-2">
                  <p className="small_txt font-weight-bold mr-3">{state.limit} Data</p>
                  <ArrowDropDown />
                </Paper>
              }
              transition>
              {listLimit.map((lm, i) => (
                <MenuItem key={`limit-${i}`} onClick={() => setState({ ...state, limit: lm })}>
                  <p className="font-weight-bold txt_table">{lm} Data</p>
                </MenuItem>
              ))}
            </Menu>
            <SearchDataTable value={find} setValue={setFind} />
          </div>
        </Grid>
      </Grid>

      {!loading ? (
        !dataSet.length ? (
          <EmptyData />
        ) : (
          <TableContainer component="div">
            <Table aria-label="simple table" style={{ borderBottom: "none" }}>
              <TableHead>
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell
                    style={{
                      minWidth: "10px",
                      background: theme.tertiary,
                      borderRadius: "10px 0 0 10px",
                    }}
                    className="py-2">
                    <p className="font-weight-bold" style={{ color: theme.primary }}>
                      No
                    </p>
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: "50px",
                      background: theme.tertiary,
                    }}
                    className="py-2">
                    <p className="font-weight-bold" style={{ color: theme.primary }}>
                      User
                    </p>
                  </TableCell>
                  <TableCell
                    style={{
                      background: theme.tertiary,
                    }}
                    className="py-1">
                    <p className="font-weight-bold" style={{ color: theme.primary }}>
                      Claimed
                    </p>
                  </TableCell>
                  <TableCell
                    style={{
                      background: theme.tertiary,
                    }}
                    className="py-1">
                    <p className="font-weight-bold" style={{ color: theme.primary }}>
                      Reedem
                    </p>
                  </TableCell>
                  <TableCell
                    style={{
                      background: theme.tertiary,
                      borderRadius: "0 10px 10px 0",
                    }}
                    className="py-1">
                    <p className="font-weight-bold" style={{ color: theme.primary }}>
                      Tindakan
                    </p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSet.map((item, i) => (
                  <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>{i + 1 + (state.page - 1) * state.limit}</TableCell>
                    <TableCell>
                      <div className="d-flex align-center flexy">
                        <Avatar alt={item.data_pelanggan.nama} src={item.data_pelanggan.foto ? item.data_pelanggan.foto_link : dummyAva} className="mr-3" />
                        <div>
                          <p className="small_txt"> {item.data_pelanggan.nama} </p>
                          <p className="txt_table grey-text"> {item.data_pelanggan.email} </p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{day(item.created_at).format("DD/MM/YYYY | HH:mm")}</TableCell>
                    <TableCell>{item.tgl_reedem ? day(item.tgl_reedem).format("DD/MM/YYYY | HH:mm") : ""}</TableCell>
                    <TableCell>
                      <Button variant="contained" className="rounded-xl capitalize" disableElevation size="small" onClick={() => deleteData(item)} style={{ background: theme.act_red }}>
                        <p className="font-weight-bold" style={{ color: theme.act_lbl_red }}>
                          Delete
                        </p>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      ) : (
        <SkeletonTable />
      )}
      {dataSet.length ? (
        <div className="d-flex align-center justify-space-between" style={{ width: "100%" }}>
          <p className="txt_table">
            Menampilkan {state.page} - {state.limit} dari {state.total}{" "}
          </p>
          <div>
            <Pagination sx={{ padding: "0" }} count={state.last_page} page={state.page} shape="rounded" onChange={handlePage} style={{ background: "#fff" }} color="primary" />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="py-3">
        <GiveUserVcr dialog={dialog.manual} close={() => setDialog({ ...dialog, manual: false })} refetch={fetchData} pocket={id} />
        <GiveManyUserVcr dialog={dialog.import} close={() => setDialog({ ...dialog, import: false })} refetch={fetchData} pocket={id} />
        <DialogDelete d_del={dialog.delete} close={() => setDialog({ ...dialog, delete: false })} pocket={pocket} refetch={fetchData} />
      </div>
    </Paper>
  );
};

export default VcrListUser;

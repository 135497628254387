import { Button, Dialog, DialogContent } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

const ModalConfirm = ({ visible = true, icon = <></>, variant = "danger", children, titleBtnNo = "", titleBtnYes = "", onNo, onYes }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const [visibility, setVisibility] = useState(visible);

  const onNoClicked = () => {
    setVisibility(false);
    setTimeout(() => {
      onNo && onNo();
    }, 500);
  };

  const onYesClicked = () => {
    onYes && onYes();
  };

  const getVariant = () => {
    let variants = {};
    switch (variant) {
      case "danger":
        variants = {
          light: theme.act_red,
          dark: theme.act_lbl_red,
        };
        break;
      case "success":
        variants = {
          light: theme.act_green,
          dark: theme.act_lbl_green,
        };
        break;
      case "warning":
        variants = {
          light: theme.act_yellow,
          dark: theme.act_lbl_yellow,
        };
        break;
      default:
        variants = {
          light: theme.act_red,
          dark: theme.act_lbl_red,
        };
        break;
    }

    return variants;
  };

  return (
    <Dialog
      open={visibility}
      onClose={() => onNoClicked()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 10, minWidth: "350px", maxWidth: "470px" },
      }}>
      <DialogContent>
        <div className="d-flex justify-center p-3">
          <div>
            <div className="d-flex justify-center">
              <div className="pa-3 d-flex justify-center align-center" style={{ background: getVariant(variant).light, borderRadius: "50%" }}>
                <div className="pa-2 d-flex justify-center align-center" style={{ background: getVariant(variant).dark, borderRadius: "50%" }}>
                  {icon}
                </div>
              </div>
            </div>
            <div className="text-center mt-4">{children}</div>
            <div className="mt-4 d-flex gap-3 justify-center">
              <Button variant="contained" className="rounded-lg capitalize" onClick={() => onNoClicked()}>
                {titleBtnNo}
              </Button>
              <Button variant="contained" className="rounded-lg capitalize" onClick={() => onYesClicked()}>
                {titleBtnYes}
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalConfirm;

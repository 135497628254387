/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGE_TITLE } from '../../../store/theming'
import JadwalKonselingAdmin from './jadwal/jadwalKonselingAdmin'
import SettingLayananPage from './layanan/settingLayananPage'
import { Button } from '@mui/material'

const KonselingSetting = () => {
    const theme = useSelector(state => state.theme.myTheme)
    const dispatch = useDispatch()
    const [tab, setTab] = useState(1)
    useEffect(() => {
        dispatch(CHANGE_TITLE('Konseling Setting'))
    }, [])
    return (
        <div>
            <div className="d-flex">
                <Button className='capitalize' onClick={() => setTab(1)} style={{ fontWeight: tab == 1 ? 'bold' : '' }}>Setting Jadwal</Button>
                <Button className='capitalize' onClick={() => setTab(2)} style={{ fontWeight: tab == 2 ? 'bold' : '' }}>Setting Layanan</Button>
            </div>
            <div className="line flexy mb-3" style={{ height: '2px', width: '100%', background: theme.primary }}></div>
            {tab == 1 ? <JadwalKonselingAdmin theme={theme} /> : <SettingLayananPage />}
        </div>
    )
}

export default KonselingSetting

import { Avatar, Card } from "@mui/material";

const CardCountWithTitle = ({ src, title = "", subTitle = "", colorTheme = "", sourceReverse = false }) => {
  return (
    <Card className="shadow-none rounded-lg py-2 px-4" style={{ border: `2px solid ${colorTheme}` }}>
      <div className="d-flex justify-center align-center gap-2">
        {!sourceReverse && <Avatar src={src} />}
        <div className="leading-4">
          <span className="px11_txt font-weight-500">{title}</span>
          <h4 style={{ color: colorTheme }}>{subTitle}</h4>
        </div>
        {sourceReverse && <Avatar src={src} />}
      </div>
    </Card>
  );
};

export default CardCountWithTitle;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { Button, Grid, Paper } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getApi } from '../../../../store/apis'
// import { Document, Page, pdfjs } from 'react-pdf';
import EmptyData from '../../../../components/base/EmptyData'
import { useEffect } from 'react'
const TestResultDetail = () => {
    const theme = useSelector(state => state.theme.myTheme)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams();
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        page: 1,
        limit: 1,
    })
    const [emptyTxt, setEmptyTxt] = useState('')
    const [dataSet, setData] = useState(null)
    const fetchData = () => {
        setLoading(true)
        let page = `&page[size]=${state.limit}&page[number]=${state.page}`
        let data = {
            path: `konselor/download-pdf?filter[transaksi_pelanggan.id]=${id}`
        }
        dispatch(getApi(data)).then(data => {
            setLoading(false)
            setData(data[0])
            setState({ ...state, total: data.total, last_page: data.last_page })
        }).catch(err => {
            if (err.status == 400) {
                setEmptyTxt(`No Data Available`)
            } else {
                setEmptyTxt(`${err.status}: ${err.data?.message}`)
            }
            setLoading(false)
            setData([])
        })
    }
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div>
            <Button className='capitalize rounded-xl mb-3' variant='contained' disableElevation onClick={() => navigate(-1)}>Kembali</Button>
            <Paper className='pa-3 rounded-lg' elevation={0}>
                {!loading ? <div><Grid container spacing={2}>
                    <Grid item xs={6}>
                        <h3 className='mb-2'>Nama Layanan</h3>
                        <Grid container spacing={1}>
                            <Grid item xs={5}>
                                <p className='font-weight-bold txt_table' style={{ color: theme.secondary }}>Nama Peserta</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className='txt_table'>Nama Peserta</p>
                            </Grid>
                            <Grid item xs={5}>
                                <p className='font-weight-bold txt_table' style={{ color: theme.secondary }}>Tanggal Checkout</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className='txt_table'>Nama Peserta</p>
                            </Grid>
                            <Grid item xs={5}>
                                <p className='font-weight-bold txt_table' style={{ color: theme.secondary }}>Tes Selesai</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className='txt_table'>Nama Peserta</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="d-flex justify-end">
                            <Button className='capitalize' variant="outlined"><p className="font-weight-bold">Unduh Report</p></Button>
                        </div>
                    </Grid>
                </Grid>
                    <Paper className='pa-3 rounded-lg mt-3' style={{ background: theme.tertiary }} elevation={0}>
                     
                    </Paper></div> : <EmptyData />}
            </Paper>
        </div>
    )
}

export default TestResultDetail

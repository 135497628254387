import React from 'react'
import { useSelector } from 'react-redux';
import styleInput from '../../assets/css/search.module.css'
/** @jsxImportSource @emotion/react */
import { css as emot } from '@emotion/react'

const FlatInput = ({
    value,
    property,
    handleChange,
    handleKeyup = () => { },
    handleBlur = () => { },
    placeholder = "",
    type = 'text'
}) => {
    const theme = useSelector((state) => state.theme.myTheme);

    return (
        <div>
            <input
                placeholder={placeholder}
                onChange={handleChange(property)}
                onKeyUp={handleKeyup}
                onBlur={handleBlur}
                value={value}
                type={type}
                css={emot`
                    background: ${value ? '#fff' : theme.tertiary};
                    outline: 2px solid ${theme.tertiary};
                    border-radius: 5px;
                    &:focus {
                        background: #fff;
                    }`}
                className={styleInput.input_psy}
            />
        </div>
    )
}

export default FlatInput

import { Grid, Card, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../store/theming";
import AllSchedule from "./allSchedule";
import "../../../assets/css/reactCalendar.css";
import DatePickerSchedule from "./datePickerSchedule";
import { dateFormatter } from "../../../utils";
import { getApi, postApi } from "../../../store/apis";
import { useSnackbar } from "notistack";
const ManageScheduleConsultation = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useSelector((state) => state.theme.myTheme);
  const [data, setData] = useState({});
  const [currentMonth, setCurrentMonth] = useState(dateFormatter(new Date()));
  const [datePicker, setDatePicker] = useState([{ date: dateFormatter(new Date()), active: true, topLabel: false, pic: true }]);
  const [selectedHour, setSelectedHour] = useState([]);
  const [dateTimes, setDateTimes] = useState({ [dateFormatter(new Date())]: [] });
  const availHour = ["08.00", "09.00", "10.00", "11.00", "12.00", "13.00", "14.00", "15.00", "16.00", "17.00", "18.00", "19.00", "20.00"];

  useEffect(() => {
    dispatch(CHANGE_TITLE("Setting Jadwal Konsultasi"));
    getSchedule(dateFormatter(new Date()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetData = () => {
    setDatePicker([...[{ date: dateFormatter(new Date()), active: true, topLabel: false, pic: true }]]);
    setSelectedHour([...[]]);
    setDateTimes({ ...{ [dateFormatter(new Date())]: [] } });
  };

  const getSchedule = (date) => {
    let data = {
      path: `konselor/setting-jadwal/v2?date=${date}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setData({ jadwal: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pickHour = (h) => {
    if (selectedHour.includes(h)) {
      let spliced = [...selectedHour];
      let index = spliced.indexOf(h);
      if (index > -1) {
        spliced.splice(index, 1);
      }
      setSelectedHour(spliced);
      setDatesData(spliced);
    } else {
      setSelectedHour([...selectedHour, h]);
      setDatesData([...selectedHour, h]);
    }
  };

  const setDatesData = (times) => {
    let datePic = datePicker.find((x) => x.active === true);
    dateTimes[datePic.date] = times;
  };

  const setLabelToCalendar = (dateFormat) => {
    // Kosongkan data berfungsi utuk mempercepat pemforma seacrh data dalam array
    let dateTimeSchedule = [];
    Object.keys(dateTimes).forEach((item, index) => {
      dateTimes[item].length > 0 && dateTimeSchedule.push(item);
      dateTimes[item].length === 0 && delete dateTimes[item]; // delete data
    });

    let active = dateFormat.find((x) => x.active === true);
    dateFormat = []; // Kosongkan data

    let datas = [];
    datas.push(active);
    dateTimeSchedule.forEach((item, index) => {
      datas.push({
        date: item,
        active: false,
        topLabel: false,
      });
    });

    setDatePicker([...datas]);
  };

  const deleteTimeEmpty = () => {
    Object.keys(dateTimes).forEach((item, index) => {
      dateTimes[item].length === 0 && delete dateTimes[item]; // delete data
    });
  };

  const onSelectedDate = (date) => {
    setDateTimes({ ...dateTimes, [date]: [...(dateTimes[date] ?? [])] });
    setSelectedHour(dateTimes[date] ?? []);
  };

  const checkDisabled = () => {
    let status = true;
    Object.keys(dateTimes).forEach((item, index) => {
      dateTimes[item].length > 0 && (status = false);
    });

    return status;
  };

  const onStore = async () => {
    deleteTimeEmpty();

    let data = {
      path: `konselor/setting-jadwal/v2/store`,
      body: {
        jadwal: JSON.stringify(dateTimes),
      },
    };

    await dispatch(postApi(data))
      .then((data) => {
        resetData();
        getSchedule(currentMonth);
        enqueueSnackbar("Jadwal Berhasil Dibuat", { variant: "success" });
      })
      .catch((err) => {
        const variant = "error";
        if (err.data.errors) {
          const error = err.data.errors;
          Object.keys(error).forEach((message) => {
            enqueueSnackbar(error[message], { variant });
          });
        } else {
          enqueueSnackbar(err.message ?? "Kelas gagal dibuat", { variant });
        }
      });
  };

  const onNextMonth = (value) => {
    setCurrentMonth(value);
    getSchedule(value);
  };

  const onPrevMonth = (value) => {
    setCurrentMonth(value);
    getSchedule(value);
  };

  return (
    <div>
      <Grid container spacing={2} className="mb-4">
        <Grid item xs={8}>
          <Card className="pa-3 rounded-lg">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="pr-2 left_bor">
                  <div style={{ minHeight: "90px" }}>
                    <p className="font-weight-bold">Jam Aktif Tersedia</p>
                    <p className="small_txt mb-3">Pilih jam aktif yang Anda inginkan. Pilihan jam berlaku untuk semua layanan aktif.</p>
                  </div>
                  <Grid container spacing={1}>
                    {availHour.map((item, i) => (
                      <Grid item xs={4} key={i}>
                        <Button className="capitalize" style={{ width: "100%" }} onClick={() => pickHour(item)} variant={selectedHour.includes(item) ? "contained" : "outlined"}>
                          {item}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ minHeight: "90px" }}>
                  <p className="font-weight-bold">Tanggal Aktif Tersedia</p>
                  <p className="small_txt mb-3">Tanggal aktif bisa dipilih lebih dari satu, dan berlaku untuk semua jam dan layanan aktif.</p>
                </div>
                <DatePickerSchedule
                  picked={datePicker}
                  setPicked={(value) => {
                    setLabelToCalendar(value);
                  }}
                  selectedDate={(value) => {
                    onSelectedDate(value);
                  }}
                />
                <div className="d-flex justify-end py-4">
                  <Button variant="contained" disabled={checkDisabled()} className="rounded-lg capitalize" onClick={() => onStore()}>
                    Buat Jadwal
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <div className="pa-3 rounded-lg" style={{ border: `2px solid ${theme.primary}`, color: theme.black }}>
            <h4>Layanan Aktif</h4>
            <small>Layanan aktif dapat diatur melalui halaman Setting Layanan.</small>
            <Card style={{ border: `1px solid ${theme.secondary}` }} className="pa-2 rounded-lg mt-3">
              <h5 style={{ color: theme.primary }}>Konseling Psikologis</h5>
              <ul className="pl-4">
                <li>
                  <small>Konseling Psikologis Ringan</small>
                </li>
                <li>
                  <small>Konseling Psikologis Sedang</small>
                </li>
              </ul>
              <h5 style={{ color: theme.primary }} className="mt-3">
                Konsultasi Tematik
              </h5>
              <ul className="pl-4">
                <li>
                  <small>Konsultasi Tematik Bebas Overthinking</small>
                </li>
                <li>
                  <small>Konsultasi Tematik Temukan Work Life</small>
                </li>
                <li>
                  <small>Konsultasi Tematik Sukses Membangun</small>
                </li>
                <li>
                  <small>Konsultasi Tematik Self Acceptance & Ir</small>
                </li>
              </ul>
            </Card>
          </div>
        </Grid>
      </Grid>
      <AllSchedule
        jadwal={data.jadwal ?? []}
        onNext={(value) => {
          onNextMonth(value);
        }}
        onPrev={(value) => {
          onPrevMonth(value);
        }}
      />
    </div>
  );
};

export default ManageScheduleConsultation;

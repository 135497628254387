export const statusForSchedule = (status) => {
  let data = {
    status: status,
    color: "#FFFFFF",
    background: "#0B81C2",
  };

  switch (status) {
    case "Aktif":
      data = {
        status: status,
        color: "#FFFFFF",
        background: "#00786A",
      };
      break;
    case "Selesai":
      data = {
        status: status,
        color: "#FFFFFF",
        background: "#6D0BCF",
      };
      break;
    case "Kadaluwarsa":
      data = {
        status: status,
        color: "#FFFFFF",
        background: "#B80222",
      };
      break;
    default:
      data = {
        status: status,
        color: "#FFFFFF",
        background: "#0B81C2",
      };
      break;
  }

  return data;
};

import { Button, Grid, Paper } from '@mui/material'
import React from 'react'
import EmptyData from '../../../../../components/base/EmptyData'
import day from 'dayjs'
import { ModeEdit, Delete as DeleteIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { DialogDelete } from '../../../../../components/base/dialogDelete'

const InfoTrainingPage = ({ dataSet, theme, loading }) => {
    const navigate = useNavigate()
    const [pocket, setPocket] = useState(null)
    const [dialog, setDialog] = useState(null)
    const deleteData = () => {
        setPocket({
            path: 'admin/training/delete',
            body: {
                id: dataSet.id
            }
        })
        setDialog(true)
    }
    return (
        !loading ? dataSet ? <Paper className='pa-5 rounded-lg'>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <img src={dataSet.gambar_decoded} height='200px' width='200px' style={{ objectFit: 'cover' }} alt="cover training" />
                </Grid>
                <Grid item xs={9}>
                    <section className='mb-3'>
                        <div className="d-flex">
                            <div className="flexy">
                                <p className='small_txt font-weight-bold' style={{ color: '#F3A100' }}> {dataSet.jenis_training} Training</p>
                                <p className="font-weight-bold" >
                                    {dataSet.judul}
                                </p>
                            </div>
                            <div className="d-flex">
                                <Button
                                    variant="contained"
                                    className="rounded-lg  mr-2"
                                    style={{ height: 'max-content' }}
                                    disableElevation
                                    fullWidth
                                    onClick={() => navigate(`/training-post/${dataSet.id}`)}
                                >
                                    <ModeEdit />
                                    <p className="font-weight-bold capitalize">
                                        Ubah
                                    </p>
                                </Button>
                                <Button
                                    variant="contained"
                                    className="rounded-lg "
                                    onClick={deleteData}
                                    disableElevation
                                    style={{ background: theme.act_red, height: 'max-content' }}
                                    fullWidth
                                >
                                    <DeleteIcon style={{ color: theme.act_lbl_red }} />
                                    <p
                                        className="font-weight-bold capitalize"
                                        style={{ color: theme.act_lbl_red }}
                                    >
                                        Delete
                                    </p>
                                </Button>
                            </div>
                        </div>

                        <p className="small_txt"> {dataSet.deskripsi} </p>
                        {dataSet.kategori_training.length ?
                            dataSet.kategori_training.map((cat, i) =>
                                <p key={`cat_training-${i}`} className='xsmall_txt mr-3' style={{ color: theme.tertiary }}> {cat.kategori.kategori} </p>)
                            : <></>}
                    </section>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <ul><li style={{ color: theme.primary }}>
                                <p style={{ fontWeight: '500' }}>Informasi Training</p>
                            </li></ul>
                            <section className='my-3'>
                                <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Pemateri</p>
                                <ul>
                                    {dataSet.pemateri_training.length ?
                                        dataSet.pemateri_training.map((pemateri, i) =>
                                            <li key={`pemateri-${i}`}>
                                                <p className="small_txt">
                                                    {pemateri.detail.nama}
                                                </p>
                                            </li>)
                                        : <></>}
                                </ul>
                            </section>
                            <section className='mb-3'>
                                <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Kategori Peserta</p>
                                <ul>
                                    {dataSet.kategori_peserta.length ?
                                        dataSet.kategori_peserta.map((peserta, i) =>
                                            <li key={`peserta-${i}`}>
                                                <p className="small_txt">
                                                    {peserta.kategori.nama}
                                                </p>
                                            </li>)
                                        : <></>}
                                </ul>
                            </section>
                            <section className='mb-3'>
                                <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Fasilitas</p>
                                <ul>
                                    {dataSet.fasilitas_decoded.length ?
                                        dataSet.fasilitas_decoded.map((fasilitas, i) =>
                                            <li key={`fasilitas-${i}`}>
                                                <p className="small_txt">
                                                    {fasilitas}
                                                </p>
                                            </li>)
                                        : <></>}
                                </ul>
                            </section>
                            <section className='mb-3'>
                                <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Informasi Tambahan</p>
                                <p className="small_txt">
                                    {dataSet.informasi_tambahan}
                                </p>
                            </section>
                            <section className='mb-3'>
                                <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Contact Person</p>
                                <p className="small_txt">
                                    {dataSet.cp_training}
                                </p>
                            </section>
                        </Grid>
                        <Grid item xs={7}>
                            <ul><li style={{ color: theme.primary }}>
                                <p style={{ fontWeight: '500' }}>Detail Harga</p>
                            </li></ul>
                            <section className='my-3'>
                                <ul>
                                    {dataSet.harga_detail.length ?
                                        dataSet.harga_detail.map((harga, i) =>
                                            <li key={`harga-${i}`}>
                                                <p className="small_txt">
                                                    {harga.nama}
                                                </p>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Harga Early Bird</p>
                                                        <p className="small_txt"> {harga.harga_early} </p>
                                                        <p className="xsmall_txt">Berlaku sampai {day(harga.tgl_early).locale("id").format('DD/MM/YYYY')} </p>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Harga Normal</p>
                                                        <p className="small_txt"> {harga.harga} </p>
                                                    </Grid>
                                                </Grid>
                                            </li>)
                                        : <></>}
                                </ul>
                            </section>
                            <ul><li style={{ color: theme.primary }}>
                                <p style={{ fontWeight: '500' }}>Detail Pelaksanaan</p>
                            </li></ul>
                            <section className='my-3'>

                                <ul>
                                    {dataSet.pelaksanaan.length ?
                                        dataSet.pelaksanaan.map((item, i) =>
                                            <li key={`pelaksanaan-${i}`}>
                                                <p className="small_txt" style={{ color: theme.primary }}>
                                                    Pertemuan {i + 1}
                                                </p>
                                                <p className="small_txt font-weight-bold" style={{ color: '#00C6C6' }}> {item.jenis} </p>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Tanggal</p>
                                                        <p className="small_txt"> {day(item.tgl_early).locale("id").format('dddd, DD MMMM YYYY')} </p>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Waktu</p>
                                                        <p className="small_txt"> {item.wkt_mulai.slice(0, 5)} - {item.wkt_selesai.slice(0, 5)} WIB </p>
                                                    </Grid>
                                                </Grid>
                                                <section className='mt-2'>
                                                    <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Media</p>
                                                    <p className="small_txt"> {item.media} </p>
                                                    <a href={item.lokasi_link} target="_blank" rel="noopener noreferrer"> <p className="small_txt">{item.lokasi_link}</p> </a>
                                                </section>
                                            </li>)
                                        : <></>}
                                </ul>
                            </section>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className="py-2">
                <DialogDelete d_del={dialog} close={() => setDialog(false)} pocket={pocket} refetch={() => navigate('/training-activity')} />

            </div>
        </Paper> : <EmptyData /> : <></>
    )
}

export default InfoTrainingPage

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../../store/theming";
import { Avatar, Button, Card, Grid, IconButton } from "@mui/material";
import { Circle, LiveHelp, Notifications, Settings, Star } from "@mui/icons-material";
import { getApi } from "../../../../store/apis";
import SkeletonProfilePage from "../../../../components/skeletons/skeletonProfilePage";
import ModalActivateCoach from "./component/ModalActivateCoach";
import ModalRequestCoach from "./component/ModalRequestCoch";
import ReactApexChart from "react-apexcharts";
import CardPanduan from "../../../../components/overview/panduan/CardPanduan";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const [data, setData] = useState({});
  const [element, setElement] = useState();
  const [series, setSeries] = useState([]);
  const [buttonTab, setButtonTab] = useState([
    {
      title: "Bulanan",
      value: "month",
      active: true,
    },
    {
      title: "Mingguan",
      value: "week",
    },
    {
      title: "Harian",
      value: "day",
    },
  ]);
  const options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
      hover: {
        size: 9,
      },
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      tickPlacement: "on",
      labels: {
        style: {
          colors: theme.secondary,
        },
      },
    },
    yaxis: {
      opposite: true,
      labels: {
        style: {
          colors: theme.secondary,
        },
      },
    },
    colors: [theme.act_blue, theme.yellow],
    grid: {
      borderColor: theme.secondary,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Profile"));
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfile = () => {
    let data = {
      path: `konselor/profile-konselor`,
    };
    dispatch(getApi(data))
      .then((data) => {
        (data === null || data === undefined) && (data = {});
        setData(data);
        formattingGrafic(data?.grafik ?? []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tabClicked = (index) => {
    buttonTab.forEach((item, index) => {
      buttonTab[index] = {
        ...item,
        active: false,
      };
    });

    buttonTab[index] = {
      ...buttonTab[index],
      active: true,
    };

    setButtonTab([...buttonTab]);
    getGrafic(buttonTab[index].value);
  };

  const getGrafic = (type) => {
    let data = {
      path: `konselor/profile-konselor/grafik?type=${type}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        (data === null || data === undefined) && (data = []);
        formattingGrafic(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formattingGrafic = (data) => {
    setSeries(data);
  };

  const renderProfilePage = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card className="pa-3 rounded-lg">
            <div className="d-flex justify-space-between pa-2 rounded-lg" style={{ backgroundColor: theme.tertiary, color: theme.darken }}>
              <span className="font-weight-700">Profile</span>
              <Settings />
            </div>
            <div className="mt-3">
              <div className="d-flex align-center gap-3">
                <div style={{ borderRadius: "50%", backgroundColor: theme.tertiary }} className="pa-1 d-inline-block">
                  <Avatar src={data.konselor.foto_link} sx={{ width: 90, height: 90 }} />
                </div>
                <div>
                  <h4>{data.konselor?.nama}</h4>
                  <div className="d-flex align-center gap-2">
                    <small>{data.konselor?.email}</small>
                    <Circle style={{ width: "5px", height: "5px" }} />
                    <small>{data.konselor?.jenis_kelamin}</small>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="rounded-lg py-1 px-3 d-flex gap-2 align-center" style={{ backgroundColor: theme.act_light_purple }}>
                      <small style={{ color: theme.act_dark_purple }} className="font-weight-700">
                        Psikolog
                      </small>
                      <div style={{ height: "1rem", width: "2px", backgroundColor: theme.act_purple }} className="rounded-lg" />
                      <Star style={{ width: "20px", height: "20px", color: theme.yellow }} />
                      <small style={{ color: theme.act_dark_purple }} className="font-weight-700">
                        4.9
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <hr style={{ border: `1.5px solid ${theme.secondary}` }} className="my-3" />
              <div>
                <Grid container spacing={2} className="mb-2">
                  <Grid item xs={4}>
                    <small className="font-weight-500" style={{ color: theme.primary }}>
                      Nomor Anggota
                    </small>
                  </Grid>
                  <Grid item xs={8}>
                    <small className="font-weight-500">{data.konselor?.no_anggota_himpsi}</small>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="mb-2">
                  <Grid item xs={4}>
                    <small className="font-weight-500" style={{ color: theme.primary }}>
                      Grup
                    </small>
                  </Grid>
                  <Grid item xs={8}>
                    <small className="font-weight-500">{data.konselor?.data_grup?.nama}</small>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="mb-2">
                  <Grid item xs={4}>
                    <small className="font-weight-500" style={{ color: theme.primary }}>
                      SSP
                    </small>
                  </Grid>
                  <Grid item xs={8}>
                    <small className="font-weight-500">{data.konselor?.ssp}</small>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="mb-2">
                  <Grid item xs={4}>
                    <small className="font-weight-500" style={{ color: theme.primary }}>
                      SIPP
                    </small>
                  </Grid>
                  <Grid item xs={8}>
                    <div className="leading-3 pt-1">
                      <div>
                        <small className="font-weight-500">{data.konselor?.sipp}</small>
                      </div>
                      <small>Berlaku sampai {data.konselor?.exp_sipp}</small>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="mb-2">
                  <Grid item xs={4}>
                    <small className="font-weight-500" style={{ color: theme.primary }}>
                      Bidang Keahlian Psikolog
                    </small>
                  </Grid>
                  <Grid item xs={8}>
                    <ul>
                      {data.konselor?.expertise?.map((item, index) => {
                        return (
                          <li key={index}>
                            <small>{item.expertise}</small>
                          </li>
                        );
                      })}
                    </ul>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Card>
          <div className="mt-3">
            <CardPanduan />
          </div>
          <Card className="mt-3 pa-4 rounded-lg" style={{ backgroundColor: theme.act_lbl_purple }}>
            <div className="d-flex gap-3">
              <LiveHelp style={{ color: theme.white, width: "3rem", height: "3rem" }} />
              <div className="grow">
                <h4 style={{ color: theme.white }}>Mau aktifkan layanan coaching?</h4>
                <small style={{ color: theme.white }}>Kirim permohonan ke admin dan lengkapi form serta dokumennya sekarang!</small>
                <div className="d-flex align-end justify-end mt-2">
                  <span
                    type="button"
                    className="py-1 cursor_pointer px-4 rounded-xl"
                    style={{ backgroundColor: theme.white, color: theme.primary }}
                    onClick={() =>
                      setElement(
                        <ModalActivateCoach
                          onSend={() => setElement(<ModalRequestCoach onOk={() => setElement(undefined)} closeModal={() => setElement(undefined)} />)}
                          closeModal={() => setElement(undefined)}
                        />
                      )
                    }>
                    <small className="font-weight-700">Aktifkan</small>
                  </span>
                </div>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <div className="d-flex justify-space-between align-center">
            <div>
              <h4 style={{ color: theme.darken }}>Rekap Aktivitas</h4>
            </div>
            <div className="temp_notif mr-3">
              <IconButton>
                <Notifications color="primary" />
              </IconButton>
            </div>
          </div>
          <div className="mt-2 d-flex justify-space-between align-center">
            <div className="leading-3">
              <h5 style={{ color: theme.black }}>Klien</h5>
              <small className="px11_txt">Grafik pembelian layanan</small>
            </div>
            <div style={{ backgroundColor: theme.secondary }} className="rounded-lg pa-1 d-flex">
              {buttonTab.map((item, index) => {
                return (
                  <Button key={index} variant={item.active ? "contained" : ""} style={{ color: theme.white }} className="capitalize rounded-lg" onClick={() => tabClicked(index)}>
                    {item.title}
                  </Button>
                );
              })}
            </div>
          </div>
          <div className="mt-2">
            <Card className="pa-2 rounded-lg" style={{ background: theme.primary }}>
              <ReactApexChart options={options} series={series} type="area" height={200} />
            </Card>
            <div className="mt-4 d-flex flex-wrap gap-3">
              <div className="pa-3 rounded-lg" style={{ border: `1px solid ${theme.secondary}` }}>
                <small style={{ color: theme.black }} className="px11_txt">
                  Rata-rata
                </small>
                <div>
                  <h5 style={{ color: theme.black }}>Waktu Aktif</h5>
                </div>
                <div className="d-flex mt-2 gap-2 align-center">
                  <div style={{ width: "3rem", height: "3rem", backgroundColor: theme.act_blue, borderRadius: "50%" }} />
                  <div className="text-center">
                    <h3 style={{ color: theme.darken }}>{data.jumlah_waktu_konsultasi_aktif}</h3>
                    <small className="font-weight-500">Jam/hari</small>
                  </div>
                </div>
              </div>
              <div className="pa-3 rounded-lg" style={{ border: `1px solid ${theme.secondary}` }}>
                <small style={{ color: theme.black }} className="px11_txt">
                  Rata-rata
                </small>
                <div>
                  <h5 style={{ color: theme.black }}>Waktu Konsultasi</h5>
                </div>
                <div className="d-flex mt-2 gap-2 align-center">
                  <div style={{ width: "3rem", height: "3rem", backgroundColor: theme.yellow, borderRadius: "50%" }} />
                  <div className="text-center">
                    <h3 style={{ color: theme.darken }}>{data.jumlah_waktu_konsultasi}</h3>
                    <small className="font-weight-500">Jam/hari</small>
                  </div>
                </div>
              </div>
              <div className="pa-3 rounded-lg" style={{ border: `1px solid ${theme.secondary}` }}>
                <div style={{ color: theme.black }} className="px11_txt pt-3 pb-2">
                  <div style={{ width: "2rem", height: "3px", backgroundColor: theme.primary }} className="rounded-lg" />
                </div>
                <div>
                  <h5 style={{ color: theme.black }}>Klien</h5>
                </div>
                <div className="d-flex mt-2 gap-2 align-center">
                  <div style={{ width: "2.5rem", height: "2.5rem", backgroundColor: theme.act_purple, borderRadius: "50%" }} />
                  <div className="text-center">
                    <h3 style={{ color: theme.darken }}>{data.jumlah_klien > 16 ? "+16" : data.jumlah_klien}</h3>
                    <small className="font-weight-500">Total {data.jumlah_klien}</small>
                  </div>
                </div>
              </div>
              <div className="pa-3 rounded-lg" style={{ border: `1px solid ${theme.secondary}` }}>
                <div style={{ color: theme.black }} className="px11_txt pt-3 pb-2">
                  <div style={{ width: "2rem", height: "3px", backgroundColor: theme.primary }} className="rounded-lg" />
                </div>
                <div>
                  <h5 style={{ color: theme.black }}>Layanan</h5>
                </div>
                <div className="d-flex mt-2 gap-2 align-center">
                  <div style={{ width: "2.5rem", height: "2.5rem", backgroundColor: theme.act_purple, borderRadius: "50%" }} />
                  <div className="text-center">
                    <h3 style={{ color: theme.darken }}>{data.jumlah_layanan}</h3>
                    <small className="font-weight-500">Layanan</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  };

  return Object.keys(data).length === 0 ? (
    <SkeletonProfilePage />
  ) : (
    <>
      {renderProfilePage()}
      {element}
    </>
  );
};

export default ProfilePage;

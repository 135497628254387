/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../store/apis";
import EmptyData from "../../../components/base/EmptyData";
import SkeletonTable from "../../../components/base/SkeletonTable";
import { ArrowDropDown } from "@mui/icons-material";
import SearchDataTable from "../../../components/base/searchDataTable";
import { Grid, Pagination, Paper, Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

import day from "dayjs";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
const IndividualUserTransaction = ({ id }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const [find, setFind] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataSet, setData] = useState([]);
  const [emptyTxt, setEmptyTxt] = useState("");

  const [state, setState] = useState({
    page: 1,
    limit: 10,
    last_page: null,
    total: null,
    gender: "",
    profesi: "",
    tipeSort: null,
    asc: true,
  });

  const fetchData = () => {
    setLoading(true);
    let page = `&page[size]=${state.limit}&page[number]=${state.page}`;
    let data = {
      path: `admin/individual-user/transaksi?id=${id}&filter[nama]=${find}${page}`,
    };

    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data.data);
        setState({ ...state, total: data.total, last_page: data.last_page });
      })
      .catch((err) => {
        if (err.status == 400) {
          if (find != "") {
            setEmptyTxt(`${find} tidak cocok untuk data apapun!`);
          } else {
            setEmptyTxt(`No Data Available`);
          }
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setData([]);
      });
  };
  const handlePage = (event, value) => {
    setState({ ...state, page: value });
  };
  const listLimit = [10, 50, 100];

  useEffect(() => {
    fetchData();
  }, [state.limit, find, state.page]);

  return (
    <div>
      <Paper className="pa-3 rounded-lg mt-3">
        <Grid container spacing={2} className="mb-5">
          <Grid item xs={6}>
            <div className="d-flex align-center">
              <h3>User Transaction</h3>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="d-flex align-center">
              <p className="small_txt font-weight-bold mr-2">Tampilkan</p>
              <Menu
                menuClassName="my_menu"
                menuButton={
                  <Card className="px-3 py-2 rounded-lg d-flex align-center mr-5 cursor_pointer pb-2">
                    <p className="small_txt font-weight-bold mr-3">{state.limit} Data</p>
                    <ArrowDropDown />
                  </Card>
                }
                transition>
                {listLimit.map((lm, i) => (
                  <MenuItem key={`limit-${i}`} onClick={() => setState({ ...state, limit: lm })}>
                    <p className="font-weight-bold txt_table">{lm} Data</p>
                  </MenuItem>
                ))}
              </Menu>
              <SearchDataTable value={find} setValue={setFind} />
            </div>
          </Grid>
        </Grid>
        {!loading ? (
          !dataSet.length ? (
            <EmptyData />
          ) : (
            <TableContainer component="div">
              <Table aria-label="simple table" style={{ borderBottom: "none" }}>
                <TableHead>
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell style={{ minWidth: "50px", background: theme.tertiary, borderRadius: "10px 0 0 10px" }} className="py-3">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        No
                      </p>
                    </TableCell>
                    <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Kategori
                      </p>
                    </TableCell>
                    <TableCell style={{ minWidth: "150px", background: theme.tertiary }} className="py-3">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Nama Layanan
                      </p>
                    </TableCell>
                    <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Harga
                      </p>
                    </TableCell>
                    <TableCell style={{ minWidth: "150px", background: theme.tertiary }} className="py-3" align="center">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Tgl. Masuk Keranjang
                      </p>
                    </TableCell>
                    <TableCell style={{ minWidth: "120px", background: theme.tertiary }} className="py-3" align="center">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Tgl. Checkout
                      </p>
                    </TableCell>
                    <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Status Pembayaran
                      </p>
                    </TableCell>
                    <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Tanggal Pembayaran
                      </p>
                    </TableCell>
                    <TableCell style={{ minWidth: "100px", background: theme.tertiary, borderRadius: "0 10px 10px 0" }} className="py-3" align="center">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Metode Pembayaran
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSet.map((item, i) => (
                    <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>
                        <p className="px11_txt">{i + 1 + (state.page - 1) * state.limit}</p>
                      </TableCell>
                      <TableCell>
                        <p className="px11_txt">{item.tipe}</p>
                      </TableCell>
                      <TableCell>
                        <p className="px11_txt">{item.nama_layanan?.judul ?? "-"}</p>
                      </TableCell>
                      <TableCell>
                        <p className="px11_txt">{item.harga}</p>
                      </TableCell>
                      <TableCell align="center">
                        <p className="px11_txt">{day(item.created_at).format("DD/MM/YY | HH:mm")}</p>
                      </TableCell>
                      <TableCell align="center">
                        <p className="px11_txt">{item.tgl_checkout}</p>
                      </TableCell>
                      <TableCell align="center">
                        <p className="font-weight-bold  px11_txt" style={{ color: item.status == "success" ? theme.act_lbl_blue : theme.act_lbl_red }}>
                          {item.status}
                        </p>
                      </TableCell>
                      <TableCell align="center">
                        <p className="px11_txt">{item.wkt_kirim_bukti}</p>
                      </TableCell>
                      <TableCell align="center">
                        <p className="px11_txt">{item.metode_decode?.metode ?? "-"}</p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        ) : (
          <SkeletonTable txt={emptyTxt} />
        )}
        {dataSet.length ? (
          <div className="d-flex align-center justify-space-between" style={{ width: "100%" }}>
            <p className="txt_table">
              Menampilkan {state.page} - {state.limit} dari {state.total}{" "}
            </p>
            <div>
              <Pagination sx={{ padding: "0" }} count={state.last_page} page={state.page} shape="rounded" onChange={handlePage} style={{ background: "#fff" }} color="primary" />
            </div>
          </div>
        ) : (
          <></>
        )}
      </Paper>

      <div className="pa-2"></div>
    </div>
  );
};

export default IndividualUserTransaction;

/* eslint-disable eqeqeq */
import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import day from "dayjs";
import LinesEllipsis from "react-lines-ellipsis";

const TrxActivityList = ({ theme, dataSet, page, limit }) => {
  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell
                style={{
                  minWidth: "50px",
                  background: theme.tertiary,
                  borderRadius: "10px 0 0 10px",
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Invoice
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "100px",
                  background: theme.tertiary,
                }}
                className="py-3"
                align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tanggal & Jam
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "200px",
                  background: theme.tertiary,
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  User
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Kategori
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} align="center" className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Sub Total
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Status
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "150px",
                  background: theme.tertiary,
                }}
                align="center"
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Metode
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "150px",
                  background: theme.tertiary,
                  borderRadius: "0 10px 10px 0",
                }}
                align="center"
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tindakan
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSet.map((item, i) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.id_invoice}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">
                    {day(item.tgl_checkout).format("DD/MM/YY")} {item.wkt_checkout.slice(0, -3)}
                  </p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt" style={{ fontWeight: 500 }}>
                    {item.data_pelanggan.nama}
                  </p>
                  <p className="px11_txt">{item.data_pelanggan.email}</p>
                </TableCell>
                <TableCell className="py-2">
                  <div className="d-flex">
                    {item.data_detail_transaksi.length ? (
                      <div className="box_pemateri flexy mr-1">
                        <LinesEllipsis className="px11_txt text-center" text={item.data_detail_transaksi[0].tipe ?? "-"} />
                      </div>
                    ) : (
                      <></>
                    )}
                    {item.data_detail_transaksi.length > 1 ? <div className="box_pemateri px11_txt"> +{item.data_detail_transaksi.length - 1} </div> : <></>}
                  </div>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.harga.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.status}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.metode_pembayaran_decode?.deskripsi ?? item.metode_pembayaran_decode?.metode}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <Button variant="contained" className="rounded-xl capitalize mr-3" disableElevation size="small" style={{ background: theme.act_blue }}>
                    <p className="font-weight-bold px11_txt" style={{ color: theme.act_lbl_blue }}>
                      Detail
                    </p>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TrxActivityList;

import "../../assets/css/auth.css";
import { Card, Box, FormControl, Input, InputAdornment, IconButton, Button, Alert, Collapse } from "@mui/material";
import { Visibility, VisibilityOff, Email, Https } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { authLogin } from "../../store/auth.js";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
    password: "",
    visible: false,
    loading: false,
    alert: false,
    err: false,
    msg: "",
  });
  const handleChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value });
  };
  const handleMouseDown = (event) => {
    event.preventDefault();
  };
  const showPwd = () => {
    setState({
      ...state,
      visible: !state.visible,
    });
  };

  const submitLogin = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
    });
    // let path = 'admin/login'
    let path = "login-all";
    let data = {
      path: path,
      body: {
        username: state.email,
        password: state.password,
      },
    };
    dispatch(authLogin(data))
      .then((res) => {
        console.log(res.data.data);

        let response = res.data.data;
        Cookies.set("token", response.token, { expires: 7 });
        Cookies.set("id", response.general.id, { expires: 7 });
        localStorage.setItem("photo", response.general?.detail?.foto_link ?? "");
        localStorage.setItem("role", response.general.role);
        localStorage.setItem("permission", JSON.stringify(response.permission));
        // Cookies.set("token", response.token, { expires: 7 });
        // Cookies.set("id", response.admin_id, { expires: 7 });
        // localStorage.setItem('role', 'ADMIN')
        setState({
          ...state,
          loading: false,
          err: false,
          msg: "Login Berhasil",
          alert: true,
        });
        navigate(0);
      })
      .catch((err) => {
        setState({
          ...state,
          loading: false,
          err: true,
          msg: err.response.data.message,
          alert: true,
        });
      });
  };

  // const logoTb = require('../')
  const logoTb = require("../../assets/img/logo_tb.png");

  return (
    <div className="container_auth">
      <Card className="card_auth rounded-lg pa-3">
        <div className="inset_shadow pa-2">
          <div className="d-flex align-center">
            <img src={logoTb} className="mr-3" height="70px" alt="" />
            <p style={{ fontWeight: "600", color: "#fff" }}>
              Welcome to <br />
              Teman Bakat Dashboard
            </p>
          </div>
        </div>
        <Collapse in={state.alert} className="mt-3">
          <Alert variant="filled" className="rounded-lg" severity={state.err ? "error" : "success"}>
            {state.msg}
          </Alert>
        </Collapse>
        <div className="pa-3 mt-5">
          <Box component="form" noValidate onSubmit={submitLogin}>
            <div className="mb-3">
              <p className="label_auth">Username</p>
              <div className="d-flex">
                <Email style={{ color: "#6909C9" }} className="mr-3 mt-1" />
                <FormControl variant="standard" className="mb-5" style={{ width: "100%" }}>
                  <Input error={state.err} value={state.email} onChange={handleChange("email")} />
                </FormControl>
              </div>
            </div>
            <div>
              <p className="label_auth">Password</p>
              <div className="d-flex">
                <Https style={{ color: "#6909C9" }} className="mr-3 mt-1" />
                <FormControl variant="standard" className="mb-5" style={{ width: "100%" }}>
                  <Input
                    type={state.visible ? "text" : "password"}
                    value={state.password}
                    error={state.err}
                    onChange={handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={showPwd} onMouseDown={handleMouseDown} edge="end">
                          {state.visible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </div>
            </div>

            <div className="d-flex justify-end">
              {state.loading ? (
                <Button
                  color="tbmain"
                  variant="contained"
                  style={{
                    color: "#fff",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                  }}>
                  <p className="capitalize font-weight-bold">Loading...</p>
                </Button>
              ) : (
                <Button
                  color="tbmain"
                  variant="contained"
                  type="submit"
                  style={{
                    color: "#fff",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                  }}>
                  <p className="capitalize font-weight-bold">Login</p>
                </Button>
              )}
            </div>
          </Box>
        </div>
      </Card>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../../store/theming";
import { Button, Card, FormControl, FormControlLabel, Menu, Pagination, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { AddCircle, Cancel, Delete, FilterList, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { fetchApi, postApi } from "../../../../store/apis";
import { currencyFormatter } from "../../../../utils/formatter";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import SkeletonTable from "../../../../components/base/SkeletonTable";
import CheckboxText from "../../../../components/base/checkboxText";
import { useSnackbar } from "notistack";

const CoachPage = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useSelector((state) => state.theme.myTheme);
  const [loading, setLoading] = useState(false);
  const [drafItemChecked, setDrafItemChecked] = useState([]);
  const [find, setFind] = useState("");
  const [filterData, setFilterData] = useState({
    action: "",
  });
  const [anchor, setAnchor] = useState({
    filter: null,
    openfilter: false,
  });
  const [data, setData] = useState({
    from: 1,
    to: 10,
    page: 1,
    limit: 10,
    checkedAll: false,
  });

  useEffect(() => {
    dispatch(CHANGE_TITLE("Setting Layanan"));
    getKelas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.page, find]);

  const getKelas = () => {
    setLoading(true);
    let page = `&page[size]=${data.limit}&page[number]=${data.page}`;
    let dataBody = {
      path: `konselor/coach/kelas?filter[nama_kelas]=${find}&filter[action]=${filterData.action}${page}`,
    };
    dispatch(fetchApi(dataBody))
      .then((data) => {
        formattingKelas(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const formattingKelas = (kelasData) => {
    let datas = checkListCheckedInDraf(kelasData.data);
    setData({ ...data, kelas: datas, from: kelasData.from, to: kelasData.to, total: kelasData.total, last_page: kelasData.last_page });
  };

  const onFilterButtonClick = (event) => {
    openMenu(event, "filter");
  };

  const openMenu = (event, type) => {
    setAnchor({ ...anchor, [type]: event.currentTarget, [`open${type}`]: true });
  };

  const resetFilterData = () => {
    setFilterData({ ...filterData, action: "" });
  };

  const onFilter = (value) => {
    setFind(value);
    setData({ ...data, page: 1 });
  };

  const handlePage = (event, value) => {
    setData({ ...data, page: value, checkedAll: false });
  };

  const checklistAll = (checked) => {
    let dataList = [...data.kelas];
    dataList.forEach((item, index) => {
      dataList[index] = {
        ...dataList[index],
        checked: checked,
      };
    });
    setDrafChecked(checked, dataList, true);
    setData({ ...data, kelas: dataList, checkedAll: checked });
  };

  const checkAllItemChecked = (dataList) => {
    let dataKelas = dataList.every((x) => x.checked ?? false);
    data.checkedAll = dataKelas;
  };

  const checkListItem = (checked, index) => {
    let kelas = [...data.kelas];
    kelas[index] = {
      ...kelas[index],
      checked: checked,
    };
    setDrafChecked(checked, kelas[index]);
    checkAllItemChecked(kelas);
    setData({ ...data, kelas: kelas });
  };

  const setDrafChecked = (checked, data = [], all = false) => {
    if (all) {
      if (checked) {
        let items = [];
        data.forEach((item, index) => {
          let indexItem = drafItemChecked.findIndex((x) => x.id === item.id);
          indexItem < 0 && items.push(item);
        });
        setDrafItemChecked([...drafItemChecked, ...items]);
      } else {
        data.forEach((item, index) => {
          let indexItem = drafItemChecked.findIndex((x) => x.id === item.id);
          drafItemChecked.splice(indexItem, 1);
        });
        setDrafItemChecked([...drafItemChecked]);
      }
    } else {
      if (checked) {
        let dataItemDraf = [...drafItemChecked, ...[data]];
        setDrafItemChecked([...dataItemDraf]);
      } else {
        let index = drafItemChecked.findIndex((x) => x.id === data.id);
        drafItemChecked.splice(index, 1);
        setDrafItemChecked([...drafItemChecked]);
      }
    }
  };

  const checkListCheckedInDraf = (listData) => {
    let items = [];
    listData.forEach((item, index) => {
      let indexItem = drafItemChecked.findIndex((x) => x.id === item.id);
      let itemChecked = indexItem < 0 ? false : true;

      items.push({ ...item, checked: itemChecked });
    });

    checkAllItemChecked(items);
    return items;
  };

  const onChangeStatus = async (index) => {
    let item = data.kelas[index];
    // eslint-disable-next-line eqeqeq
    let status = item.show == "1" ? "0" : "1";
    const dataBatch = {
      path: "konselor/coach/kelas/switch",
      body: {
        id: item.id,
        status: status,
        kolom: "show",
      },
    };

    await dispatch(postApi(dataBatch))
      .then((res) => {
        let datas = [...data.kelas];
        datas[index] = {
          ...datas[index],
          show: parseInt(status),
        };
        setData({ ...data, kelas: datas });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDeleteItems = async () => {
    let itemsId = [];
    drafItemChecked.forEach((item, index) => {
      itemsId.push(item.id);
    });

    const dataBatch = {
      path: "konselor/coach/kelas/delete-many",
      body: {
        id: JSON.stringify(itemsId),
      },
    };

    await dispatch(postApi(dataBatch))
      .then((res) => {
        deleteInLocal(itemsId);
        enqueueSnackbar(`${itemsId.length} Layanan berhasil dihapus`, { variant: "success" });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(`${itemsId.length} Layanan gagal dihapus`, { variant: "error" });
      });
  };

  const deleteInLocal = (itemsId) => {
    setDrafItemChecked([]);

    let kelas = data.kelas;
    itemsId.forEach((item, index) => {
      let indexItem = kelas.findIndex((x) => x.id === item);
      kelas.splice(indexItem, 1);
    });

    if (find !== "" || filterData.action !== "") {
      // Redirect page ketika dalam keadaan filter
      data.page === 1 ? getKelas() : setData({ ...data, page: 1 });
    } else {
      // Redirect page ketika tidak dalam keadaan filter
      const toPage = Math.ceil((data.to - itemsId.length) / data.limit);
      kelas.length > 0 || data.page === 1 ? getKelas() : setData({ ...data, page: toPage });
    }
  };

  const renderEmptyPage = () => {
    return (
      <Card className="mt-3 white-bg pa-2 rounded-lg d-flex justify-center align-center" style={{ height: "80vh" }}>
        <div className="text-center">
          <h4 style={{ color: theme.primary }}>Belum ada kelas yang dibuat!</h4>
          <small>Tambah Kelas Coaching Anda Sekarang!</small>
          <div className="mt-4">
            <Button variant="contained" onClick={() => navigation("/coach/kelas/create")}>
              <AddCircle />
              <span className="ml-2 small_txt capitalize font-weight-bold">Tambah Kelas</span>
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  const renderStatusItem = (status) => {
    let dataBg = {
      waiting: "orange",
      approved: "green",
      rejected: "red",
    };
    return (
      <Button variant="contained" onClick={() => {}} className="rounded-lg capitalize" disableElevation size="small" style={{ background: theme[dataBg[status]] ?? "" }}>
        <p className="px11_txt capitalize" style={{ color: theme.white }}>
          {status}
        </p>
      </Button>
    );
  };

  const renderKelasPage = () => {
    return (
      <Card className="mt-3 white-bg pa-2 rounded-lg px-4">
        <div className="d-flex flex-wrap justify-space-between align-center">
          <span className="font-weight-500">Layanan Coaching</span>
          <div className="d-flex gap-3">
            <Card
              className="d-flex align-center justify-space-between rounded-lg pr-1"
              elevation={0}
              style={{ border: `solid 2px ${false ? theme.primary : theme.tertiary}`, background: `${false ? "" : theme.tertiary}` }}>
              <input
                type="text"
                placeholder="Cari Layanan"
                value={find}
                onChange={(event) => onFilter(event.target.value)}
                className="search_input"
                style={{ background: false ? "" : theme.tertiary }}
              />
              {false ? <Cancel onClick={() => {}} onMouseDown={(event) => event.preventDefault()} fontSize="small" color="secondary" className="mr-2 cursor_pointer" /> : <></>}
              <Search fontSize="small" color={true ? "white" : "primary"} style={{ color: theme.darken }} />
            </Card>
            <Button variant="outlined" className="px-3 py-1 capitalize rounded-lg" onClick={(event) => onFilterButtonClick(event)}>
              <FilterList className="mr-3" />
              Filter
            </Button>
            {renderMenuFilter()}
            <Button variant="contained" className="px-3 py-1 capitalize rounded-lg" onClick={() => navigation("/coach/kelas/create")}>
              <AddCircle className="mr-3" />
              Tambah Kelas
            </Button>
          </div>
        </div>
        {drafItemChecked.length > 0 && (
          <div className="pt-3 d-flex justify-space-between align-center px-3">
            <span className="small_txt font-weight-bold" style={{ color: theme.primary }}>
              {drafItemChecked.length} Layanan Dipilih
            </span>
            <div>
              <Button variant="contained" className="px-3 capitalize rounded-lg shadow-none" style={{ backgroundColor: theme.act_red, color: theme.act_lbl_red }} onClick={() => onDeleteItems()}>
                <Delete className="mr-1" />
                Delete
              </Button>
            </div>
          </div>
        )}
        <div className="my-4">{loading ? <SkeletonTable txt={""} /> : renderTableList()}</div>
        <div>
          {data.kelas?.length > 0 ? (
            <div className="d-flex align-center justify-space-between" style={{ width: "100%" }}>
              <p className="txt_table">
                Menampilkan {data.from} - {data.to} dari {data.total}{" "}
              </p>
              <div>
                <Pagination sx={{ padding: "0" }} count={data.last_page} page={data.page} shape="rounded" onChange={handlePage} style={{ background: "#fff" }} color="primary" />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Card>
    );
  };

  const renderMenuFilter = () => {
    return (
      <Menu
        className="mt-2 rounded-lg"
        id="filter"
        anchorEl={anchor.filter}
        open={anchor.openfilter}
        onClose={() => setAnchor({ ...anchor, filter: null, openfilter: false })}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "div",
        }}
        PaperProps={{ sx: { borderRadius: "8px", width: "200px" } }}>
        <div className="px-3 d-flex align-center justify-space-between">
          <p className="font-weight-bold txt_table" style={{ color: theme.primary }}>
            Filter
          </p>
          <p className="font-weight-bold small_txt cursor_pointer" style={{ color: theme.secondary }} onClick={() => resetFilterData()}>
            Reset
          </p>
        </div>
        <div className="px-3 mt-3">
          <p className="font-weight-bold txt_table mb-1" style={{ color: theme.primary }}>
            Status
          </p>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={filterData.action}
              onChange={(event) => setFilterData({ action: event.target.value })}>
              <FormControlLabel value="waiting" control={<Radio size="small" style={{ padding: "6px" }} />} label="Waiting" />
              <FormControlLabel value="approved" control={<Radio size="small" style={{ padding: "6px" }} />} label="Approved" />
              <FormControlLabel value="rejected" control={<Radio size="small" style={{ padding: "6px" }} />} label="Rejected" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="px-3 mt-3">
          <Button
            variant="contained"
            size="small"
            className="capitalize mt-2"
            style={{ width: "100%" }}
            onClick={() => {
              setAnchor({ ...anchor, filter: null, openfilter: false });
              getKelas();
            }}>
            Terapkan
          </Button>
        </div>
      </Menu>
    );
  };

  const renderTableList = () => {
    return (
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell
                style={{
                  minWidth: "10px",
                  background: theme.tertiary,
                  borderRadius: "10px 0 0 10px",
                }}
                className="py-3">
                <CheckboxText value="0" background={theme.darken} checked={data.checkedAll} onChecked={(checked, value) => checklistAll(checked)} />
              </TableCell>
              <TableCell style={{ minWidth: "20px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  No
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "200px", background: theme.tertiary }} className="py-3" align="left">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Nama Kelas
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="left">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Kategori Kelas
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Durasi
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Sesi
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="left">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Harga Normal
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="left">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Harga Promo
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Status
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Show
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "100px",
                  background: theme.tertiary,
                  borderRadius: "0 10px 10px 0",
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Action
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="pb-4">
            {data.kelas?.map((item, index) => {
              return (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell className="py-1" align="center">
                    <CheckboxText value={item.id} background={theme.darken} checked={item.checked} onChecked={(checked, value) => checkListItem(checked, index)} />
                  </TableCell>
                  <TableCell className="py-1" align="center">
                    {data.from + index}
                  </TableCell>
                  <TableCell className="py-1">{item.nama_kelas}</TableCell>
                  <TableCell className="py-1">{item.kategori?.kategori}</TableCell>
                  <TableCell className="py-1" align="center">
                    {item.durasi}
                  </TableCell>
                  <TableCell className="py-1" align="center">
                    {item.jumlah_sesi}
                  </TableCell>
                  <TableCell className="py-1">{currencyFormatter(item.harga)}</TableCell>
                  <TableCell className="py-1">{currencyFormatter(item.potongan_harga)}</TableCell>
                  <TableCell className="py-1" align="center">
                    {renderStatusItem(item.action)}
                  </TableCell>
                  <TableCell className="py-1" align="center">
                    <div onClick={() => onChangeStatus(index)} className="d-flex align-center justify-center">
                      <Icon path={item.show === 1 ? mdiToggleSwitch : mdiToggleSwitchOffOutline} size={1.8} color={theme.primary} />
                    </div>
                  </TableCell>
                  <TableCell className="py-1" align="center">
                    <div className="d-flex gap-1">
                      <Button
                        variant="contained"
                        onClick={() => navigation(`/coach/kelas/detail/${item.id}`)}
                        className="rounded-lg capitalize"
                        disableElevation
                        size="small"
                        style={{ background: theme.act_blue }}>
                        <p className="font-weight-bold" style={{ color: theme.act_lbl_blue }}>
                          Detail
                        </p>
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => navigation(`/coach/kelas/${item.id}/edit`)}
                        className="rounded-lg capitalize"
                        disableElevation
                        size="small"
                        style={{ background: theme.act_yellow }}>
                        <p className="font-weight-bold" style={{ color: theme.act_lbl_yellow }}>
                          Edit
                        </p>
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div>
      <hr style={{ border: `1px solid ${theme.secondary}` }} />
      {!loading && find === "" && filterData.action === "" && (data.kelas?.length === 0 || data.kelas === undefined || data.kelas === null) ? renderEmptyPage() : renderKelasPage()}
    </div>
  );
};

export default CoachPage;

import { useState } from "react";
import { useSelector } from "react-redux";

const CheckboxText = ({ title = "", value, background, checked = false, onChecked }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const [style, setStyle] = useState({ background: "" });

  const onChange = (event) => {
    let checked = event.target.checked;
    let value = event.target.value;
    onChecked && onChecked(checked, value);
    if (checked) {
      setStyle({ background: background ?? theme.secondary });
    } else {
      setStyle({ background: "" });
    }
  };

  const renderBg = () => {
    let bg;
    if (checked) {
      bg = background ?? theme.secondary;
    } else {
      bg = checked ? style.background : "";
    }

    return bg;
  };

  return (
    <div className="d-flex items-center">
      <input
        type="checkbox"
        value={value}
        className="mr-2 checked-style"
        checked={checked}
        style={{ border: `2px solid ${background ?? theme.secondary}`, background: renderBg() }}
        onChange={(event) => onChange(event)}
      />
      <span className="small_txt font-weigh-500">{title}</span>
    </div>
  );
};

export default CheckboxText;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeft, ExpandMore, HighlightOff } from '@mui/icons-material'
import { Backdrop, Button, CircularProgress, Grid, Paper } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import FlatInput from '../../../components/base/flatInput'
import { getApi, postApi } from '../../../store/apis'
import { CHANGE_TITLE } from '../../../store/theming'
import Select from 'react-select';
import FlatArea from '../../../components/base/flatArea'
import DetailHargaTraining from './components/DetailHargaTraining'
import WaktuTraining from './components/WaktuTraining'
import DetailTraining from './components/DetailTraining'
import SelectPemateriTraining from './components/SelectPemateriTraining'
import LinesEllipsis from 'react-lines-ellipsis'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import SelectKategoriTraining from './components/SelectKategoriTraining'

const CreateTraining = () => {
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.theme.myTheme);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [categoryPeserta, setCategoryPeserta] = useState([])
  const { enqueueSnackbar } = useSnackbar();

  const [infoTraining, setInfoTraining] = useState({
    cover: '',
    judul: '',
    deskripsi: '',
    kuota: '',
    cp_training: ''
  })

  const handleInfoTraining = (prop) => (event) => {
    setInfoTraining({ ...infoTraining, [prop]: event.target.value });
  };

  const [dp, setDp] = useState(null)
  const [waktuTraining, setWaktuTraining] = useState([{
    jenis: '',
    date: '',
    time_start: '',
    time_end: '',
    media: '',
    link: ''
  }])

  const [infoDetail, setInfoDetail] = useState({
    informasi: ''
  })

  const [idKategori, setIdKategori] = useState([])
  const [catPeserta, setCatPeserta] = useState([])
  const [priceDetail, setPriceDetail] = useState([])
  const [materi, setMateri] = useState([''])
  const [fasilitas, setFasilitas] = useState([''])
  const [bonus, setBonus] = useState([''])
  const { type } = useParams()
  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `admin/training?filter[id]=${type}`,
    };
    dispatch(getApi(data))
      .then((res) => {
        if (res.data.length) {
          let raw = JSON.parse(JSON.stringify(res.data[0]))
          console.log(raw)
          setInfoTraining({ ...infoTraining, judul: raw.judul, deskripsi: raw.deskripsi ?? '', kuota: raw.kuota ?? '', cp_training: raw.cp_training ?? '' })
          setInfoDetail({ ...infoDetail, informasi: raw.informasi_tambahan ?? '' })
          setDp(raw.gambar_decoded ?? null)
          setPemateri(raw.pemateri_training?.length ? raw.pemateri_training.map(el => el.detail) : [])
          if (raw.fasilitas_decoded?.length) { setFasilitas(raw.fasilitas_decoded) }
          if (raw.bonus_decoded?.length) { setBonus(raw.bonus_decoded) }
          if (raw.materi_decoded?.length) { setMateri(raw.materi_decoded) }
          setIdKategori(raw.kategori_training?.length ? raw.kategori_training.map(el => el.kategori) : [])
          if (raw.pelaksanaan?.length) {
            let waktu = []
            raw.pelaksanaan.forEach(el => {
              waktu.push({
                jenis: el.jenis,
                date: new Date(el.tanggal_mulai),
                time_start: new Date(`${el.tanggal_mulai} ${el.wkt_mulai}`),
                time_end: new Date(`${el.tanggal_mulai} ${el.wkt_selesai}`),
                media: el.media ?? '',
                link: el.lokasi_link ?? ''
              })
            })
            setWaktuTraining(waktu)
          }
          if (raw.kategori_peserta?.length) {
            let cat_peserta = []
            raw.kategori_peserta.forEach(el => {
              cat_peserta.push({ label: el.kategori.nama, value: el.kategori.id })
            })
            setCatPeserta(cat_peserta)
            if (raw.harga_detail?.length) {
              let price = []
              raw.harga_detail.forEach(el => {
                let obj = {
                  label: cat_peserta.map(c => c.value == el.training_kategori_peserta_id ? c.label : ''),
                  id: el.training_kategori_peserta_id,
                  earlyBird: el.harga_early,
                  dateEarlyBird: new Date(el.tgl_early),
                  hargaNormal: el.harga_fix
                }
                price.push(obj)
              })
              setPriceDetail(price)
            }
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      });
  };

  const getKategoriTraining = () => {
    setLoading(true)
    let data = {
      path: `admin/training/kategori`
    }
    dispatch(getApi(data)).then(data => {
      let rawData = JSON.parse(JSON.stringify(data.data))
      let tempData = []
      rawData.forEach(el => {
        tempData.push({
          value: el.id,
          label: el.nama
        })
      })
      setCategories(tempData)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      setCategories([])
    })
  }

  const getKategoriPeserta = () => {
    setLoading(true)
    let data = {
      path: `admin/training/kategori-peserta`
    }
    dispatch(getApi(data)).then(data => {
      let rawData = JSON.parse(JSON.stringify(data.data))
      let tempData = []
      rawData.forEach(el => {
        tempData.push({
          value: el.id,
          label: el.nama
        })
      })
      setCategoryPeserta(tempData)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      setCategoryPeserta([])
    })
  }
  const selectCategory = e => {
    setCatPeserta(e)
    let rawPrice = [...priceDetail]
    let diff = e.find(({ value: a }) => !rawPrice.some(({ id: b }) => a === b))
    console.log(diff)
    let obj = {
      label: diff.label,
      id: diff.value,
      earlyBird: '',
      dateEarlyBird: '',
      hargaNormal: ''
    }
    setPriceDetail([...priceDetail, obj])
  }

  const renderImg = e => {
    const file = e.target.files[0];
    const fr = new FileReader();
    fr.onload = (f) => {
      setDp(f.target.result)
    };
    setInfoTraining({ ...infoTraining, cover: file });
    fr.readAsDataURL(file);
  }

  const [dialog, setDialog] = useState({
    kategori: false,
    pemateri: false
  })
  const [pemateri, setPemateri] = useState([])
  const prepareCreate = () => {
    let kategoriTraining = idKategori.length ? idKategori.map(e => e.id) : []
    let kategoriPeserta = catPeserta.length ? catPeserta.map(e => e.value) : []
    let selectedPemateri = pemateri.length ? pemateri.map(e => e.id) : []
    let harga = []
    let pelaksanaan = []
    if (priceDetail.length) {
      priceDetail.forEach(el => {
        harga.push({
          training_kategori_peserta_id: el.id,
          harga: el.hargaNormal,
          harga_early: el.earlyBird,
          tgl_early: el.dateEarlyBird ? dayjs(el.dateEarlyBird).format('YYYY-MM-DD') : ''
        })
      })
    }
    if (waktuTraining.length) {
      waktuTraining.forEach(el => {
        pelaksanaan.push({
          jenis: el.jenis,
          media: el.media,
          lokasi_link: el.link,
          tanggal_mulai: el.date ? dayjs(el.date).format('YYYY-MM-DD') : '',
          wkt_mulai: el.time_start ? dayjs(el.time_start).format('HH:mm') : '',
          wkt_selesai: el.time_end ? dayjs(el.time_end).format('HH:mm') : ''
        })
      })
    }
    let val_fasilitas = ''
    let val_bonus = ''
    let val_materi = ''
    fasilitas.forEach((el, i) => {
      if (el) {
        val_fasilitas = `${val_fasilitas}${el}${i < fasilitas.length - 1 ? ',' : ''}`
      }
    })
    bonus.forEach((el, i) => {
      if (el) {
        val_bonus = `${val_bonus}${el}${i < bonus.length - 1 ? ',' : ''}`
      }
    })
    materi.forEach((el, i) => {
      if (el) {
        val_materi = `${val_materi}${el}${i < materi.length - 1 ? ',' : ''}`
      }
    })
    createData(kategoriTraining, selectedPemateri, harga, pelaksanaan, kategoriPeserta, val_fasilitas, val_bonus, val_materi)
  }
  const [err, setErr] = useState({
    cp_training: '',
    gambar: '',
    judul: ''
  })
  const createData = (kategoriTraining, selectedPemateri, harga, pelaksanaan, kategoriPeserta, val_fasilitas, val_bonus, val_materi) => {
    let rawErr = { ...err }
    for (let i in rawErr) {
      rawErr[i] = ''
    }
    setErr(rawErr)
    setLoading(true)
    let body = new FormData()
    if (type != 'create') {
      body.append('id', type)
    }
    body.append('judul', infoTraining.judul)
    body.append('training_kategori_id', JSON.stringify(kategoriTraining))
    if (infoTraining.cover) {
      body.append('gambar', infoTraining.cover)
    }
    body.append('deskripsi', infoTraining.deskripsi)
    body.append('pemateri', JSON.stringify(selectedPemateri))
    body.append('pelaksanaan', JSON.stringify(pelaksanaan))
    body.append('materi', val_materi)
    body.append('fasilitas', val_fasilitas)
    body.append('bonus_training', val_bonus)
    body.append('training_kategori_peserta_id', JSON.stringify(kategoriPeserta))
    body.append('kuota', infoTraining.kuota)
    body.append('harga', JSON.stringify(harga))
    body.append('cp_training', infoTraining.cp_training)
    body.append('informasi_tambahan', infoDetail.informasi)

    let data = {
      path: `admin/training/${type == 'create' ? type : 'update'}`,
      body: body
    }
    dispatch(postApi(data)).then(res => {
      console.log(res)

      navigate(`/training-detail/${res.data.id}`)
      setLoading(false)
      const variant = "success";
      enqueueSnackbar(data.message ?? "Data tersimpan!", { variant });
    }).catch(err => {
      console.log(err)
      let require = err.data?.errors
      if (require) {
        for (let i in require) {
          rawErr[i] = require[i][0]
        }
      }
      setErr(rawErr)
      const variant = "error";
      enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
      setLoading(false)
    })
  }

  useEffect(() => {
    dispatch(CHANGE_TITLE(`${type == 'create' ? 'Tambah' : 'Update'}  Training`))
    if (type != 'create') {
      fetchData()
    }
    getKategoriTraining()
    getKategoriPeserta()
  }, [])

  const removePemateri = (item) => {
    setPemateri(pemateri.filter(e => e.id != item.id))
  }
  const removeKategori = item => {
    setIdKategori(idKategori.filter(e => e.id != item.id))
  }
  // custom style for select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: state.isFocused || state.hasValue ? '' : '#FBF4FE',
      borderRadius: '5px',
      border: 'none',
      outline: state.isFocused || state.hasValue ? `2px solid ${theme.tertiary}` : '',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '5px',
      padding: '3px',
    }),
    input: (provided, state) => ({
      ...provided,
      outline: state.isFocused ? '2px solid #FBF4FE' : 'none',
    }),
    multiValue: (provided) => ({
      ...provided,
      background: theme.tertiary
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: '5px',
      background: state.isSelected ? '#FBF4FE' : '',
      color: state.isSelected ? '#000' : '',
      ':hover': {
        background: '#FBF4FE',
      },
    }),
  }

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button
        variant="contained"
        style={{ minWidth: "0" }}
        className="capitalize rounded-lg  mb-3"
        onClick={() => navigate(-1)}
      >
        <ChevronLeft /> Kembali
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Paper elevation={0} className='rounded-lg pa-3'>
            {dp ? <div className='d-flex justify-center mb-3'><img src={dp} height='150px' alt='cover-psikotest' /></div> :
              <div className="d-flex align-center justify-center" style={{ height: '100px' }}>
                <p className='font-weight-bold text-center' style={{ color: theme.primary, fontWeight: '500' }}>Cover Psikotes</p>
              </div>}
            <div className="d-flex justify-center">
              <Button
                variant="outlined"
                className='capitalize'
                component="label"
              >
                <p className='font-weight-bold'>{dp ? 'Ubah Gambar' : 'Unggah Gambar'}</p>
                <input hidden onChange={renderImg} accept="image/*" multiple type="file" />
              </Button>
            </div>
            <p className='small_txt red-text  text-center'>{err.gambar}</p>

          </Paper>

        </Grid>
        <Grid item xs={9}>
          <Paper className='pa-5 rounded-lg mb-3'>
            <p className='mb-3' style={{ color: theme.primary, fontWeight: '500' }}>Detail Konsultasi</p>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Judul Training</p>
              </Grid>
              <Grid item xs={9}>
                <FlatInput
                  value={infoTraining.judul}
                  handleChange={handleInfoTraining}
                  property="judul"
                  placeholder='Tuliskan judul training'
                />
                <p className='small_txt red-text'>{err.judul}</p>
              </Grid>
              <Grid item xs={3}>
                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Kategori Training</p>
              </Grid>
              <Grid item xs={9}>
                <div
                  className='d-flex justify-space-between align-center cursor_pointer'
                  style={{
                    width: '100%',
                    height: '40px',
                    background: `${idKategori.length ? '#fff' : theme.tertiary}`,
                    borderRadius: '5px',
                    border: `2px solid ${theme.tertiary}`,
                    padding: '0 5px',
                  }}
                  onClick={() => setDialog({ ...dialog, kategori: true })}
                >
                  <div className='d-flex'>
                    {idKategori.length ? idKategori.map((el, i) => i < 4 ? <div className='label-cat mr-1 d-flex' style={{ background: theme.tertiary }} key={`idKategori-${i}`}>
                      <LinesEllipsis style={{ fontSize: '12px' }} className='mr-2' text={el.nama} />
                      <HighlightOff fontSize='small' color='red' onClick={(e) => { e.stopPropagation(); removeKategori(el) }} />
                    </div> : <></>) : <p style={{ fontSize: 'small' }}>Kategori Training</p>}
                    {idKategori.length > 4 ? <div className='label-cat' style={{ background: theme.tertiary }}>
                      <p style={{ color: theme.primary, fontSize: '12px' }}>+{idKategori.length - 4} </p>
                    </div> : <></>}
                  </div>
                  <ExpandMore color='primary' />
                </div>
                {/* {!loading ?
                  <Select
                    styles={customStyles}
                    classNamePrefix="select"
                    options={categories}
                    defaultValue={idKategori}
                    onChange={(e) => setIdKategori(e)}
                    isMulti
                    name="color"
                  /> : <></>} */}
              </Grid>
              <Grid item xs={3}>
                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Deskripsi Training</p>
              </Grid>
              <Grid item xs={9}>
                <FlatArea
                  value={infoTraining.deskripsi}
                  handleChange={handleInfoTraining}
                  property="deskripsi"
                  placeholder='Tuliskan deskripsi singkat mengenai training'
                />
              </Grid>
              <Grid item xs={3}>
                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Nama Pemateri</p>
              </Grid>
              <Grid item xs={9}>
                <div
                  className='d-flex justify-space-between align-center cursor_pointer'
                  style={{
                    width: '100%',
                    height: '40px',
                    background: `${pemateri.length ? '#fff' : theme.tertiary}`,
                    borderRadius: '5px',
                    border: `2px solid ${theme.tertiary}`,
                    padding: '0 5px',
                  }}
                  onClick={() => setDialog({ ...dialog, pemateri: true })}
                >
                  <div className='d-flex'>
                    {pemateri.length ? pemateri.map((el, i) => i < 4 ? <div className='label-cat mr-1 d-flex' style={{ background: theme.tertiary }} key={`pemateri-${i}`}>
                      <LinesEllipsis style={{ fontSize: '12px' }} className='mr-2' text={el.nama} />
                      <HighlightOff fontSize='small' color='red' onClick={(e) => { e.stopPropagation(); removePemateri(el) }} />
                    </div> : <></>) : <p style={{ fontSize: 'small' }}>Nama Pemateri</p>}
                    {pemateri.length > 4 ? <div className='label-cat' style={{ background: theme.tertiary }}>
                      <p style={{ color: theme.primary, fontSize: '12px' }}>+{pemateri.length - 4} </p>
                    </div> : <></>}
                  </div>
                  <ExpandMore color='primary' />
                </div>
              </Grid>
              <Grid item xs={3}>
                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Kuota Peserta</p>
                <p style={{ color: theme.primary, fontSize: '12px' }}>Kosongkan bila tidak ada</p>
              </Grid>
              <Grid item xs={9}>
                <div style={{ width: '50%' }}>
                  <FlatInput
                    value={infoTraining.kuota}
                    type='number'
                    handleChange={handleInfoTraining}
                    property="kuota"
                    placeholder='Jumlah maksimal peserta'
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Kategori Peserta</p>
              </Grid>
              <Grid item xs={9}>
                {!loading ?
                  <Select
                    styles={customStyles}
                    classNamePrefix="select"
                    options={categoryPeserta}
                    defaultValue={catPeserta}
                    onChange={(e) => selectCategory(e)}
                    isMulti
                    name="color"
                  /> : <></>}
              </Grid>
              <Grid item xs={3}>
                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Contact Person</p>
              </Grid>
              <Grid item xs={9}>
                <FlatInput
                  value={infoTraining.cp_training}
                  handleChange={handleInfoTraining}
                  property="cp_training"
                  placeholder='Masukkan nomor WhatsApp yang bisa dihubungi peserta'
                />
                <p className='small_txt red-text'>{err.cp_training}</p>
              </Grid>
            </Grid>
          </Paper>
          {priceDetail.length ? <DetailHargaTraining theme={theme} priceDetail={priceDetail} setPriceDetail={setPriceDetail} /> : <></>}
          <WaktuTraining theme={theme} waktuTraining={waktuTraining} setWaktuTraining={setWaktuTraining} />
          <DetailTraining
            theme={theme}
            infoDetail={infoDetail}
            setInfoDetail={setInfoDetail}
            fasilitas={fasilitas}
            setFasilitas={setFasilitas}
            bonus={bonus}
            setBonus={setBonus}
            materi={materi}
            setMateri={setMateri}
          />
        </Grid>
      </Grid>
      <div className="d-flex justify-end">
        <Button variant="contained" onClick={prepareCreate}>
          <p className="font-weight-bold small_txt capitalize">simpan</p>
        </Button>
      </div>
      <div className="pa-2">
        <SelectPemateriTraining dialog={dialog.pemateri} close={() => setDialog({ ...dialog, pemateri: false })} pemateri={pemateri} setPemateri={setPemateri} />
        <SelectKategoriTraining dialog={dialog.kategori} close={() => setDialog({ ...dialog, kategori: false })} idKategori={idKategori} setIdKategori={setIdKategori} />
      </div>
    </div>
  )
}

export default CreateTraining

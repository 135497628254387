/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, Card, Paper } from '@mui/material'
import locale from "dayjs/locale/id";
import day from 'dayjs';
import React from 'react'
import css from '../../../../assets/css/calendarAdmin.module.css'
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";
import LinesEllipsis from 'react-lines-ellipsis'
import { useEffect, useState } from 'react';
import DetailJadwal from './detailJadwal';
/** @jsxImportSource @emotion/react */
import { css as emot } from '@emotion/react'

const WeekKonselingAdmin = ({ startDate, endDate, setStart, setEnd, theme, dataSet }) => {
    day.extend(weekdayPlugin);
    day.extend(objectPlugin);
    day.extend(isTodayPlugin);
    const now = day().locale({
        ...locale,
    });

    const [arrayOfDays, setArrayOfDays] = useState([]);

    const nextWeek = () => {
        const plus = day(endDate).add(1, "day");
        setStart(day(plus).day(0).format('YYYY-MM-DD'))
        setEnd(day(plus).day(6).format('YYYY-MM-DD'))
    };

    const prevWeek = () => {
        const minus = day(startDate).subtract(1, "day");
        setStart(day(minus).day(0).format('YYYY-MM-DD'))
        setEnd(day(minus).day(6).format('YYYY-MM-DD'))
    };

    const renderHeader = () => {
        return (
            <div>
                <div className="d-flex align-center justify-space-between mb-3">
                    <h3 style={{ color: theme.primary }}>{day(startDate).format('D MMM.')} - {day(endDate).format('D MMM. YYYY')}</h3>
                    <div className="d-flex align-center">
                        <Button className='capitalize mr-2' variant="outlined" style={{ minWidth: '10px' }} onClick={() => prevWeek()}>
                            <ChevronLeft color='primary' />
                        </Button>
                        <Button className='capitalize' variant="outlined" style={{ minWidth: '10px' }} onClick={() => nextWeek()}>
                            <ChevronRight color='primary' />
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const renderDays = () => {
        let space = <div className={css.space} key='space'></div>
        const days = [];
        days.push(space)
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className={`${css.col} ${css['col-center']} font-weight-bold white-text`} key={`d-${i}`}>
                    {now.day(i).format("dddd")}
                </div>
            );
        }
        return <div className={`${css.days} ${css.row} ${css.card_days} py-3`}>{days}</div>;
    };

    const renderDates = () => {
        let space = <div className={css.space} key='space'></div>
        const days = [];
        arrayOfDays.forEach((now, i) => {
            if (now.hour) {
                days.push(space)
            } else {
                days.push(
                    <div className={`${css.col} ${css['col-center']}`} style={{ color: theme.primary }} key={`d-${i}`}>
                        <p className="font-weight-bold small_txt">
                            {day(now.waktu).format("DD/MM")}
                        </p>
                    </div>
                );
            }
        })
        return <div className={`${css.days} ${css.row} py-1`} style={{ background: theme.tertiary }}>{days}</div>;
    };

    let hours = ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20']

    const getAllDays = () => {
        let allDay = []
        let hour = {
            type: 'hour',
            hour: hours,
            waktu: null,
            year: null,
            month: null,
            date: null,
            day: null,
        }
        allDay.push(hour)
        for (let i = 0; i < 7; i++) {
            let date = day(startDate).locale('id').day(i)
            allDay.push({
                type: 'date',
                waktu: date.format('YYYY-MM-DD'),
                year: date.format('YYYY'),
                month: date.format('MM'),
                date: date.format('DD'),
                day: date.format('dddd'),
                hour: null
            })
        }
        for (let i in dataSet) {
            let index = null
            index = allDay.findIndex(ad => ad.waktu == i)
            if (index > -1) {
                allDay[index]['schedule'] = dataSet[i]
            }
        }
        setArrayOfDays(allDay)
    }

    const [pocket, setPocket] = useState(null)
    const [dialog, setDialog] = useState(false)

    const pickSchedule = item => {
        let data = {
            waktu: item.waktu,
            konselor: item.data_konselor?.nama ?? 'Anonymouse',
            layanan: null,
            klien: null,
            booked: false,
            role: item.data_konselor ? item.role : '',
            sub_role: item.data_konselor ? item.sub_role : ''
        }
        if (item.thread) {
            data['layanan'] = item.thread[0].konsultasi
            data['klien'] = item.thread[0].data_pelanggan.nama
            data['booked'] = true
        }
        setPocket(data)
        setDialog(true)
    }

    const renderCard = (h, day) => {
        let cards = []
        if (day.schedule) {
            day.schedule.forEach((ppl, i) => {
                if (ppl.hour == h) {
                    cards.push(
                        <Paper className='pa-2 mb-2 flexy cursor_pointer'
                            onClick={() => pickSchedule(ppl)}
                            sx={{
                                background: ppl.role == 'Psikolog' ? '#E8F6F8' : ppl.role == 'Coach' ? '#FFF6E7' : '#F6EDFF',
                                position: 'relative',
                                display: 'block',
                                overflow: 'hidden'
                            }}
                            elevation={ppl['status-booking'] == 'booked' ? 0 : 1} key={i}>
                            <p className="font-weight-bold"
                                style={{ fontSize: '12px', color: ppl.role == 'Psikolog' ? '#10A3B0' : ppl.role == 'Coach' ? '#FF9F0E' : '#A449FF', }}>
                                {ppl.role}
                            </p>
                            <LinesEllipsis
                                className="font-weight-bold"
                                style={{ fontSize: '12px' }}
                                text={ppl.data_konselor ? ppl.data_konselor.nama : 'Anonymous'}
                            />
                            {ppl['status-booking'] == 'booked' ? <div className="backdrop"></div> : <></>}
                        </Paper>
                    )
                    return
                }
            })
        }
        return <div> {cards} </div>
    }


    useEffect(() => {
        getAllDays()
    }, [startDate, dataSet])

    const renderCells = () => {
        const rows = [];
        let cols = []
        hours.forEach((hour, idx) => {
            arrayOfDays.forEach((day, index) => {
                cols.push(<div key={index}
                    className={`${day.hour ? css.space : css.col}`}
                    css={emot`
                        border-right: 1px solid ${theme.tertiary};
                        &:last-child {
                            border-right: none;
                        }
                    `}>
                    {day.hour ?
                        <div className='d-flex justify-center align-center' style={{ height: '100%' }}>
                            <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>{hour < 10 ? `0${hour}:00` : `${hour}:00`}</p>
                        </div>
                        : <div className='pa-1'>{renderCard(hour, day)}</div>}
                </div>)
            })
            rows.push(
                <div key={idx} css={emot`
                    border-bottom: 1px solid ${theme.tertiary};
                    min-height:100px;
                    display: flex;
                    &:last-child {
                        border-bottom: none;
                        }
                    `}>
                    {cols}
                </div>
            )
            cols = []
        })
        return <div>{rows}</div>;
    }

    return (
        <Card className="pa-3 rounded-lg">
            {renderHeader()}
            {renderDays()}
            {renderDates()}
            <div className={css.calendar} style={{ border: `1px solid ${theme.tertiary}` }}>
                {renderCells()}
            </div>
            <div className="py-2">
                <DetailJadwal dialog={dialog} theme={theme} close={() => setDialog(false)} pocket={pocket} />
            </div>
        </Card>
    )
}

export default WeekKonselingAdmin

import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material'
import { postApi } from '../../../../store/apis'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux'
import { Close as CloseIcon,  Notes } from '@mui/icons-material';
import FlatInput from '../../../../components/base/flatInput';
import FlatArea from '../../../../components/base/flatArea';

const PostSection = ({ dialog = false, close, refetch, pocket, form, setForm }) => {
    const theme = useSelector(state => state.theme.myTheme)
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const handleChange = (prop) => (event) => {
        setForm({ ...form, [prop]: event.target.value })
    }

    const postData = () => {
        setLoading(true)
        let body = {
            id_course: form.id_course,
            judul: form.judul,
            deskripsi: form.deskripsi
        }
        if (pocket.type === 'update') {
            body['id'] = form.id
        }
        let data = {
            path: pocket.path,
            body: body
        }

        let variant = ''
        dispatch(postApi(data)).then(data => {
            variant = 'success'
            enqueueSnackbar(data.message, { variant });
            setLoading(false)
            setForm({ ...form, judul: '', deskripsi: '' })
            close()
            refetch()
        }).catch(err => {
            console.log(err)
            setLoading(false)
            variant = 'error'
            if (err.error) {
                let msg = Object.values(err.error).map(el => el[0])
                msg.forEach(msg => {
                    enqueueSnackbar(msg, { variant });
                })
            } else {
                enqueueSnackbar(err.message, { variant });
            }
        })
    }
    return (
        <Dialog
            open={dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: { borderRadius: 10 }
            }}
        >
            <DialogTitle id="alert-dialog-title" className='py-3'>
                <div className="d-flex justify-space-between">
                    <h4>{pocket ? pocket.title : ''}</h4>
                    <IconButton onClick={close}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent >
                {form ?
                    <div className='borbox' style={{ width: '400px', padding: '5px' }}>
                        <div className="mb-4">
                            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>Judul Section</p>
                            <FlatInput value={form.judul} handleChange={handleChange} property='judul' />
                        </div>
                        <div className="mb-4">
                            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>Deskripsi Section</p>
                            <FlatArea value={form.deskripsi} icon={<Notes color='primary' />} handleChange={handleChange} property='deskripsi' />
                        </div>
                        <div className="d-flex justify-end">
                            {!loading ?
                                <Button variant='contained' className='capitalize rounded-lg' disableElevation onClick={() => postData()}><p className="font-weight-bold">Submit</p></Button> :
                                <Button variant='contained' className='capitalize rounded-lg' disableElevation><p className="font-weight-bold">Loading...</p></Button>}
                        </div>
                    </div> : <></>
                }
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>
    )
}

export default PostSection

import React, { useState } from "react";
import { useSelector } from "react-redux";
import css from "../../assets/css/search.module.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const InputWithIcon = ({ value, property, handleChange, placeholder = "", icon, type = "text", positionIcon = "left" }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const [show, setShow] = useState(false);
  const showPwd = () => {
    setShow(!show);
  };
  return (
    <div className="d-flex flexy align-center rounded-lg" style={{ background: theme.lighten, padding: "3px" }}>
      {positionIcon === "left" && (
        <div className="px-2 d-flex align-center" style={{ background: theme.lighten }}>
          {icon}
        </div>
      )}
      <input
        type={type === "password" && show ? "text" : type}
        value={value}
        className={css.icon_input}
        placeholder={placeholder}
        onChange={handleChange(property)}
        style={{ background: theme.tertiary, borderRadius: "0 5px 5px 0" }}
      />
      {positionIcon === "right" && (
        <div className="px-2 d-flex align-center" style={{ background: theme.lighten }}>
          {icon}
        </div>
      )}
      {type == "password" ? (
        !show ? (
          <IconButton onClick={() => showPwd()}>
            {" "}
            <Visibility />{" "}
          </IconButton>
        ) : (
          <IconButton onClick={() => showPwd()}>
            {" "}
            <VisibilityOff />{" "}
          </IconButton>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default InputWithIcon;

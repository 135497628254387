import { ArrowDropDown, ArrowRight, SearchRounded } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const SelectWithSearch = ({ itemDefault = { value: "", label: "" }, listData, placeholder, onOpen, onChange }) => {
  const ref = useRef();
  const theme = useSelector((state) => state.theme.myTheme);

  const [element, setElement] = useState({
    option: {
      display: "d-none",
    },
  });

  const [data, setData] = useState([]);
  const [input, setInput] = useState({ value: itemDefault });

  useEffect(() => {
    setData(listData);
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClosePermanent();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, listData]);

  const onClick = () => {
    onClose();
  };

  const handleOptionClick = (item) => {
    onChange && onChange(item);
    setInput({ ...input, value: item });
    onClose();
  };

  const handleKeyUp = (event) => {
    const search = event.target.value;
    let dataSearch = listData.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));
    setData(dataSearch);
  };

  const onClose = () => {
    onOpen && element.option.display === "d-none" && onOpen();
    setElement({
      option: {
        display: element.option.display === "d-block" ? "d-none" : "d-block",
      },
    });
  };

  const onClosePermanent = () => {
    setElement({
      option: {
        display: "d-none",
      },
    });
  };

  return (
    <div className="relative" ref={ref}>
      <div className="d-flex rounded-lg" style={{ background: theme.tertiary, border: "2px solid " + theme.lighten }}>
        <input
          type="text"
          value={input?.value?.label ?? ""}
          readOnly={true}
          className="rounded-lg pa-3 full_width"
          style={{ border: "none", background: theme.tertiary, outline: "none" }}
          placeholder={placeholder}
          onClick={() => onClick()}
        />
        <div className="px-2 d-flex align-center">{element.option.display === "d-block" ? <ArrowDropDown style={{ color: theme.primary }} /> : <ArrowRight style={{ color: theme.primary }} />}</div>
      </div>
      <div className={`${element.option.display} mt-2 white-bg full_width pa-2 rounded-lg absolute z-10`} style={{ border: "2px solid " + theme.lighten }}>
        <div className="d-flex rounded-lg" style={{ background: theme.tertiary, border: "2px solid " + theme.lighten }}>
          <input
            type="text"
            className="rounded-lg pa-3 full_width"
            style={{ border: "none", background: theme.tertiary, outline: "none" }}
            placeholder="Cari.."
            onKeyUp={(event) => handleKeyUp(event)}
          />
          <div className="px-2 d-flex align-center">
            <SearchRounded style={{ color: theme.primary }} />
          </div>
        </div>
        <div className="py-2" style={{ overflowY: "auto", maxHeight: "10rem" }}>
          {data.map((item, index) => {
            return (
              <div key={index} className="pa-3 cursor_pointer rounded-lg primary-light-bg" onClick={() => handleOptionClick(item)}>
                <small className="px12_txt">{item.label}</small>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectWithSearch;

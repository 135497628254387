import { ChevronLeft } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../../../store/theming";
import { useNavigate, useParams } from "react-router-dom";
import { MyInput } from "../../../../../components/base/myInput";
import FlatArea from "../../../../../components/base/flatArea";
import CheckboxText from "../../../../../components/base/checkboxText";
import ModalSelectAndAdd from "../../../../../components/base/ModalSelectAndAdd";
import { fetchApi, postApi } from "../../../../../store/apis";
import { useSnackbar } from "notistack";

const CoachUpdateClass = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const theme = useSelector((state) => state.theme.myTheme);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    listKategori: [],
  });

  useEffect(() => {
    dispatch(CHANGE_TITLE("Ubah Kelas Coaching"));
    getDetailKelas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetailKelas = () => {
    let data = {
      path: `konselor/coach/kelas/detail?kelas_id=${id}`,
    };
    dispatch(fetchApi(data))
      .then((data) => {
        setForm(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setForm = (data) => {
    const dataBatch = {
      image: {
        image: "",
        preview: data.gambar_link,
      },
      kategori: {
        id: data.kategori.id,
        value: data.kategori.kategori,
      },
      nama_kelas: data.nama_kelas,
      keterangan: data.keterangan,
      harga: data.harga,
      potongan_harga: data.potongan_harga,
      durasi: data.durasi,
      jumlah_sesi: data.jumlah_sesi,
      attributes: data.attr_decode,
    };
    setData({ ...dataBatch, kelas: data });
  };

  const getKategoriKelas = () => {
    let data = {
      path: `konselor/coach/kategori`,
    };
    dispatch(fetchApi(data))
      .then((data) => {
        let kategoriKelas = data.data;
        formattingKategori(kategoriKelas);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formattingKategori = (listData) => {
    let dataItem = [];
    listData.data.forEach((item, index) => {
      dataItem.push({
        id: item.id,
        value: item.kategori,
      });
    });

    setData({ ...data, listKategori: dataItem });
  };

  const goBack = () => {
    navigation(-1);
  };

  const handleOnChange = (event) => {
    const imageFile = event.target.files[0];
    const preview = URL.createObjectURL(imageFile);
    setData({
      ...data,
      image: {
        image: imageFile,
        preview: preview,
      },
    });
  };

  const handleFieldChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleKeterangan = (prop) => (event) => {
    let value = event.target?.value || event;
    value = value.type ? "" : value;
    handleFieldChange("keterangan", value);
  };

  const onAttributes = (checked, value) => {
    let kode = value.toLowerCase();
    kode = kode.replace(" ", "_");

    let dataItem = {
      kode: kode,
      name: value,
    };

    if (checked) {
      data.attributes && (dataItem = [...data.attributes, dataItem]);
      !data.attributes && (dataItem = [dataItem]);
      setData({ ...data, attributes: dataItem });
    } else {
      let dataAttributes = [...data.attributes];
      let indexData = dataAttributes.findIndex((x) => x.kode === kode);
      dataAttributes.splice(indexData, 1);
      setData({ ...data, attributes: dataAttributes });
    }
  };

  const checkChecked = (attribute) => {
    let status = false;
    let indexData = data.attributes ? data.attributes.find((x) => x.kode === attribute) : undefined;
    indexData !== undefined && (status = true);
    return status;
  };

  const formattingData = () => {
    const body = new FormData();
    body.append("id", id);
    if (data.image.image !== "") {
      body.append("gambar", data.image?.image ?? null);
    }
    body.append("coach_kelas_kategori_id", data.kategori?.id ?? null);
    body.append("nama_kelas", data.nama_kelas);
    body.append("keterangan", data.keterangan);
    body.append("harga", data.harga);
    body.append("potongan_harga", data.potongan_harga);
    body.append("durasi", data.durasi);
    body.append("jumlah_sesi", data.jumlah_sesi);
    body.append("attr", JSON.stringify(data.attributes));

    data.kategori && data.kategori.draf && body.append("coach_kelas_kategori_draf", data.kategori.value);

    return body;
  };

  const onRequestCreate = async () => {
    let data = {
      path: `konselor/coach/kelas/update`,
      body: formattingData(),
    };

    let status = false;
    await dispatch(postApi(data))
      .then((data) => {
        status = true;
        enqueueSnackbar("Kelas berhasil diperbarui", { variant: "success" });
      })
      .catch((err) => {
        const variant = "error";
        if (err.data.errors) {
          const error = err.data.errors;
          Object.keys(error).forEach((message) => {
            enqueueSnackbar(error[message], { variant });
          });
        } else {
          enqueueSnackbar(err.message ?? "Kelas gagal diperbarui", { variant });
        }
      });

    return status;
  };

  const onSave = async () => {
    setLoading(true);
    await onRequestCreate().then((res) => {
      setLoading(false);
      if (res) {
        navigation(-1);
      }
    });
  };

  return (
    <div>
      <Button variant="contained" style={{ minWidth: "0" }} className="capitalize rounded-lg" onClick={() => goBack()}>
        <ChevronLeft /> Kembali
      </Button>
      <div className="d-flex flex-wrap gap-3 mt-4">
        <div>
          <div className="rounded-lg white-bg pa-4">
            <span className="font-weight-500" style={{ color: theme.primary }}>
              Poster Kelas
            </span>
            {data.image && (
              <div style={{ width: "18rem" }}>
                <img src={data.image?.preview} alt="Image_upload" className="full_width" style={{ objectFit: "fill" }} />
              </div>
            )}
            <div className="mt-3 text-center" style={{ width: "18rem" }}>
              <div className="relative">
                <Button variant="outlined" className="full_width capitalize">
                  Unggah Gambar
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  className="absolute fileInputHidden"
                  onChange={(event) => {
                    handleOnChange(event);
                  }}
                />
              </div>
              <div className="leading-3 xsmall_txt mt-1 font-weight-300" style={{ color: theme.darken }}>
                <small>
                  Unggah gambar atau poster kelas anda untuk di tampilkan
                  <br />
                  di Aplikasi Teman Bakat. Dimensi gambar disarankan 1:1,
                  <br />
                  tidak lebih dari 2MB. dalam format .PNG atai JPEG
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-lg white-bg pa-4 grow">
          <span className="font-weight-500" style={{ color: theme.primary }}>
            Informasi Kelas
          </span>
          <div className="mt-3 px-2">
            <div className="d-flex align-center">
              <div style={{ width: "8rem" }}>
                <span className="px12_txt font-weight-500" style={{ color: theme.darken }}>
                  Nama Kelas
                </span>
              </div>
              <div className="grow">
                <MyInput
                  value={data.nama_kelas}
                  placeholder="..."
                  handleChange={(value) => {
                    handleFieldChange("nama_kelas", value);
                  }}
                />
              </div>
            </div>
            <div className="d-flex align-center mt-4">
              <div style={{ width: "8rem" }}>
                <span className="px12_txt font-weight-500" style={{ color: theme.darken }}>
                  Kategori Kelas
                </span>
              </div>
              <div className="grow">
                <ModalSelectAndAdd
                  defaultValue={data.kategori}
                  listData={data.listKategori}
                  placeholderChild="Tulis kategori disini"
                  placeholderParent="Pilih atau tambahkan kategori kelas"
                  onClick={() => {
                    getKategoriKelas();
                  }}
                  onChanged={(chooseItem) => {
                    handleFieldChange("kategori", chooseItem);
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-space-between">
              <div className="d-flex align-center mt-4">
                <div style={{ width: "8rem" }} className="leading-3">
                  <span className="px12_txt font-weight-500" style={{ color: theme.darken }}>
                    Durasi Kelas
                  </span>
                  <div>
                    <small className="px11_txt" style={{ color: theme.lighten }}>
                      30 - 60 menit
                    </small>
                  </div>
                </div>
                <div className="grow" style={{ width: "7rem" }}>
                  <MyInput
                    value={data.durasi ? parseFloat(data.durasi) : ""}
                    type="number"
                    placeholder="Durasi Sesi"
                    handleChange={(value) => {
                      handleFieldChange("durasi", value);
                    }}
                  />
                </div>
                <span className="px11_txt ml-2">Menit</span>
              </div>
              <div className="d-flex align-center mt-4">
                <div style={{ width: "7rem" }} className="leading-3">
                  <span className="px12_txt font-weight-500" style={{ color: theme.darken }}>
                    Jumlah Sesi
                  </span>
                  <div>
                    <small className="px11_txt" style={{ color: theme.lighten }}>
                      1 - 3 Sesi
                    </small>
                  </div>
                </div>
                <div className="grow" style={{ width: "7rem" }}>
                  <MyInput
                    value={data.jumlah_sesi ? parseFloat(data.jumlah_sesi) : ""}
                    type="number"
                    placeholder="Jumlah Sesi"
                    handleChange={(value) => {
                      handleFieldChange("jumlah_sesi", value);
                    }}
                  />
                </div>
                <span className="px11_txt ml-2">Sesi</span>
              </div>
            </div>
            <div className="d-flex justify-space-between">
              <div className="d-flex align-center mt-4">
                <div style={{ width: "8rem" }} className="leading-3">
                  <span className="px12_txt font-weight-500" style={{ color: theme.darken }}>
                    Harga Normal
                  </span>
                </div>
                <div className="grow" style={{ width: "8rem" }}>
                  <MyInput
                    value={data.harga ? parseFloat(data.harga) : ""}
                    type="number"
                    placeholder="Harga Normal"
                    handleChange={(value) => {
                      handleFieldChange("harga", value);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex align-center mt-4">
                <div style={{ width: "10rem" }} className="leading-3">
                  <span className="px12_txt font-weight-500" style={{ color: theme.darken }}>
                    Harga Stlh. Promo
                  </span>
                  <div>
                    <small className="px11_txt" style={{ color: theme.lighten }}>
                      Kosongkan bila tidak ada
                    </small>
                  </div>
                </div>
                <div className="grow" style={{ width: "8rem" }}>
                  <MyInput
                    value={data.potongan_harga ? parseFloat(data.potongan_harga) : ""}
                    type="number"
                    placeholder="Harga Setelah Promo"
                    handleChange={(value) => {
                      handleFieldChange("potongan_harga", value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex mt-4">
              <div style={{ width: "8rem" }}>
                <span className="px12_txt font-weight-500" style={{ color: theme.darken }}>
                  Deskripsi Kelas
                </span>
              </div>
              <div className="grow">
                <FlatArea
                  value={data.keterangan}
                  placeholder="Tulis deskripsi singkat gambaran kelas Anda seperti tujuan atau topik couching."
                  handleChange={handleKeterangan}
                  property="keterangan"
                  handleBlur={() => {}}
                />
              </div>
            </div>
            <div className="d-flex mt-4">
              <div style={{ width: "8rem" }}>
                <span className="px12_txt font-weight-500" style={{ color: theme.darken }}>
                  Fitur Kelas
                </span>
              </div>
              <div className="grow">
                <div className="mt-2">
                  <CheckboxText title="Voice Call" value="Voice Call" checked={checkChecked("voice_call")} onChecked={(checked, value) => onAttributes(checked, value)} />
                </div>
                <div className="mt-2">
                  <CheckboxText title="Video Call" value="Video Call" checked={checkChecked("video_call")} onChecked={(checked, value) => onAttributes(checked, value)} />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-end align-center gap-3 mt-5">
            <div>
              <span
                className="small_txt font-weight-500 cursor_pointer"
                style={{ color: theme.darken }}
                onClick={() => {
                  goBack();
                }}>
                Batalkan
              </span>
            </div>
            <div>
              <Button variant="contained" disabled={loading} className="capitalize rounded-lg" onClick={() => onSave()}>
                Simpan
                {loading && <CircularProgress color="primary" size={20} style={{ marginLeft: 5 }} />}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachUpdateClass;

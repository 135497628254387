import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { AddRounded, AutorenewRounded, CameraAltRounded, DownloadRounded, LinearScaleRounded, MoreHorizRounded, PictureAsPdfRounded, Visibility } from "@mui/icons-material";
import { Menu, MenuDivider, MenuHeader, MenuItem } from "@szhsin/react-menu";
import { useNavigate } from "react-router-dom";

const PsikotesActivityList = ({ theme, dataSet, ava, page, limit }) => {
  const env = process.env;
  // Navigation
  const navigate = useNavigate();

  const colorStatus = (status) => {
    let data = {
      Selesai: "green-text",
      "Tidak Selesai": "red-text",
      Berjalan: "purple-text",
    };

    return data[status];
  };

  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell style={{ minWidth: "50px", background: theme.tertiary, borderRadius: "10px 0 0 10px" }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  No.
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "200px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Nama User
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "150px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Email
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "150px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Judul Psikotes
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "130px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tgl. Check Out
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Status Pengerjaan
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "130px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tgl Selesai
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Waktu Selesai
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Metode Pembelian
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary, borderRadius: "0 10px 10px 0" }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tindakan
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSet.map((item, i) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell className="py-2">
                  <p className="px11_txt">{i + 1 + (page - 1) * limit}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt"> {item.data_pelanggan2?.nama ?? "-"} </p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.data_pelanggan2?.email ?? "-"}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.data_psikotes?.nama_psikotes ?? "-"}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.tgl_checkout}</p>
                </TableCell>
                <TableCell className="py-2">
                  <div className="d-flex justify-center align-center">
                    <span className={`px11_txt font-weight-bold ${colorStatus(item.status_pengerjaan)}`}>{item.status_pengerjaan}</span>
                  </div>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.status_pengerjaan === "Selesai" ? item.data_jawaban_pelanggan_last?.tgl_selesai ?? "-" : "-"}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.status_pengerjaan === "Selesai" ? item.data_jawaban_pelanggan_last?.wkt_selesai ?? "-" : "-"}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">Pending</p>
                </TableCell>
                <TableCell className="py-2">
                  <div className="d-flex justify-center items-center">
                    <Menu
                      menuStyle={{ border: "1px dashed " + theme.primary }}
                      menuButton={<MoreHorizRounded className="rounded cursor_pointer d-block" fontSize="medium" style={{ color: theme.darken, backgroundColor: theme.lighten, padding: "3px" }} />}
                      direction="left"
                      arrow={true}>
                      <MenuHeader>
                        <small>Menu</small>
                      </MenuHeader>
                      <MenuItem className="primary-light-bg" onClick={() => navigate(`/psikotes/result/${item.id}`)}>
                        <Visibility fontSize="small" className="mr-2" style={{ color: theme.primary }} />
                        <small className="font-weight-500">Lihat Hasil</small>
                      </MenuItem>
                      <MenuItem className="primary-light-bg">
                        <LinearScaleRounded fontSize="small" className="mr-2" style={{ color: theme.primary }} />
                        <small className="font-weight-500">Proctoring</small>
                      </MenuItem>
                      <MenuItem className="primary-light-bg" onClick={() => navigate(`/psikotes/capture/${item.id}/${item.data_pelanggan2.id}`, { state: { page: page } })}>
                        <CameraAltRounded fontSize="small" className="mr-2" style={{ color: theme.primary }} />
                        <small className="font-weight-500">Cam Capture</small>
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        className="primary-light-bg"
                        onClick={() => window.open(env.REACT_APP_NOS + "/testonline/" + item.data_pelanggan2.id + "/" + item.id + "/report/" + item.file_name + ".pdf", "_blank")}>
                        <PictureAsPdfRounded fontSize="small" className="mr-2" style={{ color: theme.primary }} />
                        <small className="font-weight-500">Lihat PDF</small>
                      </MenuItem>
                      <MenuItem
                        className="primary-light-bg"
                        onClick={() => window.open(env.REACT_APP_ENV + "/report/" + item.id_psikotes + "/" + item.data_pelanggan2.id + "/" + item.id + "/" + item.file_name, "_blank")}>
                        <AutorenewRounded fontSize="small" className="mr-2" style={{ color: theme.primary }} />
                        <small className="font-weight-500">Generate PDF</small>
                      </MenuItem>
                      <MenuItem className="primary-light-bg">
                        <DownloadRounded fontSize="small" className="mr-2" style={{ color: theme.primary }} />
                        <small className="font-weight-500">Download Excel</small>
                      </MenuItem>
                      <MenuItem className="primary-light-bg">
                        <AddRounded fontSize="small" className="mr-2" style={{ color: theme.primary }} />
                        <small className="font-weight-500">Tambah Kesempatan</small>
                      </MenuItem>
                    </Menu>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pa-2"></div>
    </div>
  );
};

export default PsikotesActivityList;

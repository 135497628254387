import { createSlice } from "@reduxjs/toolkit";
const axios = require("axios");

const initialState = {
  data: [],
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    GET_DATA: (state, action) => {
      state.data = [action.payload];
    },
  },
});

export const authLogin = (data) => async (dispatch) => {
  const env = process.env.REACT_APP_ENV;
  const res = await axios.post(`${env}/${data.path}`, data.body);
  dispatch(GET_DATA(res.data));
  return res;
};

export const { GET_DATA } = auth.actions;
export default auth.reducer;

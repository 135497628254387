import { StickyNote2 } from "@mui/icons-material";
import { Card } from "@mui/material";
import { useSelector } from "react-redux";

const CardPanduan = () => {
  const theme = useSelector((state) => state.theme.myTheme);

  return (
    <Card className="pa-4 rounded-lg" style={{ backgroundColor: theme.primary }}>
      <div className="d-flex gap-3">
        <StickyNote2 style={{ color: theme.white, width: "3rem", height: "3rem" }} />
        <div className="grow">
          <h4 style={{ color: theme.white }}>Panduan Psikolog/Coach</h4>
          <small style={{ color: theme.white }}>Versi 3.1.1 | Update 29 Mei 2022</small>
          <div className="d-flex align-end justify-end mt-2">
            <span type="button" className="py-1 cursor_pointer px-4 rounded-xl" style={{ backgroundColor: theme.white, color: theme.primary }}>
              <small className="font-weight-700">Buka</small>
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CardPanduan;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CHANGE_TITLE } from "../../../../store/theming";
import { Button, Card, Pagination } from "@mui/material";
import { ChevronLeft, Description, FilterAlt, PictureAsPdf, Print } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import PsikotesFilterMastab from "../psikotesFilterMastab";
import PsikotesMasterTable from "./psikotesMasterTable";
import { postApi, postBlobApi } from "../../../../store/apis";

const PsikotesMasterTablePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Location
  const location = useLocation();
  const [d_indie, setDialog] = useState(false);
  const [data, setData] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    dispatch(CHANGE_TITLE("Master Table"));
    fetchDataMastab(data.filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.page]);

  const goBack = () => {
    let pageState = location?.state?.page ?? 1;
    navigate("/psikotes-activity", { state: { page: pageState } });
  };

  const onFilter = (filterData) => {
    setData({ ...data, filter: filterData, page: 1 });
    fetchDataMastab(filterData);
    setDialog(false);
  };

  const getDataFetch = (filter) => {
    let dataFetch = {
      filter: {
        psikotes: filter?.psikotes?.value ?? "",
        company: filter?.company?.value ?? "",
        tgl_test: filter?.tanggal_test ?? "",
        kode_voucher: filter?.kode_voucher ?? "",
      },
    };

    return dataFetch;
  };

  const fetchDataMastab = (filterData) => {
    const page = `?page=${data.page}&size${data.limit}`;
    let dataRequest = {
      path: `admin/mastab/klient${page}`,
      body: getDataFetch(filterData),
    };

    dispatch(postApi(dataRequest))
      .then((response) => {
        setData({ ...data, filter: filterData, dataTable: { body: response.body, head: response.head }, from: response.from, to: response.to, total: response.total, last_page: response.last_page });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePage = (event, value) => {
    setData({ ...data, page: value });
  };

  const onExportExcel = () => {
    let dataRequest = {
      path: `admin/mastab/klient/export`,
      body: {
        psikotes: data.filter?.psikotes?.label ?? "Mastab",
        head: JSON.stringify(data.dataTable.head),
        body: JSON.stringify(data.dataTable.body),
      },
    };

    dispatch(postBlobApi(dataRequest))
      .then((response) => {
        const blob = new Blob([response]);
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = `${data.filter?.psikotes?.label ?? "Mastab"}.xlsx`; // Replace with your desired file name and extension
        downloadLink.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Button variant="contained" style={{ minWidth: "0" }} className="capitalize rounded-lg" onClick={() => goBack()}>
        <ChevronLeft /> Kembali
      </Button>
      <div className="d-flex">
        <Card elevation={1} className="mt-4 pa-4 rounded-lg d-flex flex-wrap" style={{ gap: 70 }}>
          <table className="small_txt">
            <tbody>
              <tr>
                <td className="font-weight-500 pr-4">Judul Psikotes</td>
                <td>: {data?.filter?.psikotes?.label ?? "-"}</td>
              </tr>
              <tr>
                <td className="font-weight-500 pr-4">Company/Institution</td>
                <td>: {data?.filter?.company?.label ?? "-"}</td>
              </tr>
              <tr>
                <td className="font-weight-500 pr-4">Tanggal Tes Selesai</td>
                <td>: {data?.filter?.tanggal_test?.fullDate ?? "-"}</td>
              </tr>
              <tr>
                <td className="font-weight-500 pr-4">Kode Voucher</td>
                <td>: {data?.filter?.kode_voucher ?? "-"}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <Button variant="contained" style={{ minWidth: "0" }} className="capitalize rounded-lg" onClick={() => setDialog(true)}>
              <FilterAlt /> Filter
            </Button>
          </div>
        </Card>
      </div>
      <div className="mt-3">
        <Card className="rounded-lg pa-3">
          <div className="d-flex justify-end gap-3">
            <Button variant="outlined" style={{ minWidth: "0" }} className="capitalize px-1 rounded-lg" onClick={() => onExportExcel()}>
              <Description />
            </Button>
            <Button variant="outlined" style={{ minWidth: "0" }} className="capitalize px-1 rounded-lg">
              <PictureAsPdf />
            </Button>
            <Button variant="outlined" style={{ minWidth: "0" }} className="capitalize px-1 rounded-lg">
              <Print />
            </Button>
          </div>
          {/* Table */}
          <PsikotesMasterTable head={data.dataTable?.head} body={data.dataTable?.body} />
          {/* Pagination */}
          <div>
            {data.dataTable?.body?.length > 0 ? (
              <div className="d-flex align-center justify-space-between" style={{ width: "100%" }}>
                <p className="txt_table">
                  Menampilkan {data.from} - {data.to} dari {data.total}{" "}
                </p>
                <div>
                  <Pagination sx={{ padding: "0" }} count={data.last_page} page={data.page} shape="rounded" onChange={handlePage} style={{ background: "#fff" }} color="primary" />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Card>
      </div>
      {/* Modal filter untuk filter mastab */}
      <div className="pa-2">
        <PsikotesFilterMastab d_indie={d_indie} close={() => setDialog(false)} onSave={(res) => onFilter(res)} />
      </div>
    </div>
  );
};

export default PsikotesMasterTablePage;

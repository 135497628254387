import TitleWithDate from "../partial/TitleWithDate";
import CardCountWithTitle from "../partial/CardCountWithTitle";
import { Button, Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CardTrainingItem from "../partial/CardTrainingItem";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { dateFormatter, dateFormatterV2 } from "../../../utils/formatter";
import { getApi } from "../../../store/apis";

const TrainingListSection = () => {
  const theme = useSelector((state) => state.theme.myTheme);
  const dispatch = useDispatch();
  const [trainingCourse, setTrainingCourse] = useState({});

  useEffect(() => {
    getTrainingCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTrainingCourse = (date = dateFormatter(new Date())) => {
    let config = {
      path: `admin/board-result/training-course?date=${date}`,
    };
    dispatch(getApi(config))
      .then((res) => {
        (res === null || res === undefined) && (res = {});
        setTrainingCourse(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card className="pa-3 rounded-lg">
      <TitleWithDate title="Training & Course Teman Bakat" subTitle="Jumlah kelas dan jumlah pendaftar training dan course" onDone={(value) => getTrainingCourse(dateFormatter(value.value))} />
      <div className="my-3 d-flex justify-center align-center gap-2">
        <CardCountWithTitle
          src="https://img.freepik.com/free-vector/hand-drawn-nft-style-ape-illustration_23-2149622040.jpg"
          title="Jumlah Kelas"
          subTitle={`${trainingCourse.count?.training ?? 0} Kelas`}
          colorTheme={theme.secondary}
        />
        <CardCountWithTitle
          src="https://img.freepik.com/free-vector/hand-drawn-nft-style-ape-illustration_23-2149622040.jpg"
          title="Jumlah Course"
          subTitle={`${trainingCourse.count?.course ?? 0} Course`}
          colorTheme={theme.secondary}
        />
      </div>
      <div className="mt-4" style={{ minHeight: "10rem" }}>
        {trainingCourse?.collection?.map((item, index) => {
          return (
            <CardTrainingItem
              key={index}
              metode={item.jenis_pelaksanaan}
              image={item.image}
              title={item.judul}
              subTitle={`Start: ${dateFormatterV2(item.start_date)} | End: ${dateFormatterV2(item.end_date)}`}
              total={item.jumlah}
              colorTheme={theme.act_lbl_red}
            />
          );
        })}
      </div>
      <div className="mt-4">
        <Button variant="contained" className="shadow-none capitalize full_width rounded-lg" style={{ background: theme.tertiary, color: theme.primary }}>
          Lihat Semua <KeyboardArrowDown />
        </Button>
      </div>
    </Card>
  );
};

export default TrainingListSection;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApi } from '../../../../../store/apis'
import EmptyData from '../../../../../components/base/EmptyData'
import SkeletonTable from '../../../../../components/base/SkeletonTable'
import { ArrowDropDown, DownloadForOffline } from '@mui/icons-material'
import SearchDataTable from '../../../../../components/base/searchDataTable'
import { Button, Grid, Pagination, Paper, Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Backdrop, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'


import {
    Menu,
    MenuItem
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import DetailPesertaCourse from './DetailPesertaCourse'
const ListPesertaCourse = ({ id }) => {

    const dispatch = useDispatch()
    const theme = useSelector(state => state.theme.myTheme)
    const [find, setFind] = useState('')
    const [loading, setLoading] = useState({
        get: false,
        detail: false,
    })
    const [dataSet, setData] = useState([])

    const [state, setState] = useState({
        page: 1,
        limit: 10,
        last_page: null,
        total: null,
        gender: '',
        profesi: '',
        tipeSort: null,
        asc: true,
    })
    const [pocket, setPocket] = useState(null)
    const [dialog, setDialog] = useState(false)

    const fetchData = () => {
        setLoading({ ...loading, get: true })
        let page = `&page[size]=${state.limit}&page[number]=${state.page}`
        let data = {
            path: `admin/course/daftar-peserta?id=${id}${page}`
        }

        dispatch(getApi(data)).then(data => {
            setLoading({ ...loading, get: false })
            setData(data.data)
            setState({ ...state, total: data.total, last_page: data.last_page })
        }).catch(err => {
            console.log(err)

            setLoading({ ...loading, get: false })

            setData([])
        })
    }
    const handlePage = (event, value) => {
        setState({ ...state, page: value })
    }

    const listLimit = [10, 50, 100]
    const [listSection, setListSection] = useState([])
    const [section, setSection] = useState(null)
    const getSection = () => {

        setLoading({ ...loading, detail: true })
        let data = {
            path: `admin/course/section?id=${id}`
        }

        dispatch(getApi(data)).then(data => {
            if (data.data.length) {
                setListSection(data.data)
                setSection(data.data[0])
            }
        }).catch(err => {
            console.log(err)
            setLoading({ ...loading, detail: false })
        })
    }
    const detailPeserta = item => {
        setPocket({
            id: item.data_pelanggan.id,
            nama: item.data_pelanggan.nama,
            no_telp: item.data_pelanggan.no_telp,
            email: item.data_pelanggan.email,
            kategori: item.data_pelanggan.profesi,
            pembayaran: item.transaksi_tunai?.status ?? '-',
        })
    }

    const [progress, setProgress] = useState(null)
    const getProgress = () => {
        setLoading({ ...loading, detail: true })
        let data = {
            path: `admin/course/daftar-peserta/history?id_course_section=${section.id}&id_pelanggan=${pocket.id}`
        }
        dispatch(getApi(data)).then(data => {
            console.log(data)
            setProgress(data)
            // setProgress()
            setDialog(true)
            setLoading({ ...loading, detail: false })
        }).catch(err => {
            console.log(err)
            setLoading({ ...loading, detail: false })
            setData([])
        })
    }

    useEffect(() => {
        fetchData()
        getSection()
    }, [state.limit, find, state.page])
    useEffect(() => {
        if (section && pocket) {
            getProgress()
        }
    }, [section, pocket])

    return (
        <div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading.detail
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper className='pa-3 rounded-lg mt-3'>
                <Grid container spacing={2} className='mb-5'>
                    <Grid item xs={6}>
                        <div className="d-flex align-center">
                            <p className="small_txt font-weight-bold mr-2">Tampilkan</p>
                            <Menu menuClassName='my_menu' menuButton={<Card className='px-3 py-2 rounded-lg d-flex align-center mr-5 cursor_pointer pb-2'><p className="small_txt font-weight-bold mr-3">{state.limit} Data</p><ArrowDropDown /></Card>} transition>
                                {listLimit.map((lm, i) => <MenuItem key={`limit-${i}`} onClick={() => setState({ ...state, limit: lm })}><p className='font-weight-bold txt_table'>{lm} Data</p></MenuItem>)}
                            </Menu>
                            <SearchDataTable value={find} setValue={setFind} />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="d-flex justify-end">
                            <Button className='capitalize rounded-lg' variant='contained'>
                                <DownloadForOffline className='mr-3' />
                                <p className="font-weight-bold">Unduh</p>
                            </Button>
                        </div>
                    </Grid>

                </Grid>
                {!loading.get ? !dataSet.length ? <EmptyData /> :
                    <TableContainer component='div'>
                        <Table aria-label="simple table" style={{ borderBottom: 'none' }}>
                            <TableHead >
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell style={{ minWidth: '50px', background: theme.tertiary, borderRadius: '10px 0 0 10px' }} className='py-2'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>ID</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'  >
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Nama User</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '200px', background: theme.tertiary }} className='py-1' >
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Email</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Nomor WA</p>
                                    </TableCell>
                                    {/* <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Kategori</p>
                                    </TableCell> */}
                                    <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Status Pembayaran</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Progress</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '150px', background: theme.tertiary, borderRadius: '0 10px 10px 0' }} className='py-1'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Tindakan</p>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataSet.map((item, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{item.data_pelanggan.id}</TableCell>
                                        <TableCell>{item.data_pelanggan.nama}</TableCell>
                                        <TableCell>{item.data_pelanggan.email}</TableCell>
                                        <TableCell>{item.data_pelanggan.no_telp}</TableCell>
                                        <TableCell>{item.transaksi_tunai?.status ?? '-'}</TableCell>
                                        <TableCell>{item.progress}</TableCell>
                                        <TableCell align="center">
                                            <Button
                                                variant='contained'
                                                className='rounded-xl capitalize'
                                                onClick={() => detailPeserta(item)}
                                                disableElevation size="small"
                                                style={{ background: theme.act_blue }}>
                                                <p className='font-weight-bold' style={{ color: theme.act_lbl_blue }}>Detail</p>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <SkeletonTable />}
                {dataSet.length ? <div className="d-flex align-center justify-space-between" style={{ width: '100%' }}>
                    <p className='txt_table'>Menampilkan {state.page} - {state.limit} dari {state.total} </p>
                    <div>
                        <Pagination sx={{ padding: '0' }} count={state.last_page} page={state.page} shape="rounded" onChange={handlePage} style={{ background: '#fff' }} color="primary" />
                    </div>
                </div> : <></>}
            </Paper>
            <div className="pa-2">
                <DetailPesertaCourse
                    pocket={pocket}
                    dialog={dialog}
                    close={() => setDialog(false)}
                    listSection={listSection}
                    setSection={setSection}
                    section={section}
                    progress={progress} />
            </div>
        </div>
    )
}

export default ListPesertaCourse

import { Button, CircularProgress, Grid, Paper } from '@mui/material'
import EmptyData from '../../../../../components/base/EmptyData'
import day from 'dayjs'
import { useState } from 'react'
import { Backpack, Delete as DeleteIcon, ModeEdit } from '@mui/icons-material'
import { DialogDelete } from '../../../../../components/base/dialogDelete'
import { useNavigate } from 'react-router-dom'

const InfoCoursePage = ({ dataSet, loading, theme, id }) => {
    const navigate = useNavigate()
    const [pocket, setPocket] = useState(null)
    const [dialog, setDialog] = useState(false)
    const deleteData = () => {
        setPocket({
            path: 'admin/course/delete',
            body: {
                id: dataSet.id
            }
        })
        setDialog(true)
    }
    const dummy = require('../../../../../assets/img/empty.png')
    return (
        !loading ? dataSet ? <Paper className='pa-5 rounded-lg'>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <img src={dataSet.featured_image ?? dummy} height='200px' width='200px' style={{ objectFit: 'cover' }} alt="cover training" />
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <section className='mb-3'>
                                <p className='small_txt font-weight-bold' style={{ color: '#F3A100' }}> {dataSet.jenis} {dataSet.model_pertemuan}</p>
                                <p className="font-weight-bold" >
                                    {dataSet.judul}
                                </p>
                                <p className="small_txt"> {dataSet.deskripsi_pendek} </p>
                                <div className="d-flex">
                                    {dataSet.kategori.length ?
                                        dataSet.kategori.map((cat, i) =>
                                            <p key={`cat_training-${i}`} className='small_txt mr-3' style={{ color: theme.secondary }}> {cat.nama} </p>)
                                        : <></>}
                                </div>
                            </section>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                className="rounded-lg mb-2"
                                disableElevation
                                fullWidth
                                onClick={() => navigate(`/course-section/${id}`)}
                            >
                                <Backpack />
                                <p className="font-weight-bold capitalize">
                                    Section Course
                                </p>
                            </Button>
                            <div className="d-flex">
                                <Button
                                    variant="contained"
                                    className="rounded-lg  mr-2"
                                    disableElevation
                                    fullWidth
                                    onClick={() => navigate(`/course-update/${id}`)}
                                >
                                    <ModeEdit />
                                    <p className="font-weight-bold capitalize">
                                        Ubah
                                    </p>
                                </Button>
                                <Button
                                    variant="contained"
                                    className="rounded-lg "
                                    onClick={deleteData}
                                    disableElevation
                                    style={{ background: theme.act_red }}
                                    fullWidth
                                >
                                    <DeleteIcon style={{ color: theme.act_lbl_red }} />
                                    <p
                                        className="font-weight-bold capitalize"
                                        style={{ color: theme.act_lbl_red }}
                                    >
                                        Delete
                                    </p>
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Peserta</p>
                            <p><span className='font-weight-bold' style={{ fontSize: '20px' }}> {dataSet.jumlah_peserta}</span>/{dataSet.kuota}</p>
                        </Grid>
                        <Grid item xs={7}>
                            <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Periode</p>
                            <p className='small_txt'> {day(dataSet.tgl_mulai).format('DD/MM/YYYY')} - {day(dataSet.tanggal_selesai).format('DD/MM/YYYY')} </p>
                        </Grid>
                        <Grid item xs={12}>
                            <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Detail Course</p>
                            <p className='small_txt' dangerouslySetInnerHTML={{ __html: dataSet.deskripsi_course }}></p>
                        </Grid>
                        <Grid item xs={12}>
                            <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Tujuan Umum</p>
                            <p className='small_txt' dangerouslySetInnerHTML={{ __html: dataSet.kurikulum }}></p>
                        </Grid>
                        <Grid item xs={5}>
                            <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Harga Promo</p>
                            <p className='small_txt'>{dataSet.harga_diskon}</p>
                            <p className='xsmall_txt'>Berlaku sampai {day(dataSet.tgl_diskon_exp).format('D/MM/YYYY')}</p>
                        </Grid>
                        <Grid item xs={7}>
                            <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Harga Normal</p>
                            <p className='small_txt'> {dataSet.harga} </p>
                        </Grid>
                        <Grid item xs={5}>
                            <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Fasilitas</p>
                            <ul>
                                {dataSet.fitur.length ?
                                    dataSet.fitur.map((fasilitas, i) =>
                                        <li key={`fasilitas-${i}`}>
                                            <p className="small_txt">
                                                {fasilitas}
                                            </p>
                                        </li>)
                                    : <></>}
                            </ul>
                        </Grid>
                        <Grid item xs={7}>
                            <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Bonus</p>
                            <ul>
                                {dataSet.bonus?.length ?
                                    dataSet.bonus.map((item, i) =>
                                        <li key={`bonus-${i}`}>
                                            <p className="small_txt">
                                                {item}
                                            </p>
                                        </li>)
                                    : <></>}
                            </ul>
                        </Grid>
                        <Grid item xs={12}>
                            <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Requirement Peserta</p>
                            <ul>
                                {dataSet.requirement?.length ?
                                    dataSet.requirement.map((item, i) =>
                                        <li key={`requirement-${i}`}>
                                            <p className="small_txt">
                                                {item}
                                            </p>
                                        </li>)
                                    : <></>}
                            </ul>
                        </Grid>
                        <Grid item xs={12}>
                            <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Contact Person</p>
                            <p className="small_txt"> {dataSet.no_kontak} </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className="py-2">
                <DialogDelete d_del={dialog} close={() => setDialog(false)} pocket={pocket} refetch={() => navigate('/course-activity')} />
            </div>
        </Paper> :
            <div className='d-flex align-center justify-center'>
                <CircularProgress /> <p className="font-weight-bold ml-3">Memuat...</p>
            </div> :
            <EmptyData />
    )
}

export default InfoCoursePage

/* eslint-disable eqeqeq */
import { Button, Grid, Paper, FormControlLabel, Checkbox } from "@mui/material";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import "react-datepicker/dist/react-datepicker.css";
import day from "dayjs";
import { postApi, getApi } from "../../../../store/apis";
import { useSnackbar } from "notistack";
import FlatInput from "../../../../components/base/flatInput";
import FlatDateTimePicker from "../../../../components/base/flatDateTimePicker";

import { customStyles, repeatType, arrDay } from "./constant";

const FormCreateBanner = ({
  id,
  stateUpdate,
  setLoading,
  tabActive,
  setTabActive,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [dp, setDp] = useState(id ? stateUpdate.gambar : "");
  const [arrDate, setArrDate] = useState([]);
  const [dataKategori, setDataKategori] = useState([]);
  const [detailFitur, setDetailFitur] = useState([]);
  const [idBanner, setidBanner] = useState(id ?? "");
  const theme = useSelector((state) => state.theme.myTheme);
  const [form, setForm] = useState({
    title: id ? stateUpdate.title : "",
    gambar: "",
    deskripsi: id ? stateUpdate.deskripsi : "",
    type_ref: id ? stateUpdate.type_ref : "",
    id_ref: id ? stateUpdate.id_ref : "",
  });

  const [formPub, setFormPub] = useState({
    start: id ? new Date(stateUpdate.start) : "",
    end: id ? new Date(stateUpdate.end) : "",
    repeat_type: id ? stateUpdate.repeat_type : "",
    repeat_every: id ? stateUpdate.repeat_every : "",
    repeat_on: id ? JSON.parse(stateUpdate.repeat_on) : [],
  });
  const grid = { left: 3.5, right: 8.5 };

  const renderImg = (e) => {
    const file = e.target.files[0];
    const fr = new FileReader();
    fr.onload = (f) => {
      setDp(f.target.result);
    };
    setForm({ ...form, gambar: file });
    fr.readAsDataURL(file);
  };

  const onChangeValue = (prop) => (event) => {
    let value = event.target?.value || event;
    value = value.type ? "" : value;
    if (tabActive === "Detail Banner") {
      setForm({ ...form, [prop]: value });
    } else {
      setFormPub({ ...formPub, [prop]: value });
    }
  };

  const handleCheck = (prop, item) => (event) => {
    const { checked } = event.target;
    if (checked) {
      const value = [...formPub[prop], item];
      setFormPub({ ...formPub, [prop]: value });
    } else {
      let value = [...formPub[prop]];
      value = value.filter((val) => val !== item);
      setFormPub({ ...formPub, [prop]: value });
    }
  };

  const store = () => {
    setLoading(true);
    const body = new FormData();
    body.append("title", form.title);
    body.append("gambar", form.gambar);
    body.append("deskripsi", form.deskripsi);
    body.append("type_ref", form.type_ref);
    body.append("id_ref", form.id_ref);

    let data = {
      path: "admin/advertising/banner/store",
      body: body,
    };

    dispatch(postApi(data))
      .then((res) => {
        const variant = "success";
        enqueueSnackbar(res.message, { variant });
        setidBanner(res.data.id);
        setTabActive("Jadwal Publish");
        setLoading(false);
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message, { variant });
        setLoading(false);
      });
  };

  const update = () => {
    setLoading(true);
    const body = new FormData();
    body.append("id", id);
    body.append("title", form.title);
    body.append("deskripsi", form.deskripsi);
    body.append("type_ref", form.type_ref);
    body.append("id_ref", form.id_ref);
    if (form.gambar) body.append("gambar", form.gambar);

    let data = {
      path: "admin/advertising/banner/update",
      body: body,
    };

    dispatch(postApi(data))
      .then((res) => {
        const variant = "success";
        enqueueSnackbar(res.message, { variant });
        setTabActive("Jadwal Publish");
        setLoading(false);
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message, { variant });
        setLoading(false);
      });
  };

  const getAllDaysInMonth = () => {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();

    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
      dates.push(day(new Date(date)).format("DD"));
      date.setDate(date.getDate() + 1);
    }

    setArrDate(dates);
  };

  const getKategori = () => {
    let data = {
      path: `admin/advertising/fitur/kategori`,
    };
    dispatch(getApi(data)).then((data) => {
      let kategori = [];
      data.forEach((el) => {
        kategori.push({
          value: el.type,
          label: el.title,
        });
      });
      setDataKategori(kategori);
    });
  };

  const getDetailFitur = () => {
    let data = {
      path: `admin/advertising/fitur/detail-kategori?type=${form.type_ref}`,
    };
    dispatch(getApi(data)).then((data) => {
      let detail = [];
      data.forEach((el) => {
        detail.push({
          value: el.id,
          label:
            el.nama_psikotes ??
            el.nama_konsultasi ??
            el.nama_kelas ??
            el.judul ??
            el.modul ??
            el.game ??
            el.poin,
        });
      });

      setDetailFitur(detail);
    });
  };

  const createPublish = () => {
    setLoading(true);

    let data = {
      path: "admin/advertising/banner/jadwal/store",
      body: {
        ...formPub,
        id: idBanner,
        repeat_on: JSON.stringify(formPub.repeat_on),
      },
    };

    dispatch(postApi(data))
      .then((res) => {
        const variant = "success";
        enqueueSnackbar(res.message, { variant });
        navigate(-1);
        setLoading(false);
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message, { variant });
        setLoading(false);
      });
  };

  useEffect(() => {
    getKategori();
    getAllDaysInMonth();
    if (form.type_ref) {
      getDetailFitur();
    }
    if (formPub.repeat_type) {
      setFormPub({ ...formPub, repeat_on: [] });
    }
  }, [form.type_ref, formPub.repeat_type]);

  return (
    <div>
      {tabActive === "Detail Banner" ? (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Paper elevation={0} className="pa-5 rounded-lg mb-3">
              <div className="mb-3">
                <p
                  className="mb-3 font-weight-500"
                  style={{ color: theme.primary }}
                >
                  Detail Banner
                </p>
                <Grid container spacing={2}>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Judul Banner
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <FlatInput
                      value={form.title}
                      handleChange={onChangeValue}
                      property="title"
                      placeholder="Tuliskan judul banner"
                    />
                  </Grid>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Gambar Banner
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    {dp && <img src={dp} height="100px"></img>}
                    <p className="small_txt">{form.gambar?.name || ""}</p>
                    <Button
                      variant="outlined"
                      className="capitalize"
                      component="label"
                    >
                      <p className="font-weight-bold">
                        {dp ? "Perbaharui" : "Unggah Gambar"}
                      </p>
                      <input
                        hidden
                        onChange={renderImg}
                        accept="image/*"
                        multiple
                        type="file"
                      />
                    </Button>
                    <p className="small_txt">
                      Unggah gambar dengan dimensi 1240 x 420 px
                    </p>
                  </Grid>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Keterangan Banner
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <FlatInput
                      value={form.deskripsi}
                      handleChange={onChangeValue}
                      property="deskripsi"
                      placeholder="Keterangan banner"
                    />
                  </Grid>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Kategori Layanan
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <Select
                      menuPlacement="auto"
                      styles={customStyles}
                      value={
                        form.type_ref
                          ? dataKategori.filter(
                              (el) => el.value === form.type_ref
                            )[0]
                          : ""
                      }
                      onChange={(kategori) =>
                        onChangeValue("type_ref")(kategori.value)
                      }
                      options={dataKategori}
                      placeholder="Kategori Layanan"
                      className="small_txt"
                    />
                  </Grid>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Detail Layanan
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <Select
                      menuPlacement="auto"
                      styles={customStyles}
                      value={
                        form.id_ref
                          ? detailFitur.filter(
                              (el) => el.value === form.id_ref
                            )[0]
                          : ""
                      }
                      onChange={(detail) =>
                        onChangeValue("id_ref")(detail.value)
                      }
                      options={detailFitur}
                      placeholder="Detail Layanan"
                      className="small_txt"
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="d-flex justify-end">
                <Button variant="contained" onClick={id ? update : store}>
                  <p className="font-weight-bold small_txt capitalize">
                    {id ? "Simpan Perubahan" : "Simpan"}
                  </p>
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Paper elevation={0} className="pa-5 rounded-lg mb-3">
              <div className="mb-3">
                <p
                  className="mb-3 font-weight-500"
                  style={{ color: theme.primary }}
                >
                  Jadwal Publish
                </p>
                <Grid container spacing={2}>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Start Publish
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <FlatDateTimePicker
                      value={formPub.start}
                      handleChange={onChangeValue}
                      property="start"
                      placeholder="Tanggal Publish"
                      min={new Date()}
                    />
                  </Grid>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      Repeat Publish
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <Select
                      menuPlacement="auto"
                      styles={customStyles}
                      value={formPub.repeat_type}
                      onChange={(value) => onChangeValue("repeat_type")(value)}
                      value={
                        formPub.repeat_type
                          ? repeatType.filter(
                              (el) => el.value === formPub.repeat_type
                            )[0]
                          : ""
                      }
                      onChange={(type) =>
                        onChangeValue("repeat_type")(type.value)
                      }
                      options={repeatType}
                      placeholder="Pilih 1"
                      className="small_txt"
                    />
                    {formPub.repeat_type && (
                      <>
                        <div className="d-flex flex-row align-center mt-3">
                          <p
                            className="small_txt font-weight-bold"
                            style={{ color: theme.primary }}
                          >
                            Every
                          </p>
                          <div
                            className="ml-2 mr-2"
                            style={{
                              width: "80px",
                            }}
                          >
                            <FlatInput
                              value={formPub.repeat_every}
                              handleChange={onChangeValue}
                              property="repeat_every"
                              placeholder="3"
                              type="number"
                            />
                          </div>
                          <p
                            className="small_txt font-weight-500"
                            style={{ color: theme.primary }}
                          >
                            hari
                          </p>
                        </div>
                        {formPub.repeat_type.value === "week" && (
                          <div className="mt-3">
                            <p
                              className="small_txt font-weight-bold"
                              style={{ color: theme.primary }}
                            >
                              Repeat On
                            </p>
                            {arrDay.map((value, i) => {
                              return (
                                <FormControlLabel
                                  key={i}
                                  label={
                                    <p className="small_txt capitalize">
                                      {value}
                                    </p>
                                  }
                                  control={
                                    <Checkbox
                                      checked={formPub.repeat_on.includes(
                                        value
                                      )}
                                      onChange={handleCheck("repeat_on", value)}
                                    />
                                  }
                                />
                              );
                            })}
                          </div>
                        )}
                        {formPub.repeat_type.value === "month" && (
                          <div className="mt-3">
                            <p
                              className="small_txt font-weight-bold"
                              style={{ color: theme.primary }}
                            >
                              Repeat On
                            </p>
                            <div
                              style={{
                                width: "200px",
                              }}
                            >
                              <div className="d-flex flex-row align-center flex-wrap">
                                {arrDate.map((value, i) => {
                                  return (
                                    <button
                                      className="box-date"
                                      key={i}
                                      onClick={() =>
                                        handleCheck(
                                          "repeat_on",
                                          value
                                        )({
                                          target: {
                                            checked: !formPub.repeat_on.includes(
                                              value
                                            ),
                                          },
                                        })
                                      }
                                      style={{
                                        backgroundColor: formPub.repeat_on.includes(
                                          value
                                        )
                                          ? theme.primary
                                          : "white",
                                      }}
                                    >
                                      <p
                                        className="small_txt text-center"
                                        style={{
                                          color: formPub.repeat_on.includes(
                                            value
                                          )
                                            ? theme.white
                                            : "black",
                                        }}
                                      >
                                        {parseInt(value)}
                                      </p>
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={grid.left}>
                    <p
                      className="small_txt font-weight-bold"
                      style={{ color: theme.primary }}
                    >
                      End Repeat Publish
                    </p>
                  </Grid>
                  <Grid item xs={grid.right}>
                    <FlatDateTimePicker
                      value={formPub.end}
                      handleChange={onChangeValue}
                      property="end"
                      placeholder="Tanggal Berakhir"
                      min={new Date()}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="d-flex justify-end">
                <Button
                  onClick={createPublish}
                  disabled={idBanner ? false : true}
                  variant="contained"
                >
                  <p className="font-weight-bold small_txt capitalize">
                    Publish Pop Up
                  </p>
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default FormCreateBanner;

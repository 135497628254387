/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../store/theming";
import { postApi } from "../../../store/apis";
import EmptyData from "../../../components/base/EmptyData";
import { Grid, Paper, Button, FormControlLabel, Backdrop, CircularProgress, RadioGroup, Radio } from "@mui/material";
import { Person, Email, DateRange, PhoneIphone, ChevronLeft } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import InputWithIcon from "../../../components/base/inputWithIcon";

const IndividualUserEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useSelector((state) => state.theme.myTheme);
  const { id } = useParams();
  const { state } = useLocation();
  const [data, setData] = useState({
    nama: state.nama,
    email: state.email,
    no_telp: state.no_telp,
    jenkel: state.jenkel,
    profesi: state.profesi,
    tgl_lahir: state.tgl_lahir,
  });
  const [loading, setLoading] = useState(false);

  const postData = () => {
    setLoading(true);
    const body = {
      path: "admin/individual-user/update",
      body: {
        id,
        ...data,
      },
    };

    dispatch(postApi(body))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "User berhasil diubah", { variant });
        navigate(-1);
      })
      .catch((err) => {
        const variant = "error";
        const error = err.data?.errors || false;
        if (error) {
          Object.keys(error).forEach((message) => {
            enqueueSnackbar(`${message} ${error[message]}`, { variant });
          });
        }
        enqueueSnackbar(err.message ?? "User Gagal diubah", { variant });
      });
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Ubah Individual User"));
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <div>
            <div className="d-flex justify-space-between align-center mb-3">
              <Button variant="contained" style={{ minWidth: "0" }} className="capitalize rounded-lg" onClick={() => navigate(-1)}>
                <ChevronLeft /> Kembali
              </Button>
            </div>
            {state ? (
              <Paper className="d-flex flex-column pa-3 rounded-lg" elevation={0} style={{ height: "600px" }}>
                <div className="flexy">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="mb-4">
                        <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                          Nama Lengkap
                        </p>
                        <InputWithIcon value={data.nama} icon={<Person color="primary" />} handleChange={handleChange} property="nama" />
                      </div>
                      <div className="mb-4">
                        <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                          Email Aktif
                        </p>
                        <InputWithIcon value={data.email} icon={<Email color="primary" />} handleChange={handleChange} property="email" />
                      </div>
                      <div className="mb-4">
                        <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                          Nomor Hp
                        </p>
                        <InputWithIcon value={data.no_telp} icon={<PhoneIphone color="primary" />} handleChange={handleChange} type="number" property="no_telp" />
                      </div>
                      <div className="mb-4">
                        <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                          Tanggal Lahir
                        </p>
                        <InputWithIcon value={data.tgl_lahir} icon={<DateRange color="primary" />} handleChange={handleChange} type="date" property="tgl_lahir" />
                      </div>
                      <div className="mb-4">
                        <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                          Jenis Kelamin
                        </p>
                        <RadioGroup name="rb-jenkel" row value={data.jenkel} onChange={handleChange("jenkel")}>
                          <FormControlLabel value="P" control={<Radio />} label="Perempuan" />
                          <FormControlLabel value="L" control={<Radio />} label="Laki-laki" />
                        </RadioGroup>
                      </div>
                      <div className="mb-4">
                        <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                          Status Pekerjaan
                        </p>
                        <RadioGroup name="rb-statuspekerjaan" row value={data.profesi} onChange={handleChange("profesi")}>
                          <FormControlLabel value="siswa/mahasiswa" control={<Radio />} label="Siswa/Mahasiswa" />
                          <FormControlLabel value="pekerja" control={<Radio />} label="Pekerja" />
                        </RadioGroup>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="d-flex justify-end">
                  <Button onClick={postData} variant="contained" className="capitalize rounded-lg" disableElevation>
                    <p className="font-weight-bold">Simpan</p>
                  </Button>
                </div>
              </Paper>
            ) : (
              <EmptyData />
            )}
          </div>
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </Grid>
    </div>
  );
};

export default IndividualUserEdit;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../../store/theming";
import { getApi, postApi } from "../../../../store/apis";
import EmptyData from "../../../../components/base/EmptyData";
import SkeletonTable from "../../../../components/base/SkeletonTable";
import { ArrowDropDown, ChevronLeft, AddCircle } from "@mui/icons-material";
import SearchDataTable from "../../../../components/base/searchDataTable";
import { Button, Grid, Pagination, Paper, Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";

import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { DialogDelete } from "../../../../components/base/dialogDelete";
import { useSnackbar } from "notistack";
const TrainingFormList = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const [find, setFind] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataSet, setData] = useState([]);
  const [emptyTxt, setEmptyTxt] = useState("");
  const [pocket, setPocket] = useState(null);
  const [d_del, setDel] = useState(false);

  const [state, setState] = useState({
    page: 1,
    limit: 10,
    last_page: null,
    total: null,
    gender: "",
    profesi: "",
    tipeSort: null,
    asc: true,
  });

  const fetchData = () => {
    setLoading(true);
    let page = `&page[size]=${state.limit}&page[number]=${state.page}`;
    let data = {
      path: `admin/training/form?filter[nama]=${find}${page}`,
    };

    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data.data);
        setState({ ...state, total: data.total, last_page: data.last_page });
      })
      .catch((err) => {
        console.log(err);
        if (err.status == 400) {
          if (find != "") {
            setEmptyTxt(`${find} tidak cocok untuk data apapun!`);
          } else {
            setEmptyTxt(`No Data Available`);
          }
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setData([]);
      });
  };
  const handlePage = (event, value) => {
    setState({ ...state, page: value });
  };

  const listLimit = [10, 50, 100];

  const deleteKategori = (item) => {
    /**
     * ENUM DELETED
     * 0 = delete
     * 1 = restore
     */
    const data = {
      path: "admin/training/form/delete",
      body: {
        id: item.id,
      },
    };
    setPocket(data);
    setDel(true);
  };
  const { enqueueSnackbar } = useSnackbar();
  const changeStatus = (item) => {
    let status = item.status === 1 ? 0 : 1;
    const data = {
      path: "admin/konsultasi-kategori/switch",
      body: {
        id: item.id,
        status: status,
      },
    };

    dispatch(postApi(data))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "Status berhasil diubah", { variant });
        let listData = [...dataSet];
        const index = listData.findIndex((el) => el.id === item.id);
        listData[index] = { ...listData[index], status };
        setData(listData);
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
      });
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Setting Form"));
    fetchData();
  }, [state.limit, find, state.page]);

  return (
    <div>
      <Button variant="contained" style={{ minWidth: "0" }} className="capitalize rounded-lg mb-5" onClick={() => navigate("/training-activity")}>
        <ChevronLeft /> Kembali
      </Button>
      <Grid container spacing={2} className="mb-5">
        <Grid item xs={6}>
          <div className="d-flex align-center">
            <p className="small_txt font-weight-bold mr-2">Tampilkan</p>
            <Menu
              menuClassName="my_menu"
              menuButton={
                <Card className="px-3 py-2 rounded-lg d-flex align-center mr-5 cursor_pointer pb-2">
                  <p className="small_txt font-weight-bold mr-3">{state.limit} Data</p>
                  <ArrowDropDown />
                </Card>
              }
              transition>
              {listLimit.map((lm, i) => (
                <MenuItem key={`limit-${i}`} onClick={() => setState({ ...state, limit: lm })}>
                  <p className="font-weight-bold txt_table">{lm} Data</p>
                </MenuItem>
              ))}
            </Menu>
            <SearchDataTable value={find} setValue={setFind} />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="d-flex justify-end">
            <Button className="capitalize rounded-lg" variant="contained" onClick={() => navigate("create")}>
              <AddCircle className="mr-3" />
              <p className="font-weight-bold">Tambah Form</p>
            </Button>
          </div>
        </Grid>
      </Grid>
      <Paper className="pa-3 rounded-lg mt-3">
        {!loading ? (
          !dataSet.length ? (
            <EmptyData />
          ) : (
            <TableContainer component="div">
              <Table aria-label="simple table" style={{ borderBottom: "none" }}>
                <TableHead>
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell style={{ minWidth: "50px", background: theme.tertiary, borderRadius: "10px 0 0 10px" }} className="py-3">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        ID
                      </p>
                    </TableCell>
                    <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Nama Kategori
                      </p>
                    </TableCell>
                    <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Show
                      </p>
                    </TableCell>
                    <TableCell style={{ minWidth: "150px", background: theme.tertiary, borderRadius: "0 10px 10px 0" }} className="py-3">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Tindakan
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSet.map((item, i) => (
                    <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>
                        <p className="px11_txt">{item.id}</p>
                      </TableCell>
                      <TableCell>
                        <p className="px11_txt">{item.nama}</p>
                      </TableCell>
                      <TableCell>
                        <div className="cursor_pointer flex justify-center items-center" onClick={() => changeStatus(item)}>
                          <Icon path={item.status === 1 ? mdiToggleSwitch : mdiToggleSwitchOffOutline} size={1.8} color={theme.primary} />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex">
                          <Button
                            variant="contained"
                            className="rounded-xl capitalize mr-3"
                            onClick={() => navigate(`/training-form/${item.id}`)}
                            disableElevation
                            size="small"
                            style={{ background: theme.act_orange }}>
                            <p className="font-weight-bold" style={{ color: theme.act_lbl_orange }}>
                              Edit
                            </p>
                          </Button>
                          <Button variant="contained" className="rounded-xl capitalize" onClick={() => deleteKategori(item)} disableElevation size="small" style={{ background: theme.act_red }}>
                            <p className="font-weight-bold" style={{ color: theme.act_lbl_red }}>
                              Delete
                            </p>
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        ) : (
          <SkeletonTable txt={emptyTxt} />
        )}
        {dataSet.length ? (
          <div className="d-flex align-center justify-space-between" style={{ width: "100%" }}>
            <p className="txt_table">
              Menampilkan {state.page} - {state.limit} dari {state.total}{" "}
            </p>
            <div>
              <Pagination sx={{ padding: "0" }} count={state.last_page} page={state.page} shape="rounded" onChange={handlePage} style={{ background: "#fff" }} color="primary" />
            </div>
          </div>
        ) : (
          <></>
        )}
      </Paper>

      <div className="pa-2">
        <DialogDelete pocket={pocket} d_del={d_del} close={() => setDel(false)} refetch={fetchData} />
      </div>
    </div>
  );
};

export default TrainingFormList;

import { useEffect, useState } from "react";
import { CHANGE_TITLE } from "../../../store/theming";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../store/apis";
import { Card, Grid, Skeleton, Menu, MenuItem, Button, Avatar, Pagination, FormControl, RadioGroup, FormControlLabel, Radio, Divider } from "@mui/material";
import { Search, Check, Cancel, FilterAlt } from "@mui/icons-material";
import Icon from "@mdi/react";
import { mdiMicrosoftExcel, mdiFile, mdiPrinter, mdiMenuSwap } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const env = process.env.REACT_APP_ENV;

export const ListClient = () => {
  const ava = useSelector((state) => state.theme.dummy);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.myTheme);
  const [focused, setFocused] = useState(false);
  const [state, setState] = useState({
    loading: true,
    page: 1,
    limit: 10,
    last_page: null,
    total: null,
    find: "",
    gender: "",
    profesi: "",
    tipeSort: null,
    asc: true,
  });
  const [count, setCount] = useState(0);
  const handleChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value });
  };
  const handlePage = (event, value) => {
    setState({ ...state, page: value });
  };
  const [search, setSearch] = useState("");
  const [dataSet, setDataSet] = useState([]);
  const fetchData = async () => {
    try {
      setState({
        ...state,
        loading: true,
      });
      let data = {
        path: `konselor/konselor-klien?page[number]=${state.page}&page[size]=${state.limit}&filter[pelanggan.nama]=${search}&filter[pelanggan.jenkel]=${state.gender}&filter[pelanggan.profesi]=${state.profesi}`,
      };
      const res = await dispatch(fetchApi(data));
      if (res.data.code === 200) {
        setState({
          ...state,
          loading: false,
          last_page: res.data.data.last_page,
          total: res.data.data.total,
        });
        setDataSet(res.data.data.data);
      } else {
        setState({
          ...state,
          loading: false,
        });
        setDataSet([]);
      }
    } catch (err) {
      console.log(err);
      setDataSet([]);
      setState({
        ...state,
        loading: false,
      });
    }

    let arrFilter = [state.gender, state.profesi];
    let counter = arrFilter.filter((i) => i !== "").length;
    setCount(counter);
  };
  const listLimit = [10, 25, 50, 100];
  const [anchor, setAnchor] = useState({
    limit: null,
    filter: null,
  });
  const openLimit = Boolean(anchor.limit);
  const openFilter = Boolean(anchor.filter);
  const handleLimit = (index) => {
    setAnchor({ ...anchor, limit: null });
    setState({ ...state, limit: index });
  };
  const openMenu = (event, type) => {
    setAnchor({ ...anchor, [type]: event.currentTarget });
  };
  const resetFind = () => {
    setState({ ...state, find: "" });
    setSearch("");
  };
  const handleFilter = () => {
    setAnchor({ ...anchor, filter: null });
    fetchData();
  };
  const resetFilter = () => {
    setState({ ...state, profesi: "", gender: "" });
  };

  const sortAsc = (attr) => {
    if (state.tipeSort !== attr) {
      setState({ ...state, tipeSort: attr, asc: true });

      console.log("masuk beda");
    } else {
      setState({ ...state, asc: !state.asc });
      console.log("masuk sama");
    }
    let temp = JSON.parse(JSON.stringify(dataSet));
    if (state.asc) {
      temp.sort((a, b) => {
        return ("" + a[attr]).localeCompare(b[attr]);
      });
    } else {
      temp.sort((b, a) => {
        return ("" + a[attr]).localeCompare(b[attr]);
      });
    }
    setDataSet(temp);
  };

  const exportData = () => {
    window.location = `${env}/konselor/konselor-klien-export?id_konselor=${Cookies.get("id")}`;
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Data Klien"));
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.limit, search, state.page]);

  return (
    <div>
      {/* Header */}
      <Grid container spacing={2} className="mb-5">
        <Grid item xs={2}>
          <p className="mb-2 small_txt font-weight-700" style={{ color: theme.primary }}>
            Tampilkan
          </p>
          <Card onClick={(event) => openMenu(event, "limit")} className="pa-3 flexy d-flex rounded-lg txt_table cursor_pointer" style={{ color: theme.primary }}>
            <div className="font-weight-bold">{state.limit} Data</div>
          </Card>
          <Menu
            className="mt-2 rounded-lg"
            id="limit"
            anchorEl={anchor.limit}
            open={openLimit}
            onClose={() => setAnchor({ ...anchor, limit: null })}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              role: "listbox",
            }}
            PaperProps={{ sx: { borderRadius: "8px" } }}>
            {listLimit.map((option, index) => (
              <MenuItem key={index} selected={option === state.limit} onClick={() => handleLimit(option)} style={{ width: "150px" }}>
                <div className="d-flex align-center justify-space-between cursor_pointer" style={{ width: "100%" }}>
                  <p className="font-weight-bold txt_table" style={{ color: theme.primary }}>
                    {option} Data
                  </p>
                  {option === state.limit ? <Check fontSize="small" color="primary" /> : <></>}
                </div>
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item xs={4}>
          <div style={{ height: "24px" }}></div>
          <Card
            className="d-flex align-center justify-space-between rounded-lg pr-1"
            elevation={0}
            style={{ border: `solid 2px ${focused ? theme.primary : theme.secondary}`, background: `${focused ? "" : theme.secondary}` }}>
            <input
              type="text"
              placeholder="Search"
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              value={state.find}
              onChange={handleChange("find")}
              className="search_input"
              style={{ background: focused ? "" : theme.secondary }}
            />
            {state.find !== "" ? <Cancel onClick={() => resetFind()} onMouseDown={(event) => event.preventDefault()} fontSize="small" color="secondary" className="mr-2 cursor_pointer" /> : <></>}
            <Button
              onClick={() => setSearch(state.find)}
              onMouseDown={(event) => event.preventDefault()}
              variant={focused ? "contained" : "flat"}
              color={focused ? "primary" : "secondary"}
              style={{ minWidth: "0", padding: "4px" }}>
              <Search fontSize="small" color={focused ? "white" : "primary"} />
            </Button>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <div style={{ height: "24px" }}></div>
          <Card
            className="pa-3 d-flex rounded-lg cursor_pointer"
            onClick={(event) => openMenu(event, "filter")}
            style={{ color: count ? "#fff" : theme.primary, width: "max-content", background: count ? theme.primary : "" }}>
            <FilterAlt className="mr-2" fontSize="small" />
            <div className="txt_table d-flex align-center ">
              <p className="font-weight-bold mr-2">Filter</p>
              {count ? (
                <div className="badge" style={{ color: theme.primary }}>
                  {count}
                </div>
              ) : (
                <></>
              )}
            </div>
          </Card>
          <Menu
            className="mt-2 rounded-lg"
            id="filter"
            anchorEl={anchor.filter}
            open={openFilter}
            onClose={() => setAnchor({ ...anchor, filter: null })}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              role: "div",
            }}
            PaperProps={{ sx: { borderRadius: "8px", width: "200px" } }}>
            <div className="px-3 d-flex align-center justify-space-between">
              <p className="font-weight-bold txt_table" style={{ color: theme.primary }}>
                Filter
              </p>
              <p className="font-weight-bold small_txt cursor_pointer" style={{ color: theme.secondary }} onClick={() => resetFilter("both")}>
                Reset
              </p>
            </div>
            <div className="px-3 mt-3">
              <p className="font-weight-bold txt_table mb-1" style={{ color: theme.primary }}>
                Jenis Kelamin
              </p>
              <FormControl>
                <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={state.gender} onChange={handleChange("gender")}>
                  <FormControlLabel value="L" control={<Radio size="small" style={{ padding: "6px" }} />} label="Laki-laki" />
                  <FormControlLabel value="P" control={<Radio size="small" style={{ padding: "6px" }} />} label="Perempuan" />
                </RadioGroup>
              </FormControl>
            </div>
            <Divider sx={{ my: 0.5 }} />
            <div className="px-3 mt-3">
              <p className="font-weight-bold txt_table mb-1" style={{ color: theme.primary }}>
                Profesi
              </p>
              <FormControl>
                <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={state.profesi} onChange={handleChange("profesi")}>
                  <FormControlLabel value="Siswa/Mahasiswa" control={<Radio size="small" style={{ padding: "6px" }} />} label="Siswa/Mahasiswa" />
                  <FormControlLabel value="Pekerja" control={<Radio size="small" style={{ padding: "6px" }} />} label="Pekerja" />
                </RadioGroup>
              </FormControl>
              <Button variant="contained" size="small" className="capitalize mt-2" style={{ width: "100%" }} onClick={() => handleFilter()}>
                Terapkan
              </Button>
            </div>
          </Menu>
        </Grid>
        <Grid item xs={4}>
          <div style={{ height: "20px" }}></div>
          <div className="d-flex justify-end">
            <Card className="pa-3 d-flex justify-center align-center mr-3 rounded-lg cursor_pointer" onClick={() => exportData()}>
              <Icon path={mdiMicrosoftExcel} size={1} color={theme.primary} />
            </Card>
            <Card className="pa-3 d-flex justify-center align-center mr-3 rounded-lg">
              <Icon path={mdiFile} size={1} color={theme.primary} />
            </Card>
            <Card className="pa-3 d-flex justify-center align-center mr-3 rounded-lg">
              <Icon path={mdiPrinter} size={1} color={theme.primary} />
            </Card>
          </div>
        </Grid>
      </Grid>

      {/* table header */}
      <div className="pa-3">
        <Grid container spacing={2} style={{ color: theme.primary }}>
          <Grid item xs={3}>
            <div className="d-flex align-center">
              <p className="font-weight-bold txt_table">Nama Klien</p>
              <Icon path={mdiMenuSwap} className="cursor_pointer" onClick={() => sortAsc("nama")} size={1} color={theme.primary} />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="d-flex align-center">
              <p className="font-weight-bold txt_table text-center">Tanggal Lahir</p>
              <Icon path={mdiMenuSwap} className="cursor_pointer" onClick={() => sortAsc("tgl_lahir")} size={1} color={theme.primary} />
            </div>
          </Grid>
          <Grid item xs={1}>
            <div className="d-flex align-center">
              <p className="font-weight-bold txt_table text-center ml-2">Usia</p>
              <Icon path={mdiMenuSwap} className="cursor_pointer" onClick={() => sortAsc("Usia")} size={1} color={theme.primary} />
            </div>
          </Grid>
          <Grid item xs={2}>
            <p className="font-weight-bold txt_table text-center ml-2">Jenis Kelamin</p>
          </Grid>
          <Grid item xs={2}>
            <p className="font-weight-bold txt_table">Profesi</p>
          </Grid>
        </Grid>
      </div>
      {state.loading ? (
        <div className="pa-3">
          <Skeleton variant="rectangular" className="mb-3 rounded-lg" height={60} />
          <Skeleton variant="rectangular" className="mb-3 rounded-lg" height={60} />
          <Skeleton variant="rectangular" className="mb-3 rounded-lg" height={60} />
        </div>
      ) : dataSet.length ? (
        dataSet.map((item, i) => (
          <div key={i}>
            <Card className="pa-3 rounded-lg mb-3" elevation={0}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div className="d-flex align-center">
                    <Avatar alt={item.nama} src={item.foto_link ? item.foto_link : ava} className="mr-3" />
                    <p className="txt_table">
                      {" "}
                      {item.nama} {item.id}{" "}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className="d-flex justify-center align-center" style={{ width: "100%", minHeight: "100%" }}>
                    <p className="txt_table text-center"> {item.tgl_lahir ? item.tgl_lahir : "-"} </p>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div className="d-flex justify-center align-center" style={{ width: "100%", minHeight: "100%" }}>
                    <p className="txt_table text-center"> {item.Usia ? item.Usia : "-"} </p>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className="d-flex justify-center align-center" style={{ width: "100%", minHeight: "100%" }}>
                    <p className="txt_table text-center"> {item.jenis_kelamin ? item.jenis_kelamin : "-"} </p>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div className="d-flex justify-center align-center" style={{ width: "100%", minHeight: "100%" }}>
                    <p className="txt_table"> {!item.profesi ? "-" : item.profesi !== "pekerja" ? "Pelajar" : "Pekerja"} </p>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="d-flex align-center justify-end" style={{ borderLeft: `2px solid ${theme.secondary}`, width: "100%", minHeight: "100%" }}>
                    <Button
                      variant="contained"
                      className="capitalize mr-2"
                      onClick={() => navigate(`/profile-client/${item.id_pelanggan}`)}
                      size="small"
                      style={{ borderRadius: "50px", background: "#FFE8F8" }}
                      disableElevation>
                      <p className="font-weight-bold small_txt" style={{ color: "#CF36A1" }}>
                        Profile
                      </p>
                    </Button>
                    <Button
                      variant="contained"
                      className="capitalize"
                      onClick={() => navigate(`/log-client/${item.id_pelanggan}`)}
                      size="small"
                      style={{ borderRadius: "50px", background: "#ACE1FF" }}
                      disableElevation>
                      <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                        Log Aktivitas
                      </p>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </div>
        ))
      ) : (
        <div className="pa-3">No Data Available</div>
      )}

      {dataSet.length ? (
        <div className="d-flex align-center justify-space-between" style={{ width: "100%" }}>
          <p>
            Menampilkan {state.page} - {state.limit} dari {state.total}{" "}
          </p>
          <div>
            <Pagination sx={{ padding: "0" }} count={state.last_page} page={state.page} shape="rounded" onChange={handlePage} color="primary" />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

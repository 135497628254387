import { Paper, Skeleton, Grid } from '@mui/material'
import EmptyData from '../../../../components/base/EmptyData'
import React from 'react'
import day from 'dayjs'
const ListLog = ({ theme, list = [], loading }) => {

    return (
        <div>
            {!loading ?
                !list.length ? <EmptyData /> :
                    list.map((item, i) =>
                        <div key={i}>
                            {
                                i !== 0 && list[i].tgl !== list[i - 1].tgl ? <div className="d-flex align-center">
                                    <div className="log_tgl" style={{ background: theme.tertiary }}>
                                        <p className="font-weight-bold mb-0" style={{ color: theme.primary }}>
                                            {day(item.tgl).format('DDDD, D MMMM YYYY')}
                                        </p>
                                    </div>
                                    <div className="line_log" style={{ background: theme.secondary }}></div>
                                </div> :
                                    i === 0 ?
                                        <div className="d-flex align-center">
                                            <div className="log_tgl" style={{ background: theme.tertiary }}>
                                                <p className="font-weight-bold mb-0" style={{ color: theme.primary }}>
                                                    {day(item.tgl).format('DDDD, D MMMM YYYY')}
                                                </p>
                                            </div>
                                            <div className="line_log" style={{ background: theme.secondary }}></div>
                                        </div> :
                                        <></>
                            }
                            <div className="d-flex">
                                <div className="timeline mr-2">
                                    <div className="connector" style={{ background: theme.secondary }}></div>
                                    <div className="dot mb-3" style={{ background: theme.secondary }}>
                                        <div className="inner_dot" style={{ background: theme.tertiary }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-center txt_table mr-3  mb-3">
                                    <p>{day(item.created_at).format('HH:mm')} WIB</p>
                                </div>
                                <Paper className='pa-3 flexy rounded-lg mb-3' elevation={0}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <div className="d-flex align-center">
                                                <img src={item.img} className='mr-3' height='50px' alt="" />
                                                <p className='font-weight-bold mb-0' style={{ color: item.color }}>{item.tipe}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={8} className="d-flex align-center">
                                            <div className="line_log_x mr-3" style={{ background: theme.secondary }}></div>
                                            <div className='txt_table'>
                                                {item.deskripsi}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        </div>
                    )
                :
                <div className='mt-5'>
                    <Skeleton variant="rounded" className='rounded-lg mb-2' style={{ height: '50px', width: '100%' }} />
                    <Skeleton variant="rounded" className='rounded-lg mb-2' style={{ height: '50px', width: '100%' }} />
                    <Skeleton variant="rounded" className='rounded-lg mb-2' style={{ height: '50px', width: '100%' }} />
                    <Skeleton variant="rounded" className='rounded-lg mb-2' style={{ height: '50px', width: '100%' }} />
                </div>
            }
        </div >
    )
}

export default ListLog

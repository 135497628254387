/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../store/theming";
import { getApi, postApi } from "../../../store/apis";
import EmptyData from "../../../components/base/EmptyData";
import { Grid, Paper, Skeleton, Button, Divider, Tooltip } from "@mui/material";
import { CheckCircle, Delete as DeleteIcon, ChevronLeft } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import IndividualUserTransaction from "./individualUserTransaction";
import IndividualUserActivity from "./individualUserActivity";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import day from "dayjs";
import { useSnackbar } from "notistack";
import { DialogDelete } from "../../../components/base/dialogDelete";
import ButtonEdit from "../../../components/base/ButtonEdit";

const IndividualUserDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const theme = useSelector((state) => state.theme.myTheme);
  const [find] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataSet, setData] = useState([]);
  const [setEmptyTxt] = useState("");
  const [tab, setTab] = useState(1);
  const [pocket, setPocket] = useState(null);
  const [d_del, setDel] = useState(false);
  const [state] = useState({
    page: 1,
    limit: 10,
    last_page: null,
    total: null,
    find: "",
    gender: "",
    profesi: "",
    tipeSort: null,
    asc: true,
  });

  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `admin/individual-user/detail?id=${id}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        if (err.status == 400) {
          setEmptyTxt(`No Data Available`);
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setData([]);
      });
  };

  const onChangeStatus = () => {
    const data = {
      path: "admin/individual-user/switch",
      body: {
        id: id,
        status: dataSet.status === 1 ? 0 : 1,
      },
    };

    dispatch(postApi(data))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "Status berhasil diubah", { variant });
        setData({ ...dataSet, status: dataSet.status === 1 ? 0 : 1 });
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
      });
  };

  const onDeleteAccount = () => {
    const data = {
      path: "admin/individual-user/delete",
      body: {
        id: id,
      },
    };
    setPocket(data);
    setDel(true);
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Individual User Detail"));
    fetchData();
  }, [state.limit, find, state.page]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          {!loading ? (
            dataSet ? (
              <div>
                <div className="d-flex justify-space-between align-center mb-3">
                  <Button variant="contained" style={{ minWidth: "0" }} className="capitalize rounded-lg" onClick={() => navigate(-1)}>
                    <ChevronLeft /> Kembali
                  </Button>
                  <div className="d-flex align-center">
                    <p className="small_txt font-weight-bold mr-3">{dataSet.status === 1 ? "Non-Aktifkan Akun" : "Aktifkan Akun"} </p>
                    <div className="d-flex align-center justify-center" onClick={onChangeStatus}>
                      <Icon path={dataSet.status === 1 ? mdiToggleSwitch : mdiToggleSwitchOffOutline} size={1.8} color={theme.primary} className="cursor_pointer" />
                    </div>
                  </div>
                </div>
                <Paper className="pa-3 rounded-lg" elevation={0}>
                  <div className="d-flex align-center justify-space-between mb-2">
                    <div>
                      <h3>{dataSet.nama}</h3>
                      <div className="d-flex align-center">
                        <p className="small_txt mr-1" style={{ fontWeight: 700, color: "#00AB97" }}>
                          Akun Terverifikasi
                        </p>
                        <CheckCircle fontSize="15px" style={{ color: "#00AB97" }} />
                      </div>
                    </div>
                    <div>
                      <ButtonEdit
                        onClick={() =>
                          navigate(`/individual-user/detail/${id}/edit`, {
                            state: { ...dataSet },
                          })
                        }
                      />
                      <Button
                        onClick={onDeleteAccount}
                        variant="contained"
                        className="rounded-lg capitalize ml-3"
                        disableElevation
                        size="small"
                        style={{
                          background: theme.act_red,
                          height: "max-content",
                        }}>
                        <DeleteIcon className="mr-1" style={{ color: theme.act_lbl_red }} />
                        <p className="font-weight-bold" style={{ color: theme.act_lbl_red }}>
                          {" "}
                          Delete
                        </p>
                      </Button>
                    </div>
                  </div>
                  <Divider />
                  <div className="mt-5">
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <div className="d-flex flex-start">
                          <div style={{ width: 7, height: 7, borderRadius: 50, backgroundColor: theme.primary, marginTop: 10, marginRight: 10 }} />
                          <div className="flexy mt-1">
                            <p className="small_txt" style={{ color: theme.primary }}>
                              Informasi Akun
                            </p>
                            <div className="mt-3">
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    User ID
                                  </p>
                                  <p className="small_txt">{dataSet.id}</p>
                                </Grid>
                                <Grid item xs={6}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Kategori
                                  </p>
                                  <p className="small_txt">Umum</p>
                                </Grid>
                                <Grid item xs={12}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Tanggal Registrasi
                                  </p>
                                  <p className="small_txt">{day(dataSet.created_at).locale("id").format("DD MMMM YYYY")}</p>
                                </Grid>
                                <Grid item xs={12}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Poin Teman Bakat
                                  </p>
                                  <p className="small_txt">{dataSet.poin}</p>
                                </Grid>
                                <Grid item xs={12}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Password
                                  </p>
                                  <Tooltip title={dataSet.key} placement="right" arrow>
                                    <p className="small_txt" style={{ width: "max-content" }}>
                                      *****
                                    </p>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div className="d-flex flex-start">
                          <div style={{ width: 7, height: 7, borderRadius: 50, backgroundColor: theme.primary, marginTop: 10, marginRight: 10 }} />
                          <div className="flexy mt-1">
                            <p className="small_txt" style={{ color: theme.primary }}>
                              Informasi User
                            </p>
                            <div className="mt-3">
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Email
                                  </p>
                                  <p className="small_txt">{dataSet.email}</p>
                                </Grid>
                                <Grid item xs={6}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Tanggal Lahir
                                  </p>
                                  <p className="small_txt">{day(dataSet.tgl_lahir).locale("id").format("DD MMMM YYYY")}</p>
                                </Grid>
                                <Grid item xs={6}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Jenis Kelamin
                                  </p>
                                  <p className="small_txt">{dataSet.jenkel == "L" ? "Laki-laki" : "Perempuan"}</p>
                                </Grid>
                                <Grid item xs={12}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Alamat
                                  </p>
                                  <p className="small_txt">{dataSet.alamat ?? "-"}</p>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div style={{ height: "36px" }}></div>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                              Nomor HP
                            </p>
                            <p className="small_txt">{dataSet.no_telp}</p>
                          </Grid>
                          <Grid item xs={12}>
                            <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                              Status Pekerjaan
                            </p>
                            <p className="small_txt">{dataSet.profesi}</p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </div>
            ) : (
              <EmptyData />
            )
          ) : (
            <Skeleton variant="rounded" className="rounded-lg" style={{ minHeight: "300px", width: "100%" }} />
          )}
        </Grid>
      </Grid>

      {/* tabulaltion start here */}
      <div className="d-flex align-center mt-5">
        <Button className="capitalize mr-3 rounded-lg" onClick={() => setTab(1)} color={tab === 1 ? "primary" : "white"} disableElevation variant="contained">
          <p className="font-weight-bold">Log Activity</p>
        </Button>
        <Button className="capitalize rounded-lg font-weight-bold" onClick={() => setTab(2)} color={tab === 2 ? "primary" : "white"} disableElevation variant="contained">
          <p className="font-weight-bold">Transaction</p>
        </Button>
      </div>
      {tab === 1 ? <IndividualUserActivity id={id} /> : <IndividualUserTransaction id={id} />}
      <div className="py-2">
        <DialogDelete pocket={pocket} d_del={d_del} close={() => setDel(false)} refetch={() => navigate("/individual-user")} />
      </div>
    </div>
  );
};

export default IndividualUserDetail;

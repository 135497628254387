/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import day from "dayjs";
import LinesEllipsis from "react-lines-ellipsis";
import { DialogDelete } from "../../../components/base/dialogDelete";
import { useDispatch } from "react-redux";
import { postApi } from "../../../store/apis";
import { useSnackbar } from "notistack";

const TrainingActivityList = ({ theme, dataSet, page, limit, refetch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [dialog, setDialog] = useState(false);
  const [pocket, setPocket] = useState(null);
  const [dataSetList, setDataList] = useState(dataSet);

  // On Swith status data
  const changeStatus = (item) => {
    let status_show = item.status_show === "open" ? "close" : "open";
    const data = {
      path: "admin/training/switch",
      body: {
        id: item.id,
        status_show: status_show,
      },
    };

    dispatch(postApi(data))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "Status berhasil diubah", { variant });
        let listData = [...dataSetList];
        const index = listData.findIndex((el) => el.id === item.id);
        listData[index] = { ...listData[index], status_show };
        setDataList(listData);
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
      });
  };

  const deleteData = (item) => {
    setPocket({
      path: "admin/training/delete",
      body: {
        id: item.id,
      },
    });
    setDialog(true);
  };

  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell style={{ minWidth: "50px", background: theme.tertiary, borderRadius: "10px 0 0 10px" }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  No.
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "200px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Judul Training
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "150px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Pelaksanaan
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "200px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Pemateri
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Jenis
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Jml. Peserta
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "130px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tipe Peserta
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Show
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary, borderRadius: "0 10px 10px 0" }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tindakan
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSetList.map((item, i) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell className="py-2">
                  <p className="px11_txt">{i + 1 + (page - 1) * limit}</p>
                </TableCell>
                <TableCell className="py-2">
                  <Tooltip title={item.judul} placement="top" followCursor={true} arrow>
                    <div>
                      <LinesEllipsis className="px11_txt" text={item.judul} />
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell className="py-2">
                  {item.pelaksanaan.length ? (
                    <p className="px11_txt">
                      {day(item.pelaksanaan[0].tanggal_mulai).format("DD/MM/YYYY")} {item.pelaksanaan[0].wkt_mulai.slice(0, 5)} - {item.pelaksanaan[0].wkt_selesai.slice(0, 5)}
                    </p>
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell className="py-2">
                  <div className="d-flex">
                    {item.pemateri_training.length ? (
                      <div className="box_pemateri flexy mr-1">
                        <LinesEllipsis className="px11_txt text-center" text={item.pemateri_training[0].detail.nama} />
                      </div>
                    ) : (
                      <></>
                    )}
                    {item.pemateri_training.length > 1 ? <div className="box_pemateri px11_txt"> +{item.pemateri_training.length - 1} </div> : <></>}
                  </div>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.jenis_training}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.kuota}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  {item.kategori_peserta.length ? <p className="px11_txt">{item.kategori_peserta.map((el, i) => `${el.kategori.nama}${i == item.kategori_peserta.length - 1 ? "" : ", "}`)}</p> : <></>}
                </TableCell>
                <TableCell className="py-2" align="center">
                  <div className="cursor_pointer d-flex align-center justify-center">
                    <Icon path={item.status_show == "open" ? mdiToggleSwitch : mdiToggleSwitchOffOutline} size={1.8} color={theme.primary} onClick={() => changeStatus(item)} />
                  </div>
                </TableCell>
                <TableCell className="py-2">
                  <div className="d-flex">
                    <Button
                      variant="contained"
                      className="rounded-lg capitalize mr-3"
                      onClick={() => navigate(`/training-detail/${item.id}`)}
                      disableElevation
                      size="small"
                      style={{ background: theme.act_cyan }}>
                      <p className="font-weight-bold px11_txt" style={{ color: theme.act_lbl_cyan }}>
                        View
                      </p>
                    </Button>
                    <Button
                      variant="contained"
                      className="rounded-lg capitalize mr-3"
                      onClick={() => navigate(`/counselor/detail/${item.id_konselor}`)}
                      disableElevation
                      size="small"
                      style={{ background: theme.act_orange }}>
                      <p className="font-weight-bold px11_txt" style={{ color: theme.act_lbl_orange }}>
                        Resch.
                      </p>
                    </Button>
                    <Button variant="contained" className="rounded-lg capitalize" onClick={() => deleteData(item)} disableElevation size="small" style={{ background: theme.act_red }}>
                      <p className="font-weight-bold px11_txt" style={{ color: theme.act_lbl_red }}>
                        Delete
                      </p>
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pa-2">
        <DialogDelete d_del={dialog} close={() => setDialog(false)} pocket={pocket} refetch={refetch} />
      </div>
    </div>
  );
};

export default TrainingActivityList;

import React, { useState } from "react";
import { Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Avatar, Tooltip, tooltipClasses, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Reschedule } from "./Reschedule";
import day from "dayjs";
import { dateFormatterV3 } from "../../../utils/formatter";
const TablelistSchedule = ({ theme, dataSet, ava, refetch }) => {
  const [dialog, setDialog] = useState({
    post: false,
    view: false,
  });
  const [pocket, setPocket] = useState(null);
  const [formateDate, setDate] = useState(null);
  const navigate = useNavigate();
  const Poper = styled(({ className, ...props }) => <Tooltip placement="bottom-start" {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      maxWidth: 200,
      border: "2px solid #007585",
      borderRadius: "8px",
    },
  }));

  const preReschedule = (item) => {
    let temp = JSON.parse(JSON.stringify(item));
    var customParseFormat = require("dayjs/plugin/customParseFormat");
    day.extend(customParseFormat);
    let formated = temp.date ? day(temp.date, "DD/MM/YYYY").format("YYYY-MM-DD") : day().format("YYYY-MM-DD");
    temp["date"] = new Date(formated);
    setPocket(temp);
    setDate(new Date(formated));
    setDialog({ ...dialog, post: true });
  };

  const onReschedule = (item) => {
    setPocket(item);
    setDialog({ ...dialog, post: true });
  };

  const onRefetch = (item) => {
    let datas = [...dataSet];
    let itemIndex = dataSet.findIndex((x) => x.id === item.id && x.feature === item.feature);
    datas[itemIndex] = { ...item };
    refetch && refetch(datas);
  };

  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell style={{ minWidth: "30px", background: theme.tertiary, borderRadius: "10px 0 0 10px" }} className="py-1">
                <p className="font-weight-bold" style={{ color: theme.primary }}>
                  No
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "200px", background: theme.tertiary }} className="py-1">
                <p className="font-weight-bold" style={{ color: theme.primary }}>
                  Nama Klien
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "250px", background: theme.tertiary }} className="py-1" align="center">
                <p className="font-weight-bold white-space-pre" style={{ color: theme.primary }}>
                  Tgl. & Waktu
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "250px", background: theme.tertiary }} className="py-1">
                <p className="font-weight-bold" style={{ color: theme.primary }}>
                  Kategori
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "250px", background: theme.tertiary }} className="py-1">
                <p className="font-weight-bold" style={{ color: theme.primary }}>
                  Layanan
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-1" align="center">
                <p className="font-weight-bold" style={{ color: theme.primary }}>
                  Status
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary, borderRadius: "0 10px 10px 0" }} className="py-1">
                <p className="font-weight-bold" style={{ color: theme.primary }}>
                  Tindakan
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSet.map((item, i) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>
                  <div className="d-flex align-center font-weight-500">
                    <Avatar alt={item.nama} src={item.data_pelanggan.foto_link ? item.data_pelanggan.foto_link : ava} className="mr-3" />
                    <p> {item.data_pelanggan?.nama ?? ""} </p>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="d-flex gap-2 align-center justify-center font-weight-500">
                    {dateFormatterV3(item.date)}
                    <div style={{ height: "1rem", width: "2px", background: theme.secondary }} />
                    {item.wkt_konsultasi ? item.wkt_konsultasi.substring(0, 5) : ""} {item.wkt_konsultasi ? "WIB" : ""}
                  </div>
                </TableCell>
                <TableCell>{item.kategori}</TableCell>
                <TableCell>{item.layanan}</TableCell>
                <TableCell align="center">
                  <p
                    className="font-weight-bold"
                    style={{ color: item.status === "Aktif" ? "#00786A" : item.status === "Selesai" ? "#6D0BCF" : item.status === "Kadaluwarsa" ? "#B80222" : "#0B81C2" }}>
                    {item.status}
                  </p>
                </TableCell>
                <TableCell>
                  <div className="d-flex align-center">
                    <Button
                      variant="contained"
                      className="rounded-xl capitalize mr-3"
                      disableElevation
                      size="small"
                      style={{ background: theme.act_pink }}
                      onClick={() => navigate(`/profile-client/${item.id_pelanggan}`)}>
                      <p className="font-weight-bold" style={{ color: theme.act_lbl_pink }}>
                        Profile
                      </p>
                    </Button>
                    {item.status === "Aktif" ? (
                      <Button variant="contained" className="rounded-xl capitalize mr-3" disableElevation size="small" style={{ background: theme.act_green }}>
                        <p className="font-weight-bold" style={{ color: theme.act_lbl_green }}>
                          Mulai
                        </p>
                      </Button>
                    ) : (
                      <></>
                    )}
                    {item.status === "Akan Datang" && item.hours_remaining >= 3 ? (
                      <Button variant="contained" className="rounded-xl capitalize mr-3" disableElevation size="small" style={{ background: theme.act_orange }} onClick={() => onReschedule(item)}>
                        <p className="font-weight-bold" style={{ color: theme.act_lbl_orange }}>
                          Resch.
                        </p>
                      </Button>
                    ) : (
                      <></>
                    )}
                    {(item.status === "Akan Datang" || item.status === "Aktif") && (item.hours_remaining >= 3 || item.hours_remaining < 0) ? (
                      <Button variant="contained" className="rounded-xl capitalize mr-3" disableElevation size="small" style={{ background: theme.act_red }}>
                        <p className="font-weight-bold" style={{ color: theme.act_lbl_red }}>
                          Cancel
                        </p>
                      </Button>
                    ) : (
                      <></>
                    )}
                    <Poper
                      title={
                        <React.Fragment>
                          <div className="pa-2">
                            <p className="font-weight-bold mb-1" style={{ color: theme.primary, fontSize: "15px" }}>
                              Topik Konsultasi
                            </p>
                            <p style={{ color: "#000", fontSize: "13px" }}>{item.keterangan}</p>
                          </div>
                        </React.Fragment>
                      }>
                      <Button variant="contained" className="rounded-xl capitalize mr-3" disableElevation size="small" style={{ background: theme.act_blue }}>
                        <p className="font-weight-bold" style={{ color: theme.act_lbl_blue }}>
                          Topik
                        </p>
                      </Button>
                    </Poper>

                    {item.status === "Selesai" ? (
                      <Button variant="contained" onClick={() => preReschedule(item)} className="rounded-xl capitalize mr-3" disableElevation size="small" style={{ background: theme.act_purple }}>
                        <p className="font-weight-bold" style={{ color: theme.act_lbl_purple }}>
                          Riwayat
                        </p>
                      </Button>
                    ) : (
                      <></>
                    )}
                    <Button
                      variant="contained"
                      className="rounded-xl capitalize mr-3"
                      disableElevation
                      size="small"
                      style={{ background: theme.act_blue }}
                      onClick={() => navigate(`/schedule-detail/${item.id}/${item.feature}`)}>
                      <p className="font-weight-bold" style={{ color: theme.act_lbl_blue }}>
                        Detail
                      </p>
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pa-2">
        <Reschedule close={() => setDialog({ ...dialog, post: false })} d_rscdl={dialog.post} pocket={pocket} selected={formateDate} setDate={setDate} refetch={(item) => onRefetch(item)} />
      </div>
    </div>
  );
};

export default TablelistSchedule;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CHANGE_TITLE } from "../../../store/theming";
import { getApi, postApi } from "../../../store/apis";
import EmptyData from "../../../components/base/EmptyData";
import ViewKonsultasi from "../../../components/overview/konsultasi/ViewKonsultasi";
import LogActivity from "../../../components/overview/konsultasi/LogActivity";
import KonselingActivityReschedule from "./KonselingActivityReschedule";
import KonselingActivityCancel from "./KonselingActivityCancel";
import { Grid, Skeleton, Button } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

const KonselingActivityDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingLogUser, setLoadingLogUser] = useState(true);
  const [loadingLogPsikolog, setLoadingLogPsikolog] = useState(true);
  const [dataSet, setData] = useState("");
  const [activityUser, setActivityUser] = useState("");
  const [activityPsikolog, setActivityPsikolog] = useState("");
  const [emptyTxt, setEmptyTxt] = useState("");
  const [openDialogReschedule, setOpenDialogReschedule] = useState(false);
  const [openDialogCancel, setOpenDialogCancel] = useState(false);

  const fetchData = () => {
    let data = {
      path: `admin/konseling-aktivitas/detail?konsultasi_thread_id=${id}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        if (err.status == 400) {
          setEmptyTxt(`No Data Available`);
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setData([]);
      });
  };

  const logActivityUser = (limit = 10, page = 1) => {
    let data = {
      path: `admin/konseling-aktivitas/log-activity?user_id=${dataSet.pelanggan_id}&role=user&sort=-timestamp&page[size]=${limit}&page[number]=${page}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setActivityUser(data);
        setLoadingLogUser(false);
      })
      .catch((err) => {
        if (err.status == 400) {
          setEmptyTxt(`No Data Available`);
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setActivityUser("");
        setLoadingLogUser(false);
      });
  };

  const logActivityPsikolog = (limit = 10, page = 1) => {
    let data = {
      path: `admin/konseling-aktivitas/log-activity?user_id=${dataSet.konselor_id}&role=konselor&sort=-timestamp&page[size]=${limit}&page[number]=${page}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setActivityPsikolog(data);
        setLoadingLogPsikolog(false);
      })
      .catch((err) => {
        if (err.status == 400) {
          setEmptyTxt(`No Data Available`);
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setActivityPsikolog("");
        setLoadingLogPsikolog(false);
      });
  };

  const onCloseDialogReschedule = (status) => {
    setOpenDialogReschedule(status);
  };

  const onCloseDialogCancel = (status) => {
    setOpenDialogCancel(status);
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Detail Konsultasi"));
    if (!dataSet) {
      fetchData();
    } else {
      logActivityUser();
      logActivityPsikolog();
    }
  }, [dataSet]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!loading ? (
            dataSet ? (
              <div className="mb-4">
                <div className="d-flex justify-space-between align-center mb-3">
                  <Button
                    variant="contained"
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg"
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeft /> Kembali
                  </Button>
                </div>
                <ViewKonsultasi
                  data={dataSet}
                  onReschedule={() => onCloseDialogReschedule(true)}
                  onCancel={() => onCloseDialogCancel(true)}
                ></ViewKonsultasi>
              </div>
            ) : (
              <EmptyData />
            )
          ) : (
            <Skeleton
              variant="rounded"
              className="rounded-lg mb-4"
              style={{ minHeight: "400px", width: "75%" }}
            />
          )}
        </Grid>
      </Grid>
      {loadingLogUser || loadingLogPsikolog ? (
        <div className="d-flex flex-row">
          <Skeleton
            variant="rounded"
            className="rounded-lg"
            style={{ minHeight: "300px", flex: 1, marginRight: 5 }}
          ></Skeleton>
          <Skeleton
            variant="rounded"
            className="rounded-lg"
            style={{ minHeight: "300px", flex: 1, marginLeft: 5 }}
          ></Skeleton>
        </div>
      ) : (
        <LogActivity
          logUser={activityUser}
          logPsikolog={activityPsikolog}
          onFetchUser={logActivityUser}
          onFetchPsikolg={logActivityPsikolog}
        ></LogActivity>
      )}
      <div className="pa-2">
        <KonselingActivityReschedule
          konsultasi={dataSet}
          open={openDialogReschedule}
          onClose={onCloseDialogReschedule}
          refetch={fetchData}
        />
        <KonselingActivityCancel
          konsultasi={dataSet}
          open={openDialogCancel}
          onClose={onCloseDialogCancel}
          refetch={fetchData}
        />
      </div>
    </div>
  );
};

export default KonselingActivityDetail;

import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GrafikColumnWithBar from "../../components/overview/grafik/GrafikColumnWithBar";
import TrainingListSection from "../../components/overview/training/TrainingListSection";
import JourneyProgressList from "../../components/overview/journey/JourneyProgressList";
import GameForDevelopment from "../../components/overview/game/GameForDevelopment";
import PsychologicalUpgrade from "../../components/overview/game/PsychologicalUpgrade";
import { useEffect, useState } from "react";
import { getApi } from "../../store/apis";

const OverviewAdm = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const [psikotes, setPsikotes] = useState({});
  const [konsultasi, setKonsultasi] = useState({});

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch = () => {
    getGrafikPsikotes();
    getGrafikKonsultasi();
  };

  const getGrafikPsikotes = (type = "month") => {
    let config = {
      path: `admin/board-result/psikotes?type=${type}`,
    };
    dispatch(getApi(config))
      .then((res) => {
        (res === null || res === undefined) && (res = {});
        setPsikotes({ title: res.grafik.category[res.grafik.category.length - 1], ...res });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGrafikKonsultasi = (type = "month") => {
    let config = {
      path: `admin/board-result/konsultasi-coaching?type=${type}`,
    };
    dispatch(getApi(config))
      .then((res) => {
        (res === null || res === undefined) && (res = {});
        setKonsultasi({ title: res.grafik.category[res.grafik.category.length - 1], ...res });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <hr style={{ border: `1px solid ${theme.lighten}` }} />
      <div className="mt-3 leading-5" style={{ color: theme.black }}>
        <h4>Rangkuman Aktivitas Teman Bakat</h4>
        <small>Lihat rangkuman aktivitas penggunaan layanan di aplikasi Teman Bakat</small>
      </div>
      <Grid container spacing={2} className="mt-3">
        <Grid item xs={6}>
          <GrafikColumnWithBar
            title="Pengguna Psikotes"
            subTitle="Total pengguna layanan psikotes"
            sectionTitle={`Psikotes ${psikotes.title ?? ""}`}
            sectionSubtitle="Jumlah pengguna untuk masing-masing judul psikotes"
            colorTheme={theme.act_lbl_purple}
            onNavClick={(type) => getGrafikPsikotes(type)}
            graphic={psikotes.grafik ?? {}}
            list={psikotes.psikotes ?? []}
          />
          <div className="mt-3">
            <TrainingListSection />
          </div>
          <div className="mt-3">
            <GameForDevelopment />
          </div>
        </Grid>
        <Grid item xs={6}>
          <GrafikColumnWithBar
            title="Pengguna Konsultasi & Coaching"
            subTitle="Total pengguna layanan konsultasi & coaching"
            sectionTitle={`Konsultasi & Coaching ${konsultasi.title ?? ""}`}
            sectionSubtitle="Jumlah pengguna untuk masing-masing judul konsulti & coaching"
            colorTheme={theme.act_lbl_cyan}
            onNavClick={(type) => getGrafikKonsultasi(type)}
            graphic={konsultasi.grafik}
            list={konsultasi.konsultasi}
          />
          <div className="mt-3">
            <JourneyProgressList />
          </div>
          <div className="mt-3">
            <PsychologicalUpgrade />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OverviewAdm;

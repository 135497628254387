/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MonthKonselingAdmin from './monthKonselingAdmin'
import { getApi } from '../../../../store/apis'
import {
    Button, Backdrop,
    CircularProgress,
    Paper,
} from '@mui/material'
import day from 'dayjs'
import WeekKonselingAdmin from './weekKonselingAdmin'
import locale from "dayjs/locale/id";
import CreateJadwal from './createJadwal'

// ini maksudnya setting jadwal konseling tapi dari role admin
const JadwalKonselingAdmin = ({ theme }) => {
    const now = day().locale({
        ...locale,
    });
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)
    const [type, setType] = useState('month') //type of calendar, default month
    const [startDate, setStart] = useState(day().day(0).format('YYYY-MM-DD')) //start date for date range calendar mode weekly
    const [endDate, setEnd] = useState(day().day(6).format('YYYY-MM-DD')) //end date for date range calendar mode weekly
    const [month, setMonth] = useState(day().format('MM'))
    const [year, setYear] = useState(day().format('YYYY'))
    const [currentMonth, setCurrentMonth] = useState(now);
    const [dataSet, setData] = useState(null)

    const [dialog, setDialog] = useState(false);

    const switchMode = (mode) => {
        const currentMonth = day().format('MM')
        if (mode == 'week') {
            if (currentMonth != month) {
                setStart(day(`${year}-${month}-01`).day(0).format('YYYY-MM-DD'))
                setEnd(day(`${year}-${month}-01`).day(6).format('YYYY-MM-DD'))
            } else {
                setStart(day().day(0).format('YYYY-MM-DD'))
                setEnd(day().day(6).format('YYYY-MM-DD'))
            }
        } else {
            setCurrentMonth(day(startDate))
        }
        setType(mode)
    }
    const fetchData = () => {
        setLoading(true)
        let filter = type == 'month' ? `mode=month&month=${month}&year=${year}` : `mode=custom&date_from=${startDate}&date_to=${endDate}`
        let data = {
            path: `admin/konseling-setting/jadwal-konsultasi?${filter}`
        }
        let finalData = {}
        dispatch(getApi(data)).then(data => {
            setLoading(false)
            let temp = JSON.parse(JSON.stringify(data))
            if (type == 'month') {
                let thisMonth = temp[year][month]
                if (thisMonth) {
                    for (let i in thisMonth) {
                        let arr = []
                        for (let j in thisMonth[i]) {
                            let konselor = thisMonth[i][j]
                            if (konselor.data_konselor) {
                                thisMonth[i][j]['role'] = konselor.data_konselor.permission
                                thisMonth[i][j]['sub_role'] = konselor.data_konselor.user?.sub_role == 'KONSELOR TEMAN BAKAT' ? 'Internal' : 'External'
                            }
                            arr.push(thisMonth[i][j])
                        }
                        finalData[`${year}-${month}-${i}`] = arr
                    }
                }
                setData(finalData)
            } else {
                let y = day(startDate).format('YYYY')
                let m = day(startDate).format('MM')
                let thisMonth = temp[y][m]
                if (thisMonth) {
                    for (let i in thisMonth) {
                        let arr = []
                        for (let j in thisMonth[i]) {
                            let konselor = thisMonth[i][j]
                            if (konselor.data_konselor) {
                                thisMonth[i][j]['role'] = konselor.data_konselor.permission
                                thisMonth[i][j]['sub_role'] = konselor.data_konselor.user?.sub_role == 'KONSELOR TEMAN BAKAT' ? 'Internal' : 'External'
                            }
                            thisMonth[i][j]['hour'] = day(thisMonth[i][j]['waktu']).format('H')
                            arr.push(thisMonth[i][j])
                        }
                        finalData[`${y}-${m}-${i}`] = arr
                    }
                }
                setData(finalData)
            }
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setData([])
        })
    }

    useEffect(() => {
        fetchData()
    }, [month, year, startDate, endDate, type])

    return (
        <div>
            <div className="d-flex justify-space-between align-center mb-3">
                <h3>Jadwal Konsultasi</h3>
                <Button variant='contained' onClick={() => setDialog(true)} className='capitalize'>
                    Tambah Jadwal
                </Button>
            </div>
            <div className="d-flex justify-end mb-2">
                <Paper className='pa-1 rounded-lg' elevation={0} style={{ background: theme.lighten }}>
                    <Button variant={type == 'month' ? 'contained' : ''} onClick={() => switchMode('month')} color='secondary' disableElevation className='capitalize rounded-lg'>
                        <p className='font-weight-bold' style={{ color: type == 'month' ? '' : '#777777' }}>Bulanan</p>
                    </Button>
                    <Button variant={type == 'week' ? 'contained' : ''} onClick={() => switchMode('week')} color='secondary' disableElevation className='capitalize  rounded-lg'>
                        <p className='font-weight-bold' style={{ color: type == 'week' ? '' : '#777777' }}>Mingguan</p>

                    </Button>
                </Paper>
            </div>
            {dataSet ? type == 'month'
                ? <MonthKonselingAdmin dataSet={dataSet} currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} setMonth={setMonth} setYear={setYear} theme={theme} />
                : <WeekKonselingAdmin dataSet={dataSet} setStart={setStart} setEnd={setEnd} startDate={startDate} endDate={endDate} theme={theme} />
                : <></>}
            <div className="pa-2">
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
            <div className="py">
                <CreateJadwal dialog={dialog} close={() => setDialog(false)} refetch={fetchData} />
            </div>
        </div>
    )
}

export default JadwalKonselingAdmin

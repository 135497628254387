import React, { forwardRef, useImperativeHandle, } from 'react'
import {
    collection,
    query,
    doc,
    addDoc,
    setDoc,
    updateDoc,
    getDoc,
    onSnapshot,
} from "firebase/firestore";

import { db } from "../../config/firebase";
import Cookies from "js-cookie";
import { Button, Tooltip } from '@mui/material';
import { CallEnd } from '@mui/icons-material';
import { useEffect } from 'react';

const VideoCall = forwardRef(({ css, twillio = [], thread, setOnCall }, ref) => {
    const idUser = Cookies.get('id')

    //livestream var
    let remoteStream = null;
    let remoteVideo = "";
    let videoPlayer = "";
    let remoteVideoAnswer = "";
    let videoPlayerAnswer = "";
    let remoteAudioAnswer = "";
    let audioPlayerAnswer = "";
    let callButton = "";
    let remoteAudio = "";
    let localAudio = "";

    const servers = {
        iceServers: twillio
    };
    const pc = new RTCPeerConnection(servers);
    useImperativeHandle(ref, () => ({
        // GUM : getUserMedia
        setGum(type, status) {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                videoPlayer = document.getElementById("myVid");
                remoteVideo = document.getElementById("urVid")
                let statusVideo = type === 'video' ? true : false
                navigator.mediaDevices
                    .getUserMedia({ video: statusVideo, audio: true })
                    .then((stream) => {
                        remoteStream = new MediaStream();

                        // Push tracks from local stream to peer connection
                        stream.getTracks().forEach((track) => {
                            pc.addTrack(track, stream);
                        });

                        // Pull tracks from remote stream, add to video stream
                        pc.ontrack = (event) => {
                            event.streams[0].getTracks().forEach((track) => {
                                remoteStream.addTrack(track);
                            });
                        };

                        videoPlayer.srcObject = stream;
                        videoPlayer.muted = true;
                        remoteVideo.srcObject = remoteStream;

                        videoPlayer.play();

                        if (status == 'callout') {
                            makeCall(thread.id, type)
                        } else {
                            answerCall(thread.id, type)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            } else {
                alert("canot get camera");
            }
        },

        remoteEnd() {
            endCall('video', 'incoming')
        }
    }));

    const listenCallout = () => {
        let room = doc(db, 'rooms', thread.id)
        onSnapshot(room, (qs) => {
            if (qs.data().status == 'hangup') {
                setTimeout(() => {
                    endCall('video', 'incoming')
                }, 500);
            }
        })
    }

    const makeCall = async (thread, type) => {
        // Reference Firestore collections for signaling
        const callDoc = doc(db, "rooms", thread);
        const offerCandidates = collection(db, "rooms", thread, 'callerCandidates')
        const answerCandidates = collection(db, "rooms", thread, 'calleeCandidates');

        // Get candidates for caller, save to db
        pc.onicecandidate = async (event) => {
            event.candidate && await addDoc(offerCandidates, event.candidate.toJSON())
        };

        // Create offer
        const offerDescription = await pc.createOffer();
        await pc.setLocalDescription(offerDescription);

        const offer = {
            sdp: offerDescription.sdp,
            type: offerDescription.type,
        };
        await setDoc(callDoc, { offer, status: "open", caller: idUser, type: type })

        localStorage.setItem("calling", 1);
        // Listen for remote answer
        onSnapshot(callDoc, (qs) => {
            const data = qs.data();
            if (!pc.currentRemoteDescription && data?.answer) {
                const answerDescription = new RTCSessionDescription(data.answer);
                pc.setRemoteDescription(answerDescription);
            }
        })

        // When answered, add candidate to peer connection
        onSnapshot(answerCandidates, (qs) => {
            qs.docChanges().forEach((change) => {
                if (change.type === 'added') {
                    let dt = change.doc.data()
                    const candidate = new RTCIceCandidate(dt);
                    pc.addIceCandidate(candidate);
                }
            });
        })
    };

    const answerCall = async (thread, type) => {
        // Reference Firestore collections for signaling
        const callDoc = doc(db, "rooms", thread);
        const answerCandidates = collection(db, "rooms", thread, 'calleeCandidates')
        const offerCandidates = collection(db, "rooms", thread, 'callerCandidates');

        // Get candidates for caller, save to db
        pc.onicecandidate = async (event) => {
            event.candidate && await addDoc(answerCandidates, event.candidate.toJSON())
        };

        const callData = (await getDoc(callDoc)).data();

        const offerDescription = callData.offer;
        await pc.setRemoteDescription(new RTCSessionDescription(offerDescription));

        const answerDescription = await pc.createAnswer();
        await pc.setLocalDescription(answerDescription);

        const answer = {
            type: answerDescription.type,
            sdp: answerDescription.sdp,
        };

        await updateDoc(callDoc, { answer });

        localStorage.setItem("calling", 1);
        localStorage.setItem("state_answer", 1);

        onSnapshot(offerCandidates, (qs) => {
            qs.docChanges().forEach((change) => {
                if (change.type === 'added') {
                    let data = change.doc.data();
                    pc.addIceCandidate(new RTCIceCandidate(data));
                }
            });
        })
    }

    const endCall = async (type, from = 'callout') => {
        if (type == 'video') {
            const mediaStream = videoPlayer.srcObject;
            const tracks = mediaStream.getTracks();
            tracks.forEach(element => {
                element.stop();
            });
            localStorage.setItem("calling", 0);
        } else {
            const mediaStream = localAudio.srcObject;
            const tracks = mediaStream.getTracks();
            tracks.forEach(element => {
                element.stop();
            });
            localStorage.setItem("calling", 0);
        }
        // if (from == 'callout') {
        // }
        const callDoc = doc(db, "rooms", thread.id);
        await updateDoc(callDoc, { status: "hangup" });
        setOnCall(false)
    }

    useEffect(() => {
        listenCallout()
    })

    return (
        <div className={`${css.prepare_vcall}`}>
            <video autoPlay={true} id='urVid' className={css.ur_video}></video>
            <div className={css.control_vcall}>
                <video autoPlay={true} id='myVid' className={css.my_video}></video>
                <div className='d-flex justify-center mt-3'>
                    <Tooltip title="End Call" placement="top">
                        <Button color='red' variant='contained' onClick={() => endCall('video')} disableElevation>
                            <CallEnd color='white' />
                        </Button>
                    </Tooltip>

                </div>
            </div>
        </div>
    )
})
export default VideoCall

import React, { useState } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import day from "dayjs";
import { DialogDelete } from "../../../../components/base/dialogDelete";

const BannerList = ({ theme, dataSet, onChangeStatus, refetch }) => {
  const [dialog, setDialog] = useState(false);
  const [pocket, setPocket] = useState(null);
  const navigate = useNavigate();

  const deleteData = (item) => {
    setPocket({
      path: `admin/advertising/banner/delete`,
      body: {
        id: item.id,
      },
    });
    setDialog(true);
  };

  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                style={{
                  minWidth: "50px",
                  background: theme.tertiary,
                  borderRadius: "10px 0 0 10px",
                }}
                className="py-2"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  No
                </p>
              </TableCell>

              <TableCell
                style={{ minWidth: "200px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Gambar
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "130px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Judul Banner
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "100px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Jadwal
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "100px", background: theme.tertiary }}
                className="py-1"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Show
                </p>
              </TableCell>
              <TableCell
                style={{ minWidth: "100px", background: theme.tertiary }}
                className="py-1"
                align="center"
              >
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  Action
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSet.map((item, i) => {
              return (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <p className="small_txt">{i + 1}</p>
                  </TableCell>
                  <TableCell>
                    <img src={item.gambar} height="60px"></img>
                  </TableCell>
                  <TableCell>
                    <p className="small_txt">{item.title ?? "-"}</p>
                  </TableCell>
                  <TableCell>
                    <div className="d-flex flex-row align-center">
                      <p className="small_txt font-weight-700 mr-1">
                        Start Date
                      </p>
                      <p className="small_txt">
                        {item.start
                          ? day(item.start)
                              .locale("id")
                              .format("dddd, MMMM YYYY")
                          : "-"}
                      </p>
                    </div>
                    <div className="d-flex flex-row align-center">
                      <p className="small_txt font-weight-700 mr-1">Repeat</p>
                      <p className="small_txt">
                        {item.repeat_type
                          ? `${item.repeat_type} - ${
                              item.repeat_on ? JSON.parse(item.repeat_on) : ""
                            }`
                          : "-"}
                      </p>
                    </div>
                    <div className="d-flex flex-row align-center">
                      <p className="small_txt font-weight-700 mr-1">
                        End Repeat
                      </p>
                      <p className="small_txt">
                        {item.end
                          ? day(item.end).locale("id").format("dddd, MMMM YYYY")
                          : "-"}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <Button onClick={() => onChangeStatus(item)}>
                      {item.status === "show" ? (
                        <Icon
                          path={mdiToggleSwitch}
                          size={1}
                          color={theme.primary}
                        />
                      ) : (
                        <Icon path={mdiToggleSwitchOffOutline} size={1} />
                      )}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <div className="d-flex">
                      <Button
                        variant="contained"
                        className="rounded-xl capitalize mr-3"
                        onClick={() => navigate(`/banner/detail/${item.id}`)}
                        disableElevation
                        size="small"
                        style={{ background: theme.act_blue }}
                      >
                        <p
                          className="font-weight-bold"
                          style={{ color: theme.act_lbl_blue }}
                        >
                          Detail
                        </p>
                      </Button>
                      <Button
                        variant="contained"
                        className="rounded-xl capitalize"
                        onClick={() => deleteData(item)}
                        disableElevation
                        size="small"
                        style={{ background: theme.act_red }}
                      >
                        <p
                          className="font-weight-bold"
                          style={{ color: theme.act_lbl_red }}
                        >
                          Delete
                        </p>
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pa-2">
        <DialogDelete
          d_del={dialog}
          close={() => setDialog(false)}
          pocket={pocket}
          refetch={refetch}
        />
      </div>
    </div>
  );
};

export default BannerList;

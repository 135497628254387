import { ChevronLeft, Delete, DriveFileRenameOutline, Info } from "@mui/icons-material";
import { Button, Card, Tooltip, tooltipClasses, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchApi, postApi } from "../../../../store/apis";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import { currencyFormatter } from "../../../../utils/formatter";
import { DialogDelete } from "../../../../components/base/dialogDelete";
import { SkeletonCoachDetailPage } from "../../../../components/skeletons";

const CoachDetailPage = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const theme = useSelector((state) => state.theme.myTheme);
  const [element, setElement] = useState();
  const [data, setData] = useState({});
  const Poper = styled(({ className, ...props }) => <Tooltip placement="bottom-start" {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      maxWidth: 200,
      border: "2px solid #007585",
      borderRadius: "8px",
    },
  }));

  useEffect(() => {
    getDetailKelas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    navigation(-1);
  };

  const getDetailKelas = () => {
    let data = {
      path: `konselor/coach/kelas/detail?kelas_id=${id}`,
    };
    dispatch(fetchApi(data))
      .then((data) => {
        setData({ kelas: data.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeStatus = async () => {
    let item = data.kelas;
    // eslint-disable-next-line eqeqeq
    let status = item.show == "1" ? "0" : "1";
    const dataBatch = {
      path: "konselor/coach/kelas/switch",
      body: {
        id: item.id,
        status: status,
        kolom: "show",
      },
    };

    await dispatch(postApi(dataBatch))
      .then((res) => {
        let datas = { ...data.kelas };
        datas = {
          ...datas,
          show: parseInt(status),
        };
        setData({ ...data, kelas: datas });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDelete = async () => {
    let item = data.kelas;
    const dataBatch = {
      path: "konselor/coach/kelas/delete",
      body: {
        id: item.id,
      },
    };

    setElement(
      <DialogDelete
        d_del={true}
        close={() => {
          setElement(undefined);
        }}
        pocket={dataBatch}
        refetch={() => navigation("/coach")}
      />
    );
  };

  const renderStatusItem = (status) => {
    let dataBg = {
      waiting: "orange",
      approved: "green",
      rejected: "red",
    };
    return (
      <Button variant="contained" onClick={() => {}} className="rounded-lg capitalize" disableElevation size="small" style={{ background: theme[dataBg[status]] ?? "" }}>
        <p className="px11_txt capitalize" style={{ color: theme.white }}>
          {status}
        </p>
      </Button>
    );
  };

  const renderPage = () => {
    return (
      <div className="d-flex gap-3">
        <div>{data.kelas && <img src={data.kelas?.gambar_link} alt={data.kelas.nama_kelas} width="250px" />}</div>
        <div className="grow d-flex gap-3 justify-space-between">
          <div>
            <div>
              <span>{renderStatusItem(data.kelas?.action)}</span>
            </div>
            <h4 className="mt-3">{data.kelas?.nama_kelas ?? "-"}</h4>
            <div className="leading-4 mt-1">
              <span className="small_txt">{data.kelas?.keterangan ?? "-"}</span>
            </div>
            <div className="px-1">
              <div className="mt-3">
                <h5 style={{ color: theme.darken }}>Kategori</h5>
                <span className="small_txt">{data.kelas?.kategori?.kategori ?? "-"}</span>
              </div>
              <div className="d-flex gap-3">
                <div className="mr-5">
                  <div className="mt-3">
                    <h5 style={{ color: theme.darken }}>Jumlah Sesi</h5>
                    <span className="small_txt">{data.kelas?.jumlah_sesi ?? "-"}</span>
                  </div>
                  <div className="mt-3">
                    <h5 style={{ color: theme.darken }}>Harga Normal</h5>
                    <span className="small_txt">{currencyFormatter(data.kelas?.harga)}</span>
                  </div>
                </div>
                <div>
                  <div className="mt-3">
                    <h5 style={{ color: theme.darken }}>Durasi per Sesi</h5>
                    <span className="small_txt">{data.kelas?.durasi ?? "-"}</span>
                  </div>
                  <div className="mt-3">
                    <h5 style={{ color: theme.darken }}>Harga Promo</h5>
                    <span className="small_txt">{currencyFormatter(data.kelas?.potongan_harga)}</span>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <h5 style={{ color: theme.darken }}>Fitur Kelas</h5>
                <ul className="pl-1">
                  {data.kelas?.attr_decode?.length === 0 && <span className="small_txt">Fitur tidak ditambahkan</span>}
                  {data.kelas?.attr_decode?.map((item, index) => {
                    return (
                      <li key={index}>
                        <span className="small_txt">{item.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex flex-wrap align-center justify-end">
              <span className="font-weight-500" style={{ color: theme.primary }}>
                Show
              </span>
              <Poper
                title={
                  <React.Fragment>
                    <div className="pa-2">
                      <p style={{ color: "#000", fontSize: "13px" }}>Tampilkan atau sembunyikan kelas coaching Anda di aplikasi Teman Bakat tanpa menghapusnya</p>
                    </div>
                  </React.Fragment>
                }>
                <Info style={{ fontSize: "15px", color: theme.secondary }} />
              </Poper>
              <div onClick={() => onChangeStatus()} className="d-flex align-center justify-center ml-2">
                <Icon path={data.kelas?.show === 1 ? mdiToggleSwitch : mdiToggleSwitchOffOutline} size={1.8} color={theme.primary} />
              </div>
            </div>
            <div className="d-flex gap-2 align-center mt-3">
              <Button variant="contained" className="px-3 py-1 capitalize rounded-lg" onClick={() => navigation(`/coach/kelas/${data.kelas?.id}/edit`)}>
                <DriveFileRenameOutline className="mr-3" />
                Ubah
              </Button>
              <Button variant="contained" className="px-3 py-1 capitalize rounded-lg" style={{ backgroundColor: theme.act_red, color: theme.act_lbl_red }} onClick={() => onDelete()}>
                <Delete className="mr-1" />
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Button variant="contained" style={{ minWidth: "0" }} className="capitalize rounded-lg" onClick={() => goBack()}>
        <ChevronLeft /> Kembali
      </Button>
      <div className="mt-3">
        <Card className="rounded-lg pa-4">{data.kelas ? renderPage() : <SkeletonCoachDetailPage />}</Card>
      </div>
      {element}
    </div>
  );
};

export default CoachDetailPage;

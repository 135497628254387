/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Button, Grid, Paper, Skeleton, IconButton } from "@mui/material";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../../store/theming";
import day from "dayjs";
import { getApi, postApi } from "../../../../store/apis";
import { AddCircle } from "@mui/icons-material";
import EmptyData from "../../../../components/base/EmptyData";
import { useNavigate } from "react-router-dom";
import DraggablePopupBanner from "./DraggablePopupBanner";
import Calendar from "react-calendar";
import { useSnackbar } from "notistack";

const PopupBannerActivity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.myTheme);
  const { enqueueSnackbar } = useSnackbar();
  const [tab, setTab] = useState(1);
  const [today, setToday] = useState(day());
  const [load, setLoad] = useState({
    popup: false,
    banner: false,
  });

  const [popup, setPop] = useState([]);
  const [banner, setBanner] = useState([]);

  const fetchPopup = () => {
    setLoad({ ...load, popup: true });
    let data = {
      path: `admin/advertising/popup?filter[start_promo]=${today.format(
        "YYYY-MM-DD"
      )}`,
    };

    dispatch(getApi(data))
      .then((response) => {
        setPop(response.data);
        setLoad({ ...load, popup: false });
      })
      .catch((err) => {
        setPop([]);
        setLoad({ ...load, popup: false });
      });
  };

  const fetchBanner = () => {
    setLoad({ ...load, banner: true });
    let data = {
      path: `admin/advertising/banner?filter[start]=${today.format(
        "YYYY-MM-DD"
      )}`,
    };

    dispatch(getApi(data))
      .then((response) => {
        setBanner(response.data);
        setLoad({ ...load, banner: false });
      })
      .catch((err) => {
        setBanner([]);
        setLoad({ ...load, banner: false });
      });
  };

  const onDelete = (id, type) => {
    let data = {
      path: `admin/advertising/${type === "popup" ? "popup" : "banner"}/delete`,
      body: {
        id,
      },
    };

    dispatch(postApi(data))
      .then((res) => {
        const variant = "success";
        enqueueSnackbar(res.message, { variant });
        type === "popup" ? fetchPopup() : fetchBanner();
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message, { variant });
      });
  };

  const switchOrder = (list, type) => {
    let data = {
      path: `admin/advertising/${
        type === "popup" ? "popup" : "banner"
      }/switch-queque`,
      body: {
        data: JSON.stringify(list),
      },
    };

    dispatch(postApi(data))
      .then((res) => {
        const variant = "success";
        enqueueSnackbar(res.message, { variant });
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message, { variant });
      });
  };

  const onListChange = (data, type) => {
    if (type === "popup") {
      setPop(data);
    } else {
      setBanner(data);
    }
    switchOrder(data, type);
  };

  const changeDate = (change) => {
    const date = day(today).add(change, "day");
    setToday(date);
  };

  const changeMonth = (change) => {
    const date = day(today).add(change, "month");
    setToday(date);
  };

  const onChangeCalendar = (value) => {
    const date = day(value);
    setToday(date);
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE(`Pop Up & Banner`));
    fetchPopup();
    fetchBanner();
  }, [today]);

  return (
    <div>
      <div className="d-flex mt-2">
        <Button
          variant="contained"
          className="mr-3 rounded-lg"
          color={tab == 1 ? "primary" : "white"}
          disableElevation
          onClick={() => setTab(1)}
        >
          <p
            className="font-weight-bold capitalize"
            style={{ color: tab == 1 ? "white" : theme.primary }}
          >
            Schedule
          </p>
        </Button>
        <Button
          variant="contained"
          className="mr-3 rounded-lg"
          color={tab == 2 ? "primary" : "white"}
          disableElevation
          onClick={() => setTab(2)}
        >
          <p
            className="font-weight-bold capitalize"
            style={{ color: tab == 2 ? "white" : theme.primary }}
          >
            Statistik
          </p>
        </Button>
      </div>
      <div className="mt-3">
        {tab == 1 ? (
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div className="d-flex flex-row align-center justify-space-between mb-3">
                <p
                  className="font-weight-bold"
                  style={{ color: theme.primary }}
                >
                  {today.locale("id").format("dddd, D MMMM YYYY")}
                </p>
                <div className="d-flex">
                  <IconButton
                    onClick={() => changeDate(-1)}
                    style={{
                      backgroundColor: theme.lighten,
                    }}
                  >
                    <ChevronLeft sx={{ color: theme.primary }} />
                  </IconButton>
                  <div className="mr-1 ml-1"></div>
                  <IconButton
                    onClick={() => changeDate(1)}
                    style={{
                      backgroundColor: theme.lighten,
                    }}
                  >
                    <ChevronRight sx={{ color: theme.primary }} />
                  </IconButton>
                </div>
              </div>
              <Paper className="pa-3 rounded-lg mb-5">
                <div className="d-flex justify-space-between align-center">
                  <p className="font-weight-bold">Pop Up</p>
                  <Button
                    onClick={() =>
                      navigate("/popup-banner-activity/setting-popup")
                    }
                    variant="contained"
                    className="capitalize"
                  >
                    Setting Pop up
                  </Button>
                </div>
                <Paper
                  style={{ background: theme.tertiary }}
                  elevation={0}
                  className="pa-3 rounded-lg mt-3"
                >
                  {load.popup ? (
                    <>
                      {Array(3)
                        .fill()
                        .map((_, index) => {
                          return (
                            <Skeleton
                              key={index}
                              className="rounded-lg mb-2"
                              variant="rectangular"
                              width="100%"
                              height={60}
                            />
                          );
                        })}
                    </>
                  ) : popup.length ? (
                    <DraggablePopupBanner
                      type="popup"
                      onChange={onListChange}
                      onDelete={onDelete}
                      data={popup}
                      theme={theme}
                    />
                  ) : (
                    <EmptyData />
                  )}
                  <div className="d-flex justify-end">
                    <Button
                      className="capitalize"
                      onClick={() =>
                        navigate("/popup-banner-activity/create-popup")
                      }
                    >
                      Tambah Pop Up <AddCircle className="ml-2" />
                    </Button>
                  </div>
                </Paper>
              </Paper>
              <Paper className="pa-3 rounded-lg mb-5">
                <div className="d-flex justify-space-between align-center">
                  <p className="font-weight-bold">Banner</p>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate("/popup-banner-activity/setting-banner")
                    }
                    className="capitalize"
                  >
                    Setting Banner
                  </Button>
                </div>
                <Paper
                  style={{ background: theme.tertiary }}
                  elevation={0}
                  className="pa-3 rounded-lg mt-3"
                >
                  {load.banner ? (
                    <>
                      {Array(3)
                        .fill()
                        .map((_, index) => {
                          return (
                            <Skeleton
                              key={index}
                              className="rounded-lg mb-2"
                              variant="rectangular"
                              width="100%"
                              height={60}
                            />
                          );
                        })}
                    </>
                  ) : banner.length ? (
                    <DraggablePopupBanner
                      type="banner"
                      onChange={onListChange}
                      onDelete={onDelete}
                      data={banner}
                      theme={theme}
                    />
                  ) : (
                    <EmptyData />
                  )}
                  <div className="d-flex justify-end">
                    <Button
                      className="capitalize"
                      onClick={() =>
                        navigate("/popup-banner-activity/create-banner")
                      }
                    >
                      Tambah Banner <AddCircle className="ml-2" />
                    </Button>
                  </div>
                </Paper>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className="pa-5 rounded-lg">
                <div className="d-flex align-center justify-space-between mb-2">
                  <p
                    className="small_txt font-weight-700"
                    style={{ color: theme.darken }}
                  >
                    {day(today).locale("id").format("MMMM YYYY")}
                  </p>
                  <div className="d-flex">
                    <IconButton
                      size="small"
                      onClick={() => changeMonth(-1)}
                      style={{
                        backgroundColor: theme.lighten,
                      }}
                    >
                      <ChevronLeft sx={{ color: theme.primary }} />
                    </IconButton>
                    <div className="mr-1 ml-1"></div>
                    <IconButton
                      size="small"
                      onClick={() => changeMonth(1)}
                      style={{
                        backgroundColor: theme.lighten,
                      }}
                    >
                      <ChevronRight sx={{ color: theme.primary }} />
                    </IconButton>
                  </div>
                </div>
                <Calendar
                  onChange={onChangeCalendar}
                  locale="id"
                  formatShortWeekday={(locale, date) =>
                    day(date).locale("id").format("dd")[0]
                  }
                  showNavigation={false}
                />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PopupBannerActivity;

import { Card } from "@mui/material";
import { dateFormatterV3, timeFormatter } from "../../../utils/formatter";
import { useSelector } from "react-redux";
import EmptyData from "../../base/EmptyData";

const CardListActivity = ({ title, data }) => {
  const theme = useSelector((state) => state.theme.myTheme);

  return (
    <Card className="mt-2 pa-4 rounded-lg">
      <h4 className="font-weight-500">{title}</h4>
      <div className="mt-3">
        {data?.length === 0 && <EmptyData />}
        {data?.map((item, index) => {
          return (
            <div key={index} style={{ borderLeft: `3px solid ${theme.secondary}` }} className="d-flex px-4 py-2 leading-5 relative align-center">
              <div style={{ borderRadius: "50%", width: "13px", height: "13px", border: `3px solid ${theme.secondary}`, left: -8, background: theme.white }} className="absolute" />
              <div className="pr-50">
                <h4>{dateFormatterV3(item.created_at)}</h4>
                <small>{timeFormatter(item.created_at)} WIB</small>
              </div>
              <div className="py-2 px-3 rounded grow d-flex gap-3 align-center" style={{ background: theme.tertiary }}>
                <div className="d-flex align-center pr-5">
                  <small style={{ color: theme.act_lbl_purple }} className="font-weight-700">
                    {item.tipe}
                  </small>
                </div>
                <div style={{ width: "3px", height: "2rem", background: theme.secondary }} />
                <p className="small_txt">{item.deskripsi}</p>
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default CardListActivity;

/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack';
import { Delete as DeleteIcon, Close as CloseIcon, Edit as EditIcon, Loupe, RadioButtonUnchecked, RadioButtonChecked, Clear, Check } from '@mui/icons-material';
import { getApi, postApi } from '../../../../store/apis';
import { useEffect } from 'react';
import FlatInput from '../../../../components/base/flatInput';

const SelectKategoriPeserta = ({ dialog, close, category, setCategory }) => {
    const dispatch = useDispatch()
    const theme = useSelector((state) => state.theme.myTheme)
    const { enqueueSnackbar } = useSnackbar();
    const [dataSet, setData] = useState([])
    const [onAdd, setOnAdd] = useState(false)
    const [onUpdate, setOnUpdate] = useState(null)
    const [tempCategory, setTempCategory] = useState('')
    const [state, setState] = useState({
        loading: false
    })

    const handleUpdate = (index) => (e) => {
        let raw = JSON.parse(JSON.stringify(dataSet))
        raw[index].nama = e.target.value
        setData(raw)
    }

    const addData = (e) => {
        if (e.key === 'Enter') {
            let data = {
                path: 'admin/training/kategori-peserta/create',
                body: {
                    nama: tempCategory
                },
                type: 'add'
            }
            requestApi(data)
        }
    }
    const updateData = (item) => {
        let data = {
            path: 'admin/training/kategori-peserta/update',
            body: {
                id: item.id,
                nama: item.nama
            },
            type: 'update'
        }
        requestApi(data)
    }

    const deleteData = item => {
        let data = {
            path: 'admin/training/kategori-peserta/delete',
            body: {
                id: item.id
            },
            type: 'del'
        }
        requestApi(data)
    }

    const requestApi = param => {
        setState({ ...state, loading: true })
        let data = {
            path: param.path,
            body: param.body
        }
        let variant = ''
        dispatch(postApi(data)).then(data => {
            variant = 'success'
            enqueueSnackbar(data.message, { variant });
            setTempCategory('')
            setOnAdd(false)
            setOnUpdate(null)
            fetchData()
        }).catch(err => {
            setState({ ...state, loading: false })
            variant = 'error'
            if (err.error) {
                let msg = Object.values(err.error).map(el => el[0])
                msg.forEach(msg => {
                    enqueueSnackbar(msg, { variant });
                })
            } else {
                enqueueSnackbar(err.message, { variant });
            }
        })
    }

    const [loading, setLoading] = useState(true)

    const fetchData = () => {
        setLoading(true)
        let data = {
            path: `admin/training/kategori-peserta`
        }
        dispatch(getApi(data)).then(res => {
            setLoading(false)
            setData(res.data)
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setData([])
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const empty = require('../../../../assets/img/empty.png')

    return (
        <Dialog
            open={dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: { borderRadius: 10, minWidth: '350px' }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                <div className="d-flex justify-space-between">
                    <h4>Kategori Peserta</h4>
                    <IconButton onClick={close}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                {dataSet.length ?
                    dataSet.map((item, i) => <div key={i} className='pa-2 d-flex justify-space-between align-center small_txt'>
                        {onUpdate == item.id ?
                            <div className='flexy'>
                                <FlatInput
                                    value={item.nama}
                                    handleChange={() => handleUpdate(i)}
                                    property="nama"
                                    placeholder='Tulis Nama Kategori'
                                />
                            </div>
                            :
                            <div className='d-flex align-center cursor_pointer' onClick={() => setCategory(item)}>
                                {category.id == item.id ? <RadioButtonChecked color='secondary' /> : <RadioButtonUnchecked color='secondary' />}
                                <p className='font-weight-bold ml-2' style={{ color: theme.secondary }}>{item.nama}</p>
                            </div>}
                        {onUpdate == item.id ?
                            <div className='d-flex align-center'>
                                <IconButton onClick={() => updateData(item)}>
                                    <Check fontSize='small' />
                                </IconButton>
                                <IconButton onClick={() => setOnUpdate(null)}>
                                    <Clear fontSize='small' />
                                </IconButton>
                            </div> :
                            <div className='d-flex align-center'>
                                <IconButton onClick={() => deleteData(item)}>
                                    <DeleteIcon fontSize='small' />
                                </IconButton>
                                <IconButton onClick={() => setOnUpdate(item.id)}>
                                    <EditIcon fontSize='small' />
                                </IconButton>
                            </div>}
                    </div>)
                    : <div className='d-flex justify-center'>
                        <img src={empty} height='100px' alt='teman bakat' />
                    </div>}
                {onAdd ? <div>
                    <p className="mb-1 font-weight-bold small_txt" style={{ color: theme.primary }}>
                        Nama Kategori</p>
                    <FlatInput
                        value={tempCategory}
                        handleChange={() => (e) => setTempCategory(e.target.value)}
                        handleKeyup={addData}
                        property="judul"
                        placeholder='Tulis Nama Kategori'
                    />
                </div> : <Button className='capitalize' onClick={() => setOnAdd(true)}> <Loupe /><p className="ml-2 font-weight-bold small_txt">
                    Tambah Kategori</p></Button>}
            </DialogContent>
            <DialogActions>
                <div className="d-flex justify-end">
                    {category.id ?
                        <Button variant='contained' onClick={close}>
                            <p className="font-weight-bold small_txt capitalize">
                                simpan
                            </p>
                        </Button> : <></>
                    }
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default SelectKategoriPeserta
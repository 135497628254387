/* eslint-disable eqeqeq */
import { Grid, Paper, Divider, Button, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import React from 'react'
import FlatInput from '../../../../components/base/flatInput'
import styleInput from '../../../../assets/css/search.module.css'
/** @jsxImportSource @emotion/react */
import { css as emot } from '@emotion/react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { AccessTime, DateRange, Loupe, Delete as DeleteIcon } from '@mui/icons-material'

const WaktuTraining = ({ theme, waktuTraining, setWaktuTraining }) => {

    const handleInput = (prop) => (event) => {
        let rawData = [...waktuTraining]

        if (prop.type) {
            rawData[prop.idx][prop.label] = event
        } else {
            rawData[prop.idx][prop.label] = event.target.value
        }
        setWaktuTraining(rawData)
    }

    const addSection = () => {
        let rawData = {
            jenis: '',
            date: '',
            time_start: '',
            time_end: '',
            media: '',
            link: ''
        }
        setWaktuTraining([...waktuTraining, rawData])
    }

    const removeSection = index => {
        setWaktuTraining(waktuTraining.filter((el, i) => i != index))
    }

    return (
        <Paper className='pa-5 rounded-lg mb-3'>
            <p className='mb-3' style={{ color: theme.primary, fontWeight: '500' }}>Detail Pelaksanaan</p>
            {waktuTraining.length ?
                waktuTraining.map((item, i) => <div className='mb-3' key={i}>
                    <div className="mb-3 d-flex justify-space-between">
                        <div className='d-flex justify-center align-center mr-2' style={{ width: '25px', height: '25px', borderRadius: '50%', background: theme.lighten }}>
                            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>{i + 1}</p>
                        </div>
                        {i > 0 ? <Button className='capitalize' onClick={() => removeSection(i)}> <DeleteIcon /></Button> : <></>}
                    </div>

                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Jenis Training</p>
                        </Grid>
                        <Grid item xs={9}>
                            <RadioGroup
                                name="rb-type"
                                row
                                value={item.jenis}
                                onChange={handleInput({ idx: i, label: 'jenis' })}
                            >
                                <FormControlLabel
                                    value="online"
                                    control={<Radio />}
                                    label="Online"
                                />
                                <FormControlLabel
                                    value="offline"
                                    control={<Radio />}
                                    label="Offline"
                                />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={3}>
                            <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Pelaksanaan</p>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <div className="d-flex align-center" style={{ background: theme.tertiary, borderRadius: '5px', padding: '2px' }}>
                                        <DateRange className="mx-2" />

                                        <div className="flexy">
                                            <DatePicker
                                                placeholderText='Tanggal Batas Earlybird'
                                                selected={item.date}
                                                onChange={handleInput({ idx: i, label: 'date', type: 'date' })}
                                                css={emot`
                                                    background: ${item.date ? '' : theme.tertiary};
                                                    outline: 2px solid ${theme.tertiary};
                                                    border-radius: 5px;
                                                    &:focus { 
                                                        background: #fff;
                                                    }`}
                                                dateFormat="dd MMMM yyyy"
                                                className={styleInput.input_psy}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <div className="d-flex align-center" style={{ background: theme.tertiary, borderRadius: '5px', padding: '2px' }}>
                                                <AccessTime className="mx-2" />
                                                <div className="flexy">
                                                    <DatePicker
                                                        placeholderText='Waktu Mulai'
                                                        selected={item.time_start}
                                                        onChange={handleInput({ idx: i, label: 'time_start', type: 'date' })}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption="Start"
                                                        dateFormat="hh:mm"
                                                        css={emot`
                                                        background: ${item.time_start ? '' : theme.tertiary};
                                                        outline: 2px solid ${theme.tertiary};
                                                        border-radius: 5px;
                                                        &:focus {
                                                            background: #fff;
                                                        }`}
                                                        className={styleInput.input_psy}
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="d-flex align-center" style={{ background: theme.tertiary, borderRadius: '5px', padding: '2px' }}>
                                                <AccessTime className="mx-2" />
                                                <div className="flexy">
                                                    <DatePicker
                                                        placeholderText='Waktu Selesai'
                                                        selected={item.time_end}
                                                        onChange={handleInput({ idx: i, label: 'time_end', type: 'date' })}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption="Start"
                                                        dateFormat="hh:mm"
                                                        css={emot`
                                                        background: ${item.time_end ? '' : theme.tertiary};
                                                        outline: 2px solid ${theme.tertiary} ;
                                                        border-radius: 5px;
                                                        &:focus { 
                                                            background: #fff;
                                                        }`}
                                                        className={styleInput.input_psy}
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Media Pelaksanaan</p>
                        </Grid>
                        <Grid item xs={9}>
                            <FlatInput
                                value={item.media}
                                handleChange={handleInput}
                                property={{ idx: i, label: 'media' }}
                                placeholder='Media Pelaksanaan'
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Link Training</p>
                            <p style={{ color: theme.primary, fontSize: '12px' }}>Kosongkan bila belum ada</p>
                        </Grid>
                        <Grid item xs={9}>
                            <FlatInput
                                value={item.link}
                                handleChange={handleInput}
                                property={{ idx: i, label: 'link' }}
                                placeholder='Link Training'
                            />
                        </Grid>
                    </Grid>
                    <div className="py-2">
                        <Divider></Divider>
                    </div>
                </div>) : <></>}
            <Button className='capitalize' onClick={addSection}> <Loupe /><p className="ml-2">
                Tambah Detail Pelaksanaan</p></Button>
        </Paper>
    )
}

export default React.memo(WaktuTraining)

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../../store/theming";
import { Avatar, Button, Card, Grid, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Cancel, Circle, KeyboardArrowDown, Search } from "@mui/icons-material";
import FlatArea from "../../../../components/base/flatArea";
import { fetchApi } from "../../../../store/apis";

const PortofolioPage = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const [find, setFind] = useState("");
  const [data, setData] = useState({
    page: 1,
    limit: 10,
  });
  const [controller, setController] = useState({});

  useEffect(() => {
    dispatch(CHANGE_TITLE("Portofolio"));
    getKlien();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.page, find]);

  const getKlien = () => {
    let page = `&page[size]=${data.limit}&page[number]=${data.page}`;
    let dataBody = {
      path: `konselor/list-portofolio-klien?filter[pelanggan.nama]=${find}${page}`,
    };
    dispatch(fetchApi(dataBody))
      .then((res) => {
        const dataKlien = res.data.data;
        setData({ ...data, klien: dataKlien.data, from: dataKlien.from, to: dataKlien.to, total: dataKlien.total, last_page: dataKlien.last_page });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFieldChange = (name, value) => {
    setController({ ...controller, [name]: value });
  };

  const handleSaran = (prop) => (event) => {
    let value = event.target?.value || event;
    value = value.type ? "" : value;
    handleFieldChange("saran", value);
  };

  const handlePage = (event, value) => {
    setData({ ...data, page: value });
  };

  const onClickCircle = (item) => {
    let dataBody = {
      path: `konselor/portofolio-klien?filter[personal_profile.id]=${item.id}`,
    };
    dispatch(fetchApi(dataBody))
      .then((res) => {
        let dataPersonal = res.data.data;
        console.log(dataPersonal);
        setData({ ...data, dataPersonal: dataPersonal });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderPortofolio = () => {
    return (
      <Card className="rounded-lg pa-3">
        <div style={{ backgroundColor: theme.primary, color: theme.white }} className="rounded-lg pa-2 font-weight-700">
          Portofolio Klien
        </div>
        <div className="mt-2 px12_txt leading-4">
          <div className="d-flex justify-space-between py-2 px-1">
            <div className="d-flex gap-3 align-center">
              <Avatar src={data?.dataPersonal?.foto} style={{ width: "3rem", height: "3rem" }} />
              <div>
                <h4 style={{ color: theme.primary }}>Nama</h4>
                <span>{data?.dataPersonal?.nama ?? "-"}</span>
                <p>
                  <small className="px11_txt">{data?.dataPersonal?.profesi ?? "-"}</small>
                </p>
              </div>
            </div>
            <div className="text-right">
              <h4 style={{ color: theme.primary }}>Career Goals</h4>
              <span>{data?.dataPersonal?.career_goal ?? "-"}</span>
            </div>
          </div>
          <hr style={{ border: `1px solid ${theme.primary}` }} className="mt-2" />
          <div>
            <div className="d-flex justify-space-between align-center pt-3 pb-2">
              <div className="d-flex gap-2 align-center">
                <Circle fontSize="small" color="primary" className="mr-1" />
                <h4 style={{ color: theme.primary }}>Skill</h4>
              </div>
              <KeyboardArrowDown style={{ color: theme.primary }} />
            </div>
            <div className="px-8 d-flex justify-space-between gap-2">
              <div className="full_width">
                <h4>Soft Skills</h4>
                <ul className="mt-2">
                  {data?.dataPersonal?.softskill?.map((item, index) => {
                    return <li key={index}>{item !== "" ? item : "-"}</li>;
                  })}
                </ul>
              </div>
              <div style={{ border: `1px solid ${theme.secondary}` }} />
              <div className="full_width pl-3">
                <h4>Hard Skills</h4>
                <ul className="mt-2">
                  {data?.dataPersonal?.hardskill?.map((item, index) => {
                    return <li key={index}>{item !== "" ? item : "-"}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
          <hr style={{ border: `1px solid ${theme.primary}` }} className="mt-4" />
          <div>
            <div className="d-flex justify-space-between align-center pt-3 pb-2">
              <div className="d-flex gap-2 align-center">
                <Circle fontSize="small" color="primary" className="mr-1" />
                <h4 style={{ color: theme.primary }}>Education</h4>
              </div>
              <KeyboardArrowDown style={{ color: theme.primary }} />
            </div>
            <div className="px-8 d-inline-block">
              <ul>
                {data?.dataPersonal?.education?.map((item, index) => {
                  return (
                    <li key={index}>
                      <span>
                        {item.tahun_mulai !== "" ? item.tahun_mulai : "-"}-{item.tahun_selesai !== "" ? item.tahun_selesai : "-"}
                      </span>
                      <h4>{item.sekolah !== "" ? item.sekolah : "-"}</h4>
                      <span>{item.jurusan !== "" ? item.jurusan : "-"}</span>
                      <p>
                        <span>{item.nilai}</span>
                      </p>
                      {index !== data.dataPersonal.education.length - 1 && <hr style={{ border: `1px solid ${theme.secondary}` }} className="my-3" />}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <hr style={{ border: `1px solid ${theme.primary}` }} className="mt-4" />
          <div>
            <div className="d-flex justify-space-between align-center pt-3 pb-2">
              <div className="d-flex gap-2 align-center">
                <Circle fontSize="small" color="primary" className="mr-1" />
                <h4 style={{ color: theme.primary }}>Experience</h4>
              </div>
              <KeyboardArrowDown style={{ color: theme.primary }} />
            </div>
            <div className="px-8 d-inline-block">
              <ul>
                {data?.dataPersonal?.pengalaman?.map((item, index) => {
                  return (
                    <li key={index}>
                      <span>
                        {item.tahun_mulai !== "" ? item.tahun_mulai : "-"}-{item.tahun_selesai !== "" ? item.tahun_selesai : "-"}
                      </span>
                      <h4>{item.perusahaan !== "" ? item.perusahaan : "-"}</h4>
                      <span>{item.pengalaman !== "" ? item.pengalaman : "-"}</span>
                      <p>
                        <span>{item.deskripsi}</span>
                      </p>
                      {index !== data.dataPersonal.pengalaman.length - 1 && <hr style={{ border: `1px solid ${theme.secondary}` }} className="my-3" />}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <hr style={{ border: `1px solid ${theme.primary}` }} className="mt-4" />
          <div>
            <div className="d-flex justify-space-between align-center pt-3 pb-2">
              <div className="d-flex gap-2 align-center">
                <Circle fontSize="small" color="primary" className="mr-1" />
                <h4 style={{ color: theme.primary }}>Award</h4>
              </div>
              <KeyboardArrowDown style={{ color: theme.primary }} />
            </div>
            <div className="px-8 d-inline-block">
              <ul>
                {data?.dataPersonal?.award?.map((item, index) => {
                  return (
                    <li key={index}>
                      <h4>{item.penghargaan !== "" ? item.penghargaan : "-"}</h4>
                      <span>{item.penyelenggara !== "" ? item.penyelenggara : "-"}</span>
                      <p>
                        <span>{item.tahun !== "" ? item.tahun : "-"}</span>
                      </p>
                      {index !== data.dataPersonal.award.length - 1 && <hr style={{ border: `1px solid ${theme.secondary}` }} className="my-3" />}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <hr style={{ border: `1px solid ${theme.primary}` }} className="mt-4" />
          <div>
            <div className="d-flex justify-space-between align-center pt-3 pb-2">
              <div className="d-flex gap-2 align-center">
                <Circle fontSize="small" color="primary" className="mr-1" />
                <h4 style={{ color: theme.primary }}>Career Plan</h4>
              </div>
              <KeyboardArrowDown style={{ color: theme.primary }} />
            </div>
            <div className="px-8 d-inline-block">
              <ul>
                {data?.dataPersonal?.target?.map((item, index) => {
                  return (
                    <li key={index}>
                      <h4>{item.target !== "" ? item.target : "-"}</h4>
                      <span>{item.waktu !== "" ? item.waktu : "-"}</span>
                      {index !== data.dataPersonal.target.length - 1 && <hr style={{ border: `1px solid ${theme.secondary}` }} className="my-3" />}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="py-5 px12_txt px-2">
          <h4 style={{ color: theme.primary }}>Saran Psikolog</h4>
          <div className="mt-2">
            <FlatArea value={controller.saran} handleChange={handleSaran} property="saran" placeholder="..." handleBlur={() => {}} />
          </div>
          <div className="mt-4 d-flex justify-end align-end gap-3">
            <Button variant="contained" className="px-5 rounded-lg capitalize shadow-none" style={{ background: theme.tertiary, color: theme.primary }}>
              Batalkan
            </Button>
            <Button variant="contained" className="px-5 rounded-lg capitalize shadow-none">
              Kirim
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card className="rounded-lg pa-3">
          <div className="d-flex justify-space-between align-center">
            <small className="font-weight-700">List Portofolio Klien</small>
            <div>
              <Card
                className="d-flex align-center justify-space-between rounded-lg pr-1"
                elevation={0}
                style={{ border: `solid 2px ${false ? theme.primary : theme.tertiary}`, background: `${false ? "" : theme.tertiary}` }}>
                <input
                  type="text"
                  placeholder="Cari Klien"
                  value={find}
                  onChange={(event) => setFind(event.target.value)}
                  className="search_input"
                  style={{ background: false ? "" : theme.tertiary }}
                />
                {false ? <Cancel onClick={() => {}} onMouseDown={(event) => event.preventDefault()} fontSize="small" color="secondary" className="mr-2 cursor_pointer" /> : <></>}
                <Search fontSize="small" color={true ? "white" : "primary"} style={{ color: theme.darken }} />
              </Card>
            </div>
          </div>
          <div className="mt-2">
            <TableContainer component="div">
              <Table aria-label="simple table" style={{ borderBottom: "none" }}>
                <TableHead>
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell
                      style={{
                        minWidth: "15rem",
                        background: theme.tertiary,
                        borderRadius: "10px 0 0 10px",
                      }}
                      className="py-3"
                      align="left">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Nama Klien
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: "10px",
                        background: theme.tertiary,
                      }}
                      className="py-3"
                      align="left">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Profesi
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: "10rem",
                        background: theme.tertiary,
                      }}
                      className="py-3"
                      align="left">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Career Goal
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        background: theme.tertiary,
                        borderRadius: "0 10px 10px 0",
                      }}
                      className="py-3"
                      align="center"
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.klien?.map((item, index) => {
                    return (
                      <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell className="py-1">
                          <div className="d-flex gap-3 align-center">
                            <Avatar src={item.foto} />
                            <span className="px12_txt">{item.nama}</span>
                          </div>
                        </TableCell>
                        <TableCell className="py-1 white-space-pre">{item.profesi}</TableCell>
                        <TableCell className="py-1">{item.career_goal}</TableCell>
                        <TableCell>
                          <span onClick={() => onClickCircle(item)} className="cursor_pointer">
                            <Circle fontSize="small" style={{ color: theme.act_blue }} className="mr-1" />
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div>
              {data.klien?.length > 0 ? (
                <div className="d-flex align-center justify-space-between" style={{ width: "100%" }}>
                  <p className="txt_table">
                    Menampilkan {data?.from} - {data?.to} dari {data?.total}{" "}
                  </p>
                  <div>
                    <Pagination sx={{ padding: "0" }} count={data?.last_page} page={data?.page} shape="rounded" onChange={handlePage} style={{ background: "#fff" }} color="primary" />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Card>
      </Grid>
      <Grid item xs={6}>
        {renderPortofolio()}
      </Grid>
    </Grid>
  );
};

export default PortofolioPage;

/* eslint-disable default-case */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Grid, Backdrop, CircularProgress, FormControlLabel, Checkbox } from "@mui/material";
import { postApi, getApi } from "../../../store/apis";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Close as CloseIcon, Person, Email, Key, LocationOn, PhoneIphone, ChevronLeft, ChevronRight, Check, AccountCircle, GridView, Business } from "@mui/icons-material";
import InputWithIcon from "../../../components/base/inputWithIcon";
import SelectWithIcon from "../../../components/base/selectWithIcon";
import AreaWithIcon from "../../../components/base/areaWithIcon";

const CompanyCreate = ({ d_create = false, close, refetch }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [listAcc, setListAcc] = useState([]);
  const [acc, setAcc] = useState([]);
  const [loadAcc, setLoadAcc] = useState(false);
  const listJenis = ["Profesional", "Pendidikan"];
  const [state, setState] = useState({
    loading: false,
    username: "",
    password: "",
    nama: "",
    jenis: "",
    email: "",
    alamat: "",
    no_kontak: "",
    nama_pic: "",
    email_pic: "",
    no_telp_pic: "",
    permission_name: [],
  });

  const handleChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value });
  };

  const handleCheck = (item) => (event) => {
    const { checked } = event.target;
    if (checked) {
      setAcc([...acc, item]);
    } else {
      setAcc(acc.filter((val) => val !== item));
    }
  };

  const switchStep = (state) => {
    if (state == "next") {
      postData(step, state);
    } else {
      postData(step - 1, state);
    }
  };

  const listStep = [1, 2, 3, 4];
  const [step, setStep] = useState(0);

  const fetchPermission = () => {
    setLoadAcc(true);
    let data = {
      path: `admin/permission/company`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setLoadAcc(false);
        setListAcc(data);
      })
      .catch((err) => {
        setLoadAcc(false);
        setListAcc([]);
      });
  };

  const postData = (page = 0, direction) => {
    setLoading(true);
    let body = {
      step: page,
    };

    switch (page) {
      case 1:
        body = {
          step: page,
          username: state.username,
          password: state.password,
        };
        break;
      case 2:
        body = {
          step: page,
          nama: state.nama,
          jenis: state.jenis,
          email: state.email,
          alamat: state.alamat,
          no_kontak: state.no_kontak,
        };
        break;
      case 3:
        body = {
          step: page,
          nama_klien: state.nama_pic,
          no_telp: state.no_telp_pic,
          email_klien: state.email_pic,
        };
        break;
      case 4:
        body = {
          step: page,
          permission: JSON.stringify(acc),
        };
        break;
    }
    let data = {
      path: "admin/company/create",
      body: body,
    };
    let variant = "";
    dispatch(postApi(data))
      .then((data) => {
        let res = data.data;
        if (res) {
          let step = parseInt(data.data.step);
          let tempForm = state;
          delete tempForm["step"];
          for (let key in res) {
            tempForm[key] = res[key];
          }
          if (direction === "next") {
            variant = "success";
            if (page == 4) {
              setState({
                loading: false,
                username: "",
                password: "",
                nama: "",
                jenis: "",
                email: "",
                alamat: "",
                no_kontak: "",
                nama_pic: "",
                email_pic: "",
                no_telp_pic: "",
                permission_name: [],
              });
              setStep(1);
              close();
              refetch();
              enqueueSnackbar(data.message ?? "Akun berhasil dibuat", {
                variant,
              });
            } else {
              setStep(step + 1);
            }
          } else {
            setStep(step);
          }
        } else {
          setStep(step + 1);
        }
        setLoading(false);
      })
      .catch((err) => {
        const variant = "error";
        if (err.status === "bad_request") {
          const error = err?.data?.errors ?? { validasi: ["data tidak valid"] };
          Object.keys(error).forEach((message) => {
            enqueueSnackbar(`${message} ${error[message]}`, { variant });
          });
        } else {
          enqueueSnackbar(err.message ?? "Akun gagal dibuat", { variant });
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    postData();
    fetchPermission();
  }, []);

  return (
    <Dialog
      open={d_create}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 10 },
      }}>
      <DialogTitle id="alert-dialog-title" className="py-3">
        <div className="d-flex justify-space-between">
          <h4>Tambah Company/Institution</h4>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="styled_scroll" style={{ overflowX: "hidden" }}>
        <div className="borbox" style={{ width: "450px", padding: "5px", minHeight: "400px" }}>
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="d-flex mb-3">
            {listStep.map((stp, i) => (
              <div
                key={`step-${i}`}
                style={{
                  border: `2px solid ${theme.lighten}`,
                  color: step === stp ? theme.primary : theme.lighten,
                  background: step === stp ? theme.lighten : step > stp ? theme.primary : "#fff",
                }}
                className="steper mr-2">
                {step > stp ? <Check fontSize="small" /> : stp}
              </div>
            ))}
          </div>
          {step == 1 ? (
            <div className="px-2">
              <p className="font-weight-bold mb-3" style={{ color: theme.primary }}>
                Buat Akun Konselor
              </p>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                    Username
                  </p>
                  <InputWithIcon value={state.username} icon={<AccountCircle color="primary" />} handleChange={handleChange} property="username" />
                </Grid>
                <Grid item xs={12}>
                  <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                    Password
                  </p>
                  <InputWithIcon value={state.password} icon={<Key color="primary" />} handleChange={handleChange} type="password" property="password" />
                  <small className="small_txt">Password harus memiliki setidaknya 6 karakter, satu digit, satu huruf kecil, satu huruf besar, dan satu simbol</small>
                </Grid>
              </Grid>
            </div>
          ) : step == 2 ? (
            <div className="px-2">
              <p className="font-weight-bold mb-3" style={{ color: theme.primary }}>
                Informasi Klien
              </p>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                    Nama Company/Institution
                  </p>
                  <InputWithIcon value={state.nama} icon={<Business color="primary" />} handleChange={handleChange} property="nama" />
                </Grid>
                <Grid item xs={12}>
                  <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                    Jenis Company/Institution
                  </p>
                  <SelectWithIcon value={state.jenis} handleChange={handleChange} list={listJenis} icon={<GridView color="primary" />} property="jenis" />
                </Grid>
                <Grid item xs={12}>
                  <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                    Email Aktif
                  </p>
                  <InputWithIcon value={state.email} icon={<Email color="primary" />} handleChange={handleChange} property="email" />
                </Grid>
                <Grid item xs={6}>
                  <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                    Nomor Telepon
                  </p>
                  <InputWithIcon value={state.no_kontak} icon={<PhoneIphone color="primary" />} handleChange={handleChange} type="number" property="no_kontak" />
                </Grid>
                <Grid item xs={12}>
                  <AreaWithIcon value={state.alamat} icon={<LocationOn color="primary" />} handleChange={handleChange} property="alamat" />
                </Grid>
              </Grid>
            </div>
          ) : step == 3 ? (
            <div className="px-2">
              <p className="font-weight-bold mb-3" style={{ color: theme.primary }}>
                Informasi Penanggung Jawab
              </p>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                    Nama Penanggung Jawab
                  </p>
                  <InputWithIcon value={state.nama_pic} icon={<Person color="primary" />} handleChange={handleChange} property="nama_pic" />
                </Grid>
                <Grid item xs={12}>
                  <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                    Email Aktif
                  </p>
                  <InputWithIcon value={state.email_pic} icon={<Email color="primary" />} handleChange={handleChange} property="email_pic" />
                </Grid>
                <Grid item xs={12}>
                  <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
                    Nomor Hp
                  </p>
                  <InputWithIcon value={state.no_telp_pic} icon={<PhoneIphone color="primary" />} handleChange={handleChange} type="number" property="no_telp_pic" />
                </Grid>
              </Grid>
            </div>
          ) : (
            <div>
              <p className="font-weight-bold" style={{ color: theme.primary }}>
                Access Permission
              </p>
              <p className="small_txt text-grey mb-3" style={{ color: theme.primary }}>
                Beri centang untuk fitur yang bisa diakses dan diubah konselor
              </p>
              <div className="mb-4">
                {loadAcc && listAcc.length ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <div>
                    {listAcc.map((item, i) => (
                      <FormControlLabel key={i} label={item.name} control={<Checkbox checked={acc.includes(item.name)} onChange={handleCheck(item.name)} />} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="d-flex justify-end px-5 pb-5">
          {step <= 1 ? (
            <></>
          ) : (
            <Button variant="contained" onClick={() => switchStep("prev")} className="capitalize rounded-lg mr-2 px-0" style={{ minWidth: "0" }} disableElevation>
              {" "}
              <ChevronLeft />{" "}
            </Button>
          )}
          {step < 4 ? (
            <Button onClick={() => switchStep("next")} variant="contained" className="capitalize rounded-lg" style={{ minWidth: "0" }} disableElevation>
              {" "}
              <ChevronRight />{" "}
            </Button>
          ) : (
            <Button onClick={() => switchStep("next")} variant="contained" className="capitalize rounded-lg" disableElevation>
              <p className="font-weight-bold">Buat Akun</p>
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyCreate;

import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, CircularProgress } from "@mui/material";
import { postApi } from "../../../store/apis";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Close as CloseIcon, Person, Email, Key, DateRange, PhoneIphone } from "@mui/icons-material";
import InputWithIcon from "../../../components/base/inputWithIcon";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const IndividualUserCreate = ({ d_indie = false, close, refetch, forCompany = false }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    nama: "",
    email: "",
    no_telp: "",
    tgl_lahir: "",
    jenkel: "",
    profesi: "",
    password: "",
  });

  const handleChange = (prop) => (event) => {
    setForm({ ...form, [prop]: event.target.value });
  };

  const postData = () => {
    setLoading(true);
    let data = {
      path: "admin/individual-user/store",
      body: { ...form },
    };

    if (forCompany) {
      data = {
        path: forCompany.url,
        body: { ...form, id: forCompany.id },
      };
    }

    dispatch(postApi(data))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "Akun berhasil dibuat", { variant });
        close();
        refetch();
        setForm({
          nama: "",
          email: "",
          no_telp: "",
          tgl_lahir: "",
          jenkel: "",
          profesi: "",
          password: "",
        });
        setLoading(false);
        if (forCompany) {
          forCompany.onCloseDialogAdd();
        }
      })
      .catch((err) => {
        const variant = "error";
        if (err.data.errors) {
          const error = err.data.errors;
          Object.keys(error).forEach((message) => {
            enqueueSnackbar(error[message], { variant });
          });
        } else {
          enqueueSnackbar(err.message ?? "Akun gagal dibuat", { variant });
        }
        setLoading(false);
      });
  };
  return (
    <Dialog
      open={d_indie}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 10 },
      }}>
      <DialogTitle id="alert-dialog-title" className="py-3">
        <div className="d-flex justify-space-between">
          <h4>Tambah Akun User</h4>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="borbox" style={{ width: "400px", padding: "5px" }}>
          <div className="mb-4">
            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
              Nama Lengkap
            </p>
            <InputWithIcon value={form.nama} icon={<Person color="primary" />} handleChange={handleChange} property="nama" />
          </div>
          <div className="mb-4">
            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
              Email Aktif
            </p>
            <InputWithIcon value={form.email} icon={<Email color="primary" />} handleChange={handleChange} property="email" />
          </div>
          <div className="mb-4">
            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
              Nomor Hp
            </p>
            <InputWithIcon value={form.no_telp} icon={<PhoneIphone color="primary" />} handleChange={handleChange} type="number" property="no_telp" />
          </div>
          <div className="mb-4">
            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
              Tanggal Lahir
            </p>
            <InputWithIcon value={form.tgl_lahir} icon={<DateRange color="primary" />} handleChange={handleChange} type="date" property="tgl_lahir" />
          </div>
          <div className="mb-4">
            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
              Jenis Kelamin
            </p>
            <RadioGroup name="rb-jenkel" row value={form.jenkel} onChange={handleChange("jenkel")}>
              <FormControlLabel value="P" control={<Radio />} label="Perempuan" />
              <FormControlLabel value="L" control={<Radio />} label="Laki-laki" />
            </RadioGroup>
          </div>
          <div className="mb-4">
            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
              Status Pekerjaan
            </p>
            <RadioGroup name="rb-statuspekerjaan" row value={form.profesi} onChange={handleChange("profesi")}>
              <FormControlLabel value="siswa/mahasiswa" control={<Radio />} label="Siswa/Mahasiswa" />
              <FormControlLabel value="pekerja" control={<Radio />} label="Pekerja" />
            </RadioGroup>
          </div>
          <div className="mb-4">
            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
              Password
            </p>
            <InputWithIcon value={form.password} icon={<Key color="primary" />} handleChange={handleChange} type="password" property="password" />
          </div>
          <div className="d-flex justify-end">
            <Button disabled={loading} onClick={postData} variant="contained" className="capitalize rounded-lg" disableElevation>
              <p className="font-weight-bold">Buat Akun</p>
              {loading && <CircularProgress color="primary" size={20} style={{ marginLeft: 5 }}></CircularProgress>}
            </Button>
          </div>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default IndividualUserCreate;

import { Avatar } from "@mui/material";

const AvatarProgressItem = ({ src, title = "", border = 1, colorBorder = "", colorBar = "", value = 0, total = 1 }) => {
  const getPercent = () => {
    let sub = value;
    let totalData = total === 0 || total === undefined || total === null ? 1 : total;

    let percent = parseFloat((sub / totalData) * 100);
    return percent;
  };

  return (
    <div className="rounded-lg pa-2 my-1" style={{ border: `${border}px solid ${colorBorder}` }}>
      <div className="d-flex align-center gap-3">
        <Avatar src={src} />
        <div className="grow">
          <h5>{title}</h5>
          <div className="d-flex gap-2 align-center justify-space-between">
            <div style={{ background: colorBar, width: `${getPercent()}%` }} className="py-1 rounded-xl" />
            <small>{value}</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarProgressItem;

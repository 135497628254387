/* eslint-disable eqeqeq */
import { AddCircle } from '@mui/icons-material'
import { Button, Grid, Paper } from '@mui/material'
import React from 'react'
import FlatArea from '../../../../components/base/flatArea'
import FlatInput from '../../../../components/base/flatInput'

const DetailTraining = ({ theme, infoDetail, setInfoDetail, fasilitas, setFasilitas, bonus, setBonus, materi, setMateri }) => {

    const handleInput = (prop) => (event) => {
        setInfoDetail({ ...infoDetail, [prop]: event.target.value });
    }

    const onChangeArr = (prop) => (event) => {
        if (prop.type == 'fasilitas') {
            let rawFasil = [...fasilitas]
            rawFasil[prop.idx] = event.target.value
            setFasilitas(rawFasil)
        } else if (prop.type == 'bonus') {
            let rawBonus = [...bonus]
            rawBonus[prop.idx] = event.target.value
            setBonus(rawBonus)
        } else {
            let rawReq = [...materi]
            rawReq[prop.idx] = event.target.value
            setMateri(rawReq)
        }
    }


    return (
        <Paper className='pa-5 rounded-lg mb-3'>
            <p className='mb-3' style={{ color: theme.primary, fontWeight: '500' }}>Detail Training</p>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Materi Training</p>
                </Grid>
                <Grid item xs={9}>
                    {materi.map((el, i) => <div key={`materi-${i}`} className='mb-2'><FlatInput
                        value={el}
                        handleChange={onChangeArr}
                        property={{ type: "materi", idx: i }}
                        placeholder="Tuliskan poin-poin materi"
                    /></div>)}
                    <Button
                        style={{ minWidth: "0" }}
                        className="capitalize rounded-lg  mb-3"
                        onClick={() => setMateri([...materi, ''])}
                    >
                        <AddCircle className='mr-2' /> Tambah Materi
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Fasilitas Training</p>
                </Grid>
                <Grid item xs={9}>
                    {fasilitas.map((el, i) => <div key={`fasil-${i}`} className='mb-2'><FlatInput
                        value={el}
                        handleChange={onChangeArr}
                        property={{ type: "fasilitas", idx: i }}
                        placeholder="Tuliskan apa saja fasilitas yang bisa didapatkan peserta"
                    /></div>)}

                    <Button
                        style={{ minWidth: "0" }}
                        className="capitalize rounded-lg  mb-3"
                        onClick={() => setFasilitas([...fasilitas, ''])}
                    >
                        <AddCircle className='mr-2' /> Tambah Fasilitas
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Bonus Training</p>
                    <p style={{ color: theme.primary, fontSize: '12px' }}>Kosongkan bila tidak ada</p>
                </Grid>
                <Grid item xs={9}>
                    {bonus.map((el, i) => <div key={`bonus-${i}`} className='mb-2'><FlatInput
                        value={el}
                        handleChange={onChangeArr}
                        property={{ type: "bonus", idx: i }}
                        placeholder="Tuliskan bonus yang bisa didapatkan peserta"
                    /></div>)}

                    <Button
                        style={{ minWidth: "0" }}
                        className="capitalize rounded-lg  mb-3"
                        onClick={() => setBonus([...bonus, ''])}
                    >
                        <AddCircle className='mr-2' /> Tambah Bonus
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Informasi Tambahan</p>
                    <p style={{ color: theme.primary, fontSize: '12px' }}>Kosongkan bila tidak ada</p>
                </Grid>
                <Grid item xs={9}>
                    <FlatArea
                        value={infoDetail.informasi}
                        handleChange={handleInput}
                        property='informasi'
                        placeholder='Bonus Training'
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default React.memo(DetailTraining)

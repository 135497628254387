import { Card } from "@mui/material";
import TitleWithDate from "../partial/TitleWithDate";
import CardCountWithTitle from "../partial/CardCountWithTitle";
import { useDispatch, useSelector } from "react-redux";
import AvatarProgressItem from "../partial/AvatarProgressItem";
import { useEffect, useState } from "react";
import { dateFormatter } from "../../../utils/formatter";
import { getApi } from "../../../store/apis";

const JourneyProgressList = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const [journey, setJourney] = useState({});

  useEffect(() => {
    getJourney();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getJourney = (date = dateFormatter(new Date())) => {
    let config = {
      path: `admin/board-result/journey?date=${date}`,
    };
    dispatch(getApi(config))
      .then((res) => {
        (res === null || res === undefined) && (res = {});
        setJourney(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card className="pa-3 rounded-lg">
      <TitleWithDate title="The Journey" subTitle="Jumlah pengguna yang memainkan The Journey" onDone={(value) => getJourney(dateFormatter(value.value))} />
      <div className="my-3 d-flex justify-center align-center gap-2">
        <CardCountWithTitle
          src="https://img.freepik.com/free-vector/hand-drawn-nft-style-ape-illustration_23-2149622040.jpg"
          sourceReverse={true}
          title="Top Journey"
          subTitle="Karakter"
          colorTheme={theme.act_lbl_purple}
        />
      </div>
      <div className="mt-4" style={{ minHeight: "10rem" }}>
        {journey?.collection?.map((item, index) => {
          return <AvatarProgressItem key={index} title={item.judul} colorBorder={theme.act_purple} colorBar={theme.act_lbl_purple} value={item.jumlah} total={journey.total} />;
        })}
      </div>
    </Card>
  );
};

export default JourneyProgressList;

import { LiveHelp } from "@mui/icons-material";
import { Button, Dialog, DialogContent } from "@mui/material";
import { useSelector } from "react-redux";

const ModalRequestCoach = ({ onOk, closeModal }) => {
  const theme = useSelector((state) => state.theme.myTheme);

  return (
    <Dialog
      open={true}
      onClose={() => {
        closeModal && closeModal();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 10, minWidth: "350px", maxWidth: "470px" },
      }}>
      <DialogContent>
        <div className="text-center">
          <LiveHelp style={{ color: theme.act_lbl_purple, width: "6rem", height: "6rem" }} />
          <h4 className="mt-3">Permohonan Terkirim</h4>
          <p className="small_txt">Permohonan Anda untuk menjadi Coach Teman Bakat sudah terkirim dan akan segera direspon oleh admin.</p>
        </div>
        <div className="mt-5 text-center">
          <Button variant="contained" className="rounded-lg capitalize px-3" onClick={() => onOk && onOk()}>
            Oke
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalRequestCoach;

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import locale from "dayjs/locale/id";
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";
import css from "../../../assets/css/miniCalendar.module.css";
import { Button } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useSelector } from "react-redux";

const DatePickerSchedule = ({ picked = [], setPicked, selectedDate, selected = "single", onPrev, onNext }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const now = dayjs().locale({
    ...locale,
  });

  dayjs.extend(weekdayPlugin);
  dayjs.extend(objectPlugin);
  dayjs.extend(isTodayPlugin);

  const [currentMonth, setCurrentMonth] = useState(now);
  const [arrayOfDays, setArrayOfDays] = useState([]);

  const nextMonth = () => {
    const plus = currentMonth.add(1, "month");
    setCurrentMonth(plus);
    onNext && onNext(plus);
  };

  const prevMonth = () => {
    const minus = currentMonth.subtract(1, "month");
    setCurrentMonth(minus);
    onPrev && onPrev(minus);
  };

  const renderHeader = () => {
    const dateFormat = "MMMM YYYY";

    return (
      <div className="d-flex align-center justify-space-between mb-3">
        <p className="small_txt font-weight-bold mx-2">{currentMonth.format(dateFormat)}</p>
        <div className="d-flex align-center">
          <Button className="capitalize pa-1 mr-2" variant="outlined" style={{ minWidth: "5px" }} onClick={() => prevMonth()}>
            <ChevronLeft fontSize="small" color="primary" />
          </Button>
          <Button className="capitalize pa-1" variant="outlined" style={{ minWidth: "5px" }} onClick={() => nextMonth()}>
            <ChevronRight fontSize="small" color="primary" />
          </Button>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "ddd";
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className={`${css.col} ${css["col-center"]}`} key={i}>
          {now.weekday(i).format(dateFormat)}
        </div>
      );
    }
    return <div className={`${css.days} ${css.row} small_txt font-weight-bold`}>{days}</div>;
  };

  const getAllDays = () => {
    let currentDate = currentMonth.startOf("month").weekday(0);
    const nextMonth = currentMonth.add(1, "month").month();

    let allDates = [];
    let weekDates = [];

    let weekCounter = 1;

    while (currentDate.weekday(0).toObject().months !== nextMonth) {
      const formated = formateDateObject(currentDate);

      weekDates.push(formated);
      if (weekCounter === 7) {
        allDates.push({ dates: weekDates });
        weekDates = [];
        weekCounter = 0;
      }

      weekCounter++;
      currentDate = currentDate.add(1, "day");
    }
    setArrayOfDays(allDates);
  };

  useEffect(() => {
    getAllDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth]);

  const renderCells = () => {
    const rows = [];
    let days = [];

    arrayOfDays.forEach((week, index) => {
      week.dates.forEach((d, i) => {
        let style = stylingDateBox(d);
        days.push(
          <div className={`${css.col} ${css.cell} ${!d.isCurrentMonth ? css.disabled : ""}`} style={style.parent} key={i} onClick={() => selectDate(d)}>
            <div style={{ ...style.child, position: "absolute", top: "0.4rem", right: "0.8rem", left: "0.8rem", bottom: "0.4rem" }} />
            <div className={css.number}>{d.day}</div>
          </div>
        );
      });
      rows.push(
        <div className={css.row} key={index}>
          {days}
        </div>
      );
      days = [];
    });

    return <div className={css.body}>{rows}</div>;
  };

  const stylingDateBox = (props) => {
    const date = props.date;
    const isCurrentDay = props.isCurrentDay;
    const indexDate = picked.findIndex((x) => x.date === date);
    const dateItem = picked[indexDate];

    // For default parent
    let style = {
      background: "#fff",
      color: theme.primary,
    };
    // For default child
    let styleChild = { borderBottom: isCurrentDay ? `3px solid ${theme.lighten}` : "none" };

    // Styling from date object
    let labelColor = theme.primary;
    dateItem?.active && (labelColor = theme.white);
    !dateItem?.active && (labelColor = theme.act_lbl_blue);

    let backgroundColor = theme.white;
    dateItem?.active && (backgroundColor = theme.primary);
    !dateItem?.active && (backgroundColor = theme.secondary);

    let borderTop = "none";
    dateItem?.active && dateItem?.topLabel && (borderTop = `3px solid ${theme.white}`);
    !dateItem?.active && dateItem?.topLabel && (borderTop = `3px solid ${theme.act_lbl_blue}`);

    indexDate >= 0 &&
      (style = {
        background: backgroundColor,
        color: labelColor,
      });

    indexDate >= 0 &&
      (styleChild = {
        borderTop: borderTop,
      });

    return {
      parent: style,
      child: styleChild,
    };
  };

  const selectDate = (day) => {
    let date = day.date;
    selectedDate && selectedDate(date);
    selected === "single" && selectedSiggle(date);
  };

  const selectedSiggle = (date) => {
    picked.forEach((item, index) => {
      picked[index] = {
        ...picked[index],
        active: false,
      };
      item.pic && picked.splice(index, 1);
    });

    let datePic = {
      date: date,
      active: true,
      topLabel: false,
      pic: true,
    };

    let findIndex = picked.findIndex((x) => x.date === date);
    if (findIndex >= 0) {
      picked[findIndex] = {
        ...picked[findIndex],
        active: true,
      };
      setPicked && setPicked([...picked]);
    } else {
      setPicked && setPicked([...picked, datePic]);
    }
  };

  const formateDateObject = (date) => {
    const temp = { ...date.toObject() };
    const formatedObject = {
      day: temp.date,
      month: temp.months,
      year: temp.years,
      isCurrentMonth: temp.months === currentMonth.month(),
      isCurrentDay: date.isToday(),
      date: `${temp.years}-${temp.months < 9 ? `0${temp.months + 1}` : temp.months + 1}-${temp.date < 10 ? `0${temp.date}` : temp.date}`,
    };

    return formatedObject;
  };

  return (
    <div>
      {renderHeader()}
      {renderDays()}
      <div className={css.calendar}>{renderCells()}</div>
    </div>
  );
};

export default DatePickerSchedule;

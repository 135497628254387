import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChatRounded, CloseRounded, EventNoteRounded } from "@mui/icons-material";

const KonselingActivityList = ({ theme, dataSet, ava, page, limit }) => {
  const navigate = useNavigate();

  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell
                style={{
                  minWidth: "50px",
                  background: theme.tertiary,
                  borderRadius: "10px 0 0 10px",
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  No.
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "200px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Nama User
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "150px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Kategori
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "150px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Nama Layanan
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "150px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Nama Konselor
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "130px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Waktu Pembayaran
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "130px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Waktu Konsultasi
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Status
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Waktu Reschedule
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "100px",
                  background: theme.tertiary,
                  borderRadius: "0 10px 10px 0",
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tindakan
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSet.map((item, i) => {
              console.log(item);
              return (
                <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell className="py-2">
                    <p className="px11_txt">{i + 1 + (page - 1) * limit}</p>
                  </TableCell>
                  <TableCell className="py-2">
                    <p className="px11_txt">{item.nama_pelanggan}</p>
                  </TableCell>
                  <TableCell className="py-2">
                    <p className="px11_txt">{item.konsultasi_kategori}</p>
                  </TableCell>
                  <TableCell className="py-2">
                    <p className="px11_txt">{item.konsultasi_layanan}</p>
                  </TableCell>
                  <TableCell className="py-2" align="center">
                    <p className="px11_txt">{item.nama_konselor}</p>
                  </TableCell>
                  <TableCell className="py-2">
                    <p className="px11_txt">{item.tgl_bayar}</p>
                  </TableCell>
                  <TableCell className="py-2" align="center">
                    <p className="px11_txt">{item.tgl_konsultasi}</p>
                  </TableCell>
                  <TableCell className="py-2" align="center">
                    <p className="px11_txt">{item.status}</p>
                  </TableCell>
                  <TableCell className="py-2" align="center">
                    <p className="px11_txt">{item.reschedule}</p>
                  </TableCell>
                  <TableCell className="py-2">
                    <div className="d-flex">
                      <div
                        className="cursor_pointer rounded-lg d-flex align-center justify-center pa-1_5 mr-3"
                        onClick={() => navigate(`/konseling-activity/detail/${item.konsultasi_thread_id}`)}
                        style={{ background: theme.act_blue }}>
                        <ChatRounded style={{ color: theme.act_lbl_blue }} fontSize="20px" />
                      </div>
                      <div
                        className="cursor_pointer rounded-lg d-flex align-center justify-center pa-1_5 mr-3"
                        onClick={() => navigate(`/konseling-activity/detail/${item.konsultasi_thread_id}`)}
                        style={{ background: theme.act_yellow }}>
                        <EventNoteRounded style={{ color: theme.act_lbl_yellow }} fontSize="20px" />
                      </div>
                      <div
                        className="cursor_pointer rounded-lg d-flex align-center justify-center pa-1_5 mr-3"
                        onClick={() => navigate(`/konseling-activity/detail/${item.konsultasi_thread_id}`)}
                        style={{ background: theme.act_red }}>
                        <CloseRounded style={{ color: theme.act_lbl_red }} fontSize="20px" />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pa-2"></div>
    </div>
  );
};

export default KonselingActivityList;

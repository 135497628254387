import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getYearCollection, monthFormat, firstZero } from "../../utils/formatter";

const ModalSelectDateMonthYear = ({ onDone, onClose }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const ref = useRef(null);
  const [selected, setSelected] = useState({ month: monthFormat[new Date().getMonth()], year: new Date().getFullYear() });
  const format = {
    toYear: new Date().getFullYear(),
    fromYear: 2000,
  };

  const [content, setContent] = useState({
    type: "month",
    content: [],
    typeContent: {
      month: monthFormat,
      year: getYearCollection(format.fromYear, format.toYear),
    },
  });

  useEffect(() => {
    renderContent(Object.keys(content.typeContent)[0]);
    setTimeout(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          onDoned(setFormatCallback(selected));
          onClosed();
        }
      };
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClosed = () => {
    onClose && onClose();
  };

  const onDoned = (value) => {
    onDone && onDone(value);
  };

  const renderContent = (type) => {
    let typeContent = content.typeContent[type];
    setContent({ ...content, type: type, content: typeContent });
  };

  const onClickItem = (item) => {
    onSetSelected(item);

    let typeContent = Object.keys(content.typeContent);
    let index = typeContent.findIndex((x) => x === content.type);
    let nextType = typeContent.length - 1 === index ? typeContent[0] : typeContent[index + 1];
    renderContent(nextType);
  };

  const onSetSelected = (item) => {
    let typeContent = Object.keys(content.typeContent);
    let dataArray = {};
    typeContent.forEach((itemData, index) => {
      itemData === content.type && (dataArray[itemData] = item);
    });

    setSelected({ ...selected, ...dataArray });
    closeWhenDone({ ...selected, ...dataArray });
  };

  const closeWhenDone = (value) => {
    let typeContent = Object.keys(content.typeContent);
    let lastItem = typeContent[typeContent.length - 1];

    let formatCallback = setFormatCallback(value);

    content.type === lastItem && onDoned(formatCallback);
    content.type === lastItem && onClosed();
  };

  const setFormatCallback = (value) => {
    let format = {
      display: getValue(value),
      value: `${value.year}-${firstZero(monthFormat.findIndex((x) => x === value.month) + 1)}`,
    };

    return format;
  };

  const getValue = (valueSelected) => {
    let value = "";
    Object.keys(valueSelected).forEach((item, index) => {
      value += `${valueSelected[item]} `;
    });
    const final = value.trim();
    return final;
  };

  return (
    <div className="absolute pa-2 rounded-lg shadow-lg" style={{ background: theme.white, right: 0, top: "2.5rem", border: `1.5px solid ${theme.lighten}`, width: "17rem", zIndex: 1 }} ref={ref}>
      <small className="font-weight-700 px12_txt" style={{ color: theme.primary }}>
        {getValue(selected)}
      </small>
      <div className="d-flex flex-wrap hide-scrollable gap-1 mt-2" style={{ maxHeight: "15rem", overflowY: "auto" }}>
        {content?.content?.map((item, index) => {
          return (
            <div
              key={index}
              className="capitalize d-flex align-center justify-center px12_txt py-1 font-weight-500 rounded-lg cursor_pointer"
              style={{ border: `1.5px solid ${theme.lighten}`, width: "60px" }}
              onClick={() => onClickItem(item)}>
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ModalSelectDateMonthYear;

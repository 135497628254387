/* eslint-disable eqeqeq */
import { Delete as DeleteIcon, Add as AddIcon, } from '@mui/icons-material'
import { Button, IconButton, Paper } from '@mui/material'
import React from 'react'
import FlatInput from '../../../../components/base/flatInput'
import DraggableQuestion from './draggableQuestion';

const SetDynamicalForm = ({ theme, rawForm, setRawForm }) => {
    /**
 * 
 * @param i = index section
 * @param j = index item (question)
 */

    const addOption = (i, j) => {
        let rawData = [...rawForm]
        let item = rawData[i].items[j]
        item.opsi.push('New Option')
        setRawForm(rawData)
    }

    const removeQuestion = (i, j) => {
        let rawData = [...rawForm]
        if (j > -1) {
            rawData[i].items.splice(j, 1);
            setRawForm(rawData)
        }
    }

    const addQuestion = i => {
        let rawData = [...rawForm]
        rawData[i].items.push({
            id: (Math.random() + 1).toString(36).substring(7),
            type: { label: 'Text', value: 'text' },
            pertanyaan: '',
            opsi: [],
        })
        setRawForm(rawData)
    }
    const handleInput = (prop) => (event) => {
        let rawData = [...rawForm]
        if (prop.level == 'section') {
            if (prop.type) {
                rawData[prop.idx][prop.label] = event
            } else {
                rawData[prop.idx][prop.label] = event.target.value
            }
        } else if (prop.level == 'opsi') {
            rawData[prop.idx]['items'][prop.idx_item]['opsi'][prop.idx_opt] = event.target.value
        } else {
            if (prop.type) {
                let hasOpt = ['checkbox', 'radio']
                let type = rawData[prop.idx]['items'][prop.idx_item].type.value
                if (!hasOpt.includes(type)) {
                    rawData[prop.idx]['items'][prop.idx_item]['opsi'] = []
                }
                rawData[prop.idx]['items'][prop.idx_item][prop.label] = event
            } else {
                rawData[prop.idx]['items'][prop.idx_item][prop.label] = event.target.value
            }
        }
        setRawForm(rawData)
    }

    const delSection = index => {
        setRawForm(rawForm.filter((el, i) => i != index))
    }

    const typeForm = [
        { label: 'Text', value: 'text' },
        { label: 'Number', value: 'Number' },
        { label: 'Single Option', value: 'radio' },
        { label: 'Multiple Option', value: 'checkbox' },
        { label: 'Date', value: 'date' },
        { label: 'Upload File', value: 'file' },
    ]

    const _onListChange = (newList, i) => {
        let rawData = [...rawForm]
        rawData[i].items = newList
        setRawForm(rawData)
    };


    return (
        rawForm.map((section, i) =>
            <Paper className='pa-4 mb-5 rounded-lg' key={`section-${i}`} elevation={0} style={{ background: theme.lighten }}>
                <Paper className='pa-4 rounded-lg mb-4'>
                    <div className="d-flex justify-space-between align-center">
                        <p className="font-weight-bold mb-2 small_txt" style={{ color: theme.primary }}>Bagian {i + 1}</p>
                        <IconButton color='primary' onClick={() => delSection(i)}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                    <FlatInput value={section.judul}
                        handleChange={handleInput}
                        property={{ idx: i, level: 'section', label: 'judul' }}
                        placeholder='Judul Bagian' />
                </Paper>
                <DraggableQuestion
                    section={section.items}
                    i={i}
                    handleInput={handleInput}
                    theme={theme}
                    removeQuestion={removeQuestion}
                    typeForm={typeForm}
                    addOption={addOption}
                    _onListChange={_onListChange}
                />
                <div className="d-flex justify-end"  >
                    <Button className='capitalize' onClick={() => addQuestion(i)}><AddIcon fontSize='small' />
                        <p className="font-weight-bold">Tambah Pertanyaan</p>
                    </Button>
                </div>
            </Paper>
        )
    )
}

export default React.memo(SetDynamicalForm) 

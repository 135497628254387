import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "../../assets/css/sidebar.css";
import { SidebarAdmin } from "./SidebarAdmin";
import { SidebarPsy } from "./SidebarPsy";

const SidebarMain = () => {
  let role = localStorage.getItem("role");
  // const [role, setRole] =
  const [txtRole, setTxtRole] = useState("");
  useEffect(() => {
    switch (role) {
      case "SUPERADMIN":
        setTxtRole("SUPERADMIN");
        break;
      case "ADMIN":
        setTxtRole("Admin");
        break;
      case "KONSELOR":
        setTxtRole("Psikolog & Coach");
        break;
      case 4:
        setTxtRole("Company/Institution");
        break;
      default:
        break;
    }
  });
  const { pathname } = useLocation();
  const theme = useSelector((state) => state.theme.myTheme);
  return (
    <div className="bg_side" style={{ background: theme.primary }}>
      <div className="card_company mb-3" style={{ background: theme.darken }}>
        <img src={require("../../assets/img/logo_tb.png")} className="logo_tb mr-2" alt="" />
        <div>
          <p className="white-text small_txt">Dashboard</p>
          <p className="white-text font-weight-bold small_txt">{txtRole}</p>
        </div>
      </div>
      {role === "ADMIN" ? <SidebarAdmin path={pathname} theme={theme} /> : <SidebarPsy path={pathname} theme={theme} />}
    </div>
  );
};

export default SidebarMain;

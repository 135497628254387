import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import {
  collection,
  query,
  doc,
  where,
  getDoc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../config/firebase";
import dayjs from "dayjs";

const axios = require("axios");
const initialState = {
  twillio: null,
  thread: [],
  msg: [],
};

const chat = createSlice({
  name: "chat",
  initialState,
  reducers: {
    GET_MSG: (state, action) => {
      state.msg = action.payload;
    },
    GET_THREAD: (state, action) => {
      state.thread = action.payload;
    },
    GET_DATA: (state, action) => {
      state.twillio = action.payload;
    },
  },
});

export const getTwillio = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    const env = process.env.REACT_APP_ENV;
    let config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    };
    axios
      .get(`${env}/twillio/token`, config)
      .then((res) => {
        const data = res.data.data;
        resolve(res.data.data);
        dispatch(GET_DATA(data ? data.ice_servers : []));
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getThread = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let host = "https://nos.wjv-1.neo.id/temanbakat/pelanggan/";
    let data = [];
    const q = query(
      collection(db, "thread"),
      orderBy("createdAt", "asc"),
      where("konselor_id", "==", parseInt(id))
    );

    onSnapshot(q, async (qs) => {
      for (const thread of qs.docs) {
        let item = thread.data();
        item["id"] = thread.id;
        const refs = doc(db, "users", item.pelanggan_id.toString());
        let docSnap = await getDoc(refs);
        let user = docSnap.data();
        if (user) {
          let img = `${host}${item.pelanggan_id}.${
            user.email ? user.email : "_"
          }.jpg`;
          let online = user.online ? user.online : 0;
          item["avatar"] = img;
          // item["avatar"] = await imageExists(img);
          item["createdAt"] = dayjs(item.createdAt.toDate()).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          item["online"] = online;
          data.push(item);
        }
      }
      dispatch(GET_THREAD(data.reverse()));
      resolve(data);
    });
  });
};


const imageExists = async (image_url) => {
  let pp = require("../assets/img/pp_dumy_sm.png");
  return fetch(image_url, { method: "HEAD" })
    .then((res) => {
      if (res.ok) {
        return image_url;
      } else {
        return pp;
      }
    })
    .catch((err) => {
      return false;
    });
};

export const getMsg = (thread) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(db, "messages", thread.id, thread.code),
      orderBy("createdAt", "asc")
    );
    onSnapshot(q, (qs) => {
      let data = [];
      for (const chat of qs.docs) {
        let item = chat.data();
        item["_id"] = chat.id;
        item["createdAt"] = dayjs(item.createdAt.toDate()).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        data.push(item);
      }
      // console.log(data);
      dispatch(GET_MSG(data));
      resolve(data);
    });
  });
};

export const { GET_THREAD, GET_DATA, GET_MSG } = chat.actions;
export default chat.reducer;

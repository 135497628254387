/* eslint-disable eqeqeq */
import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import { DialogDelete } from "../../../components/base/dialogDelete";
import { useState } from "react";
import { ControlledMenu, FocusableItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { postApi } from "../../../store/apis";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Done } from "@mui/icons-material";

const VcrActivityList = ({ theme, dataSet, setData, page, limit, refetch }) => {
  const navigate = useNavigate();
  const refs = useRef([]);
  const [isOpen, setOpen] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [pocket, setPocket] = useState(null);
  const deleteData = (item) => {
    setPocket({
      path: "admin/voucher/delete",
      body: {
        id: item.id,
      },
    });
    setDialog(true);
  };
  const [content, setContent] = useState("");
  const onClickCode = (item) => {
    setContent(item.kode);
    setOpen(item.id);
  };
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const changeCode = (item) => {
    let data = {
      path: "admin/voucher/update-kode",
      body: {
        id: item.id,
        kode: content,
      },
    };
    let variant = "";
    dispatch(postApi(data))
      .then((data) => {
        variant = "success";
        enqueueSnackbar(data.message, { variant });
        setOpen(null);
        refetch();
      })
      .catch((err) => {
        variant = "error";
        if (err.data.errors) {
          let msg = Object.values(err.data.errors).map((el) => el[0]);
          msg.forEach((msg) => {
            enqueueSnackbar(msg, { variant });
          });
        } else {
          enqueueSnackbar(err.message, { variant });
        }
      });
  };

  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell
                style={{
                  minWidth: "20px",
                  background: theme.tertiary,
                  borderRadius: "10px 0 0 10px",
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  No
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "150px",
                  background: theme.tertiary,
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Kode Voucher
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "200px",
                  background: theme.tertiary,
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Nama Voucher
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Peruntukkan
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Jenis
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Mode
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "150px",
                  background: theme.tertiary,
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Kedaluwarsa
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "50px",
                  background: theme.tertiary,
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Kuota
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "50px",
                  background: theme.tertiary,
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Claimed
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "50px",
                  background: theme.tertiary,
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Redeem
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "50px",
                  background: theme.tertiary,
                }}
                align="center"
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Status
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "150px",
                  background: theme.tertiary,
                  borderRadius: "0 10px 10px 0",
                }}
                align="center"
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tindakan
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSet.map((item, i) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{i + 1 + (page - 1) * limit}</p>
                </TableCell>
                <TableCell className="py-2">
                  <div onClick={() => onClickCode(item)}>
                    <p ref={refs.current[i]} className="font-weight-bold cursor_pointer small_txt" style={{ color: theme.darken }}>
                      {item.kode}
                    </p>
                  </div>
                  <ControlledMenu
                    arrow
                    arrowClassName="arrow_Class"
                    state={isOpen == item.id ? "open" : "closed"}
                    onClose={() => setOpen(null)}
                    anchorRef={refs.current[i]}
                    transition
                    style={{
                      borderRadius: "2px",
                      padding: "1px",
                    }}>
                    <FocusableItem style={{ padding: "10px" }}>
                      {({ ref }) => (
                        <div className="d-flex">
                          <input
                            ref={ref}
                            type="text"
                            className="input_vcr"
                            onChange={(e) => setContent(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.key === "Enter") changeCode(item);
                            }}
                            value={content}
                          />
                          <Button variant="contained" className="px-2" disableElevation onClick={() => changeCode(item)} style={{ minWidth: "10px" }}>
                            <Done />
                          </Button>
                        </div>
                      )}
                    </FocusableItem>
                  </ControlledMenu>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.nama}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.peruntukan ?? ""}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.type ?? ""}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">api belum</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.expire_date ?? ""}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.kuota ?? ""}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.data_voucher_pelanggan_count ?? ""}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.jumlah_reedem ?? ""}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.status}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <div className="d-flex">
                    <Button
                      variant="contained"
                      className="rounded-xl capitalize mr-3"
                      disableElevation
                      onClick={() => navigate(`/voucher-detail/${item.id}`)}
                      size="small"
                      style={{ background: theme.act_blue }}>
                      <p className="font-weight-bold px11_txt" style={{ color: theme.act_lbl_blue }}>
                        Detail
                      </p>
                    </Button>
                    <Button variant="contained" className="rounded-xl capitalize" disableElevation size="small" onClick={() => deleteData(item)} style={{ background: theme.act_red }}>
                      <p className="font-weight-bold px11_txt" style={{ color: theme.act_lbl_red }}>
                        Delete
                      </p>
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pa-2">
        <DialogDelete d_del={dialog} close={() => setDialog(false)} pocket={pocket} refetch={refetch} />
      </div>
    </div>
  );
};

export default VcrActivityList;

import InputSelectDateMonthYear from "./InputSelectDateMonthYear";

const TitleWithDate = ({ title = "", subTitle = "", onDone }) => {
  return (
    <div className="d-flex justify-space-between">
      <div className="leading-5">
        <h4>{title}</h4>
        <small>{subTitle}</small>
      </div>
      <div>
        <InputSelectDateMonthYear onDone={(value) => onDone && onDone(value)} />
      </div>
    </div>
  );
};

export default TitleWithDate;

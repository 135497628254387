/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button, Grid } from "@mui/material";
import { getApi, postApi } from "../../../store/apis";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/reactCalendar.css";
import "../../../assets/css/reschedule.css";
import DatePickerSchedule from "./datePickerSchedule";
export const Reschedule = ({ d_rscdl = false, close, refetch, pocket }) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useSelector((state) => state.theme.myTheme);
  const dispatch = useDispatch();
  const [datePicker, setDatePicker] = useState([]);
  const [state, setState] = useState({
    loading: false,
    judul: "",
    deskripsi: "",
  });
  const [picked, setPicked] = useState();
  const [jam, setJam] = useState([]);

  useEffect(() => {
    getSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSchedule = () => {
    let data = {
      path: `konselor/schedule/available-all`,
    };
    dispatch(getApi(data))
      .then((data) => {
        formattingDatePicker(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formattingDatePicker = (data) => {
    let format = [];
    data.forEach((item, index) => {
      format.push({
        date: item.date,
        topLabel: item.pembelian,
      });
    });

    setDatePicker(format);
  };

  const postData = () => {
    setState({ ...state, loading: true });

    let path = "konselor/reschedule-konsultasi";
    pocket.feature === "coaching" && (path = "konselor/coach/reschedule");
    let body = {
      id_thread: pocket.id,
      tgl_konsultasi: picked.date,
      wkt_konsultasi: picked.time,
    };
    pocket.feature === "coaching" &&
      (body = {
        coach_kelas_daftar_id: pocket.id,
        tgl_kelas: picked.date,
        wkt_kelas: picked.time,
      });

    let data = {
      path: path,
      body: body,
    };
    let variant = "";

    dispatch(postApi(data))
      .then((data) => {
        variant = "success";
        enqueueSnackbar(data.message, { variant });
        getTimeSchedule(picked.date);
        setState({ ...state, loading: false, judul: "", deskripsi: "" });
        close && close();
        onRefetch();
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        variant = "error";
        if (err.error) {
          let msg = Object.values(err.error).map((el) => el[0]);
          msg.forEach((msg) => {
            enqueueSnackbar(msg, { variant });
          });
        } else {
          enqueueSnackbar(err.message, { variant });
        }
      });
  };

  const onRefetch = () => {
    pocket = {
      ...pocket,
      date: picked.date,
      wkt_konsultasi: picked.time,
    };

    refetch && refetch(pocket);
  };

  const getTimeSchedule = (date) => {
    let data = {
      path: `konselor/schedule/available?date=${date}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        formattingTimeActive(data, date);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formattingTimeActive = (data, date) => {
    let jam = [];
    let listTime = data.find((x) => x.date === date);
    listTime !== undefined &&
      listTime.dataTime.forEach((item, index) => {
        jam.push({
          date: item?.date ?? "-",
          time: item?.time ?? "-",
          disable: item?.disable ?? true,
        });
      });

    setPicked({});
    setJam(jam);
  };

  const onSelectedDate = (date) => {
    getTimeSchedule(date);
  };

  const pickedTime = (item) => {
    setPicked(item);
  };

  return (
    <Dialog
      open={d_rscdl}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      PaperProps={{
        style: { borderRadius: 8 },
      }}>
      <DialogTitle id="alert-dialog-title">
        <p className="font-weight-bold">Reschedule Konsultasi</p>
      </DialogTitle>
      <DialogContent>
        <div className="borbox" style={{ minWidth: "650px", padding: "5px" }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <p className="font-weight-bold">Tanggal Reschedule</p>
              <div className="rounded-lg pa-3 mt-3" style={{ border: `2px solid ${theme.secondary}` }}>
                <DatePickerSchedule
                  picked={datePicker}
                  setPicked={(value) => {
                    setDatePicker(value);
                  }}
                  selectedDate={(value) => {
                    onSelectedDate(value);
                  }}
                  onNext={() => getSchedule()}
                  onPre={() => getSchedule()}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <p className="font-weight-bold mb-5">Jam Aktif</p>
              <Grid container spacing={1}>
                {jam.map((item, i) => (
                  <Grid item xs={4} key={i}>
                    <div
                      className={picked == item ? "pick" : item.disable ? "unpickDisable" : "unpick"}
                      style={{ background: picked == item ? theme.primary : "" }}
                      onClick={() => {
                        !item.disable && pickedTime(item);
                      }}>
                      <p className="txt_table" style={{ color: picked == item ? "#fff" : item.disable ? "#a2a2a2" : theme.primary }}>
                        {item.time} WIB
                      </p>
                    </div>
                  </Grid>
                ))}
              </Grid>
              {jam.length <= 0 && (
                <div className="d-flex align-center justify-center unpick ma-2">
                  <small>Jadwal aktif tidak tersedia</small>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
        <div className="d-flex justify-space-between">
          <div>
            <div className="mt-3 pl-2">
              <div className="d-flex gap-2 mt-2">
                <div className="d-flex align-center">
                  <div className="pa-2 rounded-sm" style={{ backgroundColor: theme.primary }} />
                </div>
                <small>Tanggal yang dipilih</small>
              </div>
              <div className="d-flex gap-2 mt-2">
                <div className="d-flex align-center">
                  <div className="pa-2 rounded-sm" style={{ backgroundColor: theme.secondary }} />
                </div>
                <small>Terdapat jadwal aktif pada tanggal ini</small>
              </div>
              <div className="d-flex gap-2 mt-2">
                <div className="d-flex align-start pt-1">
                  <div className="px-2 rounded-sm" style={{ backgroundColor: theme.primary, paddingTop: "5px" }} />
                </div>
                <small>Terdapat pembelian layanan oleh klien</small>
              </div>
              <div className="d-flex gap-2 mt-2">
                <div className="d-flex align-end pb-1">
                  <div className="px-2 rounded-sm" style={{ backgroundColor: theme.primary, paddingTop: "5px" }} />
                </div>
                <small>Tanggal hari ini</small>
              </div>
            </div>
          </div>
          <div className="d-flex align-end">
            <Button onClick={close} className="capitalize">
              Batalkan
            </Button>
            {!state.loading ? (
              <Button onClick={postData} variant="contained" autoFocus className="capitalize">
                Simpan
              </Button>
            ) : (
              <Button variant="contained" autoFocus className="capitalize">
                Loading...
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

/* eslint-disable eqeqeq */
import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Avatar } from "@mui/material";

const PoinActivityList = ({ theme, dataSet, page, limit }) => {
  const endpoint = process.env.REACT_APP_HOST;
  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell
                style={{
                  minWidth: "50px",
                  background: theme.tertiary,
                  borderRadius: "10px 0 0 10px",
                }}
                className="py-2">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  No.
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "80px",
                  background: theme.tertiary,
                }}
                className="py-3"
                align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  ID User
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "200px",
                  background: theme.tertiary,
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Nama User
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "150px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Email
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Nominal
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} align="center" className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Harga Pokok
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Status
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "150px",
                  background: theme.tertiary,
                  borderRadius: "0 10px 10px 0",
                }}
                className="py-3"
                align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Metode
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSet.map((item, i) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell className="py-2">
                  <p className="px11_txt">{i + 1 + (page - 1) * limit}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.data_pelanggan.id}</p>
                </TableCell>
                <TableCell className="py-2">
                  <div className="d-flex align-center">
                    <Avatar alt={item.data_pelanggan.nama} src={`${endpoint}/temanbakat/pelanggan/${item.data_pelanggan.foto}`} className="mr-3" />
                    <p className="px11_txt">{item.data_pelanggan.nama}</p>
                  </div>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.data_pelanggan.email}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.jumlah} Poin</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.harga}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.status}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <p className="px11_txt">{item.via}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PoinActivityList;

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import locale from "dayjs/locale/id";
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";
import "../../../assets/css/calendar.css";
import { Grid, Card, Button } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useSelector } from "react-redux";

const AllSchedule = ({ jadwal = [], onPrev, onNext }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const now = dayjs().locale({
    ...locale,
  });

  dayjs.extend(weekdayPlugin);
  dayjs.extend(objectPlugin);
  dayjs.extend(isTodayPlugin);

  const [currentMonth, setCurrentMonth] = useState(now);
  const [arrayOfDays, setArrayOfDays] = useState([]);

  // Contoh format jangan delete contoh format
  //   let jadwal = [
  //     {
  //       date: "2022-09-02",
  //       time: ["08:00", "10:00", "11:00", "11:00", "11:00", "11:00", "11:00", "11:00"],
  //     },
  //     {
  //       date: "2022-09-13",
  //       time: ["08:00", "10:00"],
  //     },
  //   ];

  const nextMonth = () => {
    const plus = currentMonth.add(1, "month");
    onNext && onNext(plus.format("YYYY-MM-DD"));
    setCurrentMonth(plus);
  };

  const prevMonth = () => {
    const minus = currentMonth.subtract(1, "month");
    onPrev && onPrev(minus.format("YYYY-MM-DD"));
    setCurrentMonth(minus);
  };

  const renderHeader = () => {
    const dateFormat = "MMMM YYYY";
    return (
      <div>
        <div className="d-flex align-center justify-space-between mb-3">
          <b>Jadwal Aktif Konsultasi</b>
        </div>
        <div className="d-flex align-center justify-space-between mb-3">
          <h2 style={{ color: theme.primary }}>{currentMonth.format(dateFormat)}</h2>
          <div className="d-flex align-center">
            <Button className="capitalize mr-2" variant="outlined" style={{ minWidth: "10px" }} onClick={() => prevMonth()}>
              <ChevronLeft color="primary" />
            </Button>
            <Button className="capitalize" variant="outlined" style={{ minWidth: "10px" }} onClick={() => nextMonth()}>
              <ChevronRight color="primary" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "dddd";
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center font-weight-bold white-text" key={i}>
          {now.weekday(i).format(dateFormat)}
        </div>
      );
    }
    return <div className="days row card_days py-3">{days}</div>;
  };
  const getAllDays = () => {
    let currentDate = currentMonth.startOf("month").weekday(0);
    const nextMonth = currentMonth.add(1, "month").month();

    let allDates = [];
    let weekDates = [];

    let weekCounter = 1;

    while (currentDate.weekday(0).toObject().months !== nextMonth) {
      const formated = formateDateObject(currentDate);

      weekDates.push(formated);
      if (weekCounter === 7) {
        weekDates.forEach((date) => {
          jadwal.forEach((time) => {
            if (date.date === time.date) {
              date["time"] = time.time;
            }
          });
        });
        allDates.push({ dates: weekDates });
        weekDates = [];
        weekCounter = 0;
      }
      weekCounter++;
      currentDate = currentDate.add(1, "day");
    }
    setArrayOfDays(allDates);
  };

  useEffect(() => {
    getAllDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth, jadwal]);

  const renderCells = () => {
    const rows = [];
    let days = [];

    arrayOfDays.forEach((week, index) => {
      week.dates.forEach((d, i) => {
        days.push(
          <div className={`col cell ${!d.isCurrentMonth ? "disabled" : d.isCurrentDay ? "selected on_month" : "on_month"}`} key={i}>
            <div className="number">{d.day}</div>
            <div className="pa-2">
              <Grid container spacing={1}>
                {d.time ? (
                  d.time.map((t, i) => (
                    <Grid item xs={6} key={i}>
                      {" "}
                      <div className="pill-time">{t}</div>{" "}
                    </Grid>
                  ))
                ) : (
                  <></>
                )}
              </Grid>
            </div>
          </div>
        );
      });
      rows.push(
        <div className="row" key={index}>
          {days}
        </div>
      );
      days = [];
    });

    return <div className="body">{rows}</div>;
  };

  const formateDateObject = (date) => {
    const temp = { ...date.toObject() };

    const formatedObject = {
      day: temp.date,
      month: temp.months,
      year: temp.years,
      isCurrentMonth: temp.months === currentMonth.month(),
      isCurrentDay: date.isToday(),
      date: `${temp.years}-${temp.months < 10 ? `0${temp.months + 1}` : temp.months + 1}-${temp.date < 10 ? `0${temp.date}` : temp.date}`,
    };

    return formatedObject;
  };

  return (
    <Card className="pa-3 rounded-lg">
      {renderHeader()}
      {renderDays()}
      <div className="calendar">{renderCells()}</div>
    </Card>
  );
};

export default AllSchedule;

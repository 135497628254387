/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../../store/theming";
import { getApi, postApi } from "../../../../store/apis";
import EmptyData from "../../../../components/base/EmptyData";
import { Grid, Paper, Skeleton, Button, Divider } from "@mui/material";
import { Delete as DeleteIcon, ChevronLeft, Circle } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import { DialogDelete } from "../../../../components/base/dialogDelete";
import ButtonEdit from "../../../../components/base/ButtonEdit";
import day from "dayjs";

const PopupDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const theme = useSelector((state) => state.theme.myTheme);
  const [loading, setLoading] = useState(true);
  const [dataSet, setData] = useState([]);
  const [emptyTxt, setEmptyTxt] = useState("");
  const [pocket, setPocket] = useState(null);
  const [d_del, setDel] = useState(false);

  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `admin/advertising/popup/detail?id=${id}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        if (err.status == 400) {
          setEmptyTxt(`No Data Available`);
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setData([]);
      });
  };

  const onChangeStatus = () => {
    const data = {
      path: "admin/advertising/popup/switch",
      body: {
        id: id,
        show: dataSet.show == 1 ? 0 : 1,
      },
    };

    dispatch(postApi(data))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "Status berhasil diubah", { variant });
        setData({
          ...dataSet,
          show: dataSet.show == 1 ? 0 : 1,
        });
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
      });
  };

  const onDelete = () => {
    const data = {
      path: `admin/advertising/popup/delete`,
      body: {
        id,
      },
    };

    setPocket(data);
    setDel(true);
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Pop Up Detail"));
    fetchData();
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          {!loading ? (
            dataSet ? (
              <div>
                <div className="d-flex justify-space-between align-center mb-3">
                  <Button
                    variant="contained"
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg"
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeft /> Kembali
                  </Button>
                  <div className="d-flex align-center">
                    <p className="small_txt font-weight-bold">
                      {dataSet.show == 1 ? "Aktif" : "Non-aktif"}
                    </p>
                    <Button onClick={onChangeStatus}>
                      {dataSet.show == 1 ? (
                        <Icon
                          path={mdiToggleSwitch}
                          size={1}
                          color={theme.primary}
                        />
                      ) : (
                        <Icon path={mdiToggleSwitchOffOutline} size={1} />
                      )}
                    </Button>
                  </div>
                </div>
                <Paper className="pa-5 rounded-lg" elevation={0}>
                  <div className="d-flex justify-space-between mb-2">
                    <div>
                      <h3>{dataSet.title}</h3>
                    </div>
                    <div>
                      <ButtonEdit
                        onClick={() =>
                          navigate(`/popup/detail/${id}/edit`, {
                            state: { ...dataSet },
                          })
                        }
                      />
                      <Button
                        onClick={onDelete}
                        variant="contained"
                        className="rounded-lg capitalize ml-3"
                        disableElevation
                        size="small"
                        style={{
                          background: theme.act_red,
                          height: "max-content",
                        }}
                      >
                        <DeleteIcon
                          className="mr-1"
                          style={{ color: theme.act_lbl_red }}
                        />
                        <p
                          className="font-weight-bold"
                          style={{ color: theme.act_lbl_red }}
                        >
                          {" "}
                          Delete
                        </p>
                      </Button>
                    </div>
                  </div>
                  <Divider />
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <div className="d-flex flex-start">
                        <Circle
                          fontSize="10px"
                          className="mt-1 mr-2"
                          color="primary"
                        />
                        <div className="flexy">
                          <p
                            className="small_txt"
                            style={{ color: theme.primary }}
                          >
                            Informasi Pop Up
                          </p>
                          <div className="mt-2">
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Gambar Pop Up
                                </p>
                                <img
                                  src={dataSet.gambar_url}
                                  height="200px"
                                ></img>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Caption Pop Up
                                </p>
                                <p className="small_txt">
                                  {dataSet.caption ?? "-"}
                                </p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Keterangan Pop Up
                                </p>
                                <p className="small_txt">
                                  {dataSet.keterangan ?? "-"}
                                </p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Kriteria Pop Up
                                </p>
                                {dataSet.kriteria ? (
                                  JSON.parse(dataSet.kriteria).map((item) => {
                                    return (
                                      <p
                                        key={item}
                                        className="small_txt"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        ● {item}
                                      </p>
                                    );
                                  })
                                ) : (
                                  <p
                                    className="small_txt"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    -
                                  </p>
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <div className="d-flex flex-start">
                        <Circle
                          fontSize="10px"
                          className="mt-1 mr-2"
                          color="primary"
                        />
                        <div className="flexy">
                          <p
                            className="small_txt"
                            style={{ color: theme.primary }}
                          >
                            Informasi Button Popup
                          </p>
                          <div className="mt-2">
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Close Button Pop Up
                                </p>
                                <p className="small_txt">
                                  {dataSet.button_close == 1
                                    ? "Aktif"
                                    : "Non-aktif"}
                                </p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Button Pop Up
                                </p>
                                <p className="small_txt">
                                  {dataSet.button_visibility ?? "-"}
                                </p>
                              </Grid>
                              {dataSet.data_promo_button.length > 0 && (
                                <Grid item xs={12}>
                                  <p
                                    className="small_txt font-weight-bold"
                                    style={{ color: theme.primary }}
                                  >
                                    Data Button Pop Up
                                  </p>
                                  {dataSet.data_promo_button.map((item, i) => {
                                    return (
                                      <div key={i} className="d-flex mb-1">
                                        <p
                                          className="small_txt"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          ●
                                        </p>
                                        <div className="ml-1">
                                          <p className="small_txt">
                                            Text Button : {item.label ?? "-"}
                                          </p>
                                          <p className="small_txt">
                                            Text Button Color :{" "}
                                            {item.label_color ?? "-"}
                                          </p>
                                          <p className="small_txt">
                                            Background Button :{" "}
                                            {item.bg_color ?? "-"}
                                          </p>
                                          <p className="small_txt">
                                            Action : {item.action ?? "-"}
                                          </p>
                                          <p className="small_txt">
                                            Kategori : {item.type_ref ?? "-"}
                                          </p>
                                          <p className="small_txt">
                                            Detail : {item.id_ref ?? "-"}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </Grid>
                              )}
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={3}>
                      <div className="d-flex flex-start">
                        <Circle
                          fontSize="10px"
                          className="mt-1 mr-2"
                          color="primary"
                        />
                        <div className="flexy">
                          <p
                            className="small_txt"
                            style={{ color: theme.primary }}
                          >
                            Informasi Publish
                          </p>
                          <div className="mt-2">
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Start Publish
                                </p>
                                <p className="small_txt">
                                  {dataSet.start_promo
                                    ? day(dataSet.start_promo)
                                        .locale("id")
                                        .format("dddd, MMMM YYYY")
                                    : "-"}
                                </p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Repeat
                                </p>
                                <p className="small_txt">
                                  {dataSet.repeat_type
                                    ? `${dataSet.repeat_type} - ${
                                        dataSet.repeat_on
                                          ? JSON.parse(dataSet.repeat_on)
                                          : ""
                                      }`
                                    : "-"}
                                </p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  End Repeat
                                </p>
                                <p className="small_txt">
                                  {dataSet.end_promo
                                    ? day(dataSet.end_promo)
                                        .locale("id")
                                        .format("dddd, MMMM YYYY")
                                    : "-"}
                                </p>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            ) : (
              <EmptyData />
            )
          ) : (
            <Skeleton
              variant="rounded"
              className="rounded-lg"
              style={{ minHeight: "300px", width: "100%" }}
            />
          )}
        </Grid>
      </Grid>

      <div className="py-2">
        <DialogDelete
          pocket={pocket}
          d_del={d_del}
          close={() => setDel(false)}
          refetch={() => navigate(-1)}
        />
      </div>
    </div>
  );
};

export default PopupDetail;

import { Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { postApi } from "../../../store/apis";

const PsikotesSettingList = ({ theme, dataSet, ava, page, limit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [dataSetList, setDataList] = useState(dataSet);

  // On Swith status data
  const changeStatus = (item) => {
    let status = item.status === 1 ? 0 : 1;
    const data = {
      path: "admin/psikotes-setting/switch",
      body: {
        id: item.id,
        status: status,
      },
    };

    dispatch(postApi(data))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "Status berhasil diubah", { variant });
        let listData = [...dataSetList];
        const index = listData.findIndex((el) => el.id === item.id);
        listData[index] = { ...listData[index], status };
        setDataList(listData);
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
      });
  };

  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell style={{ minWidth: "50px", background: theme.tertiary, borderRadius: "10px 0 0 10px" }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  No.
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "150px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Judul Psikotes
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "130px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Harga Normal
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Harga Promo
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "130px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Show
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary, borderRadius: "0 10px 10px 0" }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tindakan
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSetList.map((item, i) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell className="py-2">
                  <p className="px11_txt">{i + 1 + (page - 1) * limit}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.nama_psikotes}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">Rp{item.harga}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.potongan_harga ? `Rp${item.potongan_harga}` : "Tidak Ada Promo"}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <div className="d-flex align-center justify-center">
                    <Icon path={item.status === 1 ? mdiToggleSwitch : mdiToggleSwitchOffOutline} size={1.8} color={theme.primary} className="cursor_pointer" onClick={() => changeStatus(item)} />
                  </div>
                </TableCell>
                <TableCell className="py-2">
                  <Button
                    variant="contained"
                    className="rounded-xl capitalize mr-3"
                    onClick={() => navigate(`/psikotes/detail/${item.id}`)}
                    disableElevation
                    size="small"
                    style={{ background: theme.act_blue }}>
                    <p className="font-weight-bold px11_txt" style={{ color: theme.act_lbl_blue }}>
                      Detail
                    </p>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pa-2"></div>
    </div>
  );
};

export default PsikotesSettingList;

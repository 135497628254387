import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const axios = require("axios");

const initialState = {
  data: [],
  form: {
    username: "",
    password: "",
    nama: "",
    jenis: "",
    email: "",
    alamat: "",
    no_kontak: "",
    nama_pic: "",
    no_telp_pic: "",
    permission_name: "",
  },
};

const apis = createSlice({
  name: "apis",
  initialState,
  reducers: {
    GET_DATA: (state, action) => {
      state.data = [action.payload];
    },
    SORT_DATA: (state, action) => {
      state.data.sort((a, b) => {
        return ("" + a[action.payload]).localeCompare(b[action.payload]);
      });
      console.log(state.data);
    },
  },
});

export const fetchApi = (data) => async (dispatch) => {
  const env = process.env.REACT_APP_ENV;
  let config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  };
  const res = await axios.get(`${env}/${data.path}`, config);
  dispatch(GET_DATA(res.data));
  return res;
};
export const getApi = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const env = process.env.REACT_APP_ENV;
    let config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    };
    axios
      .get(`${env}/${data.path}`, config)
      .then((res) => {
        resolve(res.data.data);
        dispatch(GET_DATA(res.data.data));
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const postApi = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const env = process.env.REACT_APP_ENV;
    let config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    };

    axios
      .post(`${env}/${data.path}`, data.body, config)
      .then((res) => {
        resolve(res.data);
        dispatch(GET_DATA(res.data));
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const postBlobApi = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const env = process.env.REACT_APP_ENV;
    let config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
      responseType: "blob",
    };

    axios
      .post(`${env}/${data.path}`, data.body, config)
      .then((res) => {
        resolve(res.data);
        dispatch(GET_DATA(res.data));
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const { GET_DATA, SORT_DATA } = apis.actions;
export default apis.reducer;

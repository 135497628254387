/* eslint-disable default-case */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import { postApi } from "../../../store/apis";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Close as CloseIcon, DateRange } from "@mui/icons-material";
import InputWithIcon from "../../../components/base/inputWithIcon";
import css from "../../../assets/css/search.module.css";
import day from "dayjs";
import { useParams } from "react-router-dom";

const KonselingActivityReschedule = ({ konsultasi, open = false, onClose, refetch, }) => {
  const { id } = useParams();

  const theme = useSelector((state) => state.theme.myTheme);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [date, setDate] = useState("");
  const [time, setTime] = useState({
    hours: "",
    minutes: "",
  });
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const onReschedule = () => {
    setLoading(true)
    const data = {
      path: "admin/konseling-aktivitas/reschedule",
      body: {
        konsultasi_thread_id: id,
        waktu: `${time.hours}:${time.minutes}:00`,
        tgl_konsultasi: date
      },
    };

    dispatch(postApi(data))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "Status berhasil diubah", { variant });
        onClose(false);
        setLoading(false)
        refetch();
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
      });
  };

  const handleChange = (prop, childProp) => (event) => {
    const value = event.target.value;
    if (prop === "date") {
      setDate(value);
    }
    if (prop === "time") {
      setTime({ ...time, [childProp]: value });
    }
  };

  const checkRequire = () => {
    if (date != "" && time.hours != "" && time.minutes != "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  const timeChecker = type => {
    if (type == 'h') {
      if (time.hours > 23) {
        setTime({ ...time, hours: 23 });
      }
    } else {
      if (time.minutes > 59) {
        setTime({ ...time, minutes: 59 });
      }
    }
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="py-3">
        <div className="d-flex justify-space-between">
          <h4>Reschedule Konsultasi</h4>
          <IconButton onClick={() => onClose(false)}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="styled_scroll" style={{ overflowX: "hidden" }}>
        <div
          className="borbox"
          style={{ width: "500px", padding: "5px", minHeight: "400px" }}
        >
          <div className="pa-3 mb-2">
            <Grid container spacing={2} className="align-center mb-4">
              <Grid item xs={4}>
                <p
                  className="font-weight-bold small_txt"
                  style={{ color: theme.primary }}
                >
                  Tanggal Reschedule
                </p>
              </Grid>
              <Grid item xs={8}>
                <InputWithIcon
                  value={date}
                  icon={<DateRange color="primary" />}
                  handleChange={handleChange}
                  type="date"
                  property="date"
                  positionIcon="right"
                  placeholder="Pilih Tanggal Konsultasi"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="align-center mb-4">
              <Grid item xs={4}>
                <p
                  className="font-weight-bold small_txt"
                  style={{ color: theme.primary }}
                >
                  Jam
                </p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className={css.icon_input}
                  type="text"
                  onChange={handleChange("time", "hours")}
                  value={time.hours}
                  onBlur={() => timeChecker('h')}
                  placeholder="00"
                  style={{
                    textAlign: "center",
                    background: theme.tertiary,
                    borderRadius: "6px 6px 6px 6px",
                  }}
                  maxLength="2"
                />
              </Grid>
              <Grid item xs={0.1}>
                <p className="font-weight-bold small_txt">:</p>
              </Grid>
              <Grid item xs={2}>
                <input
                  className={css.icon_input}
                  type="text"
                  onChange={handleChange("time", "minutes")}
                  value={time.minutes}
                  onBlur={() => timeChecker('m')}
                  placeholder="00"
                  style={{
                    textAlign: "center",
                    background: theme.tertiary,
                    borderRadius: "6px 6px 6px 6px",
                  }}
                  maxLength="2"
                />
              </Grid>
              <Grid item xs={1}>
                <p className="small_txt" style={{ color: theme.primary }}>
                  WIB
                </p>
              </Grid>
            </Grid>
          </div>

          <div
            className="pa-2 mb-2 rounded-lg"
            style={{
              border: `1.5px solid ${theme.lighten}`,
            }}
          >
            <p
              className="medium_txt font-weight-500 mb-2"
              style={{ color: theme.primary }}
            >
              Informasi Klien
            </p>
            <Grid container spacing={2} className="mb-2">
              <Grid item xs={4}>
                <p className="font-weight-bold small_txt">Nama User</p>
              </Grid>
              <Grid item xs={8}>
                <p className="small_txt">{konsultasi.nama_pelanggan}</p>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mb-2">
              <Grid item xs={4}>
                <p className="font-weight-bold small_txt">Layanan</p>
              </Grid>
              <Grid item xs={8}>
                <p className="small_txt">{konsultasi.konsultasi}</p>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mb-2">
              <Grid item xs={4}>
                <p className="font-weight-bold small_txt">Waktu Konsultasi</p>
              </Grid>
              <Grid item xs={8}>
                <p className="small_txt">
                  {day(konsultasi.tgl_konsultasi)
                    .locale("id")
                    .format("dddd, DD MMMM YYYY")}
                  <br />
                  {day(konsultasi.tgl_konsultasi)
                    .locale("id")
                    .format(`HH:mm WIB`)}
                </p>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mb-2">
              <Grid item xs={4}>
                <p className="font-weight-bold small_txt">Topik Konsultasi</p>
              </Grid>
              <Grid item xs={8}>
                <p className="small_txt">{konsultasi.topik}</p>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mb-2">
              <Grid item xs={4}>
                <p className="font-weight-bold small_txt">Nama Konselor</p>
              </Grid>
              <Grid item xs={8}>
                <p className="small_txt">{konsultasi.nama_konselor}</p>
              </Grid>
            </Grid>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="d-flex justify-end px-5 pb-5">
          {!loading ? <Button
            disabled={date !== "" && time.hours !== "" && time.minutes !== "" ? false : true}
            onClick={() => onReschedule()}
            variant="contained"
            className="capitalize rounded-lg"
            disableElevation
          >
            <p className="font-weight-bold">Simpan</p>
          </Button> : <Button
            variant="contained"
            className="capitalize rounded-lg"
            disableElevation
          >
            <p className="font-weight-bold">Loading...</p>
          </Button>}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default KonselingActivityReschedule;

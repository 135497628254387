import { Delete as DeleteIcon, Pause as PauseIcon, Send as SendIcon, Stop as StopIcon, PlayArrow } from '@mui/icons-material'
import { Button } from '@mui/material'
import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react'
const MicRecorder = require('mic-recorder-to-mp3');

const AudioRecorder = forwardRef(({ css, setToRecord, setAudioMsg, sendMsg }, ref) => {
    const [Mp3Recorder, setMp3Recorder] = useState(
        new MicRecorder({ bitRate: 128 })
    );
    // DRT = DURATION
    const [rec, startRec] = useState(false)
    const [audio, setAudio] = useState(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [drtRecording, setDrtRecording] = useState('00:00')
    const [percentDrt, setPercentDrt] = useState(null)

    useImperativeHandle(ref, () => ({
        startVn() {
            Mp3Recorder.start().then(() => {
                startRec(true)
                animateDrt()
            }).catch((e) => {
                console.error(e);
            });
        }
    }));


    const rcd = useRef()
    const animateDrt = (maxDrt = 30) => {
        let count = 0
        rcd.current = setInterval(() => {
            count++
            let duration = `00:${count > 9 ? count : `0${count}`}`
            setDrtRecording(duration)
            let percent = (count / maxDrt) * 100
            setPercentDrt(percent)
            if (count >= 30) {
                clearInterval(rcd.current)
                stopVn()
            }
            console.log(count)
        }, 1000);
    }


    const cancelRecord = () => {
        clearInterval(rcd.current)
        if (audio) {
            audio.pause();
            audio.currentTime = 0;
            setToRecord(false)
        }
        setAudio(null)
        setIsPlaying(false)
        setPercentDrt(null)
        startRec(false)
    }
    const stopVn = () => {
        clearInterval(rcd.current)
        setPercentDrt(null)
        setDrtRecording('00:00')
        Mp3Recorder
            .stop()
            .getMp3().then(([buffer, blob]) => {
                const file = new File(buffer, 'tb-vn.mp3', {
                    type: blob.type,
                    lastModified: Date.now()
                });
                const player = new Audio(URL.createObjectURL(file));
                setAudio(player)
                startRec(false)
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    let base64data = reader.result;
                    setAudioMsg(base64data);
                }
            }).catch((e) => {
                alert('We could not retrieve your message');
                console.log(e);
            });
    }
    const playAudio = () => {
        audio.play()
        setIsPlaying(true)
        let duration = Math.floor(audio.duration)
        animateDrt(duration)
        audio.addEventListener('ended', (e) => {
            setIsPlaying(false)
            clearInterval(rcd.current)
            setPercentDrt(null)
        });
    }
    const sendAudio = () => {
        sendMsg('audio')
        setToRecord(false)
        audio.pause()
        setIsPlaying(false)
        setIsPlaying(false)
        setPercentDrt(null)
        startRec(false)
        clearInterval(rcd.current)
    }

    useEffect(() => {
    }, [])

    return (
        <div className='d-flex align-center mt-3'>
            <Button className='mx-3' disabled={rec} style={{ minWidth: '0', padding: '0' }} onClick={() => cancelRecord()}>
                <DeleteIcon />
            </Button>
            <div className={css.temp_recorder}>
                <div className={css.dp_rcd}>
                    {rec ? drtRecording :
                        <div>
                            {isPlaying ? drtRecording :
                                <Button className='mx-3' onClick={() => playAudio()} style={{ minWidth: '0', padding: '0' }}>
                                    <PlayArrow />
                                </Button>}
                        </div>}
                </div>
                <div className={css.recorded} style={{ width: `${percentDrt}%` }}>
                </div>
            </div>
            {rec ?
                <Button className='mx-3' onClick={() => stopVn()} style={{ minWidth: '0', padding: '0' }}>
                    <StopIcon />
                </Button> :
                <Button className='mx-3' variant='contained' onClick={() => sendAudio()} size="small" disableElevation style={{ borderRadius: '50%', minWidth: '0', width: '40px', height: '40px', padding: '0' }}>
                    <SendIcon fontSize='small' />
                </Button>}
        </div >
    )
})

export default AudioRecorder

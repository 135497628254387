import { Card } from "@mui/material";
import TitleWithDate from "../partial/TitleWithDate";
import JourneyProgressItem from "../partial/AvatarProgressItem";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { dateFormatter } from "../../../utils/formatter";
import { getApi } from "../../../store/apis";

const GameForDevelopment = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const [game, setGame] = useState({});

  useEffect(() => {
    getGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGame = (date = dateFormatter(new Date())) => {
    let config = {
      path: `admin/board-result/game?date=${date}`,
    };
    dispatch(getApi(config))
      .then((res) => {
        (res === null || res === undefined) && (res = {});
        setGame(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card className="pa-3 rounded-lg">
      <TitleWithDate title="Game For Development" subTitle="Jumlah pengguna Game For Development" onDone={(value) => getGame(dateFormatter(value.value))} />
      <div className="mt-4" style={{ minHeight: "10rem" }}>
        {game?.collection?.map((item, index) => {
          return <JourneyProgressItem key={index} title={item.judul} colorBorder={theme.act_pink} colorBar={theme.act_lbl_pink} value={item.jumlah} total={game.total} />;
        })}
      </div>
    </Card>
  );
};

export default GameForDevelopment;

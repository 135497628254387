/* eslint-disable eqeqeq */
import React from 'react'
import { MoreHoriz } from "@mui/icons-material";
import { Paper } from "@mui/material";
import LinesEllipsis from 'react-lines-ellipsis'
import "@szhsin/react-menu/dist/index.css";
const CardDate = ({ css, d, pickDay }) => {
    return (
        <div onClick={() => pickDay(d, d.isCurrentMonth)} id={d.date} className={`${css.col} ${css.cell} ${!d.isCurrentMonth ? css.disabled : d.isCurrentDay ? `${css.selected} ${css.on_month}` : css.on_month}`} >
            <div className={css.number} ref={d.ref}>{d.day}</div>
            <div className="pa-1">
                {d.schedule ? <div>
                    {d.schedule.map((item, j) => <div key={j}>{j < 2 ? <div className="d-flex">
                        {/* <p className="font-weight-bold mt-1"
                                        style={{ width: '29px', color: item['status-booking'] == 'booked' ? '#10A3B01A' : '#10A3B0' }}>
                                        {dayjs(item.waktu).format('H')}
                                    </p> */}

                        <Paper className='pa-2 mb-2 flexy'
                            sx={{
                                background: item.role == 'Psikolog' ? '#E8F6F8' : item.role == 'Coach' ? '#FFF6E7' : '#F6EDFF',
                                position: 'relative',
                                display: 'block',
                                overflow: 'hidden'
                            }}
                            elevation={item['status-booking'] == 'booked' ? 0 : 1}>
                            <LinesEllipsis
                                className="font-weight-bold"
                                style={{ fontSize: '12px' }}
                                text={item.data_konselor ? item.data_konselor.nama : 'Anonymous'}
                            />
                            {item['status-booking'] == 'booked' ? <div className="backdrop"></div> : <></>}
                        </Paper>
                    </div> : <></>}
                    </div>)}
                    <div className="d-flex justify-center">{d.schedule.length > 2 ? <MoreHoriz fontSize='small' /> : <></>}</div>
                </div>
                    : <></>}
            </div>
        </div>
    )
}

export default CardDate

/* eslint-disable react-hooks/exhaustive-deps */
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { ChevronLeft, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { Backdrop, Button, CircularProgress, Grid, Paper } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getApi } from '../../../../store/apis'
import { CHANGE_TITLE } from '../../../../store/theming'

const LayananKonselingDetail = () => {
    const navigate = useNavigate()
    const theme = useSelector((state) => state.theme.myTheme);
    const [dataSet, setData] = useState(null)
    const dummy = require('../../../../assets/img/empty.png')
    const [psiklog, setPsikolog] = useState([])
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const { id } = useParams();
    const fetchData = () => {
        setLoading(true);
        let data = {
            path: `admin/konsultasi/detail?id=${id}&include=dataKonsultasiPsikotes`,
        };
        dispatch(getApi(data))
            .then((data) => {
                setLoading(false);
                setData(data);
                getPsikologAktiv(data.konsultasi_kategori_id)
            })
            .catch((err) => {
                setLoading(false);
                setData([]);
            });
    };
    const getPsikologAktiv = (kategori_id) => {
        setLoading(true);
        let data = {
            path: `admin/konsultasi/konselor-aktif?id=${id}&konsultasi_kategori_id=${kategori_id}`,
        };
        dispatch(getApi(data))
            .then((data) => {
                setLoading(false);
                setPsikolog(data);
            })
            .catch((err) => {
                setLoading(false);
                setPsikolog([]);
            });
    }
    useEffect(() => {
        dispatch(CHANGE_TITLE("Detail Konsultasi"));
        fetchData()
    }, [])
    return (
        <div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Button
                variant="contained"
                style={{ minWidth: "0" }}
                className="capitalize rounded-lg"
                onClick={() => navigate(-1)}
            >
                <ChevronLeft /> Kembali
            </Button>
            {dataSet ?
                <div>
                    <div className="d-flex justify-end">
                        <div className="d-flex align-center">
                            <p className="small_txt font-weight-bold">
                                {dataSet.status === 1
                                    ? "Tamplikan Psikotes"
                                    : "Sembunyikan Psikotes"}
                            </p>
                            <Button>
                                {dataSet.status === 1 ? (
                                    <Icon
                                        path={mdiToggleSwitch}
                                        size={1}
                                        color={theme.primary}
                                    />
                                ) : (
                                    <Icon path={mdiToggleSwitchOffOutline} size={1} />
                                )}
                            </Button>
                        </div>
                    </div>
                    <Paper className='pa-3 rounded-lg' elevation={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <img src={dummy} height='150px' alt="cover-psikotes" />
                            </Grid>
                            <Grid item xs={7}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Kategori</p>
                                        <p className="small_txt">{dataSet.data_konsultasi_kategori.name}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Judul Konsultasi</p>
                                        <p className="small_txt">{dataSet.nama_konsultasi}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Keterangan Konsultasi</p>
                                        <p className="small_txt">{dataSet.keterangan}</p>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Harga Normal</p>
                                        <p className="small_txt">{dataSet.harga}</p>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Harga Promo</p>
                                        <p className="small_txt">{dataSet.potongan_harga ?? 'tidak ada promo'}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Periode Konsultasi</p>
                                        <p className="small_txt">api belum</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Fitur Konsultasi</p>
                                        {dataSet.attributes.length ?
                                            <ul>{dataSet.attributes.map((at, i) => <li key={`fitur_${i}`} className="small_txt  capitalize">
                                                {at.replace(/_/g, " ")}</li>)}</ul> : <p className="small_txt">Tidak Ada data</p>}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Rekomendasi</p>
                                        {dataSet.rekomendasi.length ?
                                            <ul>{dataSet.rekomendasi.map((rec, i) => <li key={`rekomendasi_${i}`} className="small_txt  capitalize">{rec}</li>)}</ul>
                                            : <p className="small_txt">Tidak Ada data</p>}

                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Psikotes</p>
                                        {dataSet.data_konsultasi_psikotes.length ?
                                            <ul>{dataSet.data_konsultasi_psikotes.map((at, i) =>
                                                <li key={`konsultasi_${i}`} className="small_txt">{at.data_psikotes.nama_psikotes}</li>)}
                                            </ul> : <p className="small_txt">Tidak Ada data</p>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>Konselor Aktif</p>
                                        <p className="small_txt">api belum</p>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={3}>
                                <div className="d-flex justify-end">
                                    <Button
                                        variant="contained"
                                        style={{ minWidth: "0" }}
                                        className="capitalize rounded-lg mr-2"
                                    >
                                        <EditIcon /> Ubah
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{ minWidth: "0", background: theme.act_red }}
                                        disableElevation
                                        className="capitalize rounded-lg"
                                    >
                                        <DeleteIcon style={{ color: theme.act_lbl_red }} /> <p style={{ color: theme.act_lbl_red }}>Hapus</p>
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </div> : <></>}
        </div>
    )
}

export default LayananKonselingDetail

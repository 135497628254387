import React, { useState } from "react";
import { Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Avatar, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import day from "dayjs";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { postApi } from "../../../store/apis";
const ListIndividualUser = ({ theme, dataSet, ava }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [dataSetList, setDataList] = useState(dataSet);

  const dummyAva = () => {
    let ava = require(`../../../assets/img/ava/ava${Math.floor(Math.random() * 11) + 1}.png`);
    return ava;
  };

  const onChangeStatus = (account) => {
    let { id, status } = account;
    status = status === 1 ? 0 : 1;
    const data = {
      path: "admin/individual-user/switch",
      body: {
        id,
        status,
      },
    };

    dispatch(postApi(data))
      .then((data) => {
        const variant = "success";
        let listData = [...dataSetList];
        const index = listData.findIndex((el) => el.id === id);
        listData[index] = { ...listData[index], status };
        setDataList(listData);
        enqueueSnackbar(data.message ?? "Status berhasil diubah", { variant });
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
      });
  };

  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell style={{ minWidth: "90px", background: theme.tertiary, borderRadius: "10px 0 0 10px" }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  User ID
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "200px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Nama User
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Email
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Nomor Hp
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Kategori
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Poin
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "135px", background: theme.tertiary }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tgl. Reg.
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Status
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary, borderRadius: "0 10px 10px 0" }} className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tindakan
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSetList.map((item, i) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.id}</p>
                </TableCell>
                <TableCell className="py-2">
                  <div className="d-flex align-center">
                    <Avatar alt={item.nama} src={item.foto ? item.foto_link : dummyAva()} className="mr-3" />
                    <p className="px11_txt">{item.nama}</p>
                  </div>
                </TableCell>
                <TableCell className="py-2" style={{ maxWidth: "230px", overflow: "hidden" }}>
                  {item.email ? (
                    <Tooltip title={item.email} placement="top" followCursor={true} arrow>
                      <p className="px11_txt">{item.email}</p>
                    </Tooltip>
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.no_telp}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.profesi}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{item.poin}</p>
                </TableCell>
                <TableCell className="py-2">
                  <p className="px11_txt">{day(item.created_at).format("DD/MM/YY | HH:mm")}</p>
                </TableCell>
                <TableCell className="py-2" align="center">
                  <div onClick={() => onChangeStatus(item)} className="d-flex align-center justify-center">
                    <Icon path={item.status === 1 ? mdiToggleSwitch : mdiToggleSwitchOffOutline} size={1.8} color={theme.primary} className="cursor_pointer" />
                  </div>
                </TableCell>
                <TableCell className="py-2">
                  <Button
                    variant="contained"
                    className="rounded-xl capitalize mr-3"
                    onClick={() => navigate(`/individual-user/detail/${item.id}`)}
                    disableElevation
                    size="small"
                    style={{ background: theme.act_blue }}>
                    <p className="font-weight-bold px11_txt" style={{ color: theme.act_lbl_blue }}>
                      Detail
                    </p>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pa-2"></div>
    </div>
  );
};

export default ListIndividualUser;

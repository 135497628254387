/* eslint-disable react-hooks/exhaustive-deps */
import { Circle, Dashboard, Group, KeyboardArrowDown, KeyboardArrowUp, CalendarMonth, FormatListBulleted, Forum, ManageAccounts } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const SidebarPsy = ({ path = "/", theme }) => {
  const menuClient = ["/client-list", "/client-test-result"];
  const menuSchedule = ["/schedule-list", "/schedule-setting"];
  const menuActivity = ["/activity-portfolio", "/activity-feed"];
  const menuSetting = ["/setting-profile", "/setting-service"];
  const [open, setOpen] = useState({
    client: false,
    jadwal: false,
    activity: false,
    setting: false,
  });
  const navigate = useNavigate();
  const goTo = (path) => {
    navigate(path);
  };
  const handleOpener = (type) => {
    setOpen({
      ...open,
      [type]: !open[type],
    });
  };

  useEffect(() => {
    switch (true) {
      case menuClient.includes(path):
        handleOpener("client");
        break;
      case menuSchedule.includes(path):
        handleOpener("jadwal");
        break;
      case menuActivity.includes(path):
        handleOpener("activity");
        break;
      case menuSetting.includes(path):
        handleOpener("setting");
        break;
      default:
        break;
    }
  }, []);
  return (
    <div>
      {/* menu dashboard */}
      <div className={path === "/" ? "item_active" : "item_unactive"} onClick={() => goTo("/")}>
        <Dashboard fontSize="small" color={path === "/" ? "primary" : "white"} className="mr-3" />
        <p className={path === "/" ? "font-weight-bold txt_menu" : "txt_menu"} style={{ color: path === "/" ? theme.primary : "#fff" }}>
          Beranda
        </p>
      </div>

      {/* menu client */}
      <div className="expandable" style={{ background: menuClient.includes(path) || open.client ? theme.lighten : "" }}>
        <div className="d-flex align-center justify-space-between cursor_pointer" onClick={() => handleOpener("client")}>
          <div className="d-flex align-center">
            <Group fontSize="small" color={menuClient.includes(path) || open.client ? "primary" : "white"} className="mr-3" />
            <p className={menuClient.includes(path) || open.client ? "font-weight-bold txt_menu" : "txt_menu"} style={{ color: menuClient.includes(path) || open.client ? theme.primary : "#fff" }}>
              Klien
            </p>
          </div>
          {open.client ? <KeyboardArrowUp fontSize="small" color="primary" /> : <KeyboardArrowDown fontSize="small" color="white" />}
        </div>

        <Collapse in={open.client} timeout="auto" unmountOnExit className="mt-3">
          <div className={path === "/client-list" ? "item_active" : "item_unactive"} onClick={() => goTo("/client-list")}>
            <Circle fontSize="small" color="primary" className="mr-1" />
            <p className="font-weight-bold txt_menu" style={{ color: theme.primary }}>
              Data Klien
            </p>
          </div>
          <div className={path === "/client-test-result" ? "item_active" : "item_unactive"} onClick={() => goTo("/client-test-result")}>
            <Circle fontSize="small" color="primary" className="mr-1" />
            <p className="font-weight-bold txt_menu" style={{ color: theme.primary }}>
              Hasil tes psikologi
            </p>
          </div>
        </Collapse>
      </div>

      {/* menu jadwal */}
      <div className="expandable" style={{ background: menuSchedule.includes(path) || open.jadwal ? theme.lighten : "" }}>
        <div className="d-flex align-center justify-space-between cursor_pointer" onClick={() => handleOpener("jadwal")}>
          <div className="d-flex align-center">
            <CalendarMonth fontSize="small" color={menuSchedule.includes(path) || open.jadwal ? "primary" : "white"} className="mr-3" />
            <p className={menuSchedule.includes(path) || open.jadwal ? "font-weight-bold txt_menu" : "txt_menu"} style={{ color: menuSchedule.includes(path) || open.jadwal ? theme.primary : "#fff" }}>
              Jadwal
            </p>
          </div>
          {open.jadwal ? <KeyboardArrowUp fontSize="small" color="primary" /> : <KeyboardArrowDown fontSize="small" color="white" />}
        </div>

        <Collapse in={open.jadwal} timeout="auto" unmountOnExit className="mt-3">
          <div className={path === "/schedule-list" ? "item_active" : "item_unactive"} onClick={() => goTo("/schedule-list")}>
            <Circle fontSize="small" color="primary" className="mr-1" />
            <p className="font-weight-bold txt_menu" style={{ color: theme.primary }}>
              Jadwal Konsultasi
            </p>
          </div>
          <div className={path === "/schedule-setting" ? "item_active" : "item_unactive"} onClick={() => goTo("/schedule-setting")}>
            <Circle fontSize="small" color="primary" className="mr-1" />
            <p className="font-weight-bold txt_menu" style={{ color: theme.primary }}>
              Setting Jadwal Konsultasi
            </p>
          </div>
        </Collapse>
      </div>

      {/* menu ruang kosultasi */}
      <div className={path === "/counseling" ? "item_active" : "item_unactive"} onClick={() => goTo("/counseling")}>
        <Forum fontSize="small" color={path === "/counseling" ? "primary" : "white"} className="mr-3" />
        <p className={path === "/counseling" ? "font-weight-bold txt_menu" : "txt_menu"} style={{ color: path === "/counseling" ? theme.primary : "#fff" }}>
          Ruang Konsultasi
        </p>
      </div>

      {/* menu aktivitas teman bakat */}
      <div className="expandable" style={{ background: menuActivity.includes(path) || open.activity ? theme.lighten : "" }}>
        <div className="d-flex align-center justify-space-between cursor_pointer" onClick={() => handleOpener("activity")}>
          <div className="d-flex align-center">
            <FormatListBulleted fontSize="small" color={menuActivity.includes(path) || open.activity ? "primary" : "white"} className="mr-3" />
            <p
              className={menuActivity.includes(path) || open.activity ? "font-weight-bold txt_menu" : "txt_menu"}
              style={{ color: menuActivity.includes(path) || open.activity ? theme.primary : "#fff" }}>
              Aktivitas Teman Bakat
            </p>
          </div>
          {open.activity ? <KeyboardArrowUp fontSize="small" color="primary" /> : <KeyboardArrowDown fontSize="small" color="white" />}
        </div>

        <Collapse in={open.activity} timeout="auto" unmountOnExit className="mt-3">
          <div className={path === "/activity/portofolio" ? "item_active" : "item_unactive"} onClick={() => goTo("/activity/portofolio")}>
            <Circle fontSize="small" color="primary" className="mr-1" />
            <p className="font-weight-bold txt_menu" style={{ color: theme.primary }}>
              Portofolio
            </p>
          </div>
          <div className={path === "/activity-feed" ? "item_active" : "item_unactive"} onClick={() => goTo("/activity-feed")}>
            <Circle fontSize="small" color="primary" className="mr-1" />
            <p className="font-weight-bold txt_menu" style={{ color: theme.primary }}>
              Papan Curhat
            </p>
          </div>
        </Collapse>
      </div>

      {/* menu pengaturan akun */}
      <div className="expandable" style={{ background: menuSetting.includes(path) || open.setting ? theme.lighten : "" }}>
        <div className="d-flex align-center justify-space-between cursor_pointer" onClick={() => handleOpener("setting")}>
          <div className="d-flex align-center">
            <ManageAccounts fontSize="small" color={menuSetting.includes(path) || open.setting ? "primary" : "white"} className="mr-3" />
            <p className={menuSetting.includes(path) || open.setting ? "font-weight-bold txt_menu" : "txt_menu"} style={{ color: menuSetting.includes(path) || open.setting ? theme.primary : "#fff" }}>
              Pengaturan Akun
            </p>
          </div>
          {open.setting ? <KeyboardArrowUp fontSize="small" color="primary" /> : <KeyboardArrowDown fontSize="small" color="white" />}
        </div>

        <Collapse in={open.setting} timeout="auto" unmountOnExit className="mt-3">
          <div className={path === "/profile" ? "item_active" : "item_unactive"} onClick={() => goTo("/profile")}>
            <Circle fontSize="small" style={{ color: theme.darken }} className="mr-1" />
            <p className="font-weight-bold txt_menu" style={{ color: theme.darken }}>
              Profile
            </p>
          </div>
          <div className="expandable_2">
            <div className="item_unactive_2" onClick={() => handleOpener("setting_layanan")}>
              <div className="d-flex align-center">
                <Circle fontSize="small" style={{ color: theme.darken }} className="mr-1" />
                <p className="font-weight-bold txt_menu" style={{ color: theme.darken }}>
                  Setting Layanan
                </p>
              </div>
              {open.setting_layanan ? <KeyboardArrowUp fontSize="small" color="primary" /> : <KeyboardArrowDown fontSize="small" color="primary" />}
            </div>
            <Collapse in={open.setting_layanan} timeout="auto" unmountOnExit className="mt-3">
              <div className={path === "/training-activity" ? "item_active" : "item_unactive"} onClick={() => {}}>
                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                <p className="font-weight-bold txt_menu ml-3" style={{ color: theme.darken }}>
                  Psikolog
                </p>
              </div>
              <div className={path === "/coach" ? "item_active" : "item_unactive"} onClick={() => goTo("/coach")}>
                {/* <Circle fontSize='small' color='primary' className='mr-1' /> */}
                <p className="font-weight-bold txt_menu ml-3" style={{ color: theme.darken }}>
                  Coach
                </p>
              </div>
            </Collapse>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

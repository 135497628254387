import { ChevronLeft } from "@mui/icons-material";
import { Avatar, Button, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EmptyData from "../../../../components/base/EmptyData";
import { getApi } from "../../../../store/apis";
import { CHANGE_TITLE } from "../../../../store/theming";

const PsikotesCapturePage = () => {
  // Param
  const params = useParams();
  // Navigation
  const navigate = useNavigate();
  // Location
  const location = useLocation();
  // Dispatch
  const dispatch = useDispatch();
  // State
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    dispatch(CHANGE_TITLE("Psikotes Capture"));
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const dummyAva = () => {
    let ava = require(`../../../../assets/img/ava/ava${Math.floor(Math.random() * 11) + 1}.png`);
    return ava;
  };

  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `admin/psikotes-aktivitas/capture?id=${params.id}&id_pelanggan=${params.idPelanggan}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 400) {
        } else {
        }
        setLoading(false);
      });
  };

  const goBack = () => {
    let pageState = location?.state?.page ?? 1;
    navigate("/psikotes-activity", { state: { page: pageState } });
  };

  return (
    <div>
      <Button variant="contained" style={{ minWidth: "0" }} className="capitalize rounded-lg" onClick={() => goBack()}>
        <ChevronLeft /> Kembali
      </Button>
      <div className="d-flex mt-3">
        <div className="pa-3 pr-50 white-bg rounded-lg border">
          <h4 className="black-text">Pelanggan</h4>
          <Divider />
          <div className="d-flex align-center mt-3">
            <Avatar alt={data?.pelanggan?.nama} src={data?.pelanggan?.foto_link ?? dummyAva()} className="mr-3" />
            <div className="leading-none">
              <span className="small_txt font-weight-bold black-text">{data?.pelanggan?.nama ?? ""}</span>
              <div>
                <small>{data?.pelanggan?.email}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider className="my-4" />
      <div className={data?.files.length === 0 ? "" : "d-flex flex-wrap"}>
        {data?.files.length === 0 ? (
          <EmptyData />
        ) : (
          data?.files.map((item, index) => {
            return (
              <div key={index} className="rounded-lg pa-2 white-bg ma-2">
                <img src={item.image} alt="ImageCapture" style={{ width: "10rem", height: "10rem" }} className="rounded-lg" />
                <div className="mt-1">
                  <small>
                    Waktu: <b>{item.time}</b>
                  </small>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default PsikotesCapturePage;

import React from "react";
import { useSelector } from "react-redux";
import styleInput from "../../assets/css/search.module.css";
/** @jsxImportSource @emotion/react */
import { css as emot } from "@emotion/react";

export const MyInput = ({ value = "", placeholder, type = "text", readOnly = false, handleChange, onClick }) => {
  const theme = useSelector((state) => state.theme.myTheme);

  return (
    <input
      type={type}
      value={value}
      readOnly={readOnly}
      placeholder={placeholder}
      onClick={() => {
        onClick && onClick();
      }}
      onChange={(event) => {
        handleChange && handleChange(event.target.value);
      }}
      css={emot`
                background: ${value ? "#fff" : theme.tertiary};
                outline: 2px solid ${theme.tertiary};
                border-radius: 5px;
                &:focus {
                    background: #fff;
                }
                font-size: small;
            `}
      className={styleInput.input_psy}
    />
  );
};

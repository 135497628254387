/* eslint-disable eqeqeq */
import { Delete, Reorder } from "@mui/icons-material";
import { IconButton, Paper, Grid } from "@mui/material";
import React, { Component } from "react";
import DraggableList from "react-draggable-list";

class template extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { item, itemSelected, dragHandleProps, commonProps } = this.props;
    const { onMouseDown, onTouchStart } = dragHandleProps;
    const { type, theme, onDelete } = commonProps;

    return (
      <Paper
        key={item.id}
        className="rounded-lg mb-2 d-flex"
        style={{ boxSizing: "border-box" }}
      >
        <Grid container spacing={3} className="align-center">
          <Grid item xs={3.5}>
            <div className="d-flex align-center">
              <IconButton
                onClick={() => onDelete(item.id, type)}
                className="ml-4"
                style={{
                  backgroundColor: theme.primary,
                }}
              >
                <Delete sx={{ color: theme.white }} />
              </IconButton>
              <p
                className="small_txt font-weight-700 ml-2"
                style={{ color: theme.primary, textTransform: "capitalize" }}
              >
                {item.type_ref}
              </p>
            </div>
          </Grid>
          <Grid item xs={7} className="align-center justify-star">
            <p className="small_txt font-weight-500">{item.title}</p>
          </Grid>
          <Grid item xs={1.5}>
            <IconButton
              className="d-flex flexy pa-4 grabbable"
              onTouchStart={(e) => {
                e.preventDefault();
                document.body.style.overflow = "hidden";
                onTouchStart(e);
              }}
              onMouseDown={(e) => {
                document.body.style.overflow = "hidden";
                onMouseDown(e);
              }}
              onTouchEnd={(e) => {
                e.target.style.backgroundColor = "black";
                document.body.style.overflow = "visible";
              }}
              onMouseUp={() => {
                document.body.style.overflow = "visible";
              }}
              style={{
                backgroundColor: "#FBF4FE",
                alignItems: "center",
                justifyItems: "center",
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <Reorder sx={{ color: theme.primary }} />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

class DraggablePopupBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useContainer: false,
    };
  }

  onListChange(newList) {
    const { onChange, type } = this.props;
    onChange(newList, type);
  }

  render() {
    const { type, data, theme, onDelete } = this.props;
    return (
      <DraggableList
        itemKey={(data) => data.id}
        template={template}
        commonProps={{
          type,
          theme,
          onDelete,
        }}
        list={data}
        onMoveEnd={(newList) => this.onListChange(newList)}
      />
    );
  }
}

export default DraggablePopupBanner;

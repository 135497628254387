import React from 'react'

const EmptyData = ({ txt = 'No Data Available' }) => {
    const icon = require('../../assets/img/empty.png')
    return (
        <div className='d-flex flex-column align-center justify-center'>
            <img src={icon} height='250px' alt="" />
            <p className="font-weight-bold">
                {txt}
            </p>
        </div>
    )
}

export default EmptyData

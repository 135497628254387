/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../../store/theming";
import { getApi, postApi } from "../../../../store/apis";
import EmptyData from "../../../../components/base/EmptyData";
import { Grid, Paper, Skeleton, Button, Divider } from "@mui/material";
import { Delete as DeleteIcon, ChevronLeft, Circle } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import { DialogDelete } from "../../../../components/base/dialogDelete";
import ButtonEdit from "../../../../components/base/ButtonEdit";
import day from "dayjs";

const BannerDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const theme = useSelector((state) => state.theme.myTheme);
  const [loading, setLoading] = useState(true);
  const [dataSet, setData] = useState([]);
  const [emptyTxt, setEmptyTxt] = useState("");
  const [pocket, setPocket] = useState(null);
  const [d_del, setDel] = useState(false);

  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `admin/advertising/banner/detail?id=${id}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        if (err.status == 400) {
          setEmptyTxt(`No Data Available`);
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setData([]);
      });
  };

  const onChangeStatus = () => {
    const data = {
      path: "admin/advertising/banner/switch",
      body: {
        id: id,
        status: dataSet.status === "show" ? "hide" : "show",
      },
    };

    dispatch(postApi(data))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "Status berhasil diubah", { variant });
        setData({
          ...dataSet,
          status: dataSet.status === "show" ? "hide" : "show",
        });
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
      });
  };

  const onDelete = () => {
    const data = {
      path: `admin/advertising/banner/delete`,
      body: {
        id,
      },
    };

    setPocket(data);
    setDel(true);
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Banner Detail"));
    fetchData();
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          {!loading ? (
            dataSet ? (
              <div>
                <div className="d-flex justify-space-between align-center mb-3">
                  <Button
                    variant="contained"
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg"
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeft /> Kembali
                  </Button>
                  <div className="d-flex align-center">
                    <p className="small_txt font-weight-bold">
                      {dataSet.status === "show" ? "Aktif" : "Non-aktif"}
                    </p>
                    <Button onClick={onChangeStatus}>
                      {dataSet.status === "show" ? (
                        <Icon
                          path={mdiToggleSwitch}
                          size={1}
                          color={theme.primary}
                        />
                      ) : (
                        <Icon path={mdiToggleSwitchOffOutline} size={1} />
                      )}
                    </Button>
                  </div>
                </div>
                <Paper className="pa-5 rounded-lg" elevation={0}>
                  <div className="d-flex justify-space-between mb-2">
                    <div>
                      <h3>{dataSet.title}</h3>
                    </div>
                    <div>
                      <ButtonEdit
                        onClick={() =>
                          navigate(`/banner/detail/${id}/edit`, {
                            state: { ...dataSet },
                          })
                        }
                      />
                      <Button
                        onClick={onDelete}
                        variant="contained"
                        className="rounded-lg capitalize ml-3"
                        disableElevation
                        size="small"
                        style={{
                          background: theme.act_red,
                          height: "max-content",
                        }}
                      >
                        <DeleteIcon
                          className="mr-1"
                          style={{ color: theme.act_lbl_red }}
                        />
                        <p
                          className="font-weight-bold"
                          style={{ color: theme.act_lbl_red }}
                        >
                          {" "}
                          Delete
                        </p>
                      </Button>
                    </div>
                  </div>
                  <Divider />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="d-flex flex-start">
                        <Circle
                          fontSize="10px"
                          className="mt-1 mr-2"
                          color="primary"
                        />
                        <div className="flexy">
                          <p
                            className="small_txt"
                            style={{ color: theme.primary }}
                          >
                            Informasi Banner
                          </p>
                          <div className="mt-2">
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Gambar Banner
                                </p>
                                <img src={dataSet.gambar} height="100px"></img>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Keterangan Banner
                                </p>
                                <p className="small_txt">
                                  {dataSet.deskripsi ?? "-"}
                                </p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Kategori Layanan Banner
                                </p>
                                <p className="small_txt capitalize">
                                  {dataSet.type_ref ?? "-"}
                                </p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Detail Layanan Banner
                                </p>
                                <p className="small_txt">
                                  {dataSet.id_ref ?? "-"}
                                </p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Link Banner
                                </p>
                                <p className="small_txt">
                                  {dataSet.link ?? "-"}
                                </p>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="d-flex flex-start">
                        <Circle
                          fontSize="10px"
                          className="mt-1 mr-2"
                          color="primary"
                        />
                        <div className="flexy">
                          <p
                            className="small_txt"
                            style={{ color: theme.primary }}
                          >
                            Informasi Publish
                          </p>
                          <div className="mt-2">
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Start Publish
                                </p>
                                <p className="small_txt">
                                  {dataSet.start
                                    ? day(dataSet.start)
                                        .locale("id")
                                        .format("dddd, MMMM YYYY")
                                    : "-"}
                                </p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  Repeat
                                </p>
                                <p className="small_txt">
                                  {dataSet.repeat_type
                                    ? `${dataSet.repeat_type} - ${
                                        dataSet.repeat_on
                                          ? JSON.parse(dataSet.repeat_on)
                                          : ""
                                      }`
                                    : "-"}
                                </p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  className="small_txt font-weight-bold"
                                  style={{ color: theme.primary }}
                                >
                                  End Repeat
                                </p>
                                <p className="small_txt">
                                  {dataSet.end
                                    ? day(dataSet.end)
                                        .locale("id")
                                        .format("dddd, MMMM YYYY")
                                    : "-"}
                                </p>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            ) : (
              <EmptyData />
            )
          ) : (
            <Skeleton
              variant="rounded"
              className="rounded-lg"
              style={{ minHeight: "300px", width: "100%" }}
            />
          )}
        </Grid>
      </Grid>

      <div className="py-2">
        <DialogDelete
          pocket={pocket}
          d_del={d_del}
          close={() => setDel(false)}
          refetch={() => navigate(-1)}
        />
      </div>
    </div>
  );
};

export default BannerDetail;

import { Circle, Notifications, Settings, Star } from "@mui/icons-material";
import { Card, Grid, IconButton, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

const SkeletonProfilePage = () => {
  const theme = useSelector((state) => state.theme.myTheme);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card className="pa-3 rounded-lg">
          <div className="d-flex justify-space-between pa-2 rounded-lg" style={{ backgroundColor: theme.tertiary, color: theme.darken }}>
            <span className="font-weight-700">Profile</span>
            <Settings />
          </div>
          <div className="mt-3">
            <div className="d-flex align-center gap-3">
              <div style={{ borderRadius: "50%", backgroundColor: theme.tertiary }} className="pa-1 d-inline-block">
                <Skeleton variant="circular" width={80} height={80} />
              </div>
              <div>
                <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "14rem" }} />
                <div className="d-flex align-center gap-2">
                  <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "10rem" }} />
                  <Circle style={{ width: "5px", height: "5px" }} />
                  <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "5rem" }} />
                </div>
                <div className="d-flex mt-2">
                  <div className="rounded-lg py-1 px-3 d-flex gap-2 align-center" style={{ backgroundColor: theme.act_light_purple }}>
                    <small style={{ color: theme.act_dark_purple }} className="font-weight-700">
                      Psikolog
                    </small>
                    <div style={{ height: "1rem", width: "2px", backgroundColor: theme.act_purple }} className="rounded-lg" />
                    <Star style={{ width: "20px", height: "20px", color: theme.yellow }} />
                    <small style={{ color: theme.act_dark_purple }} className="font-weight-700">
                      4.9
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ border: `1.5px solid ${theme.secondary}` }} className="my-3" />
            <div>
              <Grid container spacing={2} className="mb-2">
                <Grid item xs={4}>
                  <small className="font-weight-500" style={{ color: theme.primary }}>
                    Nomor Anggota
                  </small>
                </Grid>
                <Grid item xs={8}>
                  <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "10rem" }} />
                </Grid>
              </Grid>
              <Grid container spacing={2} className="mb-2">
                <Grid item xs={4}>
                  <small className="font-weight-500" style={{ color: theme.primary }}>
                    Grup
                  </small>
                </Grid>
                <Grid item xs={8}>
                  <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "15rem" }} />
                </Grid>
              </Grid>
              <Grid container spacing={2} className="mb-2">
                <Grid item xs={4}>
                  <small className="font-weight-500" style={{ color: theme.primary }}>
                    SSP
                  </small>
                </Grid>
                <Grid item xs={8}>
                  <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "10rem" }} />
                </Grid>
              </Grid>
              <Grid container spacing={2} className="mb-2">
                <Grid item xs={4}>
                  <small className="font-weight-500" style={{ color: theme.primary }}>
                    SIPP
                  </small>
                </Grid>
                <Grid item xs={8}>
                  <div className="leading-3 pt-1">
                    <div>
                      <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "10rem" }} />
                    </div>
                    <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "15rem" }} />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="mb-2">
                <Grid item xs={4}>
                  <small className="font-weight-500" style={{ color: theme.primary }}>
                    Bidang Keahlian Psikolog
                  </small>
                </Grid>
                <Grid item xs={8}>
                  <ul>
                    <li>
                      <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "13rem" }} />
                    </li>
                    <li>
                      <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "13rem" }} />
                    </li>
                    <li>
                      <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "13rem" }} />
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </div>
          </div>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <div className="d-flex justify-space-between align-center">
          <div>
            <h4 style={{ color: theme.darken }}>Rekap Aktivitas</h4>
          </div>
          <div className="temp_notif mr-3">
            <IconButton>
              <Notifications color="primary" />
            </IconButton>
          </div>
        </div>
        <div className="mt-2 d-flex justify-space-between align-center">
          <div className="leading-3">
            <h5 style={{ color: theme.black }}>Klien</h5>
            <small className="px11_txt">Grafik pembelian layanan</small>
          </div>
          <Skeleton variant="rounded" className="rounded-lg py-4" style={{ width: "10rem" }} />
        </div>
        <div className="mt-2">
          <Card className="pa-2 rounded-lg">
            <Skeleton variant="rounded" className="rounded-lg mt-1 full_width" style={{ height: "10rem" }} />
          </Card>
        </div>
      </Grid>
    </Grid>
  );
};

export default SkeletonProfilePage;

import React, { useState } from 'react'
import day from 'dayjs'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux'
import { Close as CloseIcon, Notes } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Grid } from '@mui/material'
import { postApi } from '../../../store/apis'
import FlatArea from '../../../components/base/flatArea';
import FlatInput from '../../../components/base/flatInput';
import FlatDatePicker from '../../../components/base/flatDatePicker';

const PostPointPackage = ({ dialog = false, close, refetch, pocket, form, setForm }) => {
    const theme = useSelector(state => state.theme.myTheme)
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const handleChange = (prop) => (event) => {
        let value = event.target?.value || event;
        value = value.type ? "" : value;
        setForm({ ...form, [prop]: value })
    }

    const postData = () => {
        setLoading(true)
        let body = { ...form }
        body['exp_promo'] = day(form.exp_promo).format('YYYY-MM-DD')
        body['exp_promo_harga'] = day(form.exp_promo_harga).format('YYYY-MM-DD')
        let data = {
            path: pocket.path,
            body: body
        }
        let variant = ''
        dispatch(postApi(data)).then(data => {
            variant = 'success'
            enqueueSnackbar(data.message, { variant });
            setLoading(false)
            for (let i in body) {
                body[i] = ''
            }
            setForm(body)
            close()
            refetch()
        }).catch(err => {
            console.log(err)
            setLoading(false)
            variant = 'error'
            if (err.error) {
                let msg = Object.values(err.error).map(el => el[0])
                msg.forEach(msg => {
                    enqueueSnackbar(msg, { variant });
                })
            } else {
                enqueueSnackbar(err.message, { variant });
            }
        })
    }
    return (
        <Dialog
            open={dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: { borderRadius: 10 }
            }}
        >
            <DialogTitle id="alert-dialog-title" className='py-3'>
                <div className="d-flex justify-space-between">
                    <h4>{pocket ? pocket.title : ''}</h4>
                    <IconButton onClick={close}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent className='pa-3 styled_scroll'>
                {form ?
                    <div className='borbox pa-3' style={{ minWidth: '550px', }}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>Nominal Poin</p>
                            </Grid>
                            <Grid item xs={8}>
                                <FlatInput value={form.poin} handleChange={handleChange} property='poin' />
                            </Grid>
                            <Grid item xs={4}>
                                <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>Harga Jual</p>
                            </Grid>
                            <Grid item xs={8}>
                                <FlatInput value={form.harga} handleChange={handleChange} property='harga' />
                            </Grid>
                            <Grid item xs={4}>
                                <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>Masa Aktif</p>
                            </Grid>
                            <Grid item xs={8}>
                                <FlatInput value={form.masa_aktif} handleChange={handleChange} property='masa_aktif' />
                            </Grid>
                            <Grid item xs={4}>
                                <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>Keterangan</p>
                            </Grid>
                            <Grid item xs={8}>
                                <FlatArea value={form.deskripsi} icon={<Notes color='primary' />} handleChange={handleChange} property='deskripsi' />
                            </Grid>
                            <Grid item xs={4}>
                                <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>Nominal Promo</p>
                            </Grid>
                            <Grid item xs={8}>
                                <FlatInput value={form.promo_poin} handleChange={handleChange} property='promo_poin' />
                            </Grid>
                            <Grid item xs={4}>
                                <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>Expired Promo</p>
                            </Grid>
                            <Grid item xs={8}>
                                <FlatDatePicker
                                    value={form.exp_promo}
                                    handleChange={handleChange}
                                    property="exp_promo"
                                    placeholder="Tanggal Batas Promo"
                                    min={new Date()}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>Harga Promo</p>
                            </Grid>
                            <Grid item xs={8}>
                                <FlatInput value={form.potongan_harga} handleChange={handleChange} property='potongan_harga' />
                            </Grid>
                            <Grid item xs={4}>
                                <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>Expired Promo</p>
                            </Grid>
                            <Grid item xs={8}>
                                <FlatDatePicker
                                    value={form.exp_promo_harga}
                                    handleChange={handleChange}
                                    property="exp_promo_harga"
                                    placeholder="Tanggal Batas Promo"
                                    min={new Date()}
                                />
                            </Grid>
                        </Grid>
                        <div className="d-flex justify-end mt-3">
                            {!loading ?
                                <Button variant='contained' className='capitalize rounded-lg' disableElevation onClick={() => postData()}><p className="font-weight-bold">Submit</p></Button> :
                                <Button variant='contained' className='capitalize rounded-lg' disableElevation><p className="font-weight-bold">Loading...</p></Button>}
                        </div>
                    </div> : <></>
                }
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(PostPointPackage)

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../store/apis";
import EmptyData from "../../../components/base/EmptyData";
import SkeletonTable from "../../../components/base/SkeletonTable";
import { ArrowDropDown } from "@mui/icons-material";
import SearchDataTable from "../../../components/base/searchDataTable";
import { Grid, Pagination, Paper, Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Avatar } from "@mui/material";

import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
const PsikologLayanan = ({ id }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const [find, setFind] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataSet, setData] = useState([]);
  const [emptyTxt, setEmptyTxt] = useState("");

  const [state, setState] = useState({
    page: 1,
    limit: 10,
    last_page: null,
    total: null,
    gender: "",
    profesi: "",
    tipeSort: null,
    asc: true,
  });

  const fetchData = () => {
    setLoading(true);
    let page = `&page[size]=${state.limit}&page[number]=${state.page}`;
    let data = {
      path: `admin/psikolog-coach/layanan?id=${id}${page}`,
    };

    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data.data);
        setState({ ...state, total: data.total, last_page: data.last_page });
      })
      .catch((err) => {
        if (err.status == 400) {
          if (find != "") {
            setEmptyTxt(`${find} tidak cocok untuk data apapun!`);
          } else {
            setEmptyTxt(`No Data Available`);
          }
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setData([]);
      });
  };
  const handlePage = (event, value) => {
    setState({ ...state, page: value });
  };
  const listLimit = [10, 50, 100];
  const iconPoint = require("../../../assets/img/coin.png");
  useEffect(() => {
    fetchData();
  }, [state.limit, find, state.page]);

  return (
    <div>
      <Paper className="pa-3 rounded-lg mt-3" style={{ width: "80%" }}>
        <Grid container spacing={2} className="mb-5">
          <Grid item xs={12}>
            <div className="d-flex align-center">
              <h3>Layanan Aktif</h3>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="d-flex align-center">
              <p className="small_txt font-weight-bold mr-2">Tampilkan</p>
              <Menu
                menuClassName="my_menu"
                menuButton={
                  <Card className="px-3 py-2 rounded-lg d-flex align-center mr-5 cursor_pointer pb-2">
                    <p className="small_txt font-weight-bold mr-3">{state.limit} Data</p>
                    <ArrowDropDown />
                  </Card>
                }
                transition>
                {listLimit.map((lm, i) => (
                  <MenuItem key={`limit-${i}`} onClick={() => setState({ ...state, limit: lm })}>
                    <p className="font-weight-bold txt_table">{lm} Data</p>
                  </MenuItem>
                ))}
              </Menu>
              <SearchDataTable value={find} setValue={setFind} />
            </div>
          </Grid>
        </Grid>
        {!loading ? (
          !dataSet.length ? (
            <EmptyData />
          ) : (
            <TableContainer component="div">
              <Table aria-label="simple table" style={{ borderBottom: "none" }}>
                <TableHead>
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell
                      style={{
                        minWidth: "50px",
                        background: theme.tertiary,
                        borderRadius: "10px 0 0 10px",
                      }}
                      className="py-3">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Judul Konsultasi
                      </p>
                    </TableCell>

                    <TableCell
                      style={{
                        background: theme.tertiary,
                      }}
                      className="py-3">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Kategori
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        background: theme.tertiary,
                      }}
                      className="py-3">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Durasi
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        background: theme.tertiary,
                        borderRadius: "0 10px 10px 0",
                      }}
                      className="py-3">
                      <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                        Harga
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSet.map((item, i) => (
                    <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>
                        <div className="d-flex align-center">
                          <Avatar alt={item.data_konsultasi?.nama_konsultasi} src={item?.data_konsultasi?.path ?? "Im"} className="mr-3" />
                          <p className="px11_txt">{item.data_konsultasi?.nama_konsultasi ?? "-"}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <p className="px11_txt">{item.data_konsultasi_kategori?.name ?? "-"}</p>
                      </TableCell>
                      <TableCell>
                        <p className="px11_txt">{item.data_konsultasi?.durasi ?? "0"} Menit</p>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex align-center">
                          <img src={iconPoint} alt="poin teman bakat" height="20px" /> <p className="px11_txt ml-1">{item.data_konsultasi?.harga ?? "0"} Poin</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        ) : (
          <SkeletonTable txt={emptyTxt} />
        )}
        {dataSet.length ? (
          <div className="d-flex align-center justify-space-between" style={{ width: "100%" }}>
            <p className="txt_table">
              Menampilkan {state.page} - {state.limit} dari {state.total}{" "}
            </p>
            <div>
              <Pagination sx={{ padding: "0" }} count={state.last_page} page={state.page} shape="rounded" onChange={handlePage} style={{ background: "#fff" }} color="primary" />
            </div>
          </div>
        ) : (
          <></>
        )}
      </Paper>

      <div className="pa-2"></div>
    </div>
  );
};

export default PsikologLayanan;

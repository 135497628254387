/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGE_TITLE } from '../../../../store/theming'
import { getApi, postApi } from '../../../../store/apis'
import EmptyData from '../../../../components/base/EmptyData'
import SkeletonTable from '../../../../components/base/SkeletonTable'
import { ArrowDropDown, ChevronLeft, AddCircle } from '@mui/icons-material'
import SearchDataTable from '../../../../components/base/searchDataTable'
import { Button, Grid, Pagination, Paper, Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Icon from "@mdi/react"
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js"
import day from 'dayjs'

import {
    Menu,
    MenuItem
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import PostKategoriKonseling from './postKategoriKonseling'
import { DialogDelete } from '../../../../components/base/dialogDelete'
import { useSnackbar } from 'notistack'
const KonselingKategori = () => {
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const theme = useSelector(state => state.theme.myTheme)
    const ava = useSelector(state => state.theme.dummy)
    const [find, setFind] = useState('')
    const [loading, setLoading] = useState(true)
    const [dataSet, setData] = useState([])
    const [emptyTxt, setEmptyTxt] = useState('')
    const [pocket, setPocket] = useState(null)
    const [d_del, setDel] = useState(false)
    const [formateDate, setDate] = useState(null)

    const [state, setState] = useState({
        page: 1,
        limit: 10,
        last_page: null,
        total: null,
        gender: '',
        profesi: '',
        tipeSort: null,
        asc: true,
    })
    const [form, setForm] = useState(null)
    const [dialog, setDialog] = useState(false)

    const fetchData = () => {
        setLoading(true)
        let page = `&page[size]=${state.limit}&page[number]=${state.page}`
        let data = {
            path: `admin/konsultasi-kategori?filter[name]=${find}${page}`
        }

        dispatch(getApi(data)).then(data => {
            setLoading(false)
            setData(data.data)
            setState({ ...state, total: data.total, last_page: data.last_page })
        }).catch(err => {
            console.log(err)
            if (err.status == 400) {
                if (find != '') {
                    setEmptyTxt(`${find} tidak cocok untuk data apapun!`)
                } else {
                    setEmptyTxt(`No Data Available`)
                }
            } else {
                setEmptyTxt(`${err.status}: ${err.data.message}`)
            }
            setLoading(false)
            setData([])
        })
    }
    const handlePage = (event, value) => {
        setState({ ...state, page: value })
    }

    const listLimit = [10, 50, 100]

    const createKategori = () => {
        setForm({
            kategori: '',
            keterangan: '',
        })
        setPocket({
            title: 'Tambah Kategori Konseling',
            path: 'admin/konsultasi-kategori/create',
            type: 'create'
        })
        setDialog(true)
    }

    const updateKategori = item => {
        setForm({
            id_kategori: item.konsultasi_kategori_id,
            judul_kategori: item.judul_kategori,
            keterangan: item.keterangan_kategori,
        })

        setPocket({
            title: 'Tambah Kategori Konseling',
            path: 'admin/konsultasi-kategori/update',
            type: 'update'
        })
        setDialog(true)
    }

    const deleteKategori = item => {
        /**
        * ENUM DELETED
        * 0 = delete
        * 1 = restore
        */
        const data = {
            path: "admin/konsultasi-kategori/delete",
            body: {
                konsultasi_kategori_id: item.konsultasi_kategori_id,
                deleted: '0'
            },
        };
        setPocket(data)
        setDel(true)
    }
    const { enqueueSnackbar } = useSnackbar();
    const changeStatus = item => {
        let status = item.status === 1 ? 0 : 1
        const data = {
            path: "admin/konsultasi-kategori/switch",
            body: {
                konsultasi_kategori_id: item.konsultasi_kategori_id,
                status: status,
            },
        };

        dispatch(postApi(data))
            .then((data) => {
                const variant = "success";
                enqueueSnackbar(data.message ?? "Status berhasil diubah", { variant });
                let listData = [...dataSet];
                const index = listData.findIndex((el) => el.konsultasi_kategori_id === item.konsultasi_kategori_id);
                listData[index] = { ...listData[index], status };
                setData(listData);
            })
            .catch((err) => {
                const variant = "error";
                enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
            });
    };
    useEffect(() => {
        dispatch(CHANGE_TITLE('Kategori Konseling'))
        fetchData()
    }, [state.limit, find, state.page])

    return (
        <div>
            <Button variant='contained' style={{ minWidth: '0' }} className='capitalize rounded-lg mb-5' onClick={() => navigate(-1)}><ChevronLeft />  Kembali</Button>
            <Grid container spacing={2} className='mb-5'>
                <Grid item xs={6}>
                    <div className="d-flex align-center">
                        <p className="small_txt font-weight-bold mr-2">Tampilkan</p>
                        <Menu menuClassName='my_menu' menuButton={<Card className='px-3 py-2 rounded-lg d-flex align-center mr-5 cursor_pointer pb-2'><p className="small_txt font-weight-bold mr-3">{state.limit} Data</p><ArrowDropDown /></Card>} transition>
                            {listLimit.map((lm, i) => <MenuItem key={`limit-${i}`} onClick={() => setState({ ...state, limit: lm })}><p className='font-weight-bold txt_table'>{lm} Data</p></MenuItem>)}
                        </Menu>
                        <SearchDataTable value={find} setValue={setFind} />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="d-flex justify-end">
                        <Button className='capitalize rounded-lg' variant='contained' onClick={() => createKategori()}>
                            <AddCircle className='mr-3' />
                            <p className="font-weight-bold">Tambah Kategori</p>
                        </Button>
                    </div>
                </Grid>

            </Grid>
            <Paper className='pa-3 rounded-lg mt-3'>
                {!loading ? !dataSet.length ? <EmptyData /> :
                    <TableContainer component='div'>
                        <Table aria-label="simple table" style={{ borderBottom: 'none' }}>
                            <TableHead >
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell style={{ minWidth: '50px', background: theme.tertiary, borderRadius: '10px 0 0 10px' }} className='py-2'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>ID</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'  >
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Nama Kategori</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '200px', background: theme.tertiary }} className='py-1' >
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Keterangan Kategori</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '100px', background: theme.tertiary }} className='py-1'>
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Show</p>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '150px', background: theme.tertiary, borderRadius: '0 10px 10px 0' }} className='py-1' align="center">
                                        <p className="font-weight-bold" style={{ color: theme.primary }}>Tindakan</p>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataSet.map((item, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{item.konsultasi_kategori_id}</TableCell>
                                        <TableCell>{item.nama_kategori}</TableCell>
                                        <TableCell>{item.keterangan_kategori}</TableCell>
                                        <TableCell>
                                            <Button onClick={() => changeStatus(item)}>
                                                {item.status === 1 ?
                                                    <Icon path={mdiToggleSwitch} size={1} color={theme.primary} /> : <Icon path={mdiToggleSwitchOffOutline} size={1} />
                                                }
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                            <div className="d-flex">
                                                <Button variant='contained' className='rounded-xl capitalize mr-3' onClick={() => updateKategori(item)} disableElevation size="small" style={{ background: theme.act_orange }}>
                                                    <p className='font-weight-bold' style={{ color: theme.act_lbl_orange }}>Edit</p>
                                                </Button>
                                                <Button variant='contained' className='rounded-xl capitalize' onClick={() => deleteKategori(item)} disableElevation size="small" style={{ background: theme.act_red }}>
                                                    <p className='font-weight-bold' style={{ color: theme.act_lbl_red }}>Delete</p>
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <SkeletonTable txt={emptyTxt} />}
                {dataSet.length ? <div className="d-flex align-center justify-space-between" style={{ width: '100%' }}>
                    <p className='txt_table'>Menampilkan {state.page} - {state.limit} dari {state.total} </p>
                    <div>
                        <Pagination sx={{ padding: '0' }} count={state.last_page} page={state.page} shape="rounded" onChange={handlePage} style={{ background: '#fff' }} color="primary" />
                    </div>
                </div> : <></>}
            </Paper>

            <div className="pa-2">
                <PostKategoriKonseling dialog={dialog} close={() => setDialog(false)} refetch={fetchData} form={form} setForm={setForm} pocket={pocket} />
                <DialogDelete pocket={pocket} d_del={d_del} close={() => setDel(false)} refetch={fetchData} />
            </div>
        </div>
    )
}

export default KonselingKategori

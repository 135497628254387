import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tooltipClasses, Tooltip, Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ManageNotePsy } from "../../components/overview/notes/ManageNotePsy";
import { dateFormatter, dateFormatterV2 } from "../../utils/formatter";
import { Circle, KeyboardArrowRight } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { getApi } from "../../store/apis";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import ReactApexChart from "react-apexcharts";
import CardPanduan from "../../components/overview/panduan/CardPanduan";
import EmptyData from "../../components/base/EmptyData";

const OverviewPsy = () => {
  const theme = useSelector((state) => state.theme.myTheme);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    page: 1,
    limit: 5,
  });
  const Poper = styled(({ className, ...props }) => <Tooltip placement="bottom-start" {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      maxWidth: 200,
      border: "2px solid #007585",
      borderRadius: "8px",
    },
  }));

  const [series, setSeries] = useState([]);

  const options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
      hover: {
        size: 9,
      },
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      tickPlacement: "on",
      labels: {
        style: {
          colors: theme.secondary,
        },
      },
    },
    yaxis: {
      opposite: true,
      labels: {
        style: {
          colors: theme.secondary,
        },
      },
    },
    colors: [theme.act_blue, theme.yellow],
    grid: {
      borderColor: theme.secondary,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  useEffect(() => {
    getJadwal();
    getGrafic();
    getCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGrafic = () => {
    let data = {
      path: `konselor/profile-konselor/grafik?type=day`,
    };
    dispatch(getApi(data))
      .then((data) => {
        (data === null || data === undefined) && (data = []);
        formattingGrafic(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formattingGrafic = (data) => {
    setSeries(data);
  };

  const getJadwal = () => {
    let page = `&page[size]=${data.limit}&page[number]=${data.page}`;
    let dataBatch = {
      path: `konselor/jadwal-konsultasi-klien?cfilter[date]=${dateFormatter(new Date())}${page}`,
    };
    dispatch(getApi(dataBatch))
      .then((data) => {
        setData({ jadwal: data.data, from: data.from, to: data.to, total: data.total, last_page: data.last_page });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCount = () => {
    let dataBatch = {
      path: `konselor/profile-konselor/count`,
    };
    dispatch(getApi(dataBatch))
      .then((res) => {
        (res === null || res === undefined) && (res = []);
        setData({ ...data, ...res });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderTable = () => {
    return (
      <TableContainer component="div" className="my-5">
        <Table aria-label="simple table" style={{ borderBottom: "none" }}>
          <TableHead>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell
                style={{
                  minWidth: "10px",
                  background: theme.tertiary,
                  borderRadius: "10px 0 0 10px",
                }}
                className="py-3"
                align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Nama Klien
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "200px", background: theme.tertiary }} className="py-3" align="left">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Waktu Konsultasi
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="left">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Layanan
                </p>
              </TableCell>
              <TableCell style={{ minWidth: "100px", background: theme.tertiary }} className="py-3" align="center">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Status
                </p>
              </TableCell>
              <TableCell
                style={{
                  minWidth: "100px",
                  background: theme.tertiary,
                  borderRadius: "0 10px 10px 0",
                }}
                className="py-3">
                <p className="font-weight-bold px12_txt white-space-pre" style={{ color: theme.darken }}>
                  Tindakan
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="pb-4">
            {data.jadwal?.map((item, index) => {
              return (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell className="py-1" align="center">
                    {item.data_pelanggan?.nama}
                  </TableCell>
                  <TableCell className="py-1">{item.wkt_konsultasi}</TableCell>
                  <TableCell className="py-1">{item.layanan}</TableCell>
                  <TableCell className="py-1" align="center">
                    <p
                      className="font-weight-700"
                      style={{ color: item.status === "Aktif" ? "#00786A" : item.status === "Selesai" ? "#6D0BCF" : item.status === "Kadaluwarsa" ? "#B80222" : "#0B81C2" }}>
                      {item.status}
                    </p>
                  </TableCell>
                  <TableCell className="py-1" align="center">
                    <div className="d-flex align-center">
                      <Button
                        variant="contained"
                        className="rounded-xl capitalize mr-3"
                        disableElevation
                        size="small"
                        style={{ background: theme.act_pink }}
                        onClick={() => navigation(`/profile-client/${item.id_pelanggan}`)}>
                        <p className="font-weight-bold" style={{ color: theme.act_lbl_pink }}>
                          Profile
                        </p>
                      </Button>
                      {item.status === "Aktif" ? (
                        <Button variant="contained" className="rounded-xl capitalize mr-3" disableElevation size="small" style={{ background: theme.act_green }}>
                          <p className="font-weight-bold" style={{ color: theme.act_lbl_green }}>
                            Mulai
                          </p>
                        </Button>
                      ) : (
                        <></>
                      )}
                      {item.status === "Akan Datang" && item.hours_remaining >= 3 ? (
                        <Button variant="contained" className="rounded-xl capitalize mr-3" disableElevation size="small" style={{ background: theme.act_orange }} onClick={() => {}}>
                          <p className="font-weight-bold" style={{ color: theme.act_lbl_orange }}>
                            Resch.
                          </p>
                        </Button>
                      ) : (
                        <></>
                      )}
                      {item.status === "Akan Datang" && item.hours_remaining >= 3 ? (
                        <Button variant="contained" className="rounded-xl capitalize mr-3" disableElevation size="small" style={{ background: theme.act_red }}>
                          <p className="font-weight-bold" style={{ color: theme.act_lbl_red }}>
                            Cancel
                          </p>
                        </Button>
                      ) : (
                        <></>
                      )}
                      <Poper
                        title={
                          <React.Fragment>
                            <div className="pa-2">
                              <p className="font-weight-bold mb-1" style={{ color: theme.primary, fontSize: "15px" }}>
                                Topik Konsultasi
                              </p>
                              <p style={{ color: "#000", fontSize: "13px" }}>{item.keterangan}</p>
                            </div>
                          </React.Fragment>
                        }>
                        <Button variant="contained" className="rounded-xl capitalize mr-3" disableElevation size="small" style={{ background: theme.act_blue }}>
                          <p className="font-weight-bold" style={{ color: theme.act_lbl_blue }}>
                            Topik
                          </p>
                        </Button>
                      </Poper>

                      {item.status === "Selesai" ? (
                        <Button variant="contained" onClick={() => {}} className="rounded-xl capitalize mr-3" disableElevation size="small" style={{ background: theme.act_purple }}>
                          <p className="font-weight-bold" style={{ color: theme.act_lbl_purple }}>
                            Riwayat
                          </p>
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div>
      <Paper className="pa-3 rounded-lg mb-3" elevation={0}>
        <div className="d-flex justify-space-between">
          <div className="leading-5">
            <h3>Aktivitas Hari ini</h3>
            <small style={{ color: theme.primary }}>{dateFormatterV2(new Date())}</small>
          </div>
          <div style={{ color: theme.primary }} className="d-flex gap-2 align-center cursor_pointer" onClick={() => navigation("/schedule-list")}>
            <small className="font-weight-700">Semua Jadwal</small>
            <KeyboardArrowRight style={{ color: theme.primary, backgroundColor: theme.secondary, padding: "1px", borderRadius: "50%" }} />
          </div>
        </div>
        {data.jadwal !== undefined && data.jadwal?.length > 0 && renderTable()}
        {(data.jadwal === undefined || data.jadwal?.length === 0) && <EmptyData />}
      </Paper>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Paper className="pa-3 rounded-lg mb-3" elevation={0}>
            <h3>Rekap Aktivitas</h3>
            <div className="d-flex align-center gap-2">
              <Circle fontSize="small" color="primary" className="mr-1" />
              <span className="font-weight-700" style={{ color: theme.primary }}>
                Konsultasi Minggu Ini
              </span>
            </div>
            <div className="mt-3">
              <Card className="pa-2 rounded-lg" style={{ background: theme.primary }}>
                <ReactApexChart options={options} series={series} type="area" height={200} />
              </Card>
            </div>
            <div className="mt-4 d-flex flex-wrap gap-3">
              <div className="pa-3 rounded-lg" style={{ border: `1px solid ${theme.secondary}` }}>
                <small style={{ color: theme.black }} className="px11_txt">
                  Rata-rata
                </small>
                <div>
                  <h5 style={{ color: theme.black }}>Waktu Aktif</h5>
                </div>
                <div className="d-flex mt-2 gap-2 align-center">
                  <div style={{ width: "3rem", height: "3rem", backgroundColor: theme.act_blue, borderRadius: "50%" }} />
                  <div className="text-center">
                    <h3 style={{ color: theme.darken }}>{data.jumlah_waktu_konsultasi_aktif}</h3>
                    <small className="font-weight-500">Jam/hari</small>
                  </div>
                </div>
              </div>
              <div className="pa-3 rounded-lg" style={{ border: `1px solid ${theme.secondary}` }}>
                <small style={{ color: theme.black }} className="px11_txt">
                  Rata-rata
                </small>
                <div>
                  <h5 style={{ color: theme.black }}>Waktu Konsultasi</h5>
                </div>
                <div className="d-flex mt-2 gap-2 align-center">
                  <div style={{ width: "3rem", height: "3rem", backgroundColor: theme.yellow, borderRadius: "50%" }} />
                  <div className="text-center">
                    <h3 style={{ color: theme.darken }}>{data.jumlah_waktu_konsultasi}</h3>
                    <small className="font-weight-500">Jam/hari</small>
                  </div>
                </div>
              </div>
              <div className="pa-3 rounded-lg" style={{ border: `1px solid ${theme.secondary}` }}>
                <div style={{ color: theme.black }} className="px11_txt pt-3 pb-2">
                  <div style={{ width: "2rem", height: "3px", backgroundColor: theme.primary }} className="rounded-lg" />
                </div>
                <div>
                  <h5 style={{ color: theme.black }}>Klien</h5>
                </div>
                <div className="d-flex mt-2 gap-2 align-center">
                  <div style={{ width: "2.5rem", height: "2.5rem", backgroundColor: theme.act_purple, borderRadius: "50%" }} />
                  <div className="text-center">
                    <h3 style={{ color: theme.darken }}>{data.jumlah_klien > 16 ? "+16" : data.jumlah_klien}</h3>
                    <small className="font-weight-500">Total {data.jumlah_klien}</small>
                  </div>
                </div>
              </div>
              <div className="pa-3 rounded-lg" style={{ border: `1px solid ${theme.secondary}` }}>
                <div style={{ color: theme.black }} className="px11_txt pt-3 pb-2">
                  <div style={{ width: "2rem", height: "3px", backgroundColor: theme.primary }} className="rounded-lg" />
                </div>
                <div>
                  <h5 style={{ color: theme.black }}>Layanan</h5>
                </div>
                <div className="d-flex mt-2 gap-2 align-center">
                  <div style={{ width: "2.5rem", height: "2.5rem", backgroundColor: theme.act_purple, borderRadius: "50%" }} />
                  <div className="text-center">
                    <h3 style={{ color: theme.darken }}>{data.jumlah_layanan}</h3>
                    <small className="font-weight-500">Layanan</small>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <CardPanduan />
          <div className="mt-3">
            <ManageNotePsy theme={theme} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OverviewPsy;

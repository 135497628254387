/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Grid, Paper, Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import EmptyData from "../../../components/base/EmptyData";

import day from "dayjs";

const LogActivity = ({ logUser, logPsikolog, onFetchUser, onFetchPsikolog }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const [pageUser, setPageUser] = useState(1);
  const [pagePsikolog, setPagePsikolog] = useState(1);
  const [limit] = useState(10);

  const onChange = (value, type) => {
    if (type === "user") {
      setPageUser(value);
      onFetchUser(limit, value);
    }
    if (type === "psikolog") {
      setPagePsikolog(value);
      onFetchPsikolog(limit, value);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Paper className="pa-3 rounded-lg" elevation={0}>
          <Grid container spacing={2} className="mb-5">
            <Grid item xs={12}>
              <div className="d-flex align-center mb-4">
                <h3>Log Activity User</h3>
              </div>
              {logUser?.data?.length && logUser?.data?.length > 0 ? (
                logUser.data.map((item, i) => {
                  const dateBefore = day(i !== 0 ? logUser.data[i - 1].created_at : item.created_at)
                    .locale("id")
                    .format("YYYY-MM-DD");
                  const date = day(item.created_at).locale("id").format("YYYY-MM-DD");
                  return (
                    <div key={i}>
                      {i !== 0 && date !== dateBefore ? (
                        <div className="d-flex align-center mb-3">
                          <div className="pr-3">
                            <p className="font-weight-700 mb-0" style={{ color: theme.primary }}>
                              {day(item.created_at).locale("id").format("dddd, D MMMM YYYY")}
                            </p>
                          </div>
                          <div className="line_log" style={{ background: theme.lighten }}></div>
                        </div>
                      ) : i === 0 ? (
                        <div className="d-flex align-center mb-3">
                          <div className="pr-3">
                            <p className="font-weight-700 mb-0" style={{ color: theme.primary }}>
                              {day(item.created_at).locale("id").format("dddd, D MMMM YYYY")}
                            </p>
                          </div>
                          <div className="line_log" style={{ background: theme.lighten }}></div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="d-flex">
                        <div className="timeline mr-2">
                          <div className="connector" style={{ background: theme.secondary }}></div>
                          <div className="dot mb-3" style={{ background: theme.secondary }}>
                            <div className="inner_dot" style={{ background: theme.tertiary }}></div>
                          </div>
                        </div>
                        <div className="d-flex align-center mr-3 mb-3">
                          <p
                            className="small_txt"
                            style={{
                              fontSize: "11px",
                            }}>
                            {day(item.created_at).locale("id").format("HH:mm")}
                            <br />
                            {day(item.created_at).locale("id").format("DD/MM/YY")}
                          </p>
                        </div>
                        <Paper className="pa-3 flexy rounded-lg mb-3" elevation={0}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} className="d-flex align-center">
                              <div className="txt_table">{item.activity}</div>
                            </Grid>
                          </Grid>
                        </Paper>
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyData />
              )}
              {logUser?.data?.length && logUser?.data?.length > 0 && (
                <div className="d-flex align-center justify-space-between" style={{ width: "100%" }}>
                  <p className="txt_table">
                    Menampilkan {logUser.from} - {logUser.to} dari {logUser.total}
                  </p>
                  <div>
                    <Pagination
                      sx={{ padding: "0" }}
                      count={logUser.last_page}
                      page={pageUser}
                      shape="rounded"
                      onChange={(event, value) => onChange(value, "user")}
                      style={{ background: "#fff" }}
                      color="primary"
                    />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className="pa-3 rounded-lg" elevation={0}>
          <Grid container spacing={2} className="mb-5">
            <Grid item xs={12}>
              <div className="d-flex align-center mb-4">
                <h3>Log Activity Psikolog</h3>
              </div>
              {logPsikolog?.data?.length > 0 ? (
                logPsikolog.data.map((item, i) => {
                  const dateBefore = day(i !== 0 ? logPsikolog.data[i - 1].created_at : item.created_at)
                    .locale("id")
                    .format("YYYY-MM-DD");
                  const date = day(item.created_at).locale("id").format("YYYY-MM-DD");
                  return (
                    <div key={i}>
                      {i !== 0 && date !== dateBefore ? (
                        <div className="d-flex align-center mb-3">
                          <div className="pr-3">
                            <p className="font-weight-700 mb-0" style={{ color: theme.primary }}>
                              {day(item.created_at).locale("id").format("dddd, D MMMM YYYY")}
                            </p>
                          </div>
                          <div className="line_log" style={{ background: theme.lighten }}></div>
                        </div>
                      ) : i === 0 ? (
                        <div className="d-flex align-center mb-3">
                          <div className="pr-3">
                            <p className="font-weight-700 mb-0" style={{ color: theme.primary }}>
                              {day(item.created_at).locale("id").format("dddd, D MMMM YYYY")}
                            </p>
                          </div>
                          <div className="line_log" style={{ background: theme.lighten }}></div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="d-flex">
                        <div className="timeline mr-2">
                          <div className="connector" style={{ background: theme.secondary }}></div>
                          <div className="dot mb-3" style={{ background: theme.secondary }}>
                            <div className="inner_dot" style={{ background: theme.tertiary }}></div>
                          </div>
                        </div>
                        <div className="d-flex align-center mr-3 mb-3">
                          <p
                            className="small_txt"
                            style={{
                              fontSize: "11px",
                            }}>
                            {day(item.created_at).locale("id").format("HH:mm")}
                            <br />
                            {day(item.created_at).locale("id").format("DD/MM/YY")}
                          </p>
                        </div>
                        <Paper className="pa-3 flexy rounded-lg mb-3" elevation={0}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} className="d-flex align-center">
                              <div className="txt_table">{item.activity}</div>
                            </Grid>
                          </Grid>
                        </Paper>
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyData />
              )}
              {logPsikolog?.data?.length > 0 && (
                <div className="d-flex align-center justify-space-between" style={{ width: "100%" }}>
                  <p className="txt_table">
                    Menampilkan {logPsikolog.from} - {logPsikolog.to} dari {logPsikolog.total}
                  </p>
                  <div>
                    <Pagination
                      sx={{ padding: "0" }}
                      count={logPsikolog.last_page}
                      page={pagePsikolog}
                      shape="rounded"
                      onChange={(event, value) => onChange(value, "psikolog")}
                      style={{ background: "#fff" }}
                      color="primary"
                    />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LogActivity;

/* eslint-disable react-hooks/exhaustive-deps */
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { ChevronLeft, Edit as EditIcon } from "@mui/icons-material";
import { Button, Grid, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getApi } from "../../../store/apis";
import { CHANGE_TITLE } from "../../../store/theming";

const PsikotesDetail = () => {
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.myTheme);
  const [dataSet, setData] = useState(null);
  const dummy = require("../../../assets/img/empty.png");
  const [setLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `admin/psikotes-setting/detail?id=${id}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        setLoading(false);
        setData([]);
      });
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Detail Psikotes"));
    fetchData();
  }, []);
  return (
    <div>
      <Button variant="contained" style={{ minWidth: "0" }} className="capitalize rounded-lg" onClick={() => navigate(-1)}>
        <ChevronLeft /> Kembali
      </Button>
      {dataSet ? (
        <div>
          <div className="d-flex justify-end">
            <div className="d-flex align-center">
              <p className="small_txt font-weight-bold">{dataSet.status === 1 ? "Tamplikan Psikotes" : "Sembunyikan Psikotes"}</p>
              <Button>{dataSet.status === 1 ? <Icon path={mdiToggleSwitch} size={1} color={theme.primary} /> : <Icon path={mdiToggleSwitchOffOutline} size={1} />}</Button>
            </div>
          </div>
          <Paper className="pa-5 rounded-lg" elevation={0}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <img src={dummy} height="150px" alt="cover-psikotes" />
              </Grid>
              <Grid item xs={7}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p className="font-weight-bold" style={{ color: theme.primary, fontSize: "20px" }}>
                      {dataSet.nama_psikotes}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                      Keterangan Psikotes
                    </p>
                    <p className="small_txt">{dataSet.keterangan}</p>
                  </Grid>
                  <Grid item xs={4}>
                    <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                      Harga Normal
                    </p>
                    <p className="small_txt">{dataSet.harga}</p>
                  </Grid>
                  <Grid item xs={8}>
                    <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                      Harga Promo
                    </p>
                    <p className="small_txt">{dataSet.potongan_harga ?? "tidak ada promo"}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                      Kategori Psikotes
                    </p>
                    <p className="small_txt">{dataSet.data_psikotes_kategori?.kategori ?? "-"}</p>
                  </Grid>
                  <Grid item xs={4}>
                    <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                      Alat Tes
                    </p>
                    {dataSet.data_alat_psikotes.length ? (
                      <ul>
                        {dataSet.data_alat_psikotes.map((at, i) => (
                          <li key={`at_${i}`} className="small_txt">
                            {at.data_alat_test.nama_alat}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="small_txt">Tidak Ada data</p>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                      Konsultasi
                    </p>
                    {dataSet.data_konsultasi_psikotes.length ? (
                      <ul>
                        {dataSet.data_konsultasi_psikotes.map((at, i) => (
                          <li key={`konsultasi_${i}`} className="small_txt">
                            {at.data_konsultasi.nama_konsultasi}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="small_txt">Tidak Ada data</p>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                      Angket Terbuka
                    </p>
                    {dataSet.data_angket_terbuka.length ? (
                      <ul>
                        {dataSet.data_angket_terbuka.map((at, i) => (
                          <li key={`at_${i}`} className="small_txt">
                            {at.data_angket_terbuka.pertanyaan}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="small_txt">Tidak Ada data</p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <div className="d-flex justify-end">
                  <Button variant="contained" style={{ minWidth: "0" }} className="capitalize rounded-lg">
                    <EditIcon /> Ubah Psikotes
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PsikotesDetail;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../store/theming";
import { getApi, postApi } from "../../../store/apis";
import EmptyData from "../../../components/base/EmptyData";
import { Grid, Paper, Skeleton, Button, Divider, Avatar } from "@mui/material";
import { Delete as DeleteIcon, ChevronLeft } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import PsikologLayanan from "./PsikologLayanan";
import PsikologActivity from "./psikologActivity";
import { useSnackbar } from "notistack";
import day from "dayjs";
import ButtonEdit from "../../../components/base/ButtonEdit";
import { DialogDelete } from "../../../components/base/dialogDelete";

const PsikologDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const theme = useSelector((state) => state.theme.myTheme);
  const [find] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataSet, setData] = useState([]);
  const [setEmptyTxt] = useState("");
  const [tab, setTab] = useState(1);
  const [pocket, setPocket] = useState(null);
  const [d_del, setDel] = useState(false);
  const [state] = useState({
    page: 1,
    limit: 10,
    last_page: null,
    total: null,
    find: "",
    gender: "",
    profesi: "",
    tipeSort: null,
    asc: true,
  });
  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `admin/psikolog-coach/detail?id=${id}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        if (err.status === 400) {
          setEmptyTxt(`No Data Available`);
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setData([]);
      });
  };

  const onChangeStatus = () => {
    const data = {
      path: "admin/psikolog-coach/switch",
      body: {
        user_id: dataSet.id_user,
        status: dataSet.status === 1 ? 0 : 1,
      },
    };

    dispatch(postApi(data))
      .then((data) => {
        const variant = "success";
        enqueueSnackbar(data.message ?? "Status berhasil diubah", { variant });
        setData({ ...dataSet, status: dataSet.status === 1 ? 0 : 1 });
      })
      .catch((err) => {
        const variant = "error";
        enqueueSnackbar(err.message ?? "Status gagal diubah", { variant });
      });
  };

  const onDeleteAccount = () => {
    const data = {
      path: "admin/psikolog-coach/delete",
      body: {
        id: dataSet.id,
      },
    };

    setPocket(data);
    setDel(true);
  };

  useEffect(() => {
    dispatch(CHANGE_TITLE("Psikolog & Coach Detail"));
    fetchData();
  }, [state.limit, find, state.page]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!loading ? (
            dataSet ? (
              <div>
                <div className="d-flex justify-space-between align-center mb-3">
                  <Button variant="contained" style={{ minWidth: "0" }} className="capitalize rounded-lg" onClick={() => navigate(-1)}>
                    <ChevronLeft /> Kembali
                  </Button>
                  <div className="d-flex align-center">
                    <p className="small_txt font-weight-bold">{dataSet.status === 1 ? "Non-Aktifkan Akun" : "Aktifkan Akun"} </p>
                    <div onClick={onChangeStatus} className="d-flex align-center justify-center ml-3">
                      <Icon path={dataSet.status === 1 ? mdiToggleSwitch : mdiToggleSwitchOffOutline} size={1.8} color={theme.primary} />
                    </div>
                  </div>
                </div>
                <Paper className="pa-3 rounded-lg" elevation={0}>
                  <div className="d-flex align-center justify-space-between mb-2">
                    <div className="d-flex flex-row align-center">
                      <Avatar alt={dataSet.foto} src={dataSet.foto_link ?? "Im"} sizes="20px" className="mr-3" />
                      <div className="ml-3">
                        <h3>{dataSet.nama}</h3>
                        <div className="d-flex align-center">
                          <p className="small_txt mr-1">{dataSet.username}</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ButtonEdit
                        onClick={() =>
                          navigate(`/counselor/detail/${id}/edit`, {
                            state: { ...dataSet },
                          })
                        }
                      />
                      <Button
                        onClick={onDeleteAccount}
                        variant="contained"
                        className="rounded-lg capitalize ml-3"
                        disableElevation
                        size="small"
                        style={{
                          background: theme.act_red,
                          height: "max-content",
                        }}>
                        <DeleteIcon className="mr-1" style={{ color: theme.act_lbl_red }} />
                        <p className="font-weight-bold" style={{ color: theme.act_lbl_red }}>
                          Delete
                        </p>
                      </Button>
                    </div>
                  </div>
                  <Divider />
                  <div className="mt-5">
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <div className="d-flex flex-start">
                          <div style={{ width: 7, height: 7, borderRadius: 50, backgroundColor: theme.primary, marginTop: 10, marginRight: 10 }} />
                          <div className="flexy">
                            <p className="small_txt mt-1" style={{ color: theme.primary }}>
                              Informasi Konselor
                            </p>
                            <div className="mt-2">
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Email
                                  </p>
                                  <p className="small_txt">{dataSet.email}</p>
                                </Grid>
                                <Grid item xs={12}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Nomor HP
                                  </p>
                                  <p className="small_txt">{dataSet.no_telp}</p>
                                </Grid>
                                <Grid item xs={12}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Tanggal Lahir
                                  </p>
                                  <p className="small_txt">{dataSet.tgl_lahir ? day(dataSet.tgl_lahir).format("dddd, D MMMM YYYY") : "-"}</p>
                                </Grid>
                                <Grid item xs={12}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Jenis Kelamin
                                  </p>
                                  <p className="small_txt">{dataSet.jenis_kelamin ? dataSet.jenis_kelamin : ""}</p>
                                </Grid>
                                <Grid item xs={12}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Nomor Identitas
                                  </p>
                                  <p className="small_txt">{dataSet.no_identitas}</p>
                                </Grid>
                                <Grid item xs={12}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Bank Account
                                  </p>
                                  <p className="small_txt">{dataSet.no_rek}</p>
                                  <p className="small_txt">{dataSet.nama_bank}</p>
                                  <p className="small_txt">{dataSet.nama_pemilik_rekening}</p>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div className="d-flex flex-start">
                          <div style={{ width: 7, height: 7, borderRadius: 50, backgroundColor: theme.primary, marginTop: 10, marginRight: 10 }} />
                          <div className="flexy">
                            <p className="small_txt mt-1" style={{ color: theme.primary }}>
                              Detail Konselor
                            </p>
                            <div className="mt-3">
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Nomor Anggota
                                  </p>
                                  <p className="small_txt black-text">{dataSet.no_anggota_himpsi}</p>
                                </Grid>
                                <Grid item xs={6}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Grup/Organisasi
                                  </p>
                                  <p className="small_txt black-text">{dataSet.group}</p>
                                </Grid>
                                <Grid item xs={6}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Nomor SSP
                                  </p>
                                  <p className="small_txt black-text">{dataSet.ssp}</p>
                                </Grid>
                                <Grid item xs={6}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Nomor SIPP
                                  </p>
                                  <p className="small_txt black-text">{dataSet.sipp}</p>
                                  <p style={{ fontSize: "12px" }} className="grey-text">
                                    berlaku sampai {dataSet.exp_sipp}
                                  </p>
                                </Grid>
                                <Grid item xs={6}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Bidang Keahlian Psikolog
                                  </p>
                                  {dataSet.expertise.map((el) => {
                                    return <p className="small_txt">&#x2022; {el.expertise}</p>;
                                  })}
                                </Grid>
                                <Grid item xs={6}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Bidang Keahlian Coach
                                  </p>
                                  {dataSet.expertise_coach.map((el) => {
                                    return <p className="small_txt">&#x2022; {el.expertise}</p>;
                                  })}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="d-flex flex-start">
                          <div style={{ width: 7, height: 7, borderRadius: 50, backgroundColor: theme.primary, marginTop: 10, marginRight: 10 }} />
                          <div className="flexy">
                            <p className="small_txt mt-1" style={{ color: theme.primary }}>
                              Informasi Akun
                            </p>
                            <div className="mt-3">
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    User ID
                                  </p>
                                  <p className="small_txt">{dataSet.id_user}</p>
                                </Grid>
                                <Grid item xs={6}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Tanggal Registrasi
                                  </p>
                                  <p className="small_txt">{day(dataSet.created_at).format("DD/MM/YYYY | HH:mm")}</p>
                                </Grid>
                                <Grid item xs={12}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Jenis/Kategori
                                  </p>
                                  <p className="small_txt">{dataSet.jenis}</p>
                                </Grid>
                                <Grid item xs={12}>
                                  <p className="small_txt font-weight-bold" style={{ color: theme.primary }}>
                                    Access Permission
                                  </p>
                                  <p className="small_txt">
                                    {dataSet.data_user.permission.map((value) => {
                                      return <p className="small_txt">&#x2022; {value}</p>;
                                    })}
                                  </p>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </div>
            ) : (
              <EmptyData />
            )
          ) : (
            <Skeleton variant="rounded" className="rounded-lg" style={{ minHeight: "300px", width: "100%" }} />
          )}
        </Grid>
      </Grid>

      {/* tabulaltion start here */}
      <div className="d-flex align-center mt-5">
        <Button className="capitalize mr-3 rounded-lg" onClick={() => setTab(1)} color={tab === 1 ? "primary" : "white"} disableElevation variant="contained">
          <p className="font-weight-bold">Layanan Aktif</p>
        </Button>
        <Button className="capitalize rounded-lg font-weight-bold" onClick={() => setTab(2)} color={tab === 2 ? "primary" : "white"} disableElevation variant="contained">
          <p className="font-weight-bold">Log Activity</p>
        </Button>
      </div>
      {tab === 1 ? <PsikologLayanan id={id} /> : <PsikologActivity id={id} />}
      <div className="py-2">
        <DialogDelete pocket={pocket} d_del={d_del} close={() => setDel(false)} refetch={() => navigate("/counselor-list")} />
      </div>
    </div>
  );
};

export default PsikologDetail;

/* eslint-disable eqeqeq */
import { useEffect, useState, createRef } from "react";
import dayjs from "dayjs";
import locale from "dayjs/locale/id";
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";
import "../../../../assets/css/calendar.css";
import { Card, Button, Paper } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import css from "../../../../assets/css/calendarAdmin.module.css";
import CardDate from "./cardDate";
import { MenuItem, ControlledMenu, MenuGroup, MenuHeader, MenuDivider } from "@szhsin/react-menu";
import LinesEllipsis from "react-lines-ellipsis";

import "@szhsin/react-menu/dist/index.css";
import DetailJadwal from "./detailJadwal";
const MonthKonselingAdmin = ({ dataSet, currentMonth, setCurrentMonth, setMonth, setYear, theme }) => {
  const now = dayjs().locale({
    ...locale,
  });

  dayjs.extend(weekdayPlugin);
  dayjs.extend(objectPlugin);
  dayjs.extend(isTodayPlugin);

  const [arrayOfDays, setArrayOfDays] = useState([]);

  const nextMonth = () => {
    const plus = currentMonth.add(1, "month");
    let y = dayjs(plus).format("YYYY");
    let m = dayjs(plus).format("MM");
    setMonth(m);
    setYear(y);

    setCurrentMonth(plus);
  };

  const prevMonth = () => {
    const minus = currentMonth.subtract(1, "month");
    let y = dayjs(minus).format("YYYY");
    let m = dayjs(minus).format("MM");
    setMonth(m);
    setYear(y);
    setCurrentMonth(minus);
  };

  const renderHeader = () => {
    const dateFormat = "MMMM YYYY";
    return (
      <div>
        <div className="d-flex align-center justify-space-between mb-3">
          <h2>{currentMonth.format(dateFormat)}</h2>
          <div className="d-flex align-center">
            <Button className="capitalize mr-2" variant="outlined" style={{ minWidth: "10px" }} onClick={() => prevMonth()}>
              <ChevronLeft color="primary" />
            </Button>
            <Button className="capitalize" variant="outlined" style={{ minWidth: "10px" }} onClick={() => nextMonth()}>
              <ChevronRight color="primary" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className={`${css.col} ${css["col-center"]} font-weight-bold white-text`} key={i}>
          {now.weekday(i).format("dddd")}
        </div>
      );
    }
    return <div className={`${css.days} ${css.row} ${css.card_days} py-3`}>{days}</div>;
  };

  const getAllDays = () => {
    let currentDate = currentMonth.startOf("month").weekday(0);
    const nextMonth = currentMonth.add(1, "month").month();
    let allDates = [];
    let weekDates = [];
    let weekCounter = 1;
    while (currentDate.weekday(0).toObject().months !== nextMonth) {
      let formated = formateDateObject(currentDate);
      formated["ref"] = createRef();
      weekDates.push(formated);
      if (weekCounter === 7) {
        // masukin data dari be ke kalender
        for (let i in dataSet) {
          let index = null;
          index = weekDates.findIndex((wd) => wd.date == i);
          if (index > -1) {
            weekDates[index]["schedule"] = dataSet[i];
          }
        }
        allDates.push({ dates: weekDates });
        weekDates = [];
        weekCounter = 0;
      }
      weekCounter++;
      currentDate = currentDate.add(1, "day");
    }
    setArrayOfDays(allDates);
  };
  const [open, setOpen] = useState(false);
  const [currentRef, setRef] = useState(null);
  const [pickedDate, setPicked] = useState(null);
  const pickDay = (item, isCurrent) => {
    const data = {
      title: dayjs(item.date).locale("id").format("dddd, DD MMMM YYYY"),
      schedule: item.schedule ? item.schedule : "",
    };
    setPicked(data);
    setRef(item.ref);
    setOpen(true);
  };
  const [pocket, setPocket] = useState(null);
  const [dialog, setDialog] = useState(false);

  const pickSchedule = (item) => {
    let data = {
      waktu: item.waktu,
      konselor: item.data_konselor?.nama ?? "Anonymouse",
      layanan: null,
      klien: null,
      booked: false,
      role: item.data_konselor ? item.role : "",
      sub_role: item.data_konselor ? item.sub_role : "",
    };
    if (item.thread) {
      data["layanan"] = item.thread[0].konsultasi;
      data["klien"] = item.thread[0].data_pelanggan.nama;
      data["booked"] = true;
    }
    setPocket(data);
    setDialog(true);
  };
  useEffect(() => {
    getAllDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth, dataSet]);

  const renderCells = () => {
    const rows = [];
    let days = [];
    arrayOfDays.forEach((week, index) => {
      week.dates.forEach((d, i) => {
        days.push(<CardDate pickDay={pickDay} d={d} css={css} key={i} />);
      });
      rows.push(
        <div className={css.row} key={index}>
          {days}
        </div>
      );
      days = [];
    });

    return <div className={css.body}>{rows}</div>;
  };

  const formateDateObject = (date) => {
    const temp = { ...date.toObject() };

    const formatedObject = {
      day: temp.date,
      month: temp.months,
      year: temp.years,
      isCurrentMonth: temp.months === currentMonth.month(),
      isCurrentDay: date.isToday(),
      date: `${temp.years}-${temp.months + 1 < 10 ? `0${temp.months + 1}` : temp.months + 1}-${temp.date < 10 ? `0${temp.date}` : temp.date}`,
    };

    return formatedObject;
  };
  return (
    <Card className="pa-3 rounded-lg">
      {renderHeader()}
      {renderDays()}
      <div className={css.calendar} style={{ border: `1px solid ${theme.tertiary}` }}>
        {renderCells()}
      </div>
      {/* show menu if date clicked */}
      <ControlledMenu state={open ? "open" : "closed"} onClose={() => setOpen(false)} direction="right" align="start" anchorRef={currentRef} arrow position="anchor" boundingBoxPadding="20">
        {pickedDate ? <MenuHeader style={{ textTransform: "none", color: theme.primary, fontWeight: "bold" }}>{pickedDate.title}</MenuHeader> : <></>}
        <MenuGroup className="styled_scroll px-2" style={{ maxHeight: "300px", overflow: "auto" }}>
          <MenuDivider />
          {pickedDate && pickedDate.schedule.length ? (
            pickedDate.schedule.map((el, i) => (
              <MenuItem style={{ padding: "5px 3px" }} onClick={() => pickSchedule(el)} key={i}>
                <Paper className="pa-2 flexy" sx={{ background: el.role == "Psikolog" ? "#E8F6F8" : el.role == "Coach" ? "#FFF6E7" : "#F6EDFF" }} elevation={0}>
                  <LinesEllipsis className="font-weight-bold" style={{ fontSize: "12px" }} text={el.data_konselor?.nama ?? "Anonymous"} />
                  <p className="font-weight-bold" style={{ fontSize: "12px", color: el.role == "Psikolog" ? "#10A3B0" : el.role == "Coach" ? "#FF9F0E" : "#A449FF" }}>
                    {dayjs(el.waktu).format("HH:mm")}
                  </p>
                  {el["status-booking"] == "booked" ? <div className="backdrop"></div> : <></>}
                </Paper>
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>Tidak ada jadwal</MenuItem>
          )}
        </MenuGroup>
      </ControlledMenu>
      <div className="py-2">
        <DetailJadwal dialog={dialog} theme={theme} close={() => setDialog(false)} pocket={pocket} />
      </div>
    </Card>
  );
};

export default MonthKonselingAdmin;

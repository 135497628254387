import { LiveHelp } from "@mui/icons-material";
import { Button, Dialog, DialogContent } from "@mui/material";
import { useSelector } from "react-redux";

const ModalActivateCoach = ({ onSend, closeModal }) => {
  const theme = useSelector((state) => state.theme.myTheme);

  return (
    <Dialog
      open={true}
      onClose={() => {
        closeModal && closeModal();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 10, minWidth: "350px", maxWidth: "470px" },
      }}>
      <DialogContent>
        <div className="d-flex gap-3">
          <LiveHelp style={{ color: theme.act_lbl_purple, width: "3rem", height: "3rem" }} />
          <div>
            <h4>Aktifkan layanan coaching</h4>
            <div className="leading-3">
              <small>Perluas relasi Anda dengan menambahkan layanan coaching di Teman Bakat!</small>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <ul>
            <li>
              <h5>Tentang Coach Teman Bakat</h5>
              <p className="small_txt">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A cras semper auctor neque vitae tempus quam. Etiam sit amet
                nisl purus in mollis nunc sed. Pellentesque adipiscing commodo elit at imperdiet dui accumsan. Consectetur libero id faucibus nisl tincidunt eget.
              </p>
            </li>
            <li className="pt-2">
              <h5>Syarat & Ketentuan Coach Teman Bakat</h5>
              <p className="small_txt">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Est sit amet facilisis magna etiam tempor orci eu. Risus
                quis varius quam quisque id diam vel quam. Dui faucibus in ornare quam viverra orci. Erat velit scelerisque in dictum non. Purus non enim praesent elementum facilisis leo vel. Eget
                magna fermentum iaculis eu non. In massa tempor nec feugiat nisl pretium fusce id. Gravida neque convallis a cras semper auctor. Enim diam vulputate ut pharetra sit amet aliquam id.
                Nisl nisi scelerisque eu ultrices vitae auctor.
              </p>
            </li>
          </ul>
        </div>
        <div className="mt-50">
          <Button variant="contained" className="rounded-lg full_width capitalize" onClick={() => onSend && onSend()}>
            Kirim Permohonan
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalActivateCoach;

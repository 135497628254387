import { Button, Grid, Paper, Avatar, Divider, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import EmptyData from "../../../../components/base/EmptyData";
import { getApi } from "../../../../store/apis";
import ListCounselingClient from "./listCounselingClient";
import ListPsycotestClient from "./listPsycotestClient";
const ProfileKlien = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const theme = useSelector((state) => state.theme.myTheme);
  const dummy = useSelector((state) => state.theme.dummy);
  const [category, setCategory] = useState("Konsultasi");
  const [loading, setLoading] = useState(true);
  const [dataSet, setData] = useState(null);

  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `konselor/profile-klien?id_pelanggan=${id}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        setLoading(false);
        setData(null);
      });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Button className="capitalize rounded-xl mb-3" variant="contained" disableElevation onClick={() => navigate(-1)}>
        Kembali
      </Button>

      {!loading ? (
        dataSet ? (
          <Grid container spacing={2} className="mb-4">
            <Grid item xs={7}>
              <Paper className="pa-3 rounded-lg d-flex align-start" style={{ minHeight: "100%" }}>
                <Avatar alt={dataSet.nama} className="mr-3" src={dataSet.foto_link ? dataSet.foto_link : dummy} sx={{ width: 100, height: 100 }} />
                <div className="flexy">
                  <div className="d-flex justify-space-between flexy">
                    <div>
                      <p className="font-weight-bold txt_table" style={{ color: theme.primary }}>
                        Nama
                      </p>
                      <p className="font-weight-bold txt_table">{dataSet.nama}</p>
                    </div>
                    <Button className="capitalize rounded-xl" variant="contained" disableElevation onClick={() => navigate(`/log-client/${id}`)}>
                      Log Aktivitas
                    </Button>
                  </div>
                  <div className="py-3">
                    <Divider />
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <p className="font-weight-bold txt_table" style={{ color: theme.primary }}>
                        Tanggal Lahir
                      </p>
                      <p className="txt_table">{dataSet.tgl_lahir}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="font-weight-bold txt_table" style={{ color: theme.primary }}>
                        Usia
                      </p>
                      <p className="txt_table">{dataSet.Usia}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="font-weight-bold txt_table" style={{ color: theme.primary }}>
                        Jenis Kelamin
                      </p>
                      <p className="txt_table">{dataSet.jenis_kelamin}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="font-weight-bold txt_table" style={{ color: theme.primary }}>
                        Profesi
                      </p>
                      <p className="txt_table">{dataSet.profesi}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="font-weight-bold txt_table" style={{ color: theme.primary }}>
                        Domisili
                      </p>
                      <p className="txt_table">{dataSet.kota}</p>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={5}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Paper className="pa-3 rounded-lg cursor_pointer" onClick={() => setCategory("Konsultasi")} style={{ minHeight: "72px", background: category === "Konsultasi" ? "#7D37C3" : "" }}>
                    <p className="font-weight-bold" style={{ color: category === "Konsultasi" ? "#fff" : "#7D37C3" }}>
                      Konsultasi
                    </p>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    className="pa-3 rounded-lg cursor_pointer"
                    onClick={() => setCategory("Game for Development")}
                    style={{ minHeight: "72px", background: category === "Game for Development" ? "#7D37C3" : "" }}>
                    <p className="font-weight-bold" style={{ color: category === "Game for Development" ? "#fff" : "#E53C95" }}>
                      Game for Development
                    </p>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    className="pa-3 rounded-lg cursor_pointer"
                    onClick={() => setCategory("Tes Psikologi")}
                    style={{ minHeight: "72px", background: category === "Tes Psikologi" ? "#7D37C3" : "" }}>
                    <p className="font-weight-bold" style={{ color: category === "Tes Psikologi" ? "#fff" : "#7D37C3" }}>
                      Tes Psikologi
                    </p>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    className="pa-3 rounded-lg cursor_pointer"
                    onClick={() => setCategory("Psychological Upgrade")}
                    style={{ minHeight: "72px", background: category === "Psychological Upgrade" ? "#7D37C3" : "" }}>
                    <p className="font-weight-bold" style={{ color: category === "Psychological Upgrade" ? "#fff" : "#E53C95" }}>
                      Psychological Upgrade
                    </p>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    className="pa-3 rounded-lg cursor_pointer"
                    onClick={() => setCategory("Training & Course")}
                    style={{ minHeight: "72px", background: category === "Training & Course" ? "#7D37C3" : "" }}>
                    <p className="font-weight-bold" style={{ color: category === "Training & Course" ? "#fff" : "#8E0E76" }}>
                      Training & Course
                    </p>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <EmptyData />
        )
      ) : (
        <Grid container spacing={2} className="mb-4">
          <Grid item xs={7}>
            <Skeleton variant="rounded" className="rounded-lg" style={{ minHeight: "100%", width: "100%" }} />
          </Grid>
          <Grid item xs={5}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Skeleton variant="rounded" className="rounded-lg" height={60} style={{ width: "100%" }} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rounded" className="rounded-lg" height={60} style={{ width: "100%" }} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rounded" className="rounded-lg" height={60} style={{ width: "100%" }} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rounded" className="rounded-lg" height={60} style={{ width: "100%" }} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rounded" className="rounded-lg" height={60} style={{ width: "100%" }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {category === "Konsultasi" && dataSet ? <ListCounselingClient id={id} /> : category === "Tes Psikologi" ? <ListPsycotestClient id={id} /> : <></>}
    </div>
  );
};

export default ProfileKlien;

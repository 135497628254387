const monthFormat = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"];
const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];

function getYearCollection(startYear, endYear) {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  years.reverse();
  return years;
}

const dateFormatter = (dateValue) => {
  let date = new Date(dateValue);
  let format = `${date.getFullYear()}-${firstZero(date.getMonth() + 1)}-${firstZero(date.getDate())}`;
  return format;
};

const dateFormatterV2 = (dateValue) => {
  let date = new Date(dateValue);
  let months = monthFormat;
  let format = `${days[date.getDay()]}, ${firstZero(date.getDate())} ${months[date.getMonth()]} ${date.getFullYear()}`;
  return format;
};

const dateFormatterV3 = (dateValue) => {
  let date = new Date(dateValue);
  let months = monthFormat;
  let format = `${firstZero(date.getDate())} ${months[date.getMonth()]} ${date.getFullYear()}`;
  return format;
};

const monthFormatter = (dateValue) => {
  let date = new Date(dateValue);
  let months = monthFormat;
  let format = `${months[date.getMonth()]} ${date.getFullYear()}`;
  return format;
};

const timeFormatter = (dateValue) => {
  let date = new Date(dateValue);
  let format = `${firstZero(date.getHours())}.${firstZero(date.getMinutes())}`;
  return format;
};

const currencyFormatter = (value) => {
  let currency = new Intl.NumberFormat("id-ID", { maximumFractionDigits: 0 }).format(value);
  return currency;
};

const firstZero = (value) => {
  return value < 10 ? `0${value}` : value;
};

export { dateFormatter, dateFormatterV2, monthFormatter, monthFormat, getYearCollection, currencyFormatter, firstZero, dateFormatterV3, timeFormatter };

import { createSlice } from "@reduxjs/toolkit";
// const axios = require("axios");

const initialState = {
  myTheme: {},
  title: "",
  dummy: require("../assets/img/pp_dumy_md.png"),
};

const theme = createSlice({
  name: "theme",
  initialState,
  reducers: {
    GET_THEME: (state, action) => {
      state.myTheme = action.payload;
    },
    CHANGE_TITLE: (state, action) => {
      state.title = action.payload;
    },
  },
});

export const getRole = (role) => (dispatch) => {
  let theme = {
    primary: "",
    secondary: "",
    tertiary: "",
    darken: "",
    lighten: "",
    black: "#232626",
    yellow: "#FFDB28",
    white: "#FFFFFF",
    orange: "#F3A100",
    green: "#00AB97",
    red: "#F44336",
    act_blue: "#ACE1FF",
    act_lbl_blue: "#0B81C2",
    act_cyan: "#99FFF3",
    act_lbl_cyan: "#00AB97",
    act_green: "#99DDD5",
    act_lbl_green: "#00786A",
    act_pink: "#FFE8F8",
    act_lbl_pink: "#CF36A1",
    act_red: "#F1A5B2",
    act_lbl_red: "#B80222",
    act_orange: "#FFE79A",
    act_lbl_orange: "#DC8400",
    act_purple: "#D0BAFF",
    act_lbl_purple: "#6D0BCF",
    act_light_purple: "#ECE3FF",
    act_dark_purple: "#400081",
    act_yellow: "#FFE79C",
    act_lbl_yellow: "#F3A100",
  };

  switch (role) {
    case 1:
      theme.primary = "#007585";
      theme.secondary = "#9CD3D7";
      theme.tertiary = "#F1FAFB";
      theme.darken = "#005765";
      theme.lighten = "#AAE7EC";
      theme.bg = "#AAE7EC";
      break;
    case "ADMIN":
      theme.primary = "#6D057E";
      theme.secondary = "#853191";
      theme.tertiary = "#FBF4FE";
      theme.darken = "#3D0051";
      theme.lighten = "#F6E5FE";
      break;
    case "KONSELOR":
      theme.primary = "#007585";
      theme.secondary = "#9CD3D7";
      theme.tertiary = "#F1FAFB";
      theme.darken = "#005765";
      theme.lighten = "#AAE7EC";
      break;
    default:
      theme.primary = "#007585";
      theme.secondary = "#9CD3D7";
      theme.tertiary = "#F1FAFB";
      theme.darken = "#005765";
      theme.lighten = "#AAE7EC";
      break;
  }
  dispatch(GET_THEME(theme));
};

export const { GET_THEME, CHANGE_TITLE } = theme.actions;
export default theme.reducer;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { CHANGE_TITLE } from '../../../../store/theming'
import { useDispatch, useSelector } from 'react-redux'
import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Paper, Radio, RadioGroup } from '@mui/material'
import styleInput from '../../../../assets/css/search.module.css'
import dayjs from 'dayjs'

/** @jsxImportSource @emotion/react */
import { css as emot } from '@emotion/react'
import Select from 'react-select';
import { getApi, postApi } from '../../../../store/apis'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import PickPsikotes from './pickPsikotes'
import { ChevronLeft } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'


const CreateLayananKonsultasi = () => {
    const theme = useSelector(state => state.theme.myTheme)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [recommends, setRecommend] = useState([""]);

    // custom style for select
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: state.isFocused ? '' : '#FBF4FE',
            borderRadius: '5px',
            border: 'none',
            outline: state.isFocused ? `2px solid ${theme.primary}` : '',
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '5px',
            padding: '3px',
        }),
        input: (provided, state) => ({
            ...provided,
            outline: state.isFocused ? '2px solid #FBF4FE' : 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: '5px',
            background: state.isSelected ? '#FBF4FE' : '',
            color: state.isSelected ? '#000' : '',
            ':hover': {
                background: '#FBF4FE',
            },
        }),
    }

    const addRecommend = () => {
        setRecommend([...recommends, ""]);
    };

    const removeRecommend = item => {
        setRecommend(recommends.filter(recommend => recommend != item));
    }

    const [dp, setDp] = useState(null)
    const [psikotes, setPsikotes] = useState([])
    const [idKategori, setIdKategori] = useState(null)
    const [form, setForm] = useState({
        nama_konsultasi: '',
        durasi: '0.0',
        harga: '',
        keterangan: '',
        potongan_harga: '',
        pilih_konselor: '',
        priode_awal: '',
        priode_akhir: '',
    })
    const [hiddenForm, setHiddenForm] = useState({
        fitur: [],
        sesi: '',
        rekomendasi: '',
    })

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [config, setConfig] = useState({
        konselor: '0',
        sesi: '0',
        periode: '0',
        rekomendasi: '0',
    })


    const handleChange = (prop) => (e) => {
        setForm({ ...form, [prop]: e.target.value })
    }

    const handleCheck = item => event => {
        const { checked } = event.target;
        let tempArr = [...hiddenForm.fitur]
        if (checked) {
            tempArr.push(item.value)
            setHiddenForm({ ...hiddenForm, fitur: tempArr });
        } else {
            tempArr.filter((a) => a !== item.value)
            setHiddenForm({ ...hiddenForm, fitur: tempArr });
        }
    }

    const handleConfig = (prop) => (event) => {
        setConfig({ ...config, [prop]: event.target.value });
    };

    const handleRecommend = idx => event => {
        let rawTxt = [...recommends]
        rawTxt[idx] = event.target.value
        setRecommend(rawTxt)
    }

    const renderImg = e => {
        const file = e.target.files[0];
        const fr = new FileReader();
        fr.onload = (f) => {
            setDp(f.target.result)
        };
        setForm({ ...form, cover: file });
        fr.readAsDataURL(file);
    }

    const [loading, setLoading] = useState(true)
    const [categories, setCategories] = useState([])

    const fetchData = () => {
        setLoading(true)
        let data = {
            path: `admin/konsultasi-kategori`
        }
        dispatch(getApi(data)).then(data => {
            let rawData = JSON.parse(JSON.stringify(data.data))
            let tempData = []
            rawData.forEach(el => {
                tempData.push({
                    value: el.id,
                    label: el.name
                })
            })
            setCategories(tempData)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            setCategories([])
        })
    }

    const createData = () => {
        setLoading(true)
        let val_psikotes = psikotes.length ? psikotes.map(el => el.id) : []
        let val_recommends = recommends[0] ? recommends : []
        let body = new FormData()
        body.append('path', form.cover)
        body.append('konsultasi_kategori_id', idKategori.value)
        body.append('nama_konsultasi', form.nama_konsultasi)
        body.append('durasi', form.durasi)
        body.append('harga', form.harga)
        body.append('psikotes_id', JSON.stringify(val_psikotes))
        body.append('keterangan', form.keterangan)
        body.append('potongan_harga', form.potongan_harga)
        body.append('pilih_konselor', config.konselor)
        body.append('priode_awal', startDate ? dayjs(startDate).format('YYYY-MM-DD HH:mm') : null)
        body.append('priode_akhir', endDate ? dayjs(endDate).format('YYYY-MM-DD HH:mm') : null)
        body.append('attributes', JSON.stringify(hiddenForm.fitur))
        body.append('sesi', hiddenForm.sesi)
        body.append('rekomendasi', JSON.stringify(val_recommends))
        let data = {
            path: `admin/konsultasi/store`,
            body: body
        }
        dispatch(postApi(data)).then(data => {
            navigate(`/konseling/detail/${data.data.id}`)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const listFitur = [
        {
            name: 'Voice Call',
            value: 'voice_call'
        },
        {
            name: 'Video Call',
            value: 'video_call'
        },
    ]

    useEffect(() => {
        dispatch(CHANGE_TITLE('Tambah Konsultasi'))
        fetchData()
    }, [])


    return (
        <div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Button
                variant="contained"
                style={{ minWidth: "0" }}
                className="capitalize rounded-lg  mb-3"
                onClick={() => navigate(-1)}
            >
                <ChevronLeft /> Kembali
            </Button>
            <Grid container spacing={2} >
                <Grid item xs={3}>
                    <Paper elevation={0} className='rounded-lg pa-3'>
                        {dp ? <div className='d-flex justify-center mb-3'><img src={dp} height='150px' alt='cover-psikotest' /></div> :
                            <div className="d-flex align-center justify-center" style={{ height: '100px' }}>
                                <p className='font-weight-bold text-center' style={{ color: theme.primary, fontWeight: '500' }}>Cover Psikotes</p>
                            </div>}
                        <div className="d-flex justify-center">
                            <Button
                                variant="outlined"
                                className='capitalize'
                                component="label"
                            >
                                <p className='font-weight-bold'>{dp ? 'Ubah Gambar' : 'Unggah Gambar'}</p>
                                <input hidden onChange={renderImg} accept="image/*" multiple type="file" />
                            </Button>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    <Paper elevation={0} className='rounded-lg pa-3 mb-3'>
                        <p className='mb-3' style={{ color: theme.primary, fontWeight: '500' }}>Detail Konsultasi</p>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Kategori Konsultasi</p>
                            </Grid>
                            <Grid item xs={9}>
                                {!loading ?
                                    <Select
                                        styles={customStyles}
                                        classNamePrefix="select"
                                        options={categories}
                                        defaultValue={idKategori}
                                        onChange={(e) => setIdKategori(e)}
                                        name="color"
                                        css={emot`
                                        background: ${theme.tertiary};
                                        border-radius: 5px;
                                        &:focus {
                                            outline: 2px solid ${theme.primary};
                                            background: #fff;
                                        }
                                    `}
                                    /> : <></>}
                            </Grid>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Judul Konsultasi</p>
                            </Grid>
                            <Grid item xs={9}>
                                <input
                                    placeholder='Judul Konsultasi'
                                    onChange={handleChange('nama_konsultasi')}
                                    value={form.nama_konsultasi}
                                    css={emot`
                                        background: ${theme.tertiary};
                                        border-radius: 5px;
                                        &:focus {
                                            outline: 2px solid ${theme.primary};
                                            background: #fff;
                                        }
                                    `}
                                    className={styleInput.input_psy}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Durasi Konsultasi</p>
                            </Grid>
                            <Grid item xs={9}>
                                <input
                                    placeholder='Durasi Konsultasi'
                                    value={form.durasi}
                                    onChange={handleChange('durasi')}
                                    type='number'
                                    css={emot`
                                        background: ${theme.tertiary};
                                        border-radius: 5px;
                                        width: 50%;
                                        &:focus {
                                            outline: 2px solid ${theme.primary};
                                            background: #fff;
                                        }
                                    `}
                                    className={styleInput.input_psy}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Keterangan Konsultasi</p>
                            </Grid>
                            <Grid item xs={9}>
                                <textarea
                                    placeholder='Keterangan Psikotes'
                                    value={form.keterangan}
                                    onChange={handleChange('keterangan')}
                                    type='number'
                                    css={emot`
                                        background: ${theme.tertiary};
                                        border-radius: 5px;
                                        &:focus {
                                            outline: 2px solid ${theme.primary};
                                            background: #fff;
                                        }
                                    `}
                                    className={styleInput.input_psy}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Harga Normal</p>
                            </Grid>
                            <Grid item xs={3}>
                                <input
                                    placeholder='Harga Normal'
                                    value={form.harga}
                                    type='number'
                                    onChange={handleChange('harga')}
                                    css={emot`
                                        background: ${theme.tertiary};
                                        border-radius: 5px;
                                        &:focus {
                                            outline: 2px solid ${theme.primary};
                                            background: #fff;
                                        }
                                    `}
                                    className={styleInput.input_psy}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold' style={{ color: theme.primary }}>Harga Promo</p>
                                <p style={{ color: theme.primary, fontSize: '12px' }}>Kosongkan bila tidak ada</p>
                            </Grid>
                            <Grid item xs={3}>
                                <input
                                    placeholder='Harga Promo'
                                    laceholder='Harga Normal'
                                    value={form.potongan_harga}
                                    onChange={handleChange('potongan_harga')}
                                    type='number'
                                    css={emot`
                                        background: ${theme.tertiary};
                                        border-radius: 5px;
                                        &:focus {
                                            outline: 2px solid ${theme.primary};
                                            background: #fff;
                                        }
                                    `}
                                    className={styleInput.input_psy}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold mt-2' style={{ color: theme.primary }}>Fitur Konsultasi</p>
                            </Grid>
                            <Grid item xs={9}>
                                {listFitur.map((item, i) => (
                                    <FormControlLabel
                                        key={i}
                                        label={<p style={{ fontSize: '14px', fontWeight: '500' }}>{item.name}</p>}
                                        control={
                                            <Checkbox size="small"
                                                checked={hiddenForm.fitur.includes(item.value)}
                                                onChange={handleCheck(item)}
                                            />
                                        }
                                    />
                                ))}
                            </Grid>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold mt-2' style={{ color: theme.primary }}>Pilih Konselor</p>
                            </Grid>
                            <Grid item xs={9}>
                                <RadioGroup
                                    name="rb-jenkel"
                                    row
                                    value={config.konselor}
                                    onChange={handleConfig("konselor")}
                                >
                                    <FormControlLabel
                                        value='0'
                                        control={<Radio size="small" />}
                                        label={<p style={{ fontSize: '14px', fontWeight: '500' }}>Disable</p>}
                                    />
                                    <FormControlLabel
                                        value='1'
                                        control={<Radio size="small" />}
                                        label={<p style={{ fontSize: '14px', fontWeight: '500' }}>Enable</p>}
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold mt-2' style={{ color: theme.primary }}>Jumlah Sesi</p>
                            </Grid>
                            <Grid item xs={9}>
                                <div className="d-flex align-center">
                                    <RadioGroup
                                        name="rb-jenkel"
                                        className='mr-3'
                                        row
                                        value={config.sesi}
                                        onChange={handleConfig("sesi")}
                                    >
                                        <FormControlLabel
                                            value='0'
                                            control={<Radio size="small" />}
                                            label={<p style={{ fontSize: '14px', fontWeight: '500' }}>Disable</p>}
                                        />
                                        <FormControlLabel
                                            value='1'
                                            control={<Radio size="small" />}
                                            label={<p style={{ fontSize: '14px', fontWeight: '500' }}>Enable</p>}
                                        />
                                    </RadioGroup>
                                    {config.sesi == '1' ? <input
                                        placeholder='Masukkan jumlah sesi (maks. 3 sesi)'
                                        type='number'
                                        value={form.sesi}
                                        onChange={(e) => setHiddenForm({ ...hiddenForm, sesi: e.target.value })}
                                        max={3}
                                        css={emot`
                                        background: ${theme.tertiary};
                                        border-radius: 5px;
                                        &:focus {
                                            outline: 2px solid ${theme.primary};
                                            background: #fff;
                                        }
                                    `}
                                        className={styleInput.input_psy}
                                    /> : <></>}
                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold mt-2' style={{ color: theme.primary }}>Periode</p>
                            </Grid>
                            <Grid item xs={9}>
                                <div className="d-flex align-center">
                                    <RadioGroup
                                        name="rb-jenkel"
                                        className='mr-3'
                                        row
                                        value={config.periode}
                                        onChange={handleConfig("periode")}
                                    >
                                        <FormControlLabel
                                            value='0'
                                            control={<Radio size="small" />}
                                            label={<p style={{ fontSize: '14px', fontWeight: '500' }}>Disable</p>}
                                        />
                                        <FormControlLabel
                                            value='1'
                                            control={<Radio size="small" />}
                                            label={<p style={{ fontSize: '14px', fontWeight: '500' }}>Enable</p>}
                                        />
                                    </RadioGroup>

                                    <div className="flexy">
                                        {config.periode == '1' ? <DatePicker
                                            selectsRange={true}
                                            css={emot`
                                            background: ${theme.tertiary};
                                            border-radius: 5px;
                                            margin-bottom: 10px;
                                            &:focus {
                                                outline: 2px solid ${theme.primary};
                                                background: #fff;
                                            }
                                            `}
                                            startDate={startDate}
                                            endDate={endDate}
                                            className={styleInput.input_psy}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                        /> : <></>}
                                    </div>

                                </div>
                            </Grid>

                            <Grid item xs={3}>
                                <p className='small_txt font-weight-bold mt-2' style={{ color: theme.primary }}>Rekomendasi</p>
                            </Grid>
                            <Grid item xs={9}>
                                <div className="d-flex align-start">
                                    <RadioGroup
                                        name="rb-jenkel"
                                        className='mr-3'
                                        row
                                        value={config.rekomendasi}
                                        onChange={handleConfig("rekomendasi")}
                                    >
                                        <FormControlLabel
                                            value='0'
                                            control={<Radio size="small" />}
                                            label={<p style={{ fontSize: '14px', fontWeight: '500' }}>Disable</p>}
                                        />
                                        <FormControlLabel
                                            value='1'
                                            control={<Radio size="small" />}
                                            label={<p style={{ fontSize: '14px', fontWeight: '500' }}>Enable</p>}
                                        />
                                    </RadioGroup>
                                    <div className='flexy'>
                                        {config.rekomendasi == '1' ? recommends.map((value, index) =>
                                            <input
                                                key={index}
                                                placeholder='Tulis siapa yang direkomendasikan ikut konsultasi ini'
                                                value={value}
                                                onChange={handleRecommend(index)}
                                                max={3}
                                                css={emot`
                                                    background: ${theme.tertiary};
                                                    border-radius: 5px;
                                                    margin-bottom: 10px;
                                                    &:focus {
                                                        outline: 2px solid ${theme.primary};
                                                        background: #fff;
                                                    }
                                                  `}
                                                className={styleInput.input_psy}
                                            />
                                        ) : <></>}

                                        <div className="d-flex justify-end mt-3" >
                                            {config.rekomendasi == '1' ? <Button className='capitalize' onClick={addRecommend}>Tambah Rekomendasi </Button> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper elevation={0} className='rounded-lg pa-3 mb-3'>
                        <p style={{ color: theme.primary, fontWeight: '500' }}>Psikotes</p>
                        <PickPsikotes picked={psikotes} setPicked={setPsikotes} />
                    </Paper>
                    <div className="d-flex justify-end">
                        {loading ?
                            <Button className='rounded-lg font-weight-bold capitalize' variant='contained' disableElevation>Tunggu sebentar...</Button>
                            : <Button className='rounded-lg font-weight-bold capitalize' variant='contained' disableElevation onClick={createData}>Tambah Psikotes</Button>}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreateLayananKonsultasi

import { useSelector } from "react-redux";

const CardTrainingItem = ({ metode = "", image = undefined, title = "", subTitle = "", total = 0, colorTheme = "" }) => {
  const theme = useSelector((state) => state.theme.myTheme);

  return (
    <div className="d-flex align-center justify-space-between my-2">
      <div className="d-flex gap-2">
        <img src={image} alt={title} className="rounded-lg" style={{ width: "75px", height: "75px" }} />
        <div className="leading-4">
          <span style={{ fontSize: "10px", color: colorTheme }} className="font-weight-700">
            {metode}
          </span>
          <h5>{title}</h5>
          <span className="px11_txt">{subTitle}</span>
        </div>
      </div>
      <div>
        <div className="px-3 white-space-pre rounded-lg" style={{ background: theme.lighten }}>
          <span style={{ fontSize: "10px" }} className="font-weight-700">
            {total.toString()} Orang
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardTrainingItem;

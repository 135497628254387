import React from "react";

const PsikotesMasterTable = ({ head = [], body = [] }) => {
  // Table head format
  const tableData = {
    head: head,
    body: body,
  };

  const renderRow = (key, value) => {
    return <tr key={key}>{value}</tr>;
  };

  const renderCol = ({ key = "", value = "", rowspan = 1, colspan = 1 }) => {
    return (
      <th key={key} rowSpan={rowspan} colSpan={colspan} className="white-space-pre">
        {value}
      </th>
    );
  };

  const renderColTd = ({ key = "", value = "" }) => {
    return <td key={key}>{value}</td>;
  };

  const renderHead = () => {
    let row = [];
    let col = [];

    tableData.head.forEach((item, index) => {
      if (col[item.row]) {
        col[item.row] = [...col[item.row], renderCol({ key: index, value: item.label, rowspan: item.rowspan, colspan: item.colspan })];
      } else {
        col[item.row] = [renderCol({ key: index, value: item.label, rowspan: item.rowspan, colspan: item.colspan })];
      }
    });

    col.forEach((item, index) => {
      row.push(renderRow(index, item));
    });

    return row;
  };

  const renderBody = () => {
    let row = [];
    tableData.body.forEach((item, index) => {
      let col = [];
      Object.keys(item).forEach((key, indexCol) => {
        col.push(renderColTd({ key: indexCol, value: item[key] }));
      });
      row.push(renderRow(index, col));
    });

    return row;
  };

  return (
    <div className="mt-3 table table-responsive">
      <table className="full_width">
        <thead>{renderHead()}</thead>
        <tbody>{renderBody()}</tbody>
      </table>
    </div>
  );
};

export default PsikotesMasterTable;

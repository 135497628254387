import React, { useState } from "react";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Close as CloseIcon, ConfirmationNumberRounded, DateRange } from "@mui/icons-material";
import InputWithIcon from "../../../components/base/inputWithIcon";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../store/apis";
import SelectWithSearch from "../../../components/base/selectWithSearch";
import styleInput from "../../../assets/css/search.module.css";
import ReactDatePicker from "react-datepicker";
import { dateFormatter } from "../../../utils";

const PsikotesFilterMastab = ({ d_indie = false, close, onSave }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [listPsikotes, setListPsikotes] = useState([]);
  const [listCompany, setListCompany] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [state, setState] = useState({
    psikotes: "",
    company: "",
    tanggal_test: "",
    kode_voucher: "",
  });

  const fetchData = ({ path = "", callback }) => {
    let data = {
      path: path,
    };
    dispatch(getApi(data))
      .then((data) => {
        callback(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getPsikotes = () => {
    fetchData({
      path: `admin/psikotes-aktivitas/psikotes`,
      callback: (response) => {
        let listData = [];
        response.forEach((element) => {
          listData.push({
            value: element.id_psikotes,
            label: element.nama_psikotes,
          });
        });
        setListPsikotes(listData);
      },
    });
  };

  const getCompany = () => {
    fetchData({
      path: `admin/psikotes-aktivitas/grup`,
      callback: (response) => {
        let listData = [];
        response.forEach((element) => {
          listData.push({
            value: element.id_grup,
            label: element.nama_grup,
          });
        });
        setListCompany(listData);
      },
    });
  };

  const handleSelectChange = (props) => {
    state[props.key] = props.value;
  };

  const handleDateChange = (date) => {
    let rangeDate = [];
    date.forEach((item, index) => {
      let itemDate = dateFormatter(item);
      rangeDate.push(itemDate);
    });
    state.tanggal_test = {
      rangeDate: rangeDate,
      fullDate: `${rangeDate[0]} - ${rangeDate[1]}`,
    };
    setDateRange(date);
  };

  const handleChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value });
  };

  const postData = () => {
    onSave && onSave(state);
  };

  return (
    <Dialog
      open={d_indie}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: 10 },
      }}>
      <DialogTitle id="alert-dialog-title" className="py-3">
        <div className="d-flex justify-space-between">
          <h4>Lihat Master Table</h4>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="borbox" style={{ width: "400px", padding: "5px" }}>
          <div className="mb-4">
            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
              Judul Psikotes
            </p>
            <SelectWithSearch
              itemDefault={state.psikotes}
              listData={listPsikotes}
              placeholder="Pilih Judul Psikotes"
              onOpen={() => getPsikotes()}
              onChange={(item) => handleSelectChange({ key: "psikotes", value: item })}
            />
          </div>
          <div className="mb-4">
            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
              Company/Institusi
            </p>
            <SelectWithSearch
              itemDefault={state.company}
              listData={listCompany}
              placeholder="Pilih Company/Institusi"
              onOpen={() => getCompany()}
              onChange={(item) => handleSelectChange({ key: "company", value: item })}
            />
          </div>
          <div className="mb-4">
            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
              Tanggal Tes
            </p>
            <div
              className="d-flex align-center"
              style={{
                background: theme.tertiary,
                borderRadius: "5px",
                padding: "2px",
              }}>
              <DateRange className="mx-2" />
              <ReactDatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                className={styleInput.input_psy}
                dateFormat="dd MMMM yyyy"
                onChange={(date) => {
                  handleDateChange(date);
                }}
              />
            </div>
          </div>
          <div className="mb-4">
            <p className="font-weight-bold small_txt" style={{ color: theme.primary }}>
              Kode Voucher
            </p>
            <InputWithIcon value={state.kode_voucher} icon={<ConfirmationNumberRounded color="primary" />} type="text" handleChange={handleChange} property="kode_voucher" />
          </div>
          <div className="d-flex justify-end">
            <Button disabled={loading} onClick={() => postData()} variant="contained" className="capitalize rounded-lg" disableElevation>
              <p className="font-weight-bold">Lihat Hasil</p>
              {loading && <CircularProgress color="primary" size={20} style={{ marginLeft: 5 }}></CircularProgress>}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PsikotesFilterMastab;

import { AddCircle, CheckCircle, Close, KeyboardArrowDown } from "@mui/icons-material";
import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { MyInput } from "./myInput";
import InputRadioText from "./inputRadioText";

const ModalSelectAndAdd = ({ defaultValue = {}, listData = [], placeholderChild = "", placeholderParent = "", onClick, onChanged }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const [element, setElement] = useState({ modal: false, isAddInput: false });
  const [data, setData] = useState({});

  const onClose = () => {
    setElement({ modal: false });
  };

  const onInputClick = () => {
    onClick && onClick();
    setElement({ ...element, modal: true });
  };

  const onClickAddButton = () => {
    setElement({ ...element, isAddInput: true });
  };

  const falseAllChoose = () => {
    listData.forEach((item, index) => {
      listData[index] = {
        ...item,
        active: false,
      };
    });
  };

  const checkActiveRadioButton = (index) => {
    falseAllChoose();

    listData[index] = {
      ...listData[index],
      active: true,
    };

    let dataItem = listData[index];

    delete data.inputModal;

    let chooseItem = {
      id: dataItem.id,
      value: dataItem.value,
      draf: false,
    };

    data.chooseItem = chooseItem;
    setData({ ...data });
    onDone();
  };

  const handleChangeInput = (value) => {
    falseAllChoose();
    setData({
      ...data,
      inputModal: value,
    });
  };

  const onSave = () => {
    let id = listData[listData.length - 1].id + 1;
    falseAllChoose();
    let chooseItem = {
      id: id,
      value: data.inputModal,
      draf: true,
    };

    data.chooseItem = chooseItem;
    setData({ ...data });
    onDone();
  };

  const onDone = () => {
    onChanged && onChanged(data.chooseItem);
    setElement({ modal: false, isAddInput: false });
  };

  const renderButtonAdd = () => {
    return (
      <div className="d-flex gap-3 cursor_pointer" onClick={() => onClickAddButton()}>
        <AddCircle style={{ color: theme.darken }} />
        <span style={{ color: theme.darken }} className="font-weight-500">
          Tambah Kategori
        </span>
      </div>
    );
  };

  const renderInput = () => {
    return (
      <div className="d-flex justify-space-between align-center gap-3">
        <MyInput
          value={data.inputModal ?? ""}
          placeholder={placeholderChild}
          handleChange={(value) => {
            handleChangeInput(value);
          }}
          onClick={() => {
            onInputClick();
          }}
        />
        <CheckCircle style={{ color: theme.darken }} className="cursor_pointer" onClick={() => onSave()} />
      </div>
    );
  };

  return (
    <div>
      <div className="relative">
        <MyInput
          value={data.chooseItem?.value ?? defaultValue.value}
          placeholder={placeholderParent}
          readOnly={true}
          handleChange={() => {}}
          onClick={() => {
            onInputClick();
          }}
        />
        <div className="absolute right-3 top-8">
          <KeyboardArrowDown style={{ color: theme.darken }} />
        </div>
      </div>
      <Dialog
        open={element.modal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10, minWidth: "350px" },
        }}>
        <DialogTitle id="alert-dialog-title">
          <div className="d-flex justify-space-between align-center">
            <span>Kategori Kelas</span>
            <span onClick={() => onClose()}>
              <Close className="cursor_pointer" />
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          {listData.length === 0 && <CircularProgress color="primary" size={20} style={{ marginLeft: 5 }} />}
          {listData.map((item, index) => {
            return (
              <div className="d-flex justify-space-between mb-2" key={index}>
                <div>
                  <InputRadioText title={item.value} name="kategori" active={item.active} onChanged={() => checkActiveRadioButton(index)} />
                </div>
              </div>
            );
          })}
          <div className="mb-2 mt-5">{element.isAddInput ? renderInput() : renderButtonAdd()}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalSelectAndAdd;

/* eslint-disable eqeqeq */
import { AddCircle, Book, ChevronLeft, Delete, HelpCenter, ModeEdit } from '@mui/icons-material'
import { Button, Chip, CircularProgress, Grid, Paper, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { DialogDelete } from '../../../../components/base/dialogDelete'
import { getApi, postApi } from '../../../../store/apis'
import { CHANGE_TITLE } from '../../../../store/theming'
import PostSection from './postSection'

const ListSection = () => {
    const theme = useSelector((state) => state.theme.myTheme);
    const { id } = useParams();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [loadDetail, setLoadDetail] = useState(true)
    const [dataSet, setData] = useState([])
    const [listTopic, setTopic] = useState([])
    const [listQuiz, setQuiz] = useState([])
    const [pocket, setPocket] = useState(null)
    const [dialog, setDialog] = useState({
        create: false,
        delete: false,
    })
    const [form, setForm] = useState(null)
    const dispatch = useDispatch()
    const createData = () => {
        setPocket({
            path: 'admin/course/section/store',
            title: 'Create Section',
            type: 'create'
        })
        setForm({
            id_course: id,
            judul: '',
            deskripsi: ''
        })
        setDialog({ ...dialog, create: true })
    }
    const updateData = item => {
        setPocket({
            path: 'admin/course/section/update',
            title: 'Update Section',
            type: 'update'
        })
        setForm({
            id: item.id,
            id_course: id,
            judul: item.judul,
            deskripsi: item.deskripsi,
        })
        setDialog({ ...dialog, create: true })
    }
    const deleteData = (item, type) => {
        setPocket({
            path: `admin/course/${type}/delete`,
            body: {
                id: item.id
            }
        })
        setDialog({ ...dialog, delete: true })
    }

    const [section, setSection] = useState(null)
    const [tab, setTab] = useState(0)
    const fetchData = () => {
        setLoading(true);
        let data = {
            path: `admin/course/section?id=${id}`,
        };
        dispatch(getApi(data))
            .then((res) => {
                setLoading(false);
                setData(res.data);
                if (res.data.length) {
                    setSection(res.data[0])
                    setTab(0)
                    fetchDetailSection(res.data[0])
                }
            })
            .catch((err) => {
                setLoading(false);
                setData(null);
            });
    }
    const fetchDetailSection = item => {
        setLoadDetail(true);
        let data = {
            path: `admin/course/section/detail?id=${item.id}`,
        };
        dispatch(getApi(data))
            .then((res) => {
                console.log(res)
                let raw = JSON.parse(JSON.stringify(res))
                if (raw) {
                    setTopic(raw.data_course_section_detail)
                    setQuiz(raw.data_quiz_summary[0] ?? null)
                }
                setLoadDetail(false);
            })
            .catch((err) => {
                setLoadDetail(false);
                setData(null);
            });
    }
    const createTopik = section => {
        const data = {
            path: 'admin/course/topik/store',
            body: {
                id_course: id,
                id_course_section: section.id
            }
        }
        dispatch(postApi(data)).then(res => {
            console.log(res)
            navigate(`/course-section/topic/${res.data.id_course}/${res.data.id_course_section}/${res.data.id}`)
        }).catch(err => {
            console.log(err)
        })
    }
    const pickSection = (item, i) => {
        setSection(item)
        setTab(i)
        fetchDetailSection(item)
    }
    const renderBahan = bahan => {
        let meet = []
        let other = []
        bahan.forEach(el => {
            if (el.tipe == 'meeting') {
                meet.push(el)
            } else {
                other.push(el)
            }
        })
        return (
            <div>
                {meet.length} Pertemuan & {other.length} Bahan pemberlajaran
            </div>
        )

    }
    useEffect(() => {
        dispatch(CHANGE_TITLE('Course Section'))
        fetchData()
    }, [])
    const SkeletonModule = () => {
        let skeletons = []
        for (let i = 0; i < 5; i++) {
            skeletons.push(<Skeleton key={i} variant="rounded" className='rounded-lg mb-3' style={{ height: '70px', width: '100%' }} />)
        }
        return skeletons
    }
    const dummy = require('../../../../assets/img/empty.png')
    return (
        <div>
            <div className="d-flex justify-space-between">
                <Button
                    variant="contained"
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg  mb-3"
                    onClick={() => navigate(`/course-detail/${id}`)}
                    disableElevation
                >
                    <ChevronLeft /> Kembali
                </Button>
                {dataSet.length ? <Button
                    variant="contained"
                    className="rounded-lg mt-3"
                    disableElevation
                    onClick={createData}
                >
                    <AddCircle />
                    <p className="font-weight-bold capitalize ml-2">
                        Tambah Section
                    </p>
                </Button> : <></>}
            </div>
            {
                !loading ? dataSet.length ? <Paper className='pa-4 rounded-lg mt-5' elevation={0} style={{ background: theme.lighten }}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <div style={{ paddingRight: '20px', borderRight: `2px solid ${theme.primary}`, minHeight: '100%' }}>
                                {dataSet.map((item, i) => <Paper
                                    key={`section-${i}`}
                                    className='pa-3 rounded-lg mb-3 cursor_pointer'
                                    style={{
                                        fontWeight: 500,
                                        background: `${tab == i ? theme.secondary : '#fff'}`,
                                        color: `${tab == i ? '#fff' : theme.secondary}`,
                                    }}
                                    onClick={() => pickSection(item, i)}
                                >
                                    Section {i + 1}
                                </Paper>)}
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <p className="font-weight-bold mb-3" style={{ color: theme.secondary }}>
                                Section Information
                            </p>
                            <Paper className='pa-3 rounded-lg d-flex justify-space-between mb-3'>
                                <div className='flexy'>
                                    <p className="small_txt mb-1 font-weight-bold">Section {tab + 1} </p>
                                    <p className="font-weight-bold">
                                        {section.judul}
                                    </p>
                                    <p className="small_txt mb-1">{section.deskripsi} </p>
                                </div>
                                <div className='d-flex'>
                                    <Button
                                        variant="contained"
                                        className="rounded-lg mr-2 pa-1"
                                        disableElevation
                                        size="small"
                                        style={{ background: theme.primary, height: 'max-content', minWidth: '5px' }}
                                        onClick={() => updateData(section)}
                                    >
                                        <ModeEdit fontSize='small' />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className="rounded-lg pa-1"
                                        disableElevation
                                        size="small"
                                        style={{ background: theme.act_red, height: 'max-content', minWidth: '5px' }}
                                        onClick={() => deleteData(section, 'section')}
                                    >
                                        <Delete fontSize='small' style={{ color: theme.act_lbl_red }} />
                                    </Button>
                                </div>

                            </Paper>
                            <p className="font-weight-bold mb-3" style={{ color: theme.secondary }}>
                                Section Detail
                            </p>
                            {!loadDetail ? <div>
                                {listTopic.length ? listTopic.map((item, i) =>
                                    <Paper key={`topic-${i}`} className='pa-3 rounded-lg d-flex justify-space-between mb-3'>
                                        <div className='flexy'>
                                            <div className="d-flex align-center mb-1">
                                                <p className="small_txt mr-3 font-weight-bold">Topik {i + 1} </p>
                                                {item.status_publish == 0 ? <Chip label="Draft" color="primary" /> : <></>}
                                            </div>
                                            <p className="font-weight-bold">
                                                {item.judul}
                                            </p>
                                            <p className="small_txt mb-1">{item.deskripsi} </p>
                                            {
                                                item.data_course_topik_bahan_belajar.length ?
                                                    renderBahan(item.data_course_topik_bahan_belajar) : <></>
                                            }
                                        </div>
                                        <div className='d-flex'>
                                            <Button
                                                variant="contained"
                                                className="rounded-lg mr-2 pa-1"
                                                disableElevation
                                                size="small"
                                                style={{ background: theme.primary, height: 'max-content', minWidth: '5px' }}
                                                onClick={() => navigate(`/course-section/topic/${id}/${section.id}/${item.id}`)}
                                            >
                                                <ModeEdit fontSize='small' />
                                            </Button>
                                            <Button
                                                variant="contained"
                                                className="rounded-lg pa-1"
                                                disableElevation
                                                size="small"
                                                style={{ background: theme.act_red, height: 'max-content', minWidth: '5px' }}
                                                onClick={() => deleteData(item, 'topik')}
                                            >
                                                <Delete fontSize='small' style={{ color: theme.act_lbl_red }} />
                                            </Button>
                                        </div>
                                    </Paper>) : <></>}
                                {listQuiz ? <Paper className='pa-3 rounded-lg d-flex justify-space-between mb-3'>
                                    <div className='flexy'>
                                        <p className="small_txt mb-1 font-weight-bold">Quiz </p>
                                        <p className="font-weight-bold">
                                            {listQuiz.judul}
                                        </p>
                                        <p className="small_txt mb-1" style={{ color: theme.secondary }}>{listQuiz.jumlah_quiz} Pertanyaan - {listQuiz.waktu} Menit - {listQuiz.passing_grade} Poin </p>
                                    </div>
                                    <div className='d-flex'>
                                        <Button
                                            variant="contained"
                                            className="rounded-lg mr-2 pa-1"
                                            disableElevation
                                            size="small"
                                            style={{ background: theme.primary, height: 'max-content', minWidth: '5px' }}
                                            onClick={() => navigate(`/course-section/quiz/${id}/${section.id}/update`)}
                                        >
                                            <ModeEdit fontSize='small' />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className="rounded-lg pa-1"
                                            disableElevation
                                            size="small"
                                            style={{ background: theme.act_red, height: 'max-content', minWidth: '5px' }}
                                            onClick={() => deleteData(listQuiz, 'quiz-summary')}
                                        >
                                            <Delete fontSize='small' style={{ color: theme.act_lbl_red }} />
                                        </Button>
                                    </div>
                                </Paper> : <></>}
                                <div className="d-flex justify-end mb-3">
                                    <Button
                                        variant="contained"
                                        className="rounded-lg"
                                        disableElevation
                                        onClick={() => createTopik(section)}
                                    >
                                        <Book />
                                        <p className="font-weight-bold capitalize ml-2">
                                            Tambah Topic
                                        </p>
                                    </Button>
                                    {!listQuiz ? <Button
                                        variant="contained"
                                        className="rounded-lg ml-3"
                                        disableElevation
                                        onClick={() => navigate(`/course-section/quiz/${id}/${section.id}/store`)}
                                    >
                                        <HelpCenter />
                                        <p className="font-weight-bold capitalize ml-2">
                                            Tambah Quiz
                                        </p>
                                    </Button> : <></>}

                                </div>
                            </div> : SkeletonModule()}
                        </Grid>
                    </Grid>
                </Paper> :
                    <div className='d-flex justify-center align-center flex-column' style={{ minHeight: '300px' }}>
                        <img src={dummy} height='130px' alt="temanbakat" />
                        <p style={{ color: theme.primary }}>Course ini belum memiliki section dan materi!</p>
                        <Button
                            variant="contained"
                            className="rounded-lg mt-3"
                            disableElevation
                            onClick={createData}
                        >
                            <AddCircle />
                            <p className="font-weight-bold capitalize ml-2">
                                Tambah Section
                            </p>
                        </Button>
                    </div> :
                    <div className='d-flex align-center justify-center' style={{ height: '300px' }}>
                        <CircularProgress /> <p className="font-weight-bold ml-3">Memuat...</p>
                    </div>
            }
            <div className="py-2">
                <PostSection dialog={dialog.create} close={() => setDialog({ ...dialog, create: false })} form={form} setForm={setForm} refetch={fetchData} pocket={pocket} />
                <DialogDelete d_del={dialog.delete} close={() => setDialog({ ...dialog, delete: false })} pocket={pocket} refetch={fetchData} />
            </div>
        </div>
    )
}

export default ListSection

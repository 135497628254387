import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CHANGE_TITLE } from "../../store/theming";
import OverviewPsy from "./OverviewPsy";
import OverviewAdm from "./OverviewAdm";

const MainOverview = () => {
  const dispatch = useDispatch();
  let role = localStorage.getItem("role") ? localStorage.getItem("role") : "1";

  useEffect(() => {
    dispatch(CHANGE_TITLE("Selamat Datang!"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const overvewPage = () => {
    let overview = <OverviewPsy />;
    switch (role) {
      case "ADMIN":
        overview = <OverviewAdm />;
        break;
      case "KONSELOR":
        overview = <OverviewPsy />;
        break;
      default:
        overview = <OverviewPsy />;
        break;
    }

    return overview;
  };
  return <div>{overvewPage()}</div>;
};

export default MainOverview;

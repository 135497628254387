/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, Button, CircularProgress, Grid, Paper } from '@mui/material';
import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_TITLE } from '../../../store/theming';
import {
  Delete as DeleteIcon,
  ChevronLeft,
} from "@mui/icons-material";
import ButtonEdit from '../../../components/base/ButtonEdit';
import { useState } from 'react';
import { getApi } from '../../../store/apis';
import { useNavigate, useParams } from 'react-router-dom';
import day from 'dayjs'
import { DialogDelete } from '../../../components/base/dialogDelete';
import VcrListUser from './vcrListUser';

const VcrDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.theme.myTheme);
  const [loading, setLoading] = useState(true)
  const [dataSet, setData] = useState(null)
  const navigate = useNavigate()
  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `admin/voucher/detail?id=${id}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const [dialog, setDialog] = useState(false)
  const [pocket, setPocket] = useState(null)
  const deleteData = () => {
    setPocket({
      path: 'admin/voucher/delete',
      body: {
        id: id
      }
    })
    setDialog(true)
  }
  useEffect(() => {
    dispatch(CHANGE_TITLE("Voucher Detail"));
    fetchData()
  }, []);
  const currencyFormater = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button
        variant="contained"
        style={{ minWidth: "0" }}
        className="capitalize rounded-lg  mb-3"
        onClick={() => navigate('/voucher-activity')}
      >
        <ChevronLeft /> Kembali
      </Button>
      {!loading ? <Paper className='py-5 px-2 rounded-lg mb-5'>
        <div className="px-3">
          <div className="d-flex justify-space-between mb-2">
            <div>
              <h3>{dataSet.nama}</h3>
              <p style={{ color: '#404040' }}> {dataSet.type} </p>
            </div>
            <div>
              <ButtonEdit onClick={() => navigate(`/voucher/post/${id}`)} />
              <Button
                variant="contained"
                className="rounded-lg capitalize ml-3"
                disableElevation
                size="small"
                style={{
                  background: theme.act_red,
                  height: "max-content",
                }}
                onClick={deleteData}
              >
                <DeleteIcon
                  className="mr-1"
                  style={{ color: theme.act_lbl_red }}
                />
                <p
                  className="font-weight-bold"
                  style={{ color: theme.act_lbl_red }}
                >
                  Delete
                </p>
              </Button>
            </div>
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <div className='px-3'>
              <ul className='mx-3'>
                <li style={{ color: theme.primary }}>
                  <p style={{ fontWeight: '500' }}>Informasi Voucher</p>
                  <div className='mt-3' style={{ color: 'black' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <p className="small_txt">Kode Voucher</p>
                      </Grid>
                      <Grid item xs={7}>
                        <p className="small_txt">
                          : <span className='font-weight-bold' style={{ color: theme.primary }}>{dataSet.kode}</span>
                        </p>
                      </Grid>
                      <Grid item xs={5}>
                        <p className="small_txt">Deskripsi Voucher</p>
                      </Grid>
                      <Grid item xs={7}>
                        <p className="small_txt">: {dataSet.deskripsi}</p>
                      </Grid>
                      <Grid item xs={5}>
                        <p className="small_txt">Peruntukan</p>
                      </Grid>
                      <Grid item xs={7}>
                        <p className="small_txt">: {dataSet.peruntukan}</p>
                      </Grid>
                      <Grid item xs={5}>
                        <p className="small_txt">User</p>
                      </Grid>
                      <Grid item xs={7}>
                        <p className="small_txt">: {dataSet.user_id}</p>
                      </Grid>
                      <Grid item xs={5}>
                        <p className="small_txt">Kuota Redeem</p>
                      </Grid>
                      <Grid item xs={7}>
                        <p className="small_txt">: {dataSet.kuota}</p>
                      </Grid>
                      <Grid item xs={5}>
                        <p className="small_txt">Berhasil Redeem</p>
                      </Grid>
                      <Grid item xs={7}>
                        <p className="small_txt">: {dataSet.jumlah_reedem}</p>
                      </Grid>
                      <Grid item xs={5}>
                        <p className="small_txt">Claimed</p>
                      </Grid>
                      <Grid item xs={7}>
                        <p className="small_txt">: {dataSet.data_voucher_pelanggan_count}</p>
                      </Grid>
                    </Grid>
                  </div>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={7}>
            <div className='px-3'>
              <ul className='mx-3'>
                <li style={{ color: theme.primary }}>
                  <p style={{ fontWeight: '500' }}>Detail Voucher </p>
                  <div className='mt-3' style={{ color: 'black' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <p className="small_txt">Expired Redeem</p>
                      </Grid>
                      <Grid item xs={8}>
                        <p className="small_txt">: {dataSet.expire_date ? day(dataSet.expire_date).locale('id').format('DD MMMM YYYY') : ""}</p>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="small_txt">Expired Service</p>
                      </Grid>
                      <Grid item xs={8}>
                        <p className="small_txt">: {dataSet.exp_layanan ? day(dataSet.exp_layanan).locale('id').format('DD MMMM YYYY') : ""}</p>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="small_txt">Harga Voucher</p>
                      </Grid>
                      <Grid item xs={8}>
                        <p className="small_txt">: {dataSet.nominal == 0 ?
                          <span className='font-weight-bold' style={{ color: theme.primary }}>Gratis</span> :
                          `Rp${currencyFormater(dataSet.nominal)}`}
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Paper> : <></>
      }
      <VcrListUser id={id} />
      <div className="pa-2">
        <DialogDelete d_del={dialog} close={() => setDialog(false)} pocket={pocket} refetch={() => navigate('/voucher-activity')} />
      </div>
    </div >
  )
}

export default VcrDetail

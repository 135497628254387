import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../store/theming";
import { getApi } from "../../../store/apis";
import EmptyData from "../../../components/base/EmptyData";
import SkeletonTable from "../../../components/base/SkeletonTable";
import { ArrowDropDown, ChevronLeft } from "@mui/icons-material";
import SearchDataTable from "../../../components/base/searchDataTable";
import {
  Button,
  Grid,
  Pagination,
  Paper,
  Card,
  Skeleton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import day from "dayjs";

import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
const CompanyVoucherDetail = () => {
  const { id } = useParams();

  const [dialog, setDialog] = useState({
    post: false,
    view: false,
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const ava = useSelector((state) => state.theme.dummy);
  const [find, setFind] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadClient, setLoadClient] = useState(true);
  const [client, setClient] = useState([]);
  const [dataSet, setData] = useState(null);
  const [emptyTxt, setEmptyTxt] = useState("");
  const [pocket, setPocket] = useState(null);
  const [formateDate, setDate] = useState(null);

  const [state, setState] = useState({
    page: 1,
    limit: 10,
    last_page: null,
    total: null,
    gender: "",
    profesi: "",
    tipeSort: null,
    asc: true,
  });

  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `admin/company/voucher/detail?id=${id}`,
    };

    dispatch(getApi(data))
      .then((data) => {
        setLoading(false);
        setData(data);
        getClient();
      })
      .catch((err) => {
        if (err.status == 400) {
          if (find != "") {
            setEmptyTxt(`${find} tidak cocok untuk data apapun!`);
          } else {
            setEmptyTxt(`No Data Available`);
          }
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setData([]);
      });
  };
  const getClient = () => {
    setLoadClient(true);
    let page = `&page[size]=${state.limit}&page[number]=${state.page}`;
    let data = {
      path: `admin/company/voucher/voucher-pelanggan?id=${id}${page}`,
    };

    dispatch(getApi(data))
      .then((data) => {
        setLoadClient(false);
        setClient(data.data);
        setState({ ...state, total: data.total, last_page: data.last_page });
      })
      .catch((err) => {
        if (err.status == 400) {
          if (find != "") {
            setEmptyTxt(`${find} tidak cocok untuk data apapun!`);
          } else {
            setEmptyTxt(`No Data Available`);
          }
        } else {
          setEmptyTxt(`${err.status}: ${err.data.message}`);
        }
        setLoading(false);
        setClient([]);
      });
  };
  const handlePage = (event, value) => {
    setState({ ...state, page: value });
  };
  const listLimit = [10, 50, 100];

  useEffect(() => {
    dispatch(CHANGE_TITLE("Detail Voucher"));
    fetchData();
  }, [state.limit, state.page]);

  return (
    <div>
      <Button
        variant="contained"
        style={{ minWidth: "0" }}
        className="capitalize rounded-lg mb-3"
        onClick={() => navigate(-1)}
      >
        <ChevronLeft /> Kembali
      </Button>

      {!loading ? (
        !dataSet ? (
          <EmptyData />
        ) : (
          <Grid container spacing={2} className="mb-5">
            <Grid item xs={2}>
              Kode Voucher
            </Grid>
            <Grid item xs={10}>
              : {dataSet.kode}
            </Grid>
            <Grid item xs={2}>
              Nama Voucher
            </Grid>
            <Grid item xs={10}>
              : {dataSet.nama}
            </Grid>
          </Grid>
        )
      ) : (
        <Skeleton
          variant="rounded"
          className="rounded-lg"
          style={{ minHeight: "100px", width: "40%" }}
        />
      )}

      <Grid container spacing={2} className="mb-5">
        <Grid item xs={6}>
          <div className="d-flex align-center">
            <h3>List Pengguna</h3>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="d-flex align-center">
            <p className="small_txt font-weight-bold mr-2">Tampilkan</p>
            <Menu
              menuClassName="my_menu"
              menuButton={
                <Card className="px-3 py-2 rounded-lg d-flex align-center mr-5 cursor_pointer pb-2">
                  <p className="small_txt font-weight-bold mr-3">
                    {state.limit} Data
                  </p>
                  <ArrowDropDown />
                </Card>
              }
              transition
            >
              {listLimit.map((lm, i) => (
                <MenuItem
                  key={`limit-${i}`}
                  onClick={() => setState({ ...state, limit: lm })}
                >
                  <p className="font-weight-bold txt_table">{lm} Data</p>
                </MenuItem>
              ))}
            </Menu>
            <SearchDataTable value={find} setValue={setFind} />
          </div>
        </Grid>
      </Grid>

      <Paper className="pa-3 rounded-lg mt-3">
        {!loadClient ? (
          !client.length ? (
            <EmptyData txt={emptyTxt} />
          ) : (
            <TableContainer component="div">
              <Table aria-label="simple table" style={{ borderBottom: "none" }}>
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{
                        minWidth: "50px",
                        background: theme.tertiary,
                        borderRadius: "10px 0 0 10px",
                      }}
                      className="py-2"
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.primary }}
                      >
                        User ID
                      </p>
                    </TableCell>
                    <TableCell
                      style={{ minWidth: "100px", background: theme.tertiary }}
                      className="py-1"
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.primary }}
                      >
                        Email
                      </p>
                    </TableCell>
                    <TableCell
                      style={{ minWidth: "200px", background: theme.tertiary }}
                      className="py-1"
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.primary }}
                      >
                        Nama User
                      </p>
                    </TableCell>
                    <TableCell
                      style={{ minWidth: "100px", background: theme.tertiary }}
                      className="py-1"
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.primary }}
                      >
                        Tgl. Redeem
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: "150px",
                        background: theme.tertiary,
                        borderRadius: "0 10px 10px 0",
                      }}
                      className="py-1"
                      align="center"
                    >
                      <p
                        className="font-weight-bold"
                        style={{ color: theme.primary }}
                      >
                        Tindakan
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {client.map((item, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      className="mb-2"
                    >
                      <TableCell>{item.id_voucher}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.nama}</TableCell>
                      <TableCell>{item.jumlah_voucher}</TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          className="rounded-xl capitalize mr-3"
                          onClick={() => navigate(`/company/detail/${item.id}`)}
                          disableElevation
                          size="small"
                          style={{ background: theme.act_blue }}
                        >
                          <p
                            className="font-weight-bold"
                            style={{ color: theme.act_lbl_blue }}
                          >
                            Detail
                          </p>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        ) : (
          <SkeletonTable txt={emptyTxt} />
        )}
        {client.length ? (
          <div
            className="d-flex align-center justify-space-between"
            style={{ width: "100%" }}
          >
            <p className="txt_table">
              Menampilkan {state.page} - {state.limit} dari {state.total}{" "}
            </p>
            <div>
              <Pagination
                sx={{ padding: "0" }}
                count={state.last_page}
                page={state.page}
                shape="rounded"
                onChange={handlePage}
                style={{ background: "#fff" }}
                color="primary"
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </Paper>

      <div className="pa-2"></div>
    </div>
  );
};

export default CompanyVoucherDetail;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDropDown } from '@mui/icons-material';
import { Card, Grid, Paper } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_TITLE } from '../../../store/theming';
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import day from 'dayjs'
import { getApi } from '../../../store/apis';
import Chart from "react-apexcharts";

const PointReport = () => {
    const dispatch = useDispatch()
    const theme = useSelector((state) => state.theme.myTheme);

    const [filter, setFilter] = useState('bulan')
    const [currentMonth, setMonth] = useState(day().format('MM'))
    const [currentYear, setYear] = useState(day().format('YYYY'))
    const [loading, setLoading] = useState(true)
    const currencyFormater = number => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
    const fetchData = () => {
        setLoading(true);
        let filterDate = currentYear
        let queryFilter = filter == 'bulan' ? `&filter[tgl_checkout]=${filterDate}` : ''
        // let filterDate = filter == 'Bulanan' ? `${currentYear}-${currentMonth}` : `${currentYear}`
        let tipe = filter
        let data = {
            path: `admin/poin/report/statistics?tipe=${tipe}${queryFilter}`,
        };
        dispatch(getApi(data))
            .then((data) => {
                let raw = JSON.parse(JSON.stringify(data))
                console.log(raw)
                let rawMaterial = filter == 'bulan' ? raw[filterDate] : raw
                let dataBonus = []
                let dataTopup = []
                let categories = []
                for (let key in rawMaterial) {
                    categories.push(filter == 'bulan' ? day(key).format('MMM') : key)
                    if (rawMaterial[key]['bonus']) {
                        let bonus = rawMaterial[key]['bonus']
                        let sum = bonus.map(i => i.jumlah).reduce((a, b) => a + b)
                        dataBonus.push(sum)
                    } else {
                        dataBonus.push(0)
                    }

                    if (rawMaterial[key]['top_up']) {
                        let topup = rawMaterial[key]['top_up']
                        let sum = topup.map(i => i.jumlah).reduce((a, b) => a + b)
                        dataTopup.push(sum)
                    } else {
                        dataTopup.push(0)
                    }
                }
                let series = [
                    {
                        name: 'Bonus',
                        data: dataBonus
                    },
                    {
                        name: 'Top up',
                        data: dataTopup
                    }
                ]
                makeChart(series, categories)
                setLoading(false);
            })
            .catch((err) => {
                console.log(err)
            });
    }
    const [config, setConfig] = useState(null)

    const makeChart = (series, category) => {
        let conf = {
            series: series,
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false,
                    },
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            let series = config.w.config.xaxis.categories[config.dataPointIndex]
                            console.log(series)
                            let filtered = filter == 'bulan' ? day(`2021-${series}-01`).format('YYYY-MM') : series
                            getDetail(filtered)
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        endingShape: 'rounded'
                    },
                },
                colors: ["#4FEFE5", "#CD6CDB"],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: category,
                },
                yaxis: {
                    show: false,
                },
                grid: {
                    show: false,
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return `${currencyFormater(val)} Poin`
                        }
                    }
                }
            }
        }
        setConfig(conf)
    }

    const [summary, setSummary] = useState(null)
    const getDetail = filterDate => {
        let data = {
            path: `admin/poin/report/detail?&filter[tgl_checkout]=${filterDate}`,
        };
        dispatch(getApi(data)).then(res => {
            setSummary(res)
            MakePieChart(res.metode_poin)
        }).catch(err => console.log(err))
    }

    const [pie, setPie] = useState(null)
    const [legendPie, setLegendPie] = useState([])
    const colors = [
        "#A22FB5",
        "#D67CE4",
        "#EAA9F5",
        "#F4C7FC",
        "#20BEB5",
        "#4BD8D0",
        "#87E3DE",
        "#8B11EA",
        "#A448EC",
        "#BC6EFA",
        "#D29AFF",
        "#F36B94",
        "#F89AB6",
        "#F579C4",
        "#F186F3",]

    const MakePieChart = data => {
        let raw = JSON.parse(JSON.stringify(data))
        let series = raw.map(el => el.total)
        let labels = raw.map(el => el.label)
        let conf = {
            series: series,
            options: {
                chart: {
                    width: 150,
                    type: 'pie',
                    events: {
                        // membuat legend custom
                        mounted: (chartContext, config) => {
                            let fixColor = config.globals.colors
                            raw.forEach((el, i) => {
                                el['color'] = fixColor[i]
                            })
                            setLegendPie(raw)
                        },
                        updated: (chartContext, config) => {
                            let fixColor = config.globals.colors
                            console.log(fixColor.length)
                            raw.forEach((el, i) => {
                                el['color'] = fixColor[i]
                            })
                            setLegendPie(raw)
                        }
                    }
                },
                labels: labels,
                colors: colors,
                legend: {
                    show: false,
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return `${currencyFormater(val)} Poin`
                        }
                    }
                }
            }
        }

        setPie(conf)
    }



    useEffect(() => {
        dispatch(CHANGE_TITLE("Poin Report"));
        fetchData()
        getDetail('2021-03')
    }, [filter])
    return (
        <div>
            <p className="small_txt" style={{ color: '#404040' }}>
                Lihat jumlah poin user dari penjualan, bonus, dan voucher dalam periode waktu tertentu.
            </p>
            <div className="d-flex justify-space-between mt-3">
                <Menu
                    transition
                    menuButton={
                        <Card className="px-3 py-2 rounded-lg d-flex align-center mr-5 cursor_pointer pb-2">
                            <p className="small_txt font-weight-bold mr-3">
                                {filter == 'bulan' ? 'Bulanan' : 'Tahunan'}
                            </p>
                            <ArrowDropDown />
                        </Card>
                    }
                >
                    <MenuItem onClick={() => setFilter('bulan')}>
                        <p className="font-weight-bold txt_table">Bulanan</p>
                    </MenuItem>
                    <MenuItem onClick={() => setFilter('tahun')}>
                        <p className="font-weight-bold txt_table">Tahunan</p>
                    </MenuItem>
                </Menu>


            </div>

            <Paper className='pa-5 rounded-lg my-3'>
                {config ? <Chart
                    options={config.options}
                    series={config.series}
                    height={350}
                    type="bar"
                /> : <></>}
            </Paper>
            <div className="py-3">
                <p className="text-center font-weight-bold">Ringkasan Penjualan</p>
            </div>
            {summary ?
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Paper className='pa-5 rounded-lg mb-4'>
                            <p className="small_txt" style={{ fontWeight: '600' }}>
                                Top Up Poin Revenue
                            </p>
                            <p className='font-weight-bold' style={{ fontSize: '20px', color: theme.primary }}>
                                Rp{currencyFormater(summary.total_poin.top_up.harga)}
                            </p>
                            <p className="small_txt">
                                dari total penjualan <span style={{ fontWeight: '600', color: theme.primary }}>
                                    {currencyFormater(summary.total_poin.top_up.poin)} Poin
                                </span>
                            </p>
                        </Paper>
                        <Paper className='pa-5 rounded-lg mb-4'>
                            <p className="small_txt" style={{ fontWeight: '600' }}>
                                Total Poin user dari  Bonus/Voucher
                            </p>
                            <p className='font-weight-bold' style={{ fontSize: '20px', color: theme.primary }}>
                                {currencyFormater(summary.total_poin.bonus.poin)} Poin
                            </p>
                        </Paper>
                        <Paper className='pa-5 rounded-lg mb-5'>
                            <p className='font-weight-bold' style={{ fontSize: '20px' }}>
                                Package Poin
                            </p>
                            <p className="small_txt">
                                Statistik pembelian poin per paket
                            </p>
                            <div className="d-flex justify-space-between">
                                <p className="small_txt" style={{ fontWeight: '600' }}>
                                    Paket
                                </p>
                                <p className="small_txt" style={{ fontWeight: '600' }}>
                                    Qty
                                </p>
                            </div>
                            {summary.package_poin.length ? summary.package_poin.map((el, i) => <div key={`paket${i}`} className="d-flex justify-space-between">
                                <p className="small_txt">
                                    {el.label}
                                </p>
                                <p className="small_txt">
                                    {el.total}
                                </p>
                            </div>) : <></>}
                        </Paper>
                    </Grid>
                    <Grid item xs={7}>
                        <Paper className='pa-5 rounded-lg'>
                            <p className='font-weight-bold' style={{ fontSize: '20px' }}>
                                Metode Perolehan Poin
                            </p>
                            <p className="small_txt">
                                Statistik sub total poin yang diberikan untuk, atau didapatkan oleh user.
                            </p>

                            <div className="d-flex mt-3">
                                {pie ? <Chart
                                    options={pie.options}
                                    series={pie.series}
                                    height={200}
                                    width={230}
                                    type="pie"
                                /> : <></>}
                                <div className="flexy">
                                    <div className="d-flex justify-space-between mb-3">
                                        <p className="small_txt" style={{ fontWeight: '600', color: theme.primary }}>
                                            Metode
                                        </p>
                                        <p className="small_txt" style={{ fontWeight: '600', color: theme.primary }}>
                                            Sub Total Poin
                                        </p>
                                    </div>
                                    {legendPie.length ? legendPie.map((el, i) => <div key={`paket${i}`} className="d-flex justify-space-between mb-1">
                                        <div className="d-flex align-center">
                                            <div style={{ borderRadius: '50%', width: '15px', height: '15px', background: el.color }}></div>
                                            <p className="small_txt ml-2" style={{ fontWeight: '500', color: '#000' }}>
                                                {el.label}
                                            </p>
                                        </div>
                                        <p className="small_txt" style={{ fontWeight: '500', color: '#000' }}>
                                            {currencyFormater(el.total)}
                                        </p>
                                    </div>) : <></>}
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                </Grid> : <></>}

        </div>
    )
}

export default PointReport


import { useSelector } from "react-redux";

const InputRadioText = ({ title = "", name = "", active = false, onChanged }) => {
  const theme = useSelector((state) => state.theme.myTheme);

  const onChange = (event) => {
    onChanged(event.target.value);
  };

  return (
    <div className="d-flex align-center gap-3">
      <input
        type="radio"
        value={title}
        name={name}
        onChange={(event) => onChange(event)}
        className="radio-style"
        style={{ backgroundColor: active ? theme.secondary : theme.tertiary, borderColor: theme.secondary }}
      />
      <div>
        <span>{title}</span>
      </div>
    </div>
  );
};

export default InputRadioText;

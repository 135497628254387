import { Avatar, Button, Card, Grid } from "@mui/material";
import { CalendarMonthSharp, CheckCircle, ChevronLeft, Close, PersonOutline, TextsmsOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { dateFormatterV2, timeFormatter } from "../../../../utils/formatter";
import CardListActivity from "../../../../components/overview/activity/CardListActivity";
import { statusForSchedule } from "../../../../utils/status";

const DetailScheduleCoaching = ({ data, activity, onCancel, onReschedule }) => {
  const navigation = useNavigate();
  const theme = useSelector((state) => state.theme.myTheme);

  const goBack = () => {
    navigation("/schedule-list");
  };

  return (
    <div>
      <Button variant="contained" style={{ minWidth: "0" }} className="capitalize rounded-lg" onClick={() => goBack()}>
        <ChevronLeft /> Kembali
      </Button>
      <div className="mt-3">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card className="pa-4 rounded-lg">
              <small className="font-weight-bold" style={{ color: theme.act_lbl_purple }}>
                Coaching
              </small>
              <div className="mt-1">
                <h4>{data?.kelas?.nama_kelas}</h4>
              </div>
              <Grid container spacing={2} className="mt-2">
                <Grid item xs={6}>
                  <h5 style={{ color: theme.primary }}>Durasi Konsultasi</h5>
                  <div className="font-weight-500">
                    <small>{data?.kelas?.durasi} Menit</small>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <h5 style={{ color: theme.primary }}>Fitur Konsultasi</h5>
                  <ul className="small_txt font-weight-500">
                    {data?.kelas?.attr_decode?.map((item, index) => {
                      return <li key={index}>{item.name}</li>;
                    })}
                  </ul>
                </Grid>
              </Grid>
            </Card>
            <Card className="mt-2 pa-4 rounded-lg">
              <div className="d-flex align-end justify-space-between">
                <div className="d-flex gap-3 align-center">
                  <Avatar src={data?.pelanggan?.foto_link} style={{ width: "4rem", height: "4rem" }} />
                  <div className="leading-5">
                    <div>
                      <h5 style={{ color: theme.primary }} className="font-weight-700">
                        Nama Klien
                      </h5>
                    </div>
                    <small>{data?.pelanggan?.nama}</small>
                  </div>
                </div>
                <Button variant="contained" className="shadow-none capitalize" style={{ background: theme.act_pink, color: theme.act_lbl_pink }}>
                  <PersonOutline />
                  Profile
                </Button>
              </div>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className="pa-4 rounded-lg d-flex flex-column relative" style={{ height: "100%" }}>
              <div className="absolute py-1 px-2 rounded-bottom-lg" style={{ top: 0, right: 10, background: statusForSchedule(data?.status).background, color: theme.white }}>
                <small className="font-weight-500" style={{ color: statusForSchedule(data?.status).color }}>
                  {statusForSchedule(data?.status).status}
                </small>
              </div>
              <h5 style={{ color: theme.primary }}>Tanggal & Waktu</h5>
              <ul className="mt-2 ml-4">
                {data?.data_sesi?.map((item, index) => {
                  return (
                    <li key={index} style={{ color: theme.primary }}>
                      <small>Sesi {item.sesi}</small>
                      <div className="d-flex align-center gap-2">
                        <h5 className="font-weight-700" style={{ color: statusForSchedule(data?.status).background }}>
                          {dateFormatterV2(item.tgl_kelas)} - {item.wkt_kelas ? timeFormatter(`${item.tgl_kelas} ${item.wkt_kelas}`) : "-:-:-"} WIB
                        </h5>
                        {item.selesai === 1 && <CheckCircle style={{ color: theme.act_lbl_purple, width: "1.3rem", height: "1.3rem" }} />}
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="mt-3 d-flex flex-column grow">
                <div className="d-flex justify-end align-end mt-5 gap-3 grow">
                  {(data.status === "Akan Datang" || data.status === "Aktif") && (data.hours_remaining >= 3 || data.hours_remaining < 0) && (
                    <Button
                      variant="contained"
                      className="shadow-none capitalize"
                      style={{ background: theme.act_red, color: theme.act_lbl_red }}
                      onClick={() => onCancel && onCancel({ id: data.id, feature: data.feature })}>
                      <Close />
                      Cancel
                    </Button>
                  )}
                  {data.status === "Akan Datang" && data.hours_remaining >= 3 && (
                    <Button
                      variant="contained"
                      className="shadow-none capitalize"
                      style={{ background: theme.act_yellow, color: theme.act_lbl_yellow }}
                      onClick={() => onReschedule && onReschedule({ id: data.id, feature: data.feature })}>
                      <CalendarMonthSharp />
                      Reschedule
                    </Button>
                  )}
                  <Button variant="contained" className="shadow-none capitalize" style={{ background: theme.act_purple, color: theme.act_lbl_purple }}>
                    <TextsmsOutlined />
                    Riwayat
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div className="mt-3">
        <CardListActivity title="Log Aktivitas Klien" data={activity?.data} />
      </div>
    </div>
  );
};

export default DetailScheduleCoaching;

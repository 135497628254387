/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TITLE } from "../../../store/theming";
import { Grid, Paper, Button, RadioGroup, FormControlLabel, Radio, Backdrop, CircularProgress } from "@mui/material";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import { CheckCircle, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import FlatArea from "../../../components/base/flatArea";
import FlatInput from "../../../components/base/flatInput";

import Cookies from "js-cookie";
import FlatDatePicker from "../../../components/base/flatDatePicker";
import { getApi, postApi } from "../../../store/apis";
import { useSnackbar } from "notistack";
import day from "dayjs";
import { mdiTableHeadersEyeOff } from "@mdi/js";
const axios = require("axios");

const VcrCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.myTheme);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1)
  const [info, setInfo] = useState({
    type: 'poin',
    nama: '',
    deskripsi: '',
    kuota: '',
    expire_date: '',
    nominal: '',
    jumlah_poin: '',
    jumlah_kode: 1,
    kode: ''
  })
  const [refer, setRefer] = useState(null)
  const peruntukan = [
    { value: 'individual_user', label: 'Individual User' },
    { value: 'psikolog', label: 'Psikolog' },
    { value: 'company', label: 'Company' }]
  const { type } = useParams()

  const fetchData = () => {
    setLoading(true);
    let data = {
      path: `admin/voucher/detail?id=${type}`,
    };
    dispatch(getApi(data))
      .then((raw) => {
        let res = JSON.parse(JSON.stringify(raw))
        setInfo({
          ...info,
          type: res.type,
          nama: res.nama,
          deskripsi: res.deskripsi,
          kuota: res.kuota,
          jumlah_poin: res.jumlah_poin,
          expire_date: res.expire_date ? new Date(res.expire_date) : '',
          nominal: res.nominal,
          kode: res.kode,
        })
        let ref = peruntukan.find(el => el.value == res.peruntukan)
        setRefer(ref)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const headers = [{
    title: 'Informasi Voucher',
    step: 1,
  },
  {
    title: 'Detail Voucher',
    step: 2,
  },
  {
    title: 'Kode Voucher',
    step: 3,
  }]
  const headersUpdate = [{
    title: 'Informasi Voucher',
    step: 1,
  },
  {
    title: 'Detail Voucher',
    step: 2,
  }]

  const handleChange = (prop) => (event) => {
    let value = event.target?.value || event;
    value = value.type ? "" : value;
    setInfo({ ...info, [prop]: value })
  }
  const [user, setUser] = useState(null)

  let config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  };
  const env = process.env.REACT_APP_ENV;
  const [listKonsultasi, setListKonsultasi] = useState([])
  const [listPsikotes, setListPsikotes] = useState([])
  const [defaultUser, setDefaultUser] = useState([])
  const getUser = async (inputValue) => {
    let page = `&page[size]=10&page[number]=1`;
    let data = {
      path: `admin/${refer.value == 'psikolog' ? 'psikolog-coach' : refer.value}?filter[nama]=${inputValue ?? ''}${page}`,
    };
    const res = await axios.get(`${env}/${data.path}`, config);
    let rawData = res.data.data.data
    let tempData = []
    rawData.map(el => tempData.push({ value: el.id, label: el.nama }))
    setDefaultUser(tempData)
    return tempData;
  }

  const [konsultasi, setKonsultasi] = useState([])

  const getKonsultasi = async (find) => {
    let page = `&page[size]=10&page[number]=1`;
    let data = {
      path: `admin/konsultasi?filter[nama]=${find ?? ''}${page}`,
    };
    const res = await axios.get(`${env}/${data.path}`, config);
    let rawData = res.data.data.data
    let tempData = []
    rawData.map(el => tempData.push({ value: el.id, label: el.nama }))
    setListKonsultasi(tempData)
    return tempData;
  }

  const [psikotes, setPsikotes] = useState([])
  const getPsikotes = async (find) => {
    let page = `&page[size]=10&page[number]=1`;
    let data = {
      path: `admin/psikotes-setting?filter[nama]=${find ?? ''}${page}`,
    };
    const res = await axios.get(`${env}/${data.path}`, config);
    let rawData = res.data.data.data
    let tempData = []
    rawData.map(el => tempData.push({ value: el.id, label: el.nama }))
    setListPsikotes(tempData)
    return tempData;
  }

  const { enqueueSnackbar } = useSnackbar();

  const postVcr = () => {
    setLoading(true)
    let body = {
      type: info.type,
      peruntukan: refer.value,
      nama: info.nama,
      deskripsi: info.deskripsi,
      kuota: info.kuota,
      expire_date: day(info.expire_date).format('YYYY-MM-DD'),
      jumlah_poin: info.jumlah_poin,
      nominal: info.nominal ?? 0,
      jumlah_kode: info.jumlah_kode
    }
    body[refer.value == 'psikolog' ? 'id_user' : 'id_group'] = user.value
    if (type !== 'create') {
      body['id'] = type
      body['kode'] = info.kode
    }
    let data = {
      path: `admin/voucher/${type == 'create' ? type : 'update'}`,
      body: body
    }
    let variant = ''
    dispatch(postApi(data)).then(data => {
      variant = 'success'
      enqueueSnackbar(data.message, { variant });
      setLoading(false)
      navigate(type == 'create' ? '/voucher-activity' : `/voucher-detail/${type}`)
    }).catch(err => {
      setLoading(false)
      variant = 'error'
      if (err.error) {
        let msg = Object.values(err.error).map(el => el[0])
        msg.forEach(msg => {
          enqueueSnackbar(msg, { variant });
        })
      } else {
        enqueueSnackbar(err.message, { variant });
      }
    })
  }

  useEffect(() => {
    dispatch(CHANGE_TITLE(`${type == 'create' ? 'Tambah' : 'Update'}  Voucher`))
    if (type != 'create') {
      fetchData()
    }
  }, [])

  useEffect(() => {
    if (refer) {
      getUser()
    }
  }, [refer]);

  // custom style for select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: state.isFocused || state.hasValue ? '' : '#FBF4FE',
      borderRadius: '5px',
      border: 'none',
      outline: state.isFocused || state.hasValue ? `2px solid ${theme.tertiary}` : '',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '5px',
      padding: '3px',
    }),
    input: (provided, state) => ({
      ...provided,
      outline: state.isFocused ? '2px solid #FBF4FE' : 'none',
    }),
    multiValue: (provided) => ({
      ...provided,
      background: theme.tertiary
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: '5px',
      background: state.isSelected ? '#FBF4FE' : '',
      color: state.isSelected ? '#000' : '',
      ':hover': {
        background: '#FBF4FE',
      },
    }),
  }

  const renderHeader = header => {
    return (
      header.map((head, i) => <div className="px-3 pb-2 pt-0"
        key={`head${i}`}
        style={{
          borderBottom: `${step == head.step ? `2px solid ${theme.primary}` : '2px solid #CACACA'}`,
          transition: '.5s all'
        }}>
        <p className={`small_txt ${step == head.step ? 'font-weight-bold' : ''}`}>
          {head.title}
        </p>
      </div>)
    )
  }

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button
        variant="contained"
        style={{ minWidth: "0" }}
        className="capitalize rounded-lg"
        onClick={() => navigate(type == 'create' ? '/voucher-activity' : `/voucher-detail/${type}`)}
      >
        <ChevronLeft /> Kembali
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Paper className="pa-5 rounded-lg my-3" style={{ transition: '.5s all' }}>
            <div className="d-flex align-center mb-3">
              {renderHeader(type == 'create' ? headers : headersUpdate)}
            </div>
            {step == 1 ?
              <>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <p className="small_txt font-weight-bold mt-2">
                      Jenis Voucher
                    </p>
                  </Grid>
                  <Grid item xs={9}>
                    <RadioGroup
                      name="rb-jenkel"
                      row
                      value={info.type}
                      onChange={handleChange('type')}
                    >
                      <FormControlLabel
                        value="poin"
                        control={<Radio />}
                        label="Poin"
                      />
                      <FormControlLabel
                        value="Layanan"
                        control={<Radio />}
                        label="layanan"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <p className="small_txt font-weight-bold mt-2">
                      Peruntukan
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <Select
                      styles={customStyles}
                      options={peruntukan}
                      defaultValue={refer}
                      onChange={(e) => setRefer(e)}
                    />
                  </Grid>
                  {refer?.value && refer.value != 'individual_user' ?
                    <>
                      <Grid item xs={3}>
                        <p className="small_txt font-weight-bold mt-2">
                          User
                        </p>
                      </Grid>
                      <Grid item xs={8}>
                        <AsyncSelect
                          styles={customStyles}
                          cacheOptions
                          defaultOptions={defaultUser}
                          defaultValue={user}
                          onChange={(e) => setUser(e)}
                          loadOptions={getUser}
                        />
                      </Grid>
                    </> : <></>
                  }
                  <Grid item xs={3}>
                    <p className="small_txt font-weight-bold mt-2">
                      Nama Voucher
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <FlatInput value={info.nama} placeholder='Nama Voucher' handleChange={handleChange} property='nama' />
                  </Grid>
                  <Grid item xs={3}>
                    <p className="small_txt font-weight-bold mt-2">
                      Deskripsi Voucher
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <FlatArea value={info.deskripsi} placeholder='Deskripsi Voucher' handleChange={handleChange} property='deskripsi' />
                  </Grid>
                  <Grid item xs={3}>
                    <p className="small_txt font-weight-bold mt-2">
                      Kuota Redeem
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <div style={{ width: '50%' }}>
                      <FlatInput value={info.kuota} type='number' placeholder="Kuota Redeem" handleChange={handleChange} property='kuota' />
                    </div>
                    <p className="txt_table text-grey">
                      Jumlah maksimal satu voucher diredeem/digunakan.
                      Satu user hanya bisa melakukan satu kali redeem untuk satu voucher.
                    </p>
                  </Grid>
                </Grid>
                <div className="d-flex align-end justify-end" style={{ height: '100px' }}>
                  <Button
                    variant="contained"
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg"
                    onClick={() => setStep(2)}
                  >
                    Lanjut <ChevronRight />
                  </Button>
                </div>
              </> : step == 2 ? <>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <p className="small_txt font-weight-bold mt-2">
                      Expired Reedem
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <div style={{ width: '50%' }}>
                      <FlatDatePicker
                        value={info.expire_date}
                        handleChange={handleChange}
                        property="expire_date"
                        placeholder="Expired Reedem"
                        min={new Date()}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <p className="small_txt font-weight-bold mt-2">
                      Jumlah Poin
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <div style={{ width: '50%' }}>
                      <FlatInput value={info.jumlah_poin} type='number' placeholder="Kuota Redeem" handleChange={handleChange} property='jumlah_poin' />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <p className="small_txt font-weight-bold mt-2">
                      Harga Voucher
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <div style={{ width: '50%' }}>
                      <FlatInput value={info.nominal} type='number' placeholder="Kosongin kalau voucher free" handleChange={handleChange} property='nominal' />
                    </div>
                    <p className="txt_table text-grey">
                      <i>Opsional</i>
                    </p>
                  </Grid>
                </Grid>
                <div className="d-flex align-end justify-space-between" style={{ height: '100px' }}>
                  <Button
                    variant="contained"
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg"
                    onClick={() => setStep(1)}
                  >
                    <ChevronLeft />  Kembali
                  </Button>
                  {type == 'create' ? <Button
                    variant="contained"
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg"
                    onClick={() => setStep(3)}
                  >
                    Lanjut <ChevronRight />
                  </Button> : <Button
                    variant="contained"
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg"
                    onClick={() => postVcr(2)}
                  >
                    Simpan <CheckCircle className="ml-2" />
                  </Button>}

                </div>
              </> : <>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <p className="small_txt font-weight-bold mt-2">
                      Jumlah Kode Voucher
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <div style={{ width: '50%' }}>
                      <FlatInput value={info.jumlah_kode} type='number' placeholder="Kosongin kalau voucher free" handleChange={handleChange} property='jumlah_kode' />
                    </div>
                    <p className="txt_table text-grey">
                      <i>Masukkan berapa banyak kode unik voucher yang ingin digenerate dari detail voucher yang sudah dibuat.</i>
                    </p>
                  </Grid>
                </Grid>
                <div className="d-flex align-end justify-space-between" style={{ height: '100px' }}>
                  <Button
                    variant="contained"
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg"
                    onClick={() => setStep(2)}
                  >
                    <ChevronLeft />  Kembali
                  </Button>
                  <Button
                    variant="contained"
                    style={{ minWidth: "0" }}
                    className="capitalize rounded-lg"
                    onClick={() => postVcr(2)}
                  >
                    Simpan <CheckCircle className="ml-2" />
                  </Button>
                </div>
              </>
            }
          </Paper>
        </Grid>
      </Grid>
    </div >
  );
};

export default VcrCreate;

import { createTheme } from "@mui/material/styles";

let role = localStorage.getItem("role") ? localStorage.getItem("role") : "1";
let primary = "";
let secondary = "#9CD3D7";
let tertiary = "#F1FAFB";

switch (role) {
  case 1:
    primary = "#7D37C3";
    break;
  case "ADMIN":
    primary = "#6D057E";
    secondary = "#853191";
    tertiary = "#FBF4FE";
    break;
  case "KONSELOR":
    primary = "#007585";
    secondary = "#9CD3D7";
    tertiary = "#F1FAFB";
    break;
  default:
    primary = "#7D37C3";
    break;
}

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    tertiary: {
      main: tertiary,
    },
    tbmain: {
      main: "#320071",
    },
    white: {
      main: "#fff",
    },
    red: {
      main: "#F44336",
    },
    black: {
      main: "#000",
    },
  },
});

export default theme;

import React from "react";
import { useSelector } from "react-redux";
import styleInput from "../../assets/css/search.module.css";
/** @jsxImportSource @emotion/react */
import { css as emot } from "@emotion/react";

const FlatArea = ({ value, property, handleChange, placeholder = "", handleBlur = () => {} }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  return (
    <div>
      <textarea
        rows="5"
        placeholder={placeholder}
        onChange={handleChange(property)}
        onBlur={handleBlur}
        value={value}
        css={emot`
                background: ${value ? "#fff" : theme.tertiary};
                outline: 2px solid ${theme.tertiary};
                border-radius: 5px;
                &:focus {
                    background: #fff;
                }
                font-size: small;
            `}
        className={styleInput.input_psy}
      />
    </div>
  );
};

export default FlatArea;

import { ArrowDropDown, CalendarMonthRounded } from "@mui/icons-material";
import { useState } from "react";
import { useSelector } from "react-redux";
import ModalSelectDateMonthYear from "../../base/ModalSelectDateMonthYear";
import { monthFormatter } from "../../../utils/formatter";

const InputSelectDateMonthYear = ({ onDone }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  const [element, setElement] = useState();
  const [data, setData] = useState({ display: monthFormatter(new Date()), value: `${new Date().getMonth() + 1}-${new Date().getFullYear()}` });

  const onDoneFn = (value) => {
    onDone && onDone(value);
    setData(value);
  };

  const onClick = () => {
    setElement(<ModalSelectDateMonthYear onDone={(value) => onDoneFn(value)} onClose={() => setElement(undefined)} />);
  };

  return (
    <div className="relative">
      <div className="d-flex gap-2 align-center px-2 py-1 rounded-lg cursor_pointer" style={{ color: theme.primary, border: `1.5px solid ${theme.lighten}` }} onClick={() => onClick()}>
        <CalendarMonthRounded style={{ width: "16px", height: "16px" }} />
        <small className="font-weight-bold white-space-pre">{data.display}</small>
        <ArrowDropDown />
      </div>
      {element}
    </div>
  );
};

export default InputSelectDateMonthYear;

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeft, Delete as DeleteIcon, AddCircle, Backup, InsertDriveFile, CheckCircle, Videocam } from '@mui/icons-material'
import { Backdrop, Button, CircularProgress, Grid, IconButton, Paper, Snackbar } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import FlatArea from '../../../../components/base/flatArea'
import FlatInput from '../../../../components/base/flatInput'
import { getApi, postApi } from '../../../../store/apis'
import { CHANGE_TITLE } from '../../../../store/theming'
import Select from 'react-select';
/** @jsxImportSource @emotion/react */
import { css as emot } from '@emotion/react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { AccessTime, DateRange } from '@mui/icons-material'
import styleInput from '../../../../assets/css/search.module.css'
import day from 'dayjs'

const ManageTopic = () => {
    const theme = useSelector((state) => state.theme.myTheme);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '6px',
            border: 'none',
            outline: `2px solid ${theme.tertiary}`,
            fontSize: 'small'
        }),
        menu: () => ({
            label: "menu",
            top: "100%",
            backgroundColor: "hsl(0, 0%, 100%)",
            boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
            marginBottom: 8,
            marginTop: 8,
            position: "absolute",
            width: "100%",
            zIndex: 2,
            boxSizing: "border-box",
            borderRadius: '5px',
            padding: '1px',
            fontSize: 'small',
        }),
        input: (provided, state) => ({
            ...provided,
            outline: state.isFocused ? `2px solid ${theme.tertiary}` : 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: '5px',
            zIndex: 'auto',
            background: state.isSelected ? '#FBF4FE' : '',
            color: state.isSelected ? '#000' : '',
            ':hover': {
                background: '#FBF4FE',
            },
        }),
    }

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState({
        get: true,
        update: false,
        upload: false,
    })
    const { idCourse, idSection, idModule } = useParams();
    const [topic, setTopic] = useState(null)
    const listBahan = [
        { label: 'Link', value: 'link' },
        { label: 'Dokumen', value: 'dokumen' },
        { label: 'Video', value: 'video' },
        { label: 'Class Meeting', value: 'meeting' },
    ]
    const handleChange = (prop) => (event) => {
        setTopic({ ...topic, [prop]: event.target.value })
    }
    const [bahan, setBahan] = useState([
        {
            type: { label: 'Link', value: 'link' },
            link: 'https://',
            video: null,
            dokumen: null,
            meet: { tgl_pelaksanaan: null, wkt_mulai: null, wkt_selesai: null, link: 'https://' }
        },
    ])

    const fetchData = () => {
        setLoading({ ...loading, get: true });
        let data = {
            path: `admin/course/topik/detail?id=${idModule}`,
        };
        dispatch(getApi(data))
            .then((res) => {
                console.log(res)
                let raw = JSON.parse(JSON.stringify(res))
                if (raw) {
                    setTopic(raw)
                    if (raw.data_course_topik_bahan_belajar.length) {
                        let temp = []
                        let meet = {}
                        raw.data_course_topik_bahan_belajar.forEach(el => {
                            if (el.tipe == 'meeting') {
                                let parsed = JSON.parse(el.source)
                                meet = {
                                    tgl_pelaksanaan: new Date(parsed.tgl_pelaksanaan),
                                    wkt_mulai: parsed.wkt_mulai ? new Date(`${day().format('YYYY-MM-DD')} ${parsed.wkt_mulai}`) : null,
                                    wkt_selesai: parsed.wkt_selesai ? new Date(`${day().format('YYYY-MM-DD')} ${parsed.wkt_selesai}`) : null,
                                    link: parsed.link
                                }
                            }
                            temp.push({
                                type: { label: el.tipe.charAt(0).toUpperCase() + el.tipe.slice(1), value: el.tipe },
                                link: el.tipe == 'link' ? el.source : '',
                                video: el.tipe == 'video' ? el.source : '',
                                dokumen: el.tipe == 'dokumen' ? el.source : '',
                                meet: el.tipe == 'meeting' ? meet : { tgl_pelaksanaan: null, wkt_mulai: null, wkt_selesai: null, link: 'https://' }
                            })
                        })
                        setBahan(temp)
                    }
                }
                setLoading({ ...loading, get: false });
            })
            .catch((err) => {
                console.log(err)
                setLoading({ ...loading, get: false });
            });
    }
    const [msgUpdate, setMsgUpdate] = useState('')

    const uploadFile = (prop) => (event) => {
        const file = event.target.files[0];
        setLoading({ ...loading, get: true });
        let raw = [...bahan]
        let body = new FormData()
        body.append('id_course', idCourse)
        body.append('id_course_section', idSection)
        body.append('tipe', prop.label)
        body.append('source', file)
        // if (raw[prop.idx][prop.label]) {
        //     body.append('source_old', raw[prop.idx][prop.label])
        // }

        let data = {
            path: `admin/course/topik/upload`,
            body: body
        }

        dispatch(postApi(data)).then(res => {
            console.log(res.data.source)
            raw[prop.idx][prop.label] = res.data.source
            setLoading({ ...loading, get: false });
            setBahan(raw)
            formatingBahan()
        }).catch(err => {
            setLoading({ ...loading, get: false });
            console.log(err)
            setMsgUpdate('Error!')
            setLoading({ ...loading, update: false });
        })
    }
    const addQuestion = () => {
        let raw = [...bahan]
        raw.push({
            type: { label: 'Link', value: 'link' },
            link: 'https://',
            video: null,
            dokumen: null,
            meet: { tgl_pelaksanaan: null, wkt_mulai: null, wkt_selesai: null, link: 'https://' }
        },)
        setBahan(raw)
    }

    const removeQuestion = i => {
        let raw = [...bahan]
        if (i > -1) {
            raw.splice(i, 1);
            setBahan(raw)
        }
    }

    const handleInput = (prop) => (event) => {
        let raw = [...bahan]
        if (prop.type == 'select') {
            raw[prop.idx][prop.label] = event
        } else if (prop.type == 'meet') {
            if (prop.sub == 'link') {
                raw[prop.idx][prop.label][prop.sub] = event.target.value
            } else {
                raw[prop.idx][prop.label][prop.sub] = event
            }
        } else {
            raw[prop.idx][prop.label] = event.target.value
        }
        setBahan(raw)
    }
    const formatingBahan = action => {

        let raw = [...bahan]
        let arr = []
        raw.forEach(el => {
            if (el.type.value == 'meeting') {
                el['meeting'] = JSON.stringify({
                    tgl_pelaksanaan: day(el.meet.tgl_pelaksanaan).format('YYYY-MM-DD'),
                    wkt_mulai: day(el.meet.wkt_mulai).format('HH:mm'),
                    wkt_selesai: day(el.meet.wkt_selesai).format('HH:mm'),
                    link: el.meet.link
                })
            }
            arr.push({
                tipe: el.type.value,
                source: el[el.type.value]
            })
        })
        if (action == 'launch') {
            updateData(arr, 1)
        } else {
            updateData(arr, 0)
        }
    }
    const updateData = (bahan, launch) => {
        setLoading({ ...loading, update: true });
        setMsgUpdate('Saving...')
        let body = {
            id: idModule,
            judul: topic.judul,
            deskripsi: topic.deskripsi,
            durasi: topic.durasi ?? 0,
            data_course_topik_bahan_belajar: JSON.stringify(bahan),
            status_publish: launch
        }
        let data = {
            path: `admin/course/topik/update`,
            body: body
        }

        dispatch(postApi(data)).then(res => {
            setMsgUpdate('Saved!')
            navigate(`/course-section/${idCourse}`)
            setTimeout(() => {
                setLoading({ ...loading, update: false });
            }, 3000);
        }).catch(err => {
            console.log(err)
            setMsgUpdate('Error!')
            setLoading({ ...loading, update: false });
        })
    }
    useEffect(() => {
        dispatch(CHANGE_TITLE('Tambah Topik'))
        fetchData()
    }, [])

    return (
        <div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading.get}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        style={{ minWidth: "0" }}
                        className="capitalize rounded-lg  mb-3"
                        onClick={() => navigate(-1)}
                        disableElevation
                    >
                        <ChevronLeft /> Kembali
                    </Button>
                </Grid>
                <Grid item xs={8}>
                    <div className="d-flex justify-end">
                        <Button
                            variant="contained"
                            style={{ minWidth: "0" }}
                            className="capitalize rounded-lg  mb-3"
                            onClick={() => formatingBahan('launch')}
                            disableElevation
                        > Simpan Topik
                        </Button>
                    </div>
                    {topic ? <div className='rounded-lg' style={{ background: theme.lighten }}>
                        <Paper className='pa-4 rounded-lg'>
                            <p className='mb-3' style={{ color: theme.secondary }}>Informasi Topik</p>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <p className="small_txt font-weight-bold">Nama Topik</p>
                                </Grid>
                                <Grid item xs={8}>
                                    <FlatInput value={topic.judul} handleChange={handleChange} handleBlur={formatingBahan} property='judul' />
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="small_txt font-weight-bold">Deskripsi Topik</p>
                                </Grid>
                                <Grid item xs={8}>
                                    <FlatArea value={topic.deskripsi} handleChange={handleChange} handleBlur={formatingBahan} property='deskripsi' />
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="small_txt font-weight-bold">Durasi Kelas</p>
                                </Grid>
                                <Grid item xs={8}>
                                    <div className="d-flex align-center">
                                        <FlatInput value={topic.durasi} handleChange={handleChange} handleBlur={formatingBahan} property='durasi' placeholder='Dalam menit' />
                                        <p className="ml-3">Menit</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                        <div className="pa-4">
                            <p className='mb-3' style={{ color: theme.primary, fontSize: '18px' }}>Bahan Belajar</p>
                            {
                                bahan.map((parent, i) => <Paper key={`parent-${i}`} className='pa-3 rounded-lg d-flex mb-3'>
                                    <div className="d-flex align-start">
                                        <IconButton color='primary' onClick={() => removeQuestion(i)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <div className="flexy mr-3" style={{ width: '150px' }}>
                                            <Select
                                                styles={customStyles}
                                                isDisabled={parent.dokumen || parent.video ? true : false}
                                                classNamePrefix="select"
                                                options={listBahan}
                                                defaultValue={parent.type}
                                                name="Tipe Pertanyaan"
                                                onChange={handleInput({ idx: i, level: 'parent', type: 'select', label: 'type' })}
                                            />
                                        </div>
                                    </div>
                                    <div className='flexy'>
                                        {parent.type.value == 'link' ?
                                            <FlatInput
                                                value={parent.link}
                                                handleChange={handleInput}
                                                property={{ idx: i, level: 'parent', label: 'link' }} />
                                            : parent.type.value == 'video' ?
                                                <div className='d-flex align-center' style={{ height: '100%' }} >
                                                    {parent.video ?
                                                        <div>
                                                            <div className='small_txt d-flex align-center'>
                                                                <Videocam color='primary' className='mr-3' />
                                                                <p className='mr-3'> {parent.video} </p>
                                                                <CheckCircle fontSize='small' style={{ color: '#00AB97' }} />
                                                            </div>
                                                        </div> : <Button
                                                            variant="outlined"
                                                            className='capitalize'
                                                            component="label"
                                                            fullWidth
                                                            style={{ height: '50px', borderWidth: '2px' }}
                                                        >
                                                            <div>
                                                                <div className="d-flex justify-center">
                                                                    <p className="font-weight-bold mr-2">Unggah Video</p>
                                                                    <Backup />
                                                                </div>
                                                                <p className="xsmall_txt">Video dalam format mp4 dan tidak lebih dari 10 mb</p>
                                                            </div>
                                                            <input
                                                                hidden
                                                                accept="video/mp4,video/x-m4v,video/*"
                                                                onChange={uploadFile({ idx: i, level: 'parent', label: 'video' })}
                                                                type="file" />
                                                        </Button>}
                                                </div> : parent.type.value == 'dokumen' ?
                                                    <div className='d-flex align-center' style={{ height: '100%' }} >
                                                        {parent.dokumen ?
                                                            <div>
                                                                <div className='small_txt d-flex align-center'>
                                                                    <InsertDriveFile color='primary' className='mr-3' />
                                                                    <p className='mr-3'> {parent.dokumen} </p>
                                                                    <CheckCircle fontSize='small' style={{ color: '#00AB97' }} />
                                                                </div>
                                                            </div> : <Button
                                                                variant="outlined"
                                                                className='capitalize'
                                                                component="label"
                                                                fullWidth
                                                                style={{ height: '60px', borderWidth: '2px' }}
                                                            >
                                                                <div>
                                                                    <div className="d-flex justify-center">
                                                                        <p className="font-weight-bold mr-2">Unggah Dokumen</p>
                                                                        <Backup />
                                                                    </div>
                                                                    <p className="xsmall_txt text-center">File dalam format .doc, .exe, .pptx, atau PDF dan tidak lebih dari 10 mb</p>
                                                                </div>
                                                                <input
                                                                    hidden
                                                                    onChange={uploadFile({ idx: i, level: 'parent', label: 'dokumen' })}
                                                                    type="file" />
                                                            </Button>}
                                                    </div>
                                                    : <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <div className="d-flex align-center" style={{ background: theme.tertiary, borderRadius: '5px', padding: '2px' }}>
                                                                <DateRange className="mx-2" />
                                                                <div className="flexy">
                                                                    <DatePicker
                                                                        placeholderText='Tanggal Batas Earlybird'
                                                                        selected={parent.meet.tgl_pelaksanaan}
                                                                        onChange={handleInput({ idx: i, label: 'meet', sub: 'tgl_pelaksanaan', type: 'meet' })}
                                                                        css={emot`
                                                                        background: ${parent.meet.tgl_pelaksanaan ? '' : theme.tertiary};
                                                                        outline: 2px solid ${theme.tertiary};
                                                                        border-radius: 5px;
                                                                        &:focus { 
                                                                            background: #fff;
                                                                        }`}
                                                                        dateFormat="dd MMMM yyyy"
                                                                        className={styleInput.input_psy}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <div className="d-flex align-center" style={{ background: theme.tertiary, borderRadius: '5px', padding: '2px' }}>
                                                                <AccessTime className="mx-2" />
                                                                <div className="flexy">
                                                                    <DatePicker
                                                                        placeholderText='Waktu Mulai'
                                                                        selected={parent.meet.wkt_mulai}
                                                                        onChange={handleInput({ idx: i, label: 'meet', sub: 'wkt_mulai', type: 'meet' })}
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeIntervals={15}
                                                                        timeCaption="Start"
                                                                        dateFormat="hh:mm"
                                                                        css={emot`
                                                                        background: ${parent.meet.wkt_mulai ? '' : theme.tertiary};
                                                                        outline: 2px solid ${theme.tertiary};
                                                                        border-radius: 5px;
                                                                        &:focus {
                                                                            background: #fff;
                                                                        }`}
                                                                        className={styleInput.input_psy}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <div className="d-flex align-center" style={{ background: theme.tertiary, borderRadius: '5px', padding: '2px' }}>
                                                                <AccessTime className="mx-2" />
                                                                <div className="flexy">
                                                                    <DatePicker
                                                                        placeholderText='Waktu Mulai'
                                                                        selected={parent.meet.wkt_selesai}
                                                                        onChange={handleInput({ idx: i, label: 'meet', sub: 'wkt_selesai', type: 'meet' })}
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeIntervals={15}
                                                                        timeCaption="Start"
                                                                        dateFormat="hh:mm"
                                                                        css={emot`
                                                                        background: ${parent.meet.wkt_selesai ? '' : theme.tertiary};
                                                                        outline: 2px solid ${theme.tertiary};
                                                                        border-radius: 5px;
                                                                        &:focus {
                                                                            background: #fff;
                                                                        }`}
                                                                        className={styleInput.input_psy}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FlatInput
                                                                handleChange={handleInput}
                                                                value={parent.meet.link}
                                                                property={{ idx: i, label: 'meet', sub: 'link', type: 'meet' }}
                                                                handleBlur={formatingBahan}
                                                            />
                                                        </Grid>
                                                    </Grid>}
                                    </div>
                                </Paper>)
                            }
                            <div className="d-flex justify-end"  >
                                <Button className='capitalize' onClick={addQuestion}>
                                    <p className="font-weight-bold">Tambah Bahan</p>
                                    <AddCircle fontSize='small' />
                                </Button>
                            </div>
                        </div>
                    </div> : <></>}
                </Grid>
            </Grid>
            <div style={{ height: '150px' }}>
                <Snackbar
                    open={loading.update}
                    autoHideDuration={3000}
                    message={msgUpdate}
                />
            </div>
        </div >
    )
}

export default ManageTopic

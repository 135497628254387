/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { AddCircle, ChevronLeft } from '@mui/icons-material'
import { Backdrop, Button, CircularProgress, Grid, Paper } from '@mui/material'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import FlatArea from '../../../../components/base/flatArea'
import FlatInput from '../../../../components/base/flatInput'
import { getApi, postApi } from '../../../../store/apis'
import { CHANGE_TITLE } from '../../../../store/theming'
import DraggableQuiz from './draggableQuiz'

const ManageQuiz = () => {
    const theme = useSelector((state) => state.theme.myTheme);
    const { idCourse, idSection, type } = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [dataSet, setData] = useState({
        id_course: idCourse,
        id_course_section: idSection,
        judul: '',
        waktu: '',
        passing_grade: ''
    })

    const handleChange = (prop) => (event) => {
        setData({ ...dataSet, [prop]: event.target.value })
    }
    const [quiz, setQuiz] = useState([{
        id: (Math.random() + 1).toString(36).substring(7),
        pertanyaan: '',
        pilihan: [],
        jawaban: '',
        tipe_soal: 'text',
        type: { label: 'Essay', value: 'text' },
    },])
    const [loading, setLoading] = useState({
        get: true,
        update: false,
        upload: false,
    })
    const handleInput = (prop) => (event) => {
        let raw = [...quiz]
        if (prop.level == 'item') {
            if (prop.type == 'select') {
                raw[prop.idx][prop.label] = event
            } else {
                raw[prop.idx][prop.label] = event.target.value
            }
        } else if (prop.level == 'answerOpt') {
            console.log(prop)
            raw[prop.idx][prop.label] = prop.opt
        } else {
            raw[prop.idx][prop.label][prop.idx_opt] = event.target.value
        }
        setQuiz(raw)
    }
    const addOption = (i) => {
        let raw = [...quiz]
        let item = raw[i]
        item.pilihan.push('New Option')
        setQuiz(raw)
    }

    const removeQuestion = (i) => {
        let raw = [...quiz]
        if (i > -1) {
            raw.splice(i, 1);
            setQuiz(raw)
        }
    }
    const _onListChange = (newList) => {
        setQuiz(newList)
    };

    const addQuestion = () => {
        let newList = {
            id: (Math.random() + 1).toString(36).substring(7),
            pertanyaan: '',
            pilihan: [],
            jawaban: '',
            tipe_soal: 'text',
            type: { label: 'Essay', value: 'text' },
        }
        setQuiz([...quiz, newList])
    }

    const removeOption = (i, j) => {
        let raw = [...quiz]
        if (j > -1) {
            raw['pilihan'][i].splice(j, 1);
            setQuiz(raw)
        }
    }

    const formatingAnswer = () => {
        let raw = [...quiz]
        let formated = []
        raw.forEach(el => {
            let concated = ''
            if (el.type.value == 'choices') {
                el.pilihan.forEach(opt => concated = `${concated}${opt};`)
            }
            formated.push({
                pertanyaan: el.pertanyaan,
                pilihan: JSON.stringify(el.pilihan),
                jawaban: el.jawaban,
                tipe_soal: el.type.value
            })

        })
        console.log(formated)
        createData(JSON.stringify(formated))
    }
    const [idModule, setModule] = useState(null)

    const createData = dataQuiz => {
        setLoading({ ...loading, get: true });
        let body = { ...dataSet }
        body['data_quiz'] = dataQuiz
        if (type == 'update') {
            body['id'] = idModule
        }
        let data = {
            path: `admin/course/quiz-summary/${type}`,
            body: body
        }
        dispatch(postApi(data)).then(res => {
            setLoading({ ...loading, get: false });
            if (res) {
                navigate(`/course-section/${idCourse}`)
            }
        }).catch(err => {
            console.log(err)
            setLoading({ ...loading, get: false });
        })
    }
    const generateType = tipe => {
        let listType = [
            { label: 'Essay', value: 'text' },
            { label: 'Option', value: 'choices' },
            { label: 'Task', value: 'file' },
        ]
        let found = listType.find(el => el.value == tipe)
        return found
    }

    const fetchData = () => {
        setLoading({ ...loading, get: true });
        let data = {
            path: `admin/course/section/detail?id=${idSection}`,
        };
        dispatch(getApi(data))
            .then((res) => {
                let raw = JSON.parse(JSON.stringify(res))
                if (raw.data_quiz_summary.length) {
                    let dataQuiz = raw.data_quiz_summary[0]
                    setModule(dataQuiz.id)
                    setData({
                        id_course: dataQuiz.id_course,
                        id_course_section: dataQuiz.id_course_section,
                        judul: dataQuiz.judul,
                        waktu: dataQuiz.waktu.slice(0, -2),
                        passing_grade: dataQuiz.passing_grade.slice(0, -2)
                    })
                    if (dataQuiz.data_quiz.length) {
                        let lists = []
                        dataQuiz.data_quiz.forEach(el => {
                            lists.push({
                                id: el.id,
                                pertanyaan: el.pertanyaan,
                                pilihan: el.pilihan ? JSON.parse(el.pilihan) : '',
                                jawaban: el.jawaban,
                                tipe_soal: el.tipe_soal,
                                type: generateType(el.tipe_soal),
                            })
                        })
                        console.log(lists)
                        setQuiz(lists)
                    }
                }
                setLoading({ ...loading, get: false });
            })
            .catch((err) => {
                console.log(err)
                setLoading({ ...loading, get: false });
            });
    }



    useEffect(() => {
        dispatch(CHANGE_TITLE(`${type == 'store' ? 'Tambah' : 'Update'} Quiz`))
        fetchData()
    }, [])

    return (
        <div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading.get}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        style={{ minWidth: "0" }}
                        className="capitalize rounded-lg  mb-3"
                        onClick={() => navigate(-1)}
                        disableElevation
                    >
                        <ChevronLeft /> Kembali
                    </Button>
                </Grid>
                <Grid item xs={8}>
                    <div className="d-flex justify-end">
                        <Button
                            variant="contained"
                            style={{ minWidth: "0" }}
                            className="capitalize rounded-lg  mb-3"
                            onClick={formatingAnswer}
                            disableElevation
                        > Simpan Quiz
                        </Button>
                    </div>
                    {!loading.get ? <div className='rounded-lg' style={{ background: theme.lighten }}>
                        <Paper className='pa-4 rounded-lg'>
                            <p className='mb-3' style={{ color: theme.secondary }}>Tambah Quiz</p>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <p className="small_txt font-weight-bold">Judul Quiz</p>
                                </Grid>
                                <Grid item xs={8}>
                                    <FlatInput value={dataSet.judul} handleChange={handleChange} property='judul' placeholder='Judul Quiz' />
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="small_txt font-weight-bold">Durasi Quiz</p>
                                </Grid>
                                <Grid item xs={8}>
                                    <div className="d-flex align-center">
                                        <FlatInput value={dataSet.waktu} handleChange={handleChange} type='number' property='waktu' placeholder='Dalam menit' />
                                        <p className="ml-3">Menit</p>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="small_txt font-weight-bold">Passing Grade</p>
                                </Grid>
                                <Grid item xs={8}>
                                    <div className="d-flex align-center">
                                        <FlatInput value={dataSet.passing_grade} handleChange={handleChange} type='number' property='passing_grade' placeholder='Passing Grade' />
                                        <p className="ml-3">/100</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                        <div className="pa-4">
                            <p className='mb-3' style={{ color: theme.primary, fontSize: '18px' }}>Pertanyaan Quiz</p>
                            <DraggableQuiz
                                section={quiz}
                                theme={theme}
                                handleInput={handleInput}
                                removeQuestion={removeQuestion}
                                addOption={addOption}
                                _onListChange={_onListChange}
                            />
                        </div>
                        <div className="d-flex justify-end">
                            <Button className='capitalize' onClick={addQuestion}>
                                <p className="font-weight-bold mr-2">Tambah Pertanyaan</p>
                                <AddCircle fontSize='small' />
                            </Button>
                        </div>
                    </div> : <></>}
                </Grid>
            </Grid>
            <div style={{ height: '150px' }}>
            </div>
        </div>
    )
}

export default ManageQuiz

import React from 'react'
import Cookies from "js-cookie";
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { KeyboardArrowDown, Delete as DeleteIcon, Reply as ReplyIcon } from '@mui/icons-material';
import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

const ChatBubble = ({ css, preReply, preDelMsg }) => {
    const id = Cookies.get('id')
    const allMsg = useSelector(state => state.chat.msg)
    const dispatch = useDispatch()
    const theme = useSelector(state => state.theme.myTheme)

    return (
        allMsg.map((chat, i) => (
            <div className={chat.from_id == id ? css.my_temp : css.your_temp} key={i} >
                <div className={chat.from_id == id ? css.my_half : css.your_half} >

                    <div className={chat.from_id == id ? css.my_ballon : css.your_ballon} >
                        {chat.reply ?
                            <div className={`${css.ur_reply}`}>
                                {chat.reply.replyType == 'text' ?
                                    <p> {chat.reply.replyText}</p> : chat.reply.replyType == 'image' ?
                                        <img src={chat.reply.replyText} alt="img" className={css.prereply_img} /> : <></>}
                            </div>
                            : <></>}
                        <div className="d-flex justify-space-between">
                            {chat.type == 'text' ?
                                <p className={css.pre_format}> {chat.text} </p> : chat.type == 'image' ?
                                    <img src={chat.text} alt="img" className={css.image_in_msg} /> :
                                    <audio src={chat.text} controls controlsList="nodownload" />}

                            <div>
                                <Menu style={{ width: 'max-content' }} menuButton={
                                    <Button className='pa-0 ml-2' style={{ minWidth: '10px', padding: '0', height: 'max-content' }}>
                                        <KeyboardArrowDown fontSize='small' style={{ color: chat.from_id == id ? '#fff' : theme.primary }} />
                                    </Button>
                                }
                                    transition arrow>
                                    <MenuItem className='px-3' onClick={() => preReply(chat)}><ReplyIcon fontSize='small' style={{ color: '' }} /><p className='font-weight-bold small_txt ml-2' >Reply</p></MenuItem>
                                    <MenuItem className='px-3' onClick={() => preDelMsg(chat)}> <DeleteIcon fontSize='small' style={{ color: 'red' }} /> <p className='font-weight-bold small_txt ml-2' >Delete</p></MenuItem>
                                </Menu>
                            </div>
                        </div>
                        <div className="d-flex justify-end">
                            <p className={`${css.clock} mt-2`}>{dayjs(chat.createdAt).format('HH:mm')}</p>
                        </div>
                    </div>
                </div>
            </div>
        ))
    )
}

export default ChatBubble


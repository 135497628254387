import React from 'react'
import { Grid, Skeleton } from '@mui/material'

const SkeletonTable = () => {
    const bodySekelton = () => {
        let data = []
        for (let i = 0; i < 30; i++) {
            data.push(
                <Grid item xs={2} key={i}>
                    <Skeleton variant="rounded" className='rounded-lg' style={{ height: '30px', width: '100%' }} />
                </Grid>
            );
        }
        return <Grid container spacing={2}> {data} </Grid>;
    }
    return (
        <div>
            <Skeleton variant="rounded" className='rounded-lg mb-3' style={{ height: '40px', width: '100%' }} />
            {bodySekelton()}
        </div>
    )
}

export default SkeletonTable

import { useEffect, useState } from "react";
import { CHANGE_TITLE } from "../../../../store/theming";
import { useDispatch, useSelector } from "react-redux";
import DetailScheduleCoaching from "./DetailScheduleCoaching";
import { useParams } from "react-router-dom";
import DetailScheduleKonsultasi from "./DetailScheduleKonsultasi";
import { getApi, postApi } from "../../../../store/apis";
import ModalConfirm from "../../../../components/base/ModalConfirm";
import { useSnackbar } from "notistack";
import { Reschedule } from "../Reschedule";
import { CalendarMonthRounded, Close } from "@mui/icons-material";

const DetailSchedulePage = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useSelector((state) => state.theme.myTheme);
  const { id, feature } = useParams();
  const [data, setData] = useState({});
  const [activity, setActivity] = useState({});
  const [element, setElement] = useState(<></>);
  // eslint-disable-next-line no-unused-vars
  const [pocket, setPocket] = useState(null);
  const [formateDate, setDate] = useState(null);

  useEffect(() => {
    dispatch(CHANGE_TITLE("Detail Konsultasi/Coach"));
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = () => {
    let data = {
      path: `konselor/jadwal-konsultasi-klien/detail?id=${id}&feature=${feature}`,
    };
    dispatch(getApi(data))
      .then((data) => {
        setData(data);
        getActivity(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getActivity = (data) => {
    const id_pelanggan = data.id_pelanggan ?? data.pelanggan_id;
    let dataBatch = {
      path: `konselor/activity-klien?id_pelanggan=${id_pelanggan}&page[size]=10`,
    };
    dispatch(getApi(dataBatch))
      .then((data) => {
        setActivity(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCancel = (data) => {
    setElement(
      <ModalConfirm
        visible={true}
        icon={<Close style={{ color: theme.white, width: "3.5rem", height: "3.5rem" }} />}
        variant="danger"
        titleBtnNo="Tutup"
        titleBtnYes="Batalkan Jadwal"
        onNo={() => emptyElement()}
        onYes={() => onCancelSchedule(data)}>
        <h4>Batalkan Jadwal Konsultasi ?</h4>
        <p>Klien akan di arahkan untuk memilih ulang jadwal dan psikolog</p>
      </ModalConfirm>
    );
  };

  const emptyElement = () => {
    setElement(undefined);
  };

  const onCancelSchedule = async (data) => {
    let config = {};
    switch (data.feature) {
      case "coaching":
        config = {
          path: `konselor/coach/cancel-schedule`,
          body: {
            id: data.id,
          },
        };
        break;
      case "konsultasi":
        config = {
          path: `konselor/index-konselor/cancel`,
          body: {
            id: data.id,
            cancel_konsultasi: "1",
          },
        };
        break;
      default:
        config = {};
        break;
    }

    await dispatch(postApi(config))
      .then((data) => {
        fetchData();
        emptyElement();
        enqueueSnackbar("Jadwal Berhasil Dibatalkan", { variant: "success" });
      })
      .catch((err) => {
        const variant = "error";
        if (err.data.errors) {
          const error = err.data.errors;
          Object.keys(error).forEach((message) => {
            enqueueSnackbar(error[message], { variant });
          });
        } else {
          enqueueSnackbar(err.message ?? "Kelas Gagal Dibatalkan", { variant });
        }
      });
  };

  const onConfirmReschedule = (data) => {
    setElement(
      <ModalConfirm
        visible={true}
        icon={<CalendarMonthRounded style={{ color: theme.white, width: "3.5rem", height: "3.5rem" }} />}
        variant="warning"
        titleBtnNo="Tutup"
        titleBtnYes="Ubah Jadwal"
        onNo={() => emptyElement()}
        onYes={() => onReschedule(data)}>
        <h4 className="capitalize">Ubah Jadwal {data.feature}</h4>
        <p>
          Anda dapat mengubah jadwal {data.feature} sebanyak <span className="font-weight-500">2 kali</span> setelah pembelian.
        </p>
        <p className="mt-2">Jika kesempatan sudah habis. Anda bisa menghubungi Admin.</p>
        <p className="font-weight-500 mt-2">Kesempatan ubah jadwal: 0 kesempatan</p>
      </ModalConfirm>
    );
  };

  const onReschedule = (data) => {
    setElement(<Reschedule close={() => emptyElement()} d_rscdl={true} pocket={data} selected={formateDate} setDate={setDate} refetch={(item) => fetchData()} />);
  };

  const renderContent = () => {
    let content = <span>Content tidak di definisikan</span>;
    switch (feature) {
      case "coaching":
        content = <DetailScheduleCoaching data={data} activity={activity} onCancel={(data) => onCancel(data)} onReschedule={(data) => onConfirmReschedule(data)} />;
        break;
      case "konsultasi":
        content = <DetailScheduleKonsultasi data={data} activity={activity} onCancel={(data) => onCancel(data)} onReschedule={(data) => onConfirmReschedule(data)} />;
        break;
      default:
        content = <DetailScheduleCoaching data={data} activity={activity} onCancel={(data) => onCancel(data)} onReschedule={(data) => onConfirmReschedule(data)} />;
        break;
    }

    return content;
  };

  return (
    <>
      {renderContent()}
      {element}
    </>
  );
};

export default DetailSchedulePage;

import { Paper, Divider, Skeleton } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { getApi } from '../../../store/apis'
import LinesEllipsis from 'react-lines-ellipsis'
import { MoreHoriz, Search, Add as AddIcon, } from '@mui/icons-material'
import { useState } from 'react'
import { useEffect } from 'react'
import { PostNotePsy } from './postNotePsy'
import { ViewNotePsy } from './viewNote'
import {
    Menu,
    MenuItem,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import day from 'dayjs'
export const ManageNotePsy = () => {

    const theme = useSelector(state => state.theme.myTheme)
    const dispatch = useDispatch()
    const [dataSet, setDataSet] = useState([])
    const [state, setState] = useState({
        loading: false,
        page: 1,
        limit: 3,
        find: '',
        lainya: false,
    })
    const [dialog, setDialog] = useState({
        post: false,
        view: false,
    })
    const [inventory, setInventory] = useState(null)
    const viewNote = (item) => {
        setInventory(item)
        setDialog({ ...dialog, view: true })
    }
    const [find, setFind] = useState('')
    const fetchData = () => {
        setState({
            ...state, loading: true
        })
        let data = {
            path: `konselor/catatan?page[number]=${state.page}&page[size]=${state.limit}&filter[judul]=${find}`,
        }
        dispatch(getApi(data)).then(data => {
            setState({
                ...state, loading: false
            })
            setDataSet(data.data)
        }).catch(err => {
            setDataSet([])
            setState({
                ...state, loading: false
            })
        })
    }



    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [find])

    return (
        <Paper className='pa-3 rounded-lg mb-3' elevation={0} style={{ minHeight: '200px', position: 'relative' }}>
            <div className="d-flex justify-space-between">
                <h3 style={{ color: theme.primary }}>Catatan</h3>
                <div>
                    <Menu menuButton={<MoreHoriz color='primary' />} transition>
                        <MenuItem><p className='font-weight-bold txt_table' style={{ color: theme.primary }}>Lihat semua catatan</p></MenuItem>
                    </Menu>
                </div>
            </div>
            <div className="temp-search-keyup mb-3">
                <input type="text" value={find} onChange={event => setFind(event.target.value)} className='search-keyup' placeholder='Cari catatan' style={{ background: theme.secondary }} />
                <Search style={{ marginLeft: '-30px' }} color='primary' />
            </div>
            {state.loading ?
                <div>
                    <Skeleton variant="rectangular" className='mb-2 rounded-lg' height={50} />
                    <Skeleton variant="rectangular" className='mb-2 rounded-lg' height={50} />
                    <Skeleton variant="rectangular" className='mb-2 rounded-lg' height={50} />
                </div> :
                dataSet.length ?
                    <div>
                        {
                            dataSet.map((item, i) => <div key={i} className='mb-2 item_note cursor_pointer' onClick={() => viewNote(item)}>
                                <p className="font-weight-bold mb-2">{item.judul}</p>
                                <div className="d-flex align-center mb-2">
                                    <p className="small_txt mr-3"> {day(item.created_at).format('DD/MM/YY')} </p>
                                    <LinesEllipsis
                                        className="small_txt"
                                        text={item.deskripsi}
                                    />
                                </div>
                                <Divider />
                            </div>)
                        }
                    </div>
                    : <div>No data</div>}
            <div style={{ height: '35px' }}>
                <PostNotePsy d_cnote={dialog.post} close={() => setDialog({ ...dialog, post: false })} refetch={fetchData} />
                <ViewNotePsy d_vnote={dialog.view} close={() => setDialog({ ...dialog, view: false })} inventory={inventory} />
            </div>
            <button className='add-note' onClick={() => setDialog({ ...dialog, post: true })} style={{ background: theme.primary }}>
                <AddIcon color='white'></AddIcon>
            </button>

        </Paper >
    )
}

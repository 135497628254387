import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

const SkeletonCoachDetailPage = () => {
  const theme = useSelector((state) => state.theme.myTheme);

  return (
    <div className="d-flex gap-3">
      <div>
        <Skeleton variant="rounded" className="rounded-lg" style={{ width: "250px", height: "250px" }} />
      </div>
      <div className="grow d-flex gap-3 justify-space-between">
        <div>
          <div>
            <Skeleton variant="rounded" className="rounded-lg py-3" style={{ width: "70px" }} />
          </div>
          <Skeleton variant="rounded" className="rounded-lg mt-3" style={{ width: "20rem" }} />
          <div className="leading-4 mt-1">
            <Skeleton variant="rounded" className="rounded-lg" style={{ width: "15rem" }} />
          </div>
          <div className="px-1">
            <div className="mt-3">
              <h5 style={{ color: theme.darken }}>Kategori</h5>
              <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "15rem" }} />
            </div>
            <div className="d-flex gap-3">
              <div className="mr-5">
                <div className="mt-3">
                  <h5 style={{ color: theme.darken }}>Jumlah Sesi</h5>
                  <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "8rem" }} />
                </div>
                <div className="mt-3">
                  <h5 style={{ color: theme.darken }}>Harga Normal</h5>
                  <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "8rem" }} />
                </div>
              </div>
              <div>
                <div className="mt-3">
                  <h5 style={{ color: theme.darken }}>Durasi per Sesi</h5>
                  <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "8rem" }} />
                </div>
                <div className="mt-3">
                  <h5 style={{ color: theme.darken }}>Harga Promo</h5>
                  <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "8rem" }} />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <h5 style={{ color: theme.darken }}>Fitur Kelas</h5>
              <Skeleton variant="rounded" className="rounded-lg mt-1" style={{ width: "10rem" }} />
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex gap-3 flex-wrap align-center justify-end">
            <Skeleton variant="rounded" className="rounded-lg py-2" style={{ width: "4rem" }} />
            <Skeleton variant="rounded" className="rounded-lg py-3" style={{ width: "5rem" }} />
          </div>
          <div className="d-flex gap-2 justify-end align-center mt-3">
            <Skeleton variant="rounded" className="rounded-lg py-4" style={{ width: "5rem" }} />
            <Skeleton variant="rounded" className="rounded-lg py-4" style={{ width: "5rem" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCoachDetailPage;

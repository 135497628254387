import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CHANGE_TITLE } from "../../../../store/theming";

const PsikotesHasilPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CHANGE_TITLE("Psikotes Capture"));
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const fetchData = () => {
    console.log("ok Dispatch");
  };

  return (
    <div>
      <div>Hasil Psikotes</div>
    </div>
  );
};

export default PsikotesHasilPage;

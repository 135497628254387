import { KeyboardArrowDown } from "@mui/icons-material";
import { Avatar, Button, Card } from "@mui/material";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const GrafikColumnWithBar = ({ title = "", subTitle = "", sectionTitle = "", sectionSubtitle = "", colorTheme = "", graphic = {}, list = [], onNavClick }) => {
  const theme = useSelector((state) => state.theme.myTheme);
  // eslint-disable-next-line no-unused-vars
  const [fullPercent, setFullPercent] = useState(0);
  const [buttonTab, setButtonTab] = useState([
    {
      title: "Bulanan",
      value: "month",
      active: true,
    },
    {
      title: "Tahunan",
      value: "year",
    },
  ]);

  const tabClicked = (index) => {
    buttonTab.forEach((item, index) => {
      buttonTab[index] = {
        ...item,
        active: false,
      };
    });

    buttonTab[index] = {
      ...buttonTab[index],
      active: true,
    };

    onNavClick && onNavClick(buttonTab[index].value);
    setButtonTab([...buttonTab]);
  };

  const series = [
    {
      data: graphic.value ?? [],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: graphic.category ?? [],
    },
    colors: [colorTheme],
    yaxis: {},
    fill: {
      opacity: 1,
    },
  };

  const getPercent = (jumlah = 0) => {
    let sub = jumlah;
    let total = fullPercent === 0 ? graphic.value[graphic.value.length - 1] ?? 1 : fullPercent;

    let percent = parseFloat((sub / total) * 100);
    return percent;
  };

  return (
    <Card className="pa-3 rounded-lg">
      <div className="d-flex justify-space-between">
        <div className="leading-5">
          <h4>{title}</h4>
          <small>{subTitle}</small>
        </div>
        <div>
          <div style={{ backgroundColor: theme.tertiary }} className="rounded-lg d-flex">
            {buttonTab.map((item, index) => {
              return (
                <Button
                  key={index}
                  variant={item.active ? "contained" : ""}
                  style={{ color: item.active ? theme.white : theme.primary }}
                  className="capitalize rounded-lg"
                  onClick={() => tabClicked(index)}>
                  {item.title}
                </Button>
              );
            })}
          </div>
        </div>
      </div>
      <div id="chart mt-2">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
      <div className="my-3">
        <hr style={{ border: `1px solid ${theme.lighten}` }} />
      </div>
      <div>
        <div className="leading-5">
          <h4>{sectionTitle}</h4>
          <small>{sectionSubtitle}</small>
        </div>
      </div>
      <div className="mt-3">
        {list.map((item, index) => {
          return (
            <div key={index} className="d-flex gap-3 align-center mt-2">
              <Avatar src={item.image} variant="rounded" style={{ height: "50px", width: "50px" }} />
              <div className="grow">
                <h5>{item.judul}</h5>
                <div className="d-flex gap-2 align-center justify-space-between">
                  <div style={{ background: colorTheme, width: `${getPercent(item.jumlah ?? 0)}%` }} className="py-1 rounded-xl" />
                  <small>{item.jumlah}</small>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-4">
        <Button variant="contained" className="shadow-none capitalize full_width rounded-lg" style={{ background: theme.tertiary, color: theme.primary }}>
          Lihat Semua <KeyboardArrowDown />
        </Button>
      </div>
    </Card>
  );
};

export default GrafikColumnWithBar;
